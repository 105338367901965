import * as React from 'react';
import styled from 'styled-components';
import {Puff} from '@agney/react-loading';

export type SpinnerProps = {
  size: number
} & SpinnerStyle

type SpinnerStyle = {
  width?: string
  padding?: string
  color?: string
}

export function Spinner(props: SpinnerProps): JSX.Element {
  const {size = 24, color = '#1f87e5', width, padding} = props;
  return (
    <StyledContainer width={width} padding={padding}>
      <Puff color={color} height={size} />
    </StyledContainer>
  );
}

const StyledContainer = styled.div<SpinnerStyle>`
  display: flex;
  justify-content: center;
  align-content: center;
  width: ${(p) => p.width || 'unset'};
  padding: ${(p) => p.padding || 'unset'};
`;
