import {RoseMap} from '@types';
import {cleanFileName} from './helpers';

export const pdfToRoseMap = (dataObjects: any): RoseMap[] | undefined => {
  const tempArray: RoseMap[] = [];
  dataObjects.map((data: any, index: number) => {
    const fileName = cleanFileName(data.metas.file_name);
    const newMap: RoseMap = {
      type: 'map',
      code: `${fileName}-table${index + 1 }`,
      values: {
        columns: data.values.columns,
        data: data.values.data
      },
      warnings: []
    };
    tempArray.push(newMap);
  });

  return tempArray;
};
