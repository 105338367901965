import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ModalPropsWithChildren } from 'antd';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import Joyride, { CallBackProps, ACTIONS, EVENTS, STATUS, Step } from 'react-joyride';
import DashboardContainer from '@utils/state/dashboardContainer';
import { Elements } from '@stripe/react-stripe-js';
import { Marketplace } from '@components/dashboard/Marketplace';
import { NotebookContainer } from '@components/dashboard/Notebook';
import Settings from '@components/dashboard/Settings/Settings';
import Sharing from '@components/dashboard/Sharing/index';
import { stripePromise } from '@components/dashboard/Settings/SettingsContainer';
import { AdminPortal } from '@components/dashboard/AdminPortal/AdminPortal';
import { DashboardContainer as Container } from '@components/common/Containers';
import UserService from '@utils/apis/UserService';
import { useURLQueryParams } from '@hooks';
import { trackError } from '@utils/createSentry';
import UserContainer from '@utils/state/userContainer';
import { Modal } from '@components/common';
import { interactiveGuideSteps } from '@helpers';
import { Header } from './Header';
import { Discover } from './Discover';
import { isMobile } from 'react-device-detect';
import { TrialModal } from './TrialModal/TrialModal';

function Dashboard(): JSX.Element {
  const { dashboard } = DashboardContainer.useContainer();
  const params: any = useParams();
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [isAcceptingTerms, setIsAcceptingTerms] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const query = useURLQueryParams();
  const datasets = query.has('datasets') ? [query.get('datasets')] : [];
  const [run, setRun] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<Number>(0);
  const { user, setUser } = UserContainer.useContainer();
  const [welcomeVisible, setWelcomeVisible] = React.useState(true);
  const [askVisible, setAskVisible] = React.useState(true);



  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const optimisticallyUpdateIsAskRose = (isAskRose: boolean) => {
    setUser({ ...user, askrose: isAskRose });
  };

  useEffect(() => {
    UserService.checkTos()
      .then(() => setShowTerms(false))
      .catch((err) => {
        if (err.response.status === 402) {
          setShowTerms(true);
        }
      });
  }, []);

  const handleModalClose = () => {
    setWelcomeVisible(false);
  };

  const handleOnAgree = () => {
    setIsAcceptingTerms(true);
    // eslint-disable-next-line promise/catch-or-return
    UserService.signTos()
      .then(() => {
        setShowTerms(false);
        setTermsAccepted(true);
        setRun(true);
      })
      .catch((err) => {
        console.error('error signing terms of service: ', err);
        trackError(err);
        setTermsAccepted(false);
        setShowTerms(false);
      })
      .finally(() => setIsAcceptingTerms(false));
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type, step } = data;
    const nextStepStatus: string[] = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND];
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (nextStepStatus.includes(type)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));

      // check if step.target === '#ask-rose-button' and if user has access => action === ACTIONS.PREV ? -2 : 2
      // if (step.target === '#ask-rose-button' && userHasAccessToBetaFeatures(user) === false) {}
    } else if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  };
  
  return (
    <>
      <Container>
        <Header />
        {dashboard === 'notebook' &&
          <NotebookContainer
            code={params?.['*'] ?? ''}
            initialRoseCodes={datasets}
          >
          </NotebookContainer>
        }
        {dashboard === 'help' && <NotebookContainer code="rose.intro" />}
        {dashboard === 'marketplace' && (
          <div className="mt-8"> {/* Adds ~2rem of spacing to prevent overlap */}
            <Marketplace />
          </div>
        )}
        {dashboard === 'settings' && (
          <div className="mt-8"> {/* Adds ~2rem of spacing to prevent overlap */}
            <Settings />
          </div>
        )}
        {dashboard === 'admin' && <AdminPortal />}
        {dashboard === 'security' && <Sharing />}
        {dashboard === 'discover' && <Discover />}
      </Container>
      <Modal
        visible={showTerms}
        centered
        closable={false}
        title="Terms of Service"
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={isAcceptingTerms}
            onClick={handleOnAgree}
          >
            I Agree
          </Button>
        ]}
      >
        <span>
          {'By using the Rose Platform, you are adhering to the '}
          <Link to="/terms" target="blank_">
            Terms of Service.
          </Link>
        </span>
      </Modal>
    </>
  );

}

const StyledModal = styled<React.FC<ModalPropsWithChildren>>(Modal)`
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default Dashboard;
