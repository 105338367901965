import React, {useState, useEffect, useRef, useMemo} from 'react';
import styled from 'styled-components';
import MarkdownView from 'react-showdown';
import {IoTrash} from '@react-icons/all-files/io5/IoTrash';
import {IoAdd} from '@react-icons/all-files/io5/IoAdd';
import {IoCode} from '@react-icons/all-files/io5/IoCode';
import {AiOutlineColumnWidth} from '@react-icons/all-files/ai/AiOutlineColumnWidth';
import {AiOutlineAlignLeft} from '@react-icons/all-files/ai/AiOutlineAlignLeft';
import {AiOutlineAlignCenter} from '@react-icons/all-files/ai/AiOutlineAlignCenter';
import {ModuleLayout, ViewMode} from '@components/common/Layouts';
import {ThemeVarNames} from '@theme';
import {RoseModule, RoseModuleMarkdown} from '@types';
import {changeWidth} from './common/ChangeWidth';
import {OptionMenu} from './common/Base';
import {Input} from './common/Input';
import {HelperText} from './common/HelperText';
import {Options} from './common/Options';

const COLOR = '#50c878';

export type MarkdownProps = {
  mod: RoseModuleMarkdown
  onAddModule: (mod: Partial<RoseModule>) => void
  onRemoveModule: () => void
  onModuleChanged: (newMod: RoseModule) => void
  options: OptionMenu[]
  updateCurrentModKey: (modKey: string) => void
  isReadOnly: boolean
  autoFocus?: boolean
  view?: ViewMode
}

export function Markdown(props: MarkdownProps): JSX.Element {
  const {
    mod,
    onAddModule,
    onRemoveModule,
    onModuleChanged,
    options,
    updateCurrentModKey,
    isReadOnly,
    autoFocus,
    view
  } = props;
  const [mode, setMode] = useState<'edit' | 'read'>(() => isReadOnly || mod.runOnLoad ? 'read' : 'edit');
  const [value, setValue] = useState(mod.textBox);
  const [centerContent, setCenterContent] = useState(false);
  const markdownContainerRef = useRef(null);
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      if (mode === 'read') {
        markdownContainerRef.current.focus();
      }
    } else {
      isMounted.current = true;
    }
  }, [mode]);

  useEffect(() => {
    setValue(mod.textBox);
  }, [mod.textBox]);

  useEffect(() => {
    if (markdownContainerRef.current && autoFocus) {
      markdownContainerRef.current.focus();
    }
  }, [autoFocus]);

  const handleOnRun = () => {
    setMode('read');
    onModuleChanged({...mod, textBox: value});
  };

  function handleOnAddModule() {
    onAddModule(RoseModule.create({type: 'markdown'}));
  }

  const handleOnChange = (textBox: string) => {
    setValue(textBox);
  };

  const toggleAlignment = () => {
    setCenterContent(!centerContent)
  }

  function handleOnChangeWidthClick(width: '33%' | '50%' | '66%' | '100%') {
    return changeWidth({
      width,
      mod,
      textBox: value,
      onModuleChanged
    });
  }

  function onConvertToCode() {
    const roseModuleCode = RoseModule.toCode(mod, {textBox: value});
    onModuleChanged(roseModuleCode);
  }

  const mergedOptions: OptionMenu[] = [
    ...options,
    {
      title: 'Delete cell',
      icon: <IoTrash style={{marginRight: 10}} />,
      onClick: onRemoveModule
    },
    {
      title: 'Convert to code',
      icon: <IoCode style={{marginRight: 10}} />,
      onClick: onConvertToCode
    },
    {
      title: centerContent ? 'Left-align content' : 'Center content',
      icon: centerContent ? <AiOutlineAlignLeft style={{marginRight: 10}}/> : <AiOutlineAlignCenter style={{marginRight: 10}}/>,
      onClick: toggleAlignment,
    },
    {
      hidden: true,
      title: 'Width',
      icon: <AiOutlineColumnWidth style={{marginRight: 10}} />,
      options: [
        {
          title: '100%',
          onClick: handleOnChangeWidthClick('100%')
        },
        {
          title: '66%',
          onClick: handleOnChangeWidthClick('66%')
        },
        {
          title: '50%',
          onClick: handleOnChangeWidthClick('50%')
        },
        {
          title: '33%',
          onClick: handleOnChangeWidthClick('33%')
        }
      ]
    }
  ];

  useEffect(() => {
    if (view === 'view') {handleOnRun();}
  }, [view]);

  /**
   * Markdown does not render extra blank space unless explicitly told to.
   * So the idea is to give a re-formatted text block with `<br />` tags
   * where necessary, but without actually displaying them to the user.
   */
  function addBreaks(text: string) {
    const textWithBreaks = text.replace(/\n\n/gi, '\n<br />\n');
    return textWithBreaks;
  }

  /**
   * Memoized to avoid running regex on every render
   */
  const valueWithBreaks = useMemo(() => addBreaks(value), [value]);

  return (
    <ModuleLayout
      key={mod.key}
      width={RoseModule.getWidth(mod)}
      isReadOnly={isReadOnly}
      view={view}
    >
      <ModuleLayout.ToolbarTop />
      <ModuleLayout.Input dataArea={isReadOnly || view === 'view' ? 'input-allowed-viewonly' : null} id={mod.key}>
        {mode === 'edit' ?
          <Input
            value={value}
            onRun={handleOnRun}
            onChange={handleOnChange}
            color={COLOR}
            spellCheck
            autoFocus={autoFocus}
            currentMod={mod.key}
            updateCurrentModKey={updateCurrentModKey}
            customStyles={centerContent && { textAlign: 'center' }}
          /> :
          mode === 'read' ?
            <MarkdownContainer
              ref={markdownContainerRef}
              tabIndex={0}
              isReadOnly={isReadOnly}
              centerContent={centerContent}
              onClick={() => {
                if (!isReadOnly) {
                  if (view === 'edit') {
                    setMode('edit');
                  }
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !isReadOnly) {
                  e.preventDefault();
                  setMode('edit');
                }
              }}
            >
              <StyledMarkView
                markdown={valueWithBreaks}
                options={{
                  tables: true,
                  ghCodeBlocks: true,
                  tasklists: true,
                  emoji: true,
                  underline: true,
                  simpleLineBreaks: false,
                  strikethrough: true,
                  simplifiedAutoLink: true,
                  openLinksInNewWindow: true
                }}
              />
            </MarkdownContainer> :
            null}
      </ModuleLayout.Input>
      {isReadOnly || view === 'view' ? null :
        <ModuleLayout.Options>
          <OptionsContainer>
            <Options options={mergedOptions} color={COLOR} />
            <IconContainer onClick={handleOnAddModule}>
              <AddIcon color={COLOR} size={24} />
            </IconContainer>
          </OptionsContainer>
        </ModuleLayout.Options>
      }
      <ModuleLayout.ToolbarBottom>
        <ToolbarContainer>
          <HelperText />
        </ToolbarContainer>
      </ModuleLayout.ToolbarBottom>
    </ModuleLayout>
  );
}

const MarkdownContainer = styled.div<{ isReadOnly: boolean, centerContent: boolean }>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(p) => p.centerContent ? 'center' : 'flex-start'};
  text-align: ${(p) => p.centerContent ? 'center' : 'start'};
  padding: 8px;
  min-height: 80px;
  border-radius: 10px;

  :is(:hover, :focus) {
    cursor: ${(p) => p.isReadOnly ? 'auto' : 'pointer'};
    box-shadow: ${(p) => p.isReadOnly ? 'none' : '0 0 1px hsl(0 0% 60%)'};
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45px;
  position: relative;
`;

const IconContainer = styled.span`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const AddIcon = styled(IoAdd)`
  fill: ${(p: { color: string }) => p.color};

  :hover {
    cursor: pointer;
  }
`;
const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

const StyledMarkView = styled(MarkdownView)`
  font-size: 1.2rem;

  * {
    color: var(${ThemeVarNames.PrimaryText});
  }

  code {
    color: var(${ThemeVarNames.SecondaryText});
    white-space: pre-wrap;
  }

  img {
    max-width: 100%;
  }

  h1 { font-size: 2rem; }
  h2 { font-size: 1.8rem; }
  h3 { font-size: 1.6rem; }
  h4 { font-size: 1.4rem; }
  h5 { font-size: 1.2rem; }
  h6 { font-size: 1.1rem; }

  ul, ol { 
    display: block;
    list-style: disc inside;
  }

  ol { 
    list-style: decimal inside;
  }

  li {
    display: list-item;
  }

  ul ul, ol ul {
    list-style-type: circle;
    margin-left: 1em; 
  }

  ol ol, ul ol {
    list-style-type: lower-latin;
    margin-left: 1em; 
  }
`;
