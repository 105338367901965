import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import axios, {AxiosResponse, CancelTokenSource} from 'axios';
import {AiOutlineCheck} from '@react-icons/all-files/ai/AiOutlineCheck';
import {AiOutlineClose} from '@react-icons/all-files/ai/AiOutlineClose';
import {UploadOutlined} from '@ant-design/icons';
import {Alert, Button, Collapse, Form, Input, Spinner, Typography} from '@components/common';
import {checkType, toRoseMap} from '@utils/helpers/csv';
import {RoseMap} from '@types';
import ObjectService from '@utils/apis/ObjectService';
import UserContainer from '@utils/state/userContainer';
import {pdfToRoseMap} from '@utils/helpers/pdf';
import FileService from '@utils/apis/FileService';
import {MapTable} from '../../../Result/MapTable';
import {Upload} from '../../../../../common/DataEntry/Upload';

const {Panel} = Collapse;

export type ImportTabProps = {
  type?: string,
  resize: boolean,
  setResize: (prop: boolean) => void,
  onClose: () => void
};
interface UpdatedRoseMap extends RoseMap {
  isOverwrite: boolean;
  result: AxiosResponse | string;
  status: string;
}

type ModifiedRoseMap = UpdatedRoseMap | undefined

export const ImportTab = ({resize, onClose, setResize}: ImportTabProps): JSX.Element => {
  const {user} = UserContainer.useContainer();
  const [form] = Form.useForm();
  const [files, setFiles] = useState<ModifiedRoseMap[]>();
  const [nameError, setNameError] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const cancelTokenSourceRef = useRef<CancelTokenSource>(null);
  const [fileRaw, setFileRaw] = useState<File | null>(null);

  const handleUpload = () => {
    cancelTokenSourceRef.current?.cancel?.();
    cancelTokenSourceRef.current = axios.CancelToken.source();
    const reader = new FileReader();
    const pdfFileFormat = new FormData();
    const fileArray: ModifiedRoseMap[] = [];

    setIsLoading(true);
    setUploadError(null);

    reader.onload = async () => {
      if (fileRaw.type === 'text/csv') {
        fileArray.push(toRoseMap(reader.result as string, fileRaw.name) as UpdatedRoseMap);
        setFiles(fileArray);
        setIsLoading(false);
        setResize(true);
      } else if (fileRaw.type === 'application/pdf') {
        try {
          pdfFileFormat.append('file', fileRaw);
          const response = await FileService.formatPdfToMap(pdfFileFormat, cancelTokenSourceRef.current.token);
          if (response.length === 0) {
            setUploadError('We could not process your document, please try again with a different file.');
            setFileRaw(null);
            setIsLoading(false);
          } else if (response.length !== 0) {
            const tempArray = pdfToRoseMap(response.objects) as UpdatedRoseMap[];
            setFiles(tempArray);
            setResize(true);
          }

          setIsLoading(false);
        } catch (err) {
          setUploadError(err.message);
          setIsLoading(false);
        }
      }
    };

    reader.readAsText(fileRaw);
  };

  const onCancel = () => {
    if (isLoading) {
      cancelTokenSourceRef.current?.cancel?.();
    } else {
      onClose();
    }
  };

  const handleInputChange = async (e: any, index: number) => {
    const updatedFiles = _.cloneDeep(files);
    updatedFiles[index as number].code = e.target.value.toLowerCase();
    setFiles(updatedFiles);
  };

  const checkNames = (files: UpdatedRoseMap[]) => {
    const valueArr = files.map((file) => file.code);
    return valueArr.some((item, idx) => valueArr.indexOf(item) !== idx);
  };

  const initialOnSubmit = async (files: UpdatedRoseMap[]) => {
    setIsLoading(true);
    try {
      const results = await Promise.all(
        files.map((file) => ObjectService.pushNew(
          file.code,
          [file.values.columns, ...file.values.data],
          file.values.columns, user, file.isOverwrite)
        )
      );

      const updatedFiles = _.cloneDeep(files);
      setFiles(updatedFiles.map((file, index) => ({
        ...file,
        result: results[index as number],
        status: checkType(results[index as number])
      })));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onSubmit = async (files: UpdatedRoseMap[]) => {
    if (checkNames(files)) {
      setNameError(true);
    } else {
      setNameError(false);
      setIsLoading(true);

      try {
        const successFiles = files.filter((file) => isSuccess(file));
        const unsuccessFiles = files.filter((file) => !isSuccess(file));
        const results = await Promise.all(
          unsuccessFiles.map((file) => ObjectService.pushNew(
            file.code,
            [file.values.columns, ...file.values.data],
            file.values.columns, user, file.isOverwrite)
          )
        );

        const updatedFiles = _.cloneDeep(unsuccessFiles);
        const formattedFiles = updatedFiles.map((file, index) => ({
          ...file,
          result: results[index as number],
          status: checkType(results[index as number])
        }));

        const mergedFiles = formattedFiles.concat(successFiles);
        setFiles(mergedFiles);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const handleOnDone = () => {
    setFiles([]);
    onClose();
  };

  const buttonLayoutType = (file: UpdatedRoseMap, index: number) => {
    if (file.status === 'Success') {
      return (
        <AiOutlineCheck style={{height: '75%', marginLeft: 10, color: 'green', fontSize: '1.5em'}} />
      );
    } else if (file.status === 'rose-object-exist-by-another-user') {
      return (
        <StyledInlineButtonContainer>
          <AiOutlineClose style={{height: '75%', marginLeft: 10, color: 'red', fontSize: '1.5em'}} />
        </StyledInlineButtonContainer>
      );
    } else if (file.status === 'user-has-the-rose-object') {
      return (
        <StyledInlineButtonContainer>
          <AiOutlineClose style={{height: '75%', marginLeft: 10, color: 'red', fontSize: '1.5em'}} />
          <Button onClick={() => {
            const updatedFiles = _.cloneDeep(files);
            updatedFiles[index as number].isOverwrite = true;
            updatedFiles[index as number].status = null;
            setFiles(updatedFiles);
          }}>
            Overwrite
          </Button>
        </StyledInlineButtonContainer>
      );
    }
  };

  const errorHandlingMessage = (file: UpdatedRoseMap) => {
    if (file.status === 'user-has-the-rose-object') {
      return (
        <Alert
          type="error"
          message="Current rosecode is owned by you, press `Overwrite` if you wish to overwrite data or rename"
          style={{textAlign: 'center', marginBottom: '24px', padding: '4px'}}
        />
      );
    } else if (file.status === 'rose-object-exist-by-another-user') {
      return (
        <Alert
          type="error"
          message="Rosecode exists and cannot be overwritten"
          style={{textAlign: 'center', marginBottom: '24px', padding: '4px'}}
        />
      );
    }

    return null;
  };

  // Add Data Component
  // ---------------------------------------------
  const addTable =
    <StyledContent>
      <Alert
        message=""
        description="Files supported: PDF and CSV. Recommended maximum file size: 1MB"
        type="info"
        showIcon
        style={{marginBottom: '25px'}}
      />
      <Upload
        accept=".pdf, .csv"
        maxCount={1}
        beforeUpload={(file: any) => {
          setFileRaw(file);
          return false;
        }}
        onRemove={() => { setFileRaw(null); }}
      >
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload>
      {uploadError &&
        <Alert
          type="error"
          message={uploadError}
          style={{textAlign: 'center', marginTop: '10px'}} />}
      <RightContainerButtons>
        <Button type="ghost" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={() => handleUpload()} disabled={!fileRaw} loading={isLoading}>
          {isLoading ? 'Formatting' : 'Submit'}
        </Button>
      </RightContainerButtons>
      <StyledFooterContainer>
        <Typography as="p" variant="primary">
          <strong>Need Help?</strong>
        </Typography>
        <Collapse accordion>
          <Panel header="How to Import CSV/PDF as a map" key="1">
            <ol style={{marginBottom: '0px', listStyleType: 'decimal'}}>
              <li>Click Select File</li>
              <li>Choose your CSV or PDF from your computer’s file explorer</li>
              <li>Click Submit</li>
              <li>
                {/* eslint-disable-next-line max-len */}
                You will see a preview of the data and the rosecode generated from the filename (Rose will automatically convert the file name to lowercase).
              </li>
              <li>Optional: rename your rosecode</li>
              <li>Click Done</li>
            </ol>
          </Panel>
          <Panel header="Unsupported filetypes" key="2">
            <p style={{marginBottom: '0px', paddingLeft: '10px'}}>CSV</p>
            <ul style={{marginBottom: '0px'}}>
              <li>XLSX CSVs with timeseries data running horizontally
                <ul style={{listStyle: 'none'}}>
                  <li>Ex. CSVs downloaded from the World Bank </li>
                </ul>
              </li>
            </ul>
            <br />
            <section>
              <p style={{marginBottom: '0px', paddingLeft: '10px'}}>PDF</p>
              <ul style={{marginBottom: '0px'}}>
                <li>Handwritten tables</li>
                <li>Table with partial or missing borderlines</li>
                <li>Non-table images</li>
              </ul>
            </section>
          </Panel>
          <Panel header="Overwriting Rosecodes" key="3">
            <p style={{marginBottom: '0px'}}>
              When importing a CSV/PDF, you may see a warning message that the rosecode already exists.
              You can either re-name or overwrite the rosecode. Note, overwriting will only replace
              the data for rosecode that you own.
            </p>
          </Panel>
          <Panel header="Additional notes" key="4">
            <p style={{marginBottom: '0px'}}>
              Some CSVs have a blank cell for the first value. Rose will fill in that cell automatically,
              allowing the user to upload.
            </p>
          </Panel>
        </Collapse>
      </StyledFooterContainer>
    </StyledContent>
    ;

  // Preview Component
  // ---------------------------------------------
  const previewTable = () =>
    <StyledContent>
      {files ?
        <Form
          layout="horizontal"
          form={form}
          onFinish={() => {
            if (files[0].status || files[0].isOverwrite) {
              onSubmit(files);
            } else {
              initialOnSubmit(files);
            }
          }}
        >
          <StyledList>
            {files.map((file, index) =>
              <li key={index}>
                <StyledInline>
                  <Form.Item
                    label="Rose Code Name"
                    name={`rosecode ${index}`}
                    style={{width: '100%', marginBottom: '10px'}}
                  >
                    <Input
                      allowClear
                      size="middle"
                      placeholder="Set rosecode"
                      onChange={(e) => handleInputChange(e, index)}
                      defaultValue={file.code.toLowerCase()}
                      id={`rosecode-input-${index}`}
                      style={{textTransform: 'lowercase', color: '#1890ff'}}
                    />
                  </Form.Item>
                  {file.result ? buttonLayoutType(file, index) : null}
                </StyledInline>
                {!nameError ? file.result ? errorHandlingMessage(file) : null : null}
                {!file.result ? <MapTable roseObject={file} /> : null}
              </li>
            )}
          </StyledList>

          {nameError ?
            <Alert
              type="error"
              message="Two or more input fields have the same rose code name"
              style={{textAlign: 'center'}}
            /> : null
          }
          {isLoading ? <Spinner size={36} /> : null}
          <Form.Item>
            <RightContainerButtons>
              {files.every(isSuccess) ?
                <Button type="primary" onClick={handleOnDone}>Done</Button> :
                <>
                  <Button type="ghost" onClick={() => setResize(false)}>Back</Button>
                  <Button htmlType="submit" disabled={isLoading}>Submit</Button>
                </>
              }
            </RightContainerButtons>
          </Form.Item>
        </Form> :
        null
      }
    </StyledContent>
    ;

  return (
    <>
      {resize ? previewTable() : addTable}
    </>
  );
};

const RightContainerButtons = styled.div`
  padding-top: 20px;
  justify-content: flex-end;
  display: flex;
  flex-direction: 'row';
  gap: 10px;
`;

const StyledContent = styled.div`
  margin-top: 25px;
`;

const StyledList = styled.ul`
  list-style-type: none;
`;

const StyledInline = styled.div`
  display: flex;
  width: 100%;
`;

const StyledInlineButtonContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  gap: 10px;
`;

const StyledFooterContainer = styled.div`
  padding-top: 40px;
`;

// HELPERS
const isSuccess = (file: UpdatedRoseMap) => file.status === 'Success';
