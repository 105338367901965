/* eslint-disable complexity */
import React, {useState, useEffect, ReactNode} from 'react';
import styled from 'styled-components';
import {Layout, Spin, Tag} from 'antd';
import {isEmpty} from 'lodash';
import {SearchOutlined} from '@ant-design/icons';
import {useGenericDatasets} from '@service/useGenericDataset';
import {Button, Input, Pagination, Table} from '@components/common';
import ShareDatasetModal from './Modals/ShareDatasetModal';
import ShareAllModal from './Modals/ShareAllModal';
import ShareLogicModal from './Modals/ShareLogicModal';
import ShareNotebookModal from './Modals/ShareNotebookModal';
import ShareSearchResultsModal from './Modals/ShareSearchResultsModal';
import ShareQueryModal from './Modals/ShareQueryModal';
import RevokeObjectModal from './Modals/RevokeObjectModal';
import RevokeAllModal from './Modals/RevokeAllModal';
import RevokeSearchResultsModal from './Modals/RevokeSearchResultsModal';

const {Content} = Layout;

const StyledContent = styled(Content)`
  background-color: inherit;
`;

const Form = styled.form`
  margin: 0;
`;

const PageInfo = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ResultsAndButton = styled.div`
  display: inline-flex;
  margin-bottom: 10px;

  button {
    margin-left: 10px;
  }
`;
const StyledButton = styled(Button)`
  margin-right: 10px;
`;
const StyledPagination = styled(Pagination)`
  margin-bottom: 10px;
`;
const StyledTag = styled(Tag)`
  margin-top: 10px;
`;

const Results = styled.span`
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const StyledSpin = styled(Spin)`
  width: 100%;
  padding-top: 100px;
  height: 200px;
`;

type GenericDatasetProps = {
  organization: any
  cacheType: string
  children?: ReactNode
  updateShareAndRevokeLists: () => void
}

export default ({organization, cacheType, updateShareAndRevokeLists}: GenericDatasetProps): JSX.Element => {
  const [inputValue, setInputValue] = useState('');
  const [searchResultsCount, setSearchResultsCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedSearchKeywords, setSelectedSearchKeywords] = useState([]);
  const [objForModal, setObjForModal] = useState(null);
  const [isGenericAllModalVisible, setIsGenericAllModalVisible] = useState(false);
  const [
    isGenericSearchResultsModalVisible,
    setIsGenericSearchResultsModalVisible
  ] = useState(false);

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handlePageChange = (page: number) => {
    setPageNumber(page - 1);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    if (inputValue.length) {
      setSelectedSearchKeywords(selectedSearchKeywords.concat([inputValue]));
    }

    setInputValue('');
  };

  const result = useGenericDatasets(
    organization.id,
    selectedSearchKeywords,
    pageNumber,
    cacheType,
    !isEmpty(selectedSearchKeywords) || cacheType === 'to-revoke'
  );

  const {objects, length: resultCount} = result?.data?.data || {};

  const searchResults =
    objects?.map((obj: any) => ({
      key: obj.code,
      code: obj.code,
      type: obj.type,
      owner: obj.actor.code,
      button: obj
    })) || [];

  useEffect(() => {
    setPageNumber(0);
  }, [selectedSearchKeywords]);

  useEffect(() => {
    if (pageNumber === 0) {
      setSearchResultsCount(resultCount);
    }
  }, [pageNumber, resultCount]);

  const handleRemoveTag = (keyword: string) => {
    setSelectedSearchKeywords(
      selectedSearchKeywords.filter((k) => k !== keyword)
    );
  };

  const openGenericModal = (dataset: any) => {
    setObjForModal(dataset);
  };

  const tableColumns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner'
    },
    {
      title: '',
      dataIndex: 'button',
      key: 'button',
      align: 'right' as const,
      fixed: 'right' as const,
      // eslint-disable-next-line react/display-name
      render: (dataset: any) =>
        <Button
          id={cacheType === 'to-share' ? 'share' : 'revoke'}
          onClick={() => openGenericModal(dataset)}
        >
          {cacheType === 'to-share' ? 'Share' : 'Revoke'}
        </Button>
    }
  ];

  const resultsText = searchResultsCount ?
    `Results ${pageNumber * 10 + 1} - ${
      searchResultsCount > pageNumber * 10 + 10 ?
        pageNumber * 10 + 10 :
        searchResultsCount
    } of ${searchResultsCount}` :
    'Add search keywords to start search';

  const modals = (cacheType: string) => {
    if (cacheType === 'to-share') {
      switch (true) {
      case objForModal?.type === 'notebook': {
        return (
          <ShareNotebookModal
            isVisible
            closeModal={() => setObjForModal(null)}
            object={objForModal}
            organization={organization}
            onShareSuccess={() => updateShareAndRevokeLists()}
          />
        );
      }

      case objForModal?.type === 'timeseries' ||
        objForModal?.type === 'map' ||
        objForModal?.type === 'connection' ||
        objForModal?.type === 'image' ||
        objForModal?.type === 'file': {
        return (
          <ShareDatasetModal
            isVisible
            closeModal={() => setObjForModal(null)}
            object={objForModal}
            organization={organization}
            onShareSuccess={() => updateShareAndRevokeLists()}
          />
        );
      }

      case objForModal?.type === 'logic': {
        return (
          <ShareLogicModal
            isVisible
            closeModal={() => setObjForModal(null)}
            object={objForModal}
            organization={organization}
            onShareSuccess={() => updateShareAndRevokeLists()}
          />
        );
      }

      case objForModal?.type === 'query': {
        return (
          <ShareQueryModal
            isVisible
            closeModal={() => setObjForModal(null)}
            object={objForModal}
            organization={organization}
            onShareSuccess={() => updateShareAndRevokeLists()}
          />
        );
      }

      case isGenericAllModalVisible: {
        return (
          <ShareAllModal
            isVisible
            closeModal={() => setIsGenericAllModalVisible(false)}
            object={[]}
            organization={organization}
            onShareSuccess={() => updateShareAndRevokeLists()}
          />
        );
      }

      case isGenericSearchResultsModalVisible: {
        return (
          <ShareSearchResultsModal
            isVisible
            closeModal={() => setIsGenericSearchResultsModalVisible(false)}
            object={selectedSearchKeywords}
            organization={organization}
            onShareSuccess={() => updateShareAndRevokeLists()}
          />
        );
      }

      default:
        return null;
      }
    } else if (cacheType === 'to-revoke') {
      switch (true) {
      case !isEmpty(objForModal): {
        return (
          <RevokeObjectModal
            isVisible
            closeModal={() => setObjForModal(null)}
            object={objForModal}
            organization={organization}
            onRevokeSuccess={() => updateShareAndRevokeLists()}
          />
        );
      }

      case isGenericAllModalVisible: {
        return (
          <RevokeAllModal
            isVisible
            closeModal={() => setIsGenericAllModalVisible(false)}
            object={[]}
            organization={organization}
            onRevokeSuccess={() => updateShareAndRevokeLists()}
          />
        );
      }

      case isGenericSearchResultsModalVisible: {
        return (
          <RevokeSearchResultsModal
            isVisible
            closeModal={() => setIsGenericSearchResultsModalVisible(false)}
            object={selectedSearchKeywords}
            organization={organization}
            onRevokeSuccess={() => updateShareAndRevokeLists()}
          />
        );
      }

      default:
        return null;
      }
    }
  };

  return (
    <StyledContent>
      <Form onSubmit={handleSearch}>
        <Input
          allowClear
          size="large"
          prefix={<SearchOutlined />}
          placeholder="Search"
          value={inputValue}
          onChange={handleInputChange}
          id="input-search"
        />
      </Form>
      {selectedSearchKeywords.map((keyword) =>
        <StyledTag
          key={keyword}
          closable
          onClose={() => handleRemoveTag(keyword)}
        >
          {keyword}
        </StyledTag>
      )}
      {!result.isFetching ?
        <PageInfo>
          <ResultsAndButton>
            <Results>{resultsText}</Results>
            {searchResultsCount ?
              selectedSearchKeywords.length ?
                <StyledButton
                  id={cacheType === 'to-share' ? 'share-search-results' : 'revoke-search-results'}
                  onClick={() => setIsGenericSearchResultsModalVisible(true)}
                >
                  {cacheType === 'to-share' ? 'Share Search Results' : 'Revoke Search Results'}
                </StyledButton> :
                <StyledButton
                  id={cacheType === 'to-share' ? 'share-all' : 'revoke-all'}
                  onClick={() => setIsGenericAllModalVisible(true)}
                >
                  {cacheType === 'to-share' ? 'Share All' : 'Revoke All'}
                </StyledButton> :

              null}
          </ResultsAndButton>
          {searchResultsCount ?
            <StyledPagination
              onChange={handlePageChange}
              total={searchResultsCount}
              current={pageNumber + 1}
              showSizeChanger={false}
            /> :
            null}
        </PageInfo> :
        null}
      {!result.isFetching ?
        searchResultsCount ?
          <Table
            columns={tableColumns}
            dataSource={searchResults}
            pagination={false}
            scroll={{x: 400}}
          /> :
          null :

        <StyledSpin size="large" />
      }
      {modals(cacheType)}
    </StyledContent>
  );
};
