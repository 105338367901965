import React, {useState} from 'react';
import styled from 'styled-components';
import {Drawer, Tabs} from '@components/common';
import {RoseModule} from '@types';
import {UploadTab} from './tabs/UploadTab';
import {ImportTab} from './tabs/ImportTab';

const {TabPane} = Tabs;

export type UploadImportDrawerProps = {
    visible: boolean;
    children: React.ReactNode;
    setVisible: any;
    onAddModule: (partialMod: Partial<RoseModule>) => void
  };

export const UploadImportDrawer = (props: UploadImportDrawerProps): JSX.Element => {
  const {visible, setVisible, onAddModule, children} = props;
  const [resize, setResize] = useState(false);

  return (
    <StyledDrawer
      placement="left"
      closable
      onClose={() => {
        setVisible(false);
      }}
      visible={visible}
      contentWrapperStyle={{
        width: '100%',
        maxWidth: resize ? '1000px' : '600px',
        position: 'absolute'
      }}
      getContainer={false}
    >
      <StyledContainer>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Import data from CSV/PDF" key="1">
            <ImportTab onClose={setVisible} resize={resize} setResize={setResize}/>
          </TabPane>
          <TabPane tab="Upload Files/Images" key="2">
            <UploadTab onAddModule={onAddModule} onClose={setVisible}/>
          </TabPane>
        </Tabs>
      </StyledContainer>
    </StyledDrawer>);
};

const StyledDrawer = styled(Drawer)`
.ant-drawer-body {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-bottom: 0;
}

.ant-tabs {
  width: 100%;
}

.ant-tabs-content-holder {
  overflow: auto;
}
`;

const StyledContainer = styled.div`
  position: relative;
  align-items: center;
  justify-content: left;
  padding: 24px 56px 0px 56px;
  clear: both;
  width: 100%;
`;
