import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {List} from 'antd';
import {Elements} from '@stripe/react-stripe-js';

import ActorService from '@utils/apis/ActorService';
import HelmetWrapper from '@utils/HelmetWrapper';
import {Alert} from '@components/common';

import {
  ModalState,
  StyledListItem,
  CreditCardCard,
  CardsContainer,
  stripePromise,
  CheckoutForm
} from '../SettingsContainer';
import CreditCardModal from './CreditCardModal';

export function PaymentMethodsSettings({ creditCardInfo, setCreditCardInfo }: any): JSX.Element {
  const [creditCardNumberInput, setCreditCardNumberInput] = useState(creditCardInfo ? `************${creditCardInfo.last4}` : '');
  const [expirationDateInput, setExpirationDateInput] = useState(creditCardInfo ? `${creditCardInfo.expMonth}/${creditCardInfo.expYear}` : '');
  const [cvcNumberInput, setCvcNumberInput] = useState(creditCardInfo ? '***' : '');
  const [zipcodeInput, setZipcodeInput] = useState(creditCardInfo ?  creditCardInfo.addressZip: '');
  const [errorMessage, setErrorMessage] = useState('');
  const [creditCardModalState, setCreditCardModalState] = useState(
    ModalState.Hidden
  );
  const [isLoading, setIsLoading] = useState(true);

  const removeCreditCard = async () => {
    try {
      setCreditCardModalState(ModalState.Loading);
      await ActorService.removeCC();
      setCreditCardModalState(ModalState.Success);
      setCreditCardNumberInput('');
      setExpirationDateInput('');
      setCvcNumberInput('');
      setZipcodeInput('');
      setCreditCardInfo(null); // Update the parent component's state
    } catch (e) {
      setErrorMessage(e.data.message);
      setCreditCardModalState(ModalState.Error);
    }
  };

  const updateCreditCardInfo = (data: any) => {
    setCreditCardNumberInput(`************${data.last4}`);
    setExpirationDateInput(`${data.expMonth}/${data.expYear}`);
    setCvcNumberInput('***');
    setZipcodeInput(data.addressZip);
    setCreditCardInfo(data); // Store the info in parent component's state
  };


  useEffect(() => {
    if (creditCardInfo) {
      setCreditCardNumberInput(`************${creditCardInfo.last4}`);
      setExpirationDateInput(`${creditCardInfo.exp_month}/${creditCardInfo.exp_year}`);
      setCvcNumberInput('***');
      setZipcodeInput(creditCardInfo.addressZip);
      setIsLoading(false);
    }
  }, [creditCardInfo]);

  const creditCardListDataSource = [
    {
      label: 'Card Number',
      value: creditCardNumberInput
    },
    {
      label: 'Expiration Date',
      value: expirationDateInput
    },
    {
      label: 'CVC Number',
      value: cvcNumberInput
    },
    {
      label: 'Zipcode',
      value: zipcodeInput
    }
  ];

  const elementsProps = {
    stripe: stripePromise,
    options: {
      style: {
        base: {
          paddingBottom: '10px'
        }
      }
    }
  };

  const AlertWithMargin = styled(Alert)`
    margin-top: 20px;
  `;

  return (
    <div>
      <HelmetWrapper title="Profile" description="User Profile" />
      <CardsContainer>
        <CreditCardCard title="Payment Method" id="creditCard">
          <>
            {creditCardNumberInput !== '' ? (
              <List
                size="large"
                renderItem={(item: any) => (
                  <StyledListItem>
                    <span data-id={item.label}>
                      <b>{item.label}</b>
                    </span>
                    <span data-id="value">{item.value}</span>
                  </StyledListItem>
                )}
                dataSource={creditCardListDataSource}
              />
            ) : (
              <AlertWithMargin
                type="error"
                message="A credit card is required for using paid plan of Ask Rose or purchasing data packages in Marketplace."
                style={{textAlign: 'center'}}
              />
            )}
            <Elements {...elementsProps}>
              <CheckoutForm
                onRemoveCreditCard={removeCreditCard}
                getCreditCardInfo={updateCreditCardInfo}
              />
            </Elements>
          </>
        </CreditCardCard>
      </CardsContainer>
      <CreditCardModal
        state={creditCardModalState}
        onClose={() => setCreditCardModalState(ModalState.Hidden)}
        errorMessage={errorMessage}
      />
    </div>
  );
}
