import {RoseWarning} from './RoseWarning';

export type RoseMap = {
  type: 'map'
  code?: string
  actor?: string
  actorId?: number
  metas?: Record<string, any>
  values: {
    columns: string[]
    data: any[]
    index?: any[]
  }
  warnings: RoseWarning
}

export const RoseMap = {
  deserialize(data: any): RoseMap {
    return {
      type: 'map',
      code: data?.code,
      actor: data?.actor,
      actorId: data?.actor_id,
      metas: data?.metas,
      values: {
        columns: data.values.columns,
        data: data.values.data,
        index: data.values?.index
      },
      warnings: RoseWarning.deserialize(data.metas.warning)
    };
  },
  isMap(data: any): boolean {
    return data.type === 'map';
  }
};
