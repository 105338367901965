import {RoseUser} from '@types';

export enum RoseEnv {
  Workstation = 'workstation',
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export const getHostname = (): string =>
  typeof window !== 'undefined' ? window.location.hostname : 'localhost';

export const getEnvironment = (): RoseEnv => {
  const hostname = getHostname();
  if (
    !hostname.includes('rose.ai') ||
    hostname.includes('local.') ||
    hostname.includes('localhost')
  ) {
    return RoseEnv.Workstation;
  }

  if (hostname.includes('dev.')) {
    return RoseEnv.Development;
  }

  if (hostname.includes('staging.')) {
    return RoseEnv.Staging;
  }

  if (hostname.includes('rose.ai') && hostname.split('.').length === 2) {
    return RoseEnv.Production;
  }

  return RoseEnv.Workstation;
};

export const isTracking = (): boolean =>
  getEnvironment() !== RoseEnv.Workstation;

const PERMITTED = [
  'rose',
  'snow',
  'snowinterns'
];

export const isRoseEmployee = (user: RoseUser): boolean =>
  PERMITTED.indexOf(user?.actor?.code) > -1;
