import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {isTracking, getEnvironment, RoseEnv} from '@utils/platform';

const IS_TRACKING = isTracking();
const ENVIRONMENT = getEnvironment();

if (IS_TRACKING) {
  Sentry.init({
    dsn:
      'https://a2e428e2ea6443f8a51d4dd176918bef@o1001520.ingest.sentry.io/6079758',
    environment: ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: ENVIRONMENT === RoseEnv.Production ? 0.25 : 1
  });
} else {
  console.info('Sentry Disabled on Workstation');
}

export const trackError = (
  error: Error | string | unknown,
  isFatal = true
): void => {
  if (IS_TRACKING) {
    if (isFatal) {
      Sentry.captureException(error, {level: 'fatal'});
    } else {
      Sentry.captureException(error, {level: 'error'});
    }
  } else {
    console.info(
      `trackError[${isFatal ? 'fatal' : 'error'}]`,
      error
    );
  }
};
