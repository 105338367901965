import * as React from 'react';

import { createGlobalStyle } from 'styled-components';

import { Theme, ThemeVarNames, themesMap } from './types';
import { useTheme } from './useTheme';

export function ThemeAndOverriders(): JSX.Element {
  const theme = useTheme();
  const css = Theme.createCss(theme);
  // console.log(theme, css.split('\n'));
  const optionalColorScheme = theme === 'dark' ? 'color-scheme: dark;' : '';
  /**
   * The override of `--plot-background` in `svg [aria-label=tip]` is related to this conversation: https://github.com/observablehq/plot/issues/2080 https://rosetechnology.atlassian.net/browse/ROSE-2340
   */
  const ThemeGlobalStyle = createGlobalStyle`
    :root {
      /* stylelint-disable */
      ${optionalColorScheme}
      ${css}
      /* stylelint-enable */
    }
    svg [aria-label=tip] {
      --plot-background: ${themesMap[theme][ThemeVarNames.PlotBackground]};
    }
    `;

  return (
    <>
      <ThemeGlobalStyle />
      <AntdStyleOverriders />
    </>
  );
}


const AntdStyleOverriders = createGlobalStyle`
  .ant-popconfirm {
    .anticon-exclamation-circle {
      color: var(${ThemeVarNames.Warning});
    }

    .ant-popover-message-title {
      color: var(${ThemeVarNames.PrimaryText});
    }
  }

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    padding: 0;
    background-color: var(${ThemeVarNames.TertiaryBg});

    .ant-popover-message,
    .ant-popover-buttons {
      padding: 12px;
    }
  }

  .ant-dropdown-arrow {
    &&& {
      border-color: var(${ThemeVarNames.TertiaryBg});
      box-shadow: var(${ThemeVarNames.Shadow});
    }
  }

  .ant-dropdown-menu,
  .ant-menu-submenu-popup .ant-menu {
    background-color: var(${ThemeVarNames.TertiaryBg});
  }

  .ant-menu-submenu-popup .ant-menu-item *,
  .ant-menu-submenu-popup .ant-menu-submenu *,
  .ant-menu-submenu-popup .ant-menu-title-content * {
    color: var(${ThemeVarNames.PrimaryText});
  }

  .ant-menu-submenu-popup .ant-menu-item:hover,
  .ant-menu-submenu-popup .ant-menu-submenu:hover,
  .ant-menu-submenu-popup .ant-dropdown-menu-item:hover {
    background-color: var(${ThemeVarNames.PrimaryBg});
  }

  .ant-menu {
    background-color: var(${ThemeVarNames.TertiaryBg});
    border: none;
  }

  .ant-menu-item-divider {
    &&& {
      background-color: var(${ThemeVarNames.PrimaryBg});
    }
  }

  .ant-menu-item *,
  .ant-menu-submenu *,
  .ant-menu-title-content * {
    color: var(${ThemeVarNames.PrimaryText});
  }

  .ant-menu-item:hover,
  .ant-menu-submenu:hover,
  .ant-dropdown-menu-item:hover,
  .ant-menu-item-selected {
    &&& {
      background-color: var(${ThemeVarNames.Hover});
    }
  }

  .ant-menu-item:hover *,
  .ant-menu-submenu:hover *,
  .ant-dropdown-menu-item:hover * {
    &&& {
      color: var(${ThemeVarNames.SecondaryText});
    }
  }

  .ant-popover {
    .ant-menu-submenu-title {
      width: 100%;
    }
  }

  .ant-menu-item {
    img {
      max-width: 24px;
    }
  }

  .ant-select-dropdown {
    color: var(${ThemeVarNames.PrimaryText});
    background-color: var(${ThemeVarNames.TertiaryBg});
    box-shadow: var(${ThemeVarNames.Shadow});

    .ant-select-item {
      color: var(${ThemeVarNames.PrimaryText});

      &.ant-select-item-option-active {
        background-color: var(${ThemeVarNames.Hover});
      }
    }
  }

}
`;
