import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Progress} from 'antd';

export function RoseLogoWithProgressBar(): JSX.Element {
  const [loaded, setLoaded] = useState(0);
  useEffect(() => {
    setLoaded(100);
  }, []);

  return (
    <LoadingContainer>
      <LoadingWrapper>
        <img
          src="/img/new_logo/svg/Roselogo-gradient.svg"
          alt="text"
          style={{marginBottom: 20}}
          height={48}
          width={48}
        />
        <Progress strokeColor="#7D57F4" percent={loaded} showInfo={false} />
      </LoadingWrapper>
    </LoadingContainer>
  );
}

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8fafc;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px;
  min-width: 200px;
`;
