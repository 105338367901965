import React from 'react';
import { Typography } from '@components/common';
import { RoseUser } from '../../../types/RoseUser';
import { isMobile } from 'react-device-detect';


type ConfirmModalProps = {
  user: RoseUser
}

export default function ConfirmModal({ user }: ConfirmModalProps) {
  return (
    <>
      <Typography size="large">Welcome to Rose Pro, {user?.firstName || user.email}!</Typography>
      <br />
      <Typography size="medium"> {isMobile
        ? "After you close this message, the page will refresh and the Ask Rose feature will be available."
        : "Start asking a question and getting a chart by clicking the rose logo on the left toolbar."}</Typography>
    </>
  );
}
