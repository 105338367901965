import React from "react";
import {PopupButton} from '@typeform/embed-react';

const HeroSection = () => {
  return (
    <div
      className="text-white"
      style={{ 
        background: 'url("/img/solutions-hero-background.png")',
        backgroundPosition: "center top",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className="pt-52 container px-3 md:px-5 mx-auto">
        <div className="flex gap-40 lg:gap-10 flex-col lg:flex-row items-center lg:items-start">
          <div className="flex flex-col gap-5 items-center lg:items-start max-w-[540px] order-2 lg:order-1">
            <div className="text-[48px] md:text-[64px] font-semibold text-center lg:text-left leading-tight">
              Institutional Solutions
            </div>
            <div className="text-[20px] md:text-[24px] text-center lg:text-left">
              Experience tailored data-driven strategies for your specific
              needs.
            </div>
            <div className="px-10 py-2 bg-primary rounded-3xl">
              <PopupButton size={95} id="hSNDAHge" transitiveSearchParams={true}>
                Contact Us
              </PopupButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
