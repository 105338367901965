/* eslint-disable camelcase */
import axios, {AxiosError} from 'axios';
import {toJson} from '@utils/helpers/csv';
import {RoseNotebook, RoseObject, RoseUser} from '@types';
import {Theme} from '../../theme/types';
import {HttpClient} from './HttpClient';

export type RoseObjectError = {
  type: 'rose-object-error'
  message: string
}

export type ExistReturnType =
  | {reason: 'no-exist'}
  | {reason: 'user-has-the-notebook'; roseObject: RoseNotebook}
  | {reason: 'notebook-exist-by-another-user'; roseObject: RoseNotebook}
  | {reason: 'user-has-the-rose-object'; roseObject: RoseObject}
  | {reason: 'rose-object-exist-by-another-user'; roseObject: RoseObject}

/**
 * API client for the `object` services
 * @singleton
 */
class ObjectAPI extends HttpClient {
  private static _instance: ObjectAPI;

  private constructor() {
    // empty params since not many of the routes follow the same `/objects` params
    super('/');
  }

  public static get instance(): ObjectAPI {
    if (!ObjectAPI._instance) {
      ObjectAPI._instance = new ObjectAPI();
    }

    return ObjectAPI._instance;
  }

  public search = (keywords: any, pageNumber: any, pageSize: any, type: any) => this.instance.get('objects/search', {
    params: {
      keywords: JSON.stringify(keywords),
      page_number: pageNumber,
      page_size: pageSize,
      type
    }
  });

  public getPopularObjects = () => this.instance.get('search/popular');

  public get = (code: string, headers?: any): Promise<RoseObject> => this.instance
    .get(`objects?rosecode=${encodeURIComponent(code)}`, {
      headers: headers || null
    })
    .then((res) => RoseObject.deserialize(res.data))
    .catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        const responseData: any = err.response?.data;
        const message =
          err?.response?.status === 504 ?
            `Calculation for ${code} took more than 60 seconds and the server timed out. ¿
              The server is still calculating, so please try again in a moments` :
            responseData?.message ?? `Fetching ${code} error`;

        throw new Error(message);
      } else {
        throw err;
      }
    })

    public getCSV = (code: string, actorId : string) => this.instance.get(`data/${code}/csv`, {
      params: {
        actor_id: actorId
      }
    });

    public getQueryCSV = (connectionCode: string, query : string) => this.instance.get(`/connections/${connectionCode}/download`, {
      params: {
        query
      }
    });

    public getFile = (code: string) => this.instance.get(`objects/${code}`);

    public exist = (code: string, user: RoseUser) => this.instance
      .get(`objects/${code}`)
      .then((res) => RoseObject.deserialize(res.data))
      .then(
        (roseObject): ExistReturnType => {
          const isCurrentUserTheOwner = [user.actorId, user.id].includes(
            RoseObject.getActorId(roseObject)
          );

          if (roseObject.type === 'notebook') {
            return {
              reason: isCurrentUserTheOwner ?
                'user-has-the-notebook' :
                'notebook-exist-by-another-user',
              roseObject
            };
          }

          return {
            reason: isCurrentUserTheOwner ?
              'user-has-the-rose-object' :
              'rose-object-exist-by-another-user',
            roseObject
          };
        }
      )
      .catch(
        (err: Error | AxiosError): ExistReturnType => {
          if (axios.isAxiosError(err)) {
            if (err.response.status === 404) {
              return {reason: 'no-exist'};
            }
          }

          throw err;
        }
      );

    public create = (code: any, values: any, metas: any, type: any, objects: any) => {
      if (!!objects) {
        return this.instance.post(
          'data',
          {
            code,
            values,
            metas,
            type,
            objects
          }
        );
      }

      return this.instance.post(
        'data',
        {
          code,
          values,
          metas,
          type
        }
      );
    }

    public pushFile = (fileObject: FormData) => this.instance.post('data', fileObject);

    public rename = (code: any, newName: any) => this.instance.put(
      `objects/${code}`,
      {
        new_code: newName
      }
    );

    public getType = (code: any) => this.instance.get(`objects/${code}/type`);

    public pushLogic = (code: any, logic: any) => this.instance.post(
      'logic',
      {
        code,
        logic
      }
    );

    public push = async (code: any, values: any, metas: string[]) => this.instance.post(
      'data',
      {
        code,
        // eslint-disable-next-line camelcase
        metas: {column_order: JSON.stringify(metas).replaceAll('\"', "'")},
        values: await toJson(values, 'map')
      }
    );

    public pushNew = async (code: any, values: any, metas: string[], user: RoseUser, overwrite: boolean) => {
      const exist = await this.exist(code, user);
      if (exist.reason === 'rose-object-exist-by-another-user') {
        return exist.reason as string;
      } else if (exist.reason === 'user-has-the-rose-object' && !overwrite) {
        return exist.reason as string;
      }

      return this.push(code, values, metas);
    }

    public getChartString = async <T = any>(code: string, theme: Theme): Promise<T> => await this.instance.get(`render/${code}?theme=${theme}`);

}

const ObjectService = ObjectAPI.instance;

export default ObjectService;
