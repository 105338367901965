import React, {
  ReactNode,
  PropsWithChildren,
  useRef,
  createContext,
  ReactElement
} from 'react';
import styled from 'styled-components';

import {device} from '@utils/breakpoints';
import {Percentage} from '@types';
import {ThemeVarNames} from '../../../theme/types';

export type ModuleLayoutProps = {
  children: ReactNode
  className?: string
  width: Percentage
  isReadOnly: boolean
  view?: ViewMode
}

export function ModuleLayout(props: ModuleLayoutProps): JSX.Element {
  const {children, isReadOnly, view, width, className} = props;
  const layoutStr = () => {
    if (isReadOnly) {return 'readOnly';}

    return view;
  };

  const layoutTypes = {
    readOnly: LayoutViewOnly,
    edit: Layout,
    view: LayoutViewOnly
  };

  const LayoutType = layoutTypes[layoutStr() ? layoutStr() : 'edit'];

  return (
    <LayoutType className={className} key={width} width={width}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return child;
        } else if (!child) {return null;}

        return React.cloneElement(child as ReactElement<any>, {width});
      })}
    </LayoutType>
  );
}

const setWidth = (p: {width: Percentage | number}) => {
  let width;
  if (typeof p.width === 'number') {
    width = `${p.width}%`;
  } else {
    width = p.width;
  }

  if (width === '66%') {
    width = '67%';
  }

  return width;
};

const Layout = styled.section<{width: Percentage | number}>`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) 40px;
  grid-template-rows: 24px min-content 24px min-content;
  gap: 4px;
  grid-template-areas:
    'toolbar-top toolbar-top'
    'input options'
    'toolbar-bottom .'
    'rose-object rose-object';

  ${device.desktop} {
    width: ${setWidth};
    grid-template-areas:
      'toolbar-top .'
      'input options'
      'toolbar-bottom .'
      'rose-object .';

    [data-area='input']:focus-within ~ [data-area='toolbar-bottom'] {
      display: flex;
    }
  }

  &:focus-within :where([data-area='toolbar-top'], [data-area='options']) {
    display: flex;
  }

  &:hover :where([data-area='toolbar-top'], [data-area='options']) {
    display: flex;
  }
`;

ModuleLayout.ToolbarTop = function ModuleLayoutToolbarTop({children}: PropsWithChildren<unknown>) {
  return <StyledToolbarTop data-area="toolbar-top">{children}</StyledToolbarTop>;
};

ModuleLayout.ToolbarBottom = function ModuleLayoutToolbarBottom({
  children
}: PropsWithChildren<unknown>) {
  return (
    <StyledToolbarBottom data-area="toolbar-bottom">
      {children}
    </StyledToolbarBottom>
  );
};

const StyledInput = styled.div<{area?: string}>`
  grid-area: ${({area}) => area || 'input'};
  position: relative;

  .upload-cover.focus {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(${ThemeVarNames.PrimaryBg});
    z-index: 10;
  }
  :hover .upload-cover, .upload-cover.unfocus {
    visibility: hidden;
  }

  > div.ant-list {
    display: none;
  }

  &:focus-within div.ant-list,
  &:active div.ant-list,
  div.ant-list:hover {
    display: block;
  }


`;

const LayoutViewOnly = styled.section<{width: Percentage}>`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) 40px;
  grid-template-rows: auto;
  grid-template-areas:
    'toolbar-top .'
    'input-allowed-viewonly .'
    'toolbar-bottom .'
    'rose-object options';

  ${device.desktop} {
    width: ${setWidth};
    grid-template-areas:
    'toolbar-top'
    'input-allowed-viewonly'
    'toolbar-bottom'
    'rose-object options';

      [class='highcharts-container']:focus-within ~ [data-area='toolbar-bottom'] {
        display: flex;
      }
  }

  &:focus-within :where([data-area='toolbar-top'], [data-area='options']) {
    display: flex;
  }

  &:hover :where([data-area='toolbar-top'], [data-area='options']) {
    display: flex;
  }

  /* prettier-ignore */
  & :where([data-area='toolbar-top'], [data-area='input'], [data-area='hidden-in-read-only']) {
    display: none !important;
  }
`;

export type ViewMode = 'view' | 'edit';

// TODO: cleaner impl of below
// const View = {
//   view: ViewOnly,
//   edit: <></>
// };

// temp before using cleaner impl above
ModuleLayout.View = function ModuleViewMode({children, mode, width}:
  PropsWithChildren<{mode: ViewMode, width: Percentage}>
) {
  switch (mode) {
  case 'view':
    return <LayoutViewOnly width={width} />;
  default:
    return <>{children}</>;
  }
};

ModuleLayout.Input = function ModuleLayoutInput(
  {children, id, dataArea}: PropsWithChildren<{id: string; dataArea?: string}>
) {
  return (
    <StyledInput className=".no-print" id={id} area={dataArea} data-area={dataArea || 'input'}>
      {children}
    </StyledInput>
  );
};

ModuleLayout.Options = function ModuleLayoutOptions({children}: PropsWithChildren<unknown>) {
  return <StyledOptions data-area="options">{children}</StyledOptions>;
};

export type ModuleLayoutRoseObjectProps = {
  width?: Percentage
  children: ReactNode
}

ModuleLayout.RoseObjectContainerWidthContext = createContext({
  width: undefined
});

ModuleLayout.RoseObject = function ModuleLayoutRoseObject(props: ModuleLayoutRoseObjectProps) {
  const {children, width} = props;
  const ref = useRef<HTMLDivElement>();

  return (
    <ModuleLayout.RoseObjectContainerWidthContext.Provider value={{width}}>
      <StyledRoseObject ref={ref}>{children}</StyledRoseObject>
    </ModuleLayout.RoseObjectContainerWidthContext.Provider>
  );
};

ModuleLayout.HiddenInReadOnly = function ModuleLayoutHiddenInReadOnly({
  children
}: PropsWithChildren<unknown>) {
  return <div data-area="hidden-in-read-only">{children}</div>;
};

ModuleLayout.HiddenInViewOnly = function ModuleLayoutHiddenInViewOnly({
  children
}: PropsWithChildren<unknown>) {
  return <div data-area="hidden-in-read-only">{children}</div>;
};

const StyledToolbarTop = styled.div`
  grid-area: toolbar-top;
  position: relative;
  display: flex;

  ${device.desktop} {
    display: none;
  }
`;

const StyledToolbarBottom = styled.div`
  grid-area: toolbar-bottom;
  position: relative;
  display: none;

  ${device.desktop} {
    display: none;
  }
`;

const StyledOptions = styled.div`
  grid-area: options;
  position: relative;
  display: flex;

  ${device.desktop} {
    display: none;
  }
`;

const StyledRoseObject = styled.div`
  grid-area: rose-object;
  position: relative;
`;

// Helpers
export function focusModuleInput(id: string): void {
  const tryFocus = () => {
    const $mod = document.getElementById(id);
    if (!$mod) {
      return false;
    }

    $mod.querySelector('textarea')?.focus();
    return true;
  };

  if (tryFocus()) {
    return;
  }

  setTimeout(tryFocus, 300);
}
