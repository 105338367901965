/* eslint-disable max-statements */
import React, {forwardRef, useEffect, useRef, useState, useTransition} from 'react';
import styled from 'styled-components';
import {UseMutationResult} from '@tanstack/react-query';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {Dropdown, message} from 'antd';
import {IoSaveOutline} from '@react-icons/all-files/io5/IoSaveOutline';
import {IoSearchOutline} from '@react-icons/all-files/io5/IoSearchOutline';
import {FiTool} from '@react-icons/all-files/fi/FiTool';
import {MutationType} from '@service/useNotebook';
import {useConnections} from '@service/useConnections';
import {RoseModule, RoseModuleType, RoseNotebook} from '@types';
import {LayoutStickyHeader, ViewMode} from '@components/common/Layouts';
import {Spinner, focusModuleInput, Typography, Button, Menu} from '@components/common';
import {device} from '@utils/breakpoints';
import HelmetWrapper from '@utils/HelmetWrapper';
import {calculateModuleWidth} from '@helpers';
import {Switch} from '../../common/DataEntry/Switch';
import {ThemeVarNames} from '../../../theme/types';
import {CodeModule, QueryModule, MarkdownModule, AskModule} from './Module';
import {ModuleList} from './ModuleList';
import {Sidebar} from './Sidebar';
import {Search} from './Sidebar/Search';
import {Title} from './Title';
import {UploadImportDrawer} from './Sidebar/UploadImport';
import {CreateNewNotebookModal} from './common/Save';
import {ImageModule} from './Module/ImageModule';

export type NotebookProps = {
  notebook: RoseNotebook;
  isReadOnly: boolean;
  mutation: UseMutationResult<RoseNotebook, any, MutationType, unknown>;
};

export function Notebook(props: NotebookProps): JSX.Element {
  const {notebook, mutation, isReadOnly} = props;

  // Refactor this state logic
  const [search, setSearch] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [currentMod, setMod] = useState('');
  const [mode, setMode] = useState<ViewMode>('edit');
  const [titleModal, setTitleModal] = useState<boolean>(false);
  const [isSwitchingMode, startSwitchingMode] = useTransition();
  const modulesRef = useRef(null);
  const notebookRef = useRef(notebook);
  notebookRef.current = notebook;
  const toggleMode = (mode: ViewMode) => {
    if (mode === 'view') {setMode('edit');} else {setMode('view');}
  };

  const handleOnModuleChanged = (newMod: RoseModule, mutate = true) => {
    // mutations all use notebook so just copying all notebook data with one module since everything else will be
    // updated properly in query

    if (!mutate) {
      mutation.mutate({
        action: 'replaceModule_noSave',
        notebook: {...notebookRef.current, modules: [newMod]}
      });
    } else {
      mutation.mutate({
        action: 'replaceModule_save',
        notebook: {...notebookRef.current, modules: [newMod]}
      });
    }
  };

  const handleAddModule = (partialMod: Partial<RoseModule>) => {
    let currentId = notebookRef.current.modules.findIndex(
      (value) => value.key === currentMod
    );
    if (currentId === -1) {
      currentId = 0;
    }

    const width = calculateModuleWidth(notebookRef.current.modules, currentId);
    const newModule = RoseModule.create(partialMod, {width});
    const newNotebook = RoseNotebook.update(notebookRef.current, {
      modules: RoseModule.insert(notebookRef.current.modules, newModule, currentId)
    });

    mutation.mutate({
      action: 'replace',
      notebook: newNotebook
    });
    if (search) {
      setSearch(false);
    }

    focusModuleInput(newModule.key);
  };

  const handleOnModuleListChange = (list: RoseModule[]) => {
    const newNotebook: RoseNotebook = {...notebookRef.current, modules: list};
    mutation.mutate({action: 'replace', notebook: newNotebook});
  };

  const handleOnSearch = () => {
    setSearch(!search);
    if (uploadFile === true) {
      setUploadFile(false);
    }
  };

  const handleCurrentMod = (modKey: string) => {
    setMod(modKey);
  };

  // union type: instead of separate booleans (drawer status instead of state)
  // if drawer table show table else if search true show search

  const handleOnUpdateModule = (
    partialMod: Partial<RoseModule>,
    index: number
  ) => {
    const newNotebook = {...notebookRef.current};
    const newModule = RoseModule.create(partialMod);
    newNotebook.modules.splice(index, 1, newModule);
    mutation.mutate({action: 'create', notebook: newNotebook});
  };

  const handleOnUploadFile = () => {
    setUploadFile(!uploadFile);
    if (search === true) {
      setSearch(false);
    }
  };

  const handleUpdateNotebookMetas = (newMeta: any) => {
    mutation.mutate({
      action: 'replace',
      notebook: {
        ...notebookRef.current,
        metas: {...notebookRef.current.metas, ...newMeta}
      }
    });
  };

  const onConvertToAsk = (mod: RoseModule) => {
    const roseModuleAsk = RoseModule.toAsk(mod, {textBox: ''});
    handleOnModuleChanged(roseModuleAsk);
  };

  useEffect(() => {
    if (isReadOnly && mode !== 'view' && notebook?.code) {setMode('view');}
  }, [isReadOnly]);

  if (localStorage.getItem('referrerMod')) {
    const modules: RoseModule[] = JSON.parse(localStorage.getItem('referrerMod'));
    handleOnModuleListChange(modules);
    if (localStorage.getItem('referrerMod')) {localStorage.removeItem('referrerMod');}
  }

  const PAGE_TITLE = notebook.code ?
    `${notebook.code} - Workspace` :
    'Workspace';

  // Dropdown tool menu when in mobile
  const menu =
    <StyledMenuContainer>
      <Menu>
        { isReadOnly ?
          <>
            <Menu.Item>
              <Link
                className="ant-btn ant-btn-primary"
                to="/dashboard"
                style={{
                  display: 'inline-block',
                  alignContent: 'center',
                  marginRight: '10px'
                }}
                target="_blank"
                onClick={() => {localStorage.setItem('referrerMod', JSON.stringify(notebook.modules));}}
              >
            Explore All
              </Link>
            </Menu.Item>
          </> :
          <>
            <Menu.Item>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '5px'
              }}>
                <Typography variant="primary">Edit</Typography>
                <Switch
                  disabled={isSwitchingMode}
                  loading={isSwitchingMode}
                  checked={mode === 'view'}
                  onChange={() => {
                    startSwitchingMode(() => {
                      toggleMode(mode);
                    });
                  }}
                />
                <Typography variant="primary">View</Typography>
              </div>
            </Menu.Item>
            <Menu.Item
              onClick={handleOnSearch}
            >
              <StyledItem>
                <IoSearchOutline size={18} />
                <span>Search</span>
              </StyledItem>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                const newNotebook = {...notebook};
                if (notebook.code) {
                  mutation.mutate(
                    {action: 'replace', notebook: newNotebook},
                    {
                      onSuccess() {
                        message.success('Notebook saved');
                      }
                    }
                  );
                } else {
                  setTitleModal(true);
                }

              }}
            >
              <StyledItem>
                <IoSaveOutline size={18} />
                <span>Save</span>
              </StyledItem>
            </Menu.Item>
          </>
        }
      </Menu>
    </StyledMenuContainer>;

  return (
    <>
      <HelmetWrapper title={PAGE_TITLE} description="Rose Workspace" />
      <LayoutStickyHeader
        header={
          <>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                padding: '10px 70px 0px'
              }}>
              { isReadOnly ?
                <>
                  <StyledExploreLink
                    className="ant-btn ant-btn-primary"
                    to="/dashboard"
                    target="_blank"
                    onClick={() => {localStorage.setItem('referrerMod', JSON.stringify(notebook.modules));}}
                  >Explore All</StyledExploreLink>
                  {/* <ReactToPrint
                    trigger={() =>
                      <Button
                        className="ant-btn ant-btn-primary"
                        style={{
                          display: 'inline-block',
                          alignContent: 'center'
                        }}
                      >Print</Button>
                    }
                    content={() => modulesRef.current}
                    copyStyles={false}
                    pageStyle={() => `
                      @page {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        position: relative;
                        z-index: 0;
                      }
                    `}
                  /> */}
                </> :
                <StyledEditToggle>
                  <Typography variant="primary">Edit</Typography>
                  <Switch
                    disabled={isSwitchingMode}
                    loading={isSwitchingMode}
                    checked={mode === 'view'}
                    onChange={() => {
                      startSwitchingMode(() => {
                        toggleMode(mode);
                      });
                    }}
                  />
                  <Typography variant="primary">View</Typography>
                </StyledEditToggle>}
            </div>
            <StyledTitleContainer>
              <StyledSpinnerTitleCointainer>
                <Title value={notebook.code} notebook={notebook} mutation={mutation} isReadOnly={isReadOnly} />
                {mutation.status === 'loading' ? <Spinner size={18} /> : null}
              </StyledSpinnerTitleCointainer>

            </StyledTitleContainer>
          </>
        }
        top="70px"
      />
      <Wrapper>
        <Sidebar
          onAddModule={handleAddModule}
          onSearch={handleOnSearch}
          onUploadFile={handleOnUploadFile}
          onConvertToAsk={onConvertToAsk}
          notebook={notebook}
          mutation={mutation}
          isReadOnly={isReadOnly || mode === 'view'}
        />
        <Modules
          ref={modulesRef}
          modules={notebook.modules}
          onListChange={handleOnModuleListChange}
          onModuleChange={handleOnModuleChanged}
          onNotebookMetasChange={handleUpdateNotebookMetas}
          updateCurrentModKey={handleCurrentMod}
          isReadOnly={isReadOnly}
          view={mode}
          defaultConnection={notebook.metas.defaultConnection}
        />
      </Wrapper>

      {uploadFile ?
        <UploadImportDrawer visible={uploadFile} setVisible={setUploadFile} onAddModule={handleAddModule}>
          <Sidebar
            onAddModule={handleAddModule}
            onSearch={handleOnSearch}
            onUploadFile={handleOnUploadFile}
            onConvertToAsk={onConvertToAsk}
            notebook={notebook}
            mutation={mutation}
            isReadOnly={isReadOnly}
            isSearchOpen
          />
        </UploadImportDrawer> :

        <Search
          visible={search}
          setVisible={setSearch}
          onAdd={(code_: string, moduleType: RoseModuleType = 'code') => {
            const modIndex = _.findIndex(notebook?.modules, {
              key: currentMod
            });
            if (notebookRef.current?.modules[modIndex]?.textBox === '') {
              handleOnUpdateModule(
                {type: moduleType, textBox: code_, runOnLoad: true},
                modIndex
              );
            } else {
              handleAddModule({
                type: moduleType,
                textBox: code_,
                runOnLoad: true
              });
            }
          }}
        >
        </Search>
      }

      {/* <SearchButton onClick={handleOnSearch} /> */}
      <StyledDropdown overlay={menu} trigger={['click']}>
        <Button
          type="ghost"
          shape="circle"
          className="ant-dropdown-link"
          onClick={(event) => event.preventDefault()}
          style={{border: 'none', background: 'hsl(0deg 0% 100% / 50%)'}}
        >
          <FiTool color="#1F87E5"/>
        </Button>
      </StyledDropdown>
      <CreateNewNotebookModal
        visible={titleModal}
        setVisible={setTitleModal}
        code={notebook.code}
        type="create"
        notebook={notebook}
        mutation={mutation}
      />
    </>
  );
}

type ModulesProps = {
  modules: RoseModule[];
  onListChange: (newList: RoseModule[]) => void;
  onModuleChange: (newMod: RoseModule) => void;
  onNotebookMetasChange: (metas: any) => void;
  updateCurrentModKey: (modKey: string) => void;
  isReadOnly: boolean;
  view: ViewMode;
  defaultConnection: string
};

const Modules = forwardRef<HTMLDivElement, ModulesProps>((props: ModulesProps, forwardedRef) => {
  const {
    modules,
    onListChange,
    onModuleChange,
    updateCurrentModKey,
    isReadOnly,
    view,
    defaultConnection,
    onNotebookMetasChange
  } = props;
  const connections = useConnections();

  return (
    <Main id="main">
      <ModuleList ref={forwardedRef} list={modules} onChange={onListChange} view={view} isReadOnly={isReadOnly}>
        {(mod, {addNewItem, removeItem, options}, index) => {
          const autoFocus = index === 0;
          switch (mod.type) {
          case 'code':
            return (
              <CodeModule
                autoFocus={autoFocus}
                key={mod.key}
                mod={mod}
                onModuleChanged={onModuleChange}
                onAddModule={addNewItem}
                onRemoveModule={removeItem}
                options={options}
                updateCurrentModKey={updateCurrentModKey}
                isReadOnly={isReadOnly}
                view={view}
              />
            );
          case 'query':
            return (
              <QueryModule
                autoFocus={autoFocus}
                key={mod.key}
                mod={mod}
                onModuleChanged={onModuleChange}
                connections={connections.data}
                defaultConnection={defaultConnection || connections?.data?.[0]?.code}
                onNotebookMetasChanged={onNotebookMetasChange}
                onAddModule={addNewItem}
                onRemoveModule={removeItem}
                options={options}
                updateCurrentModKey={updateCurrentModKey}
                isReadOnly={isReadOnly}
                view={view}
              />
            );
          case 'markdown':
            return (
              <MarkdownModule
                autoFocus={autoFocus}
                key={mod.key}
                mod={mod}
                onModuleChanged={onModuleChange}
                onAddModule={addNewItem}
                onRemoveModule={removeItem}
                options={options}
                updateCurrentModKey={updateCurrentModKey}
                isReadOnly={isReadOnly}
                view={view}
              />
            );
          case 'ask':
            return (
              <AskModule
                autoFocus={autoFocus}
                key={mod.key}
                mod={mod}
                onModuleChanged={onModuleChange}
                onAddModule={addNewItem}
                onRemoveModule={removeItem}
                options={options}
                updateCurrentModKey={updateCurrentModKey}
                isReadOnly={isReadOnly}
                view={view}
              />
            );
          case 'image':
            return (
              <ImageModule
                key={mod.key}
                code={mod.textBox}
                mod={mod}
                options={options}
                onAddModule={addNewItem}
                onRemoveModule={removeItem}
                onModuleChanged={onModuleChange}
                isReadOnly={isReadOnly}
                view={view}
              />
            );
          default:
            return null;
          }
        }}
      </ModuleList>
    </Main>
  );
});
Modules.displayName = 'Modules';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 0;
`;

const Main = styled.main`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;

  ${device.tablet} {
    padding-top: 20px;
  }
`;

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSpinnerTitleCointainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  & > div:nth-child(2) {
    position: absolute;
    top: 0;
    transform: translate(0, 50%);
    left: 100%;
  }
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledDropdown = styled(Dropdown)`
  position: fixed;
  left: 15px;
  top: 80px;
  z-index: 15;

  ${device.tablet} {
    display: none;
  }
`;

const StyledMenuContainer = styled.div`
  .ant-menu {
    background-color: var(${ThemeVarNames.PrimaryBg});
    border: 1px solid var(${ThemeVarNames.SecondaryBg});
    border-radius: 5px;
  }
  .ant-menu-item:hover,
  .ant-menu-submenu:hover,
  .ant-dropdown-menu-item:hover,
  .ant-menu-item-selected {
    &&& {
      background-color: var(${ThemeVarNames.PrimaryBg});
    }
  }
  
`;

const StyledEditToggle = styled.div`
  display: none;

  ${device.tablet} {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0px 70px;
  }
`;

const StyledExploreLink = styled(Link)`
  display: none;

  ${device.tablet} {
    display: inline-block;
    align-content: center;
    margin-right: 10px;
  }
`;
