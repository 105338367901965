import React, {forwardRef, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {IoArrowUp} from '@react-icons/all-files/io5/IoArrowUp';
import {IoArrowDown} from '@react-icons/all-files/io5/IoArrowDown';
import {ReactNode} from 'react-markdown';

import {ModuleLayout, ViewMode, focusModuleInput} from '@components/common';
import {RoseModule, OptionMenu} from '@types';
import {calculateModuleWidth} from '@helpers';
import {UploadArea} from '../Sidebar/UploadImport/tabs/UploadArea';

export type ModuleListProps = {
  list: RoseModule[]
  onChange: (list: RoseModule[]) => void
  children: (mod: RoseModule, options: ModuleListOptions, index: number) => ReactNode
  view?: ViewMode
  isReadOnly?: boolean
}

export type ModuleListOptions = {
  addNewItem: (mod: Partial<RoseModule>) => void
  removeItem: () => void
  options: OptionMenu[]
}

function UploadCover() {
  const [isFocused, setIsFocused] = useState(true);

  useEffect(() => {
    function onFocus() {
      setIsFocused(true);
    }

    function onBlur() {
      setIsFocused(false);
    }

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return function cleanup() {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return <div className={isFocused ? 'upload-cover focus' : 'upload-cover unfocus'} />;
}

export const ModuleList =
forwardRef<HTMLDivElement, ModuleListProps>((props: ModuleListProps, forwardedRef) => {
  const {list = [], onChange, children, view, isReadOnly} = props;
  const listRef = useRef(list);
  listRef.current = list;
  useEffect(() => {
    if (list.length === 0) {
      addNewItem(0)(RoseModule.create({type: 'code'}));
    }
  }, []);

  function addNewItem(idx: number) {
    return (mod: Partial<RoseModule>) => {
      const width = calculateModuleWidth(listRef.current, idx);
      const newModule = RoseModule.create(mod, {width});
      const newList = RoseModule.insert(listRef.current, newModule, idx);
      onChange(newList);
      focusModuleInput(newModule.key);
    };
  }

  function removeItem(idx: number) {
    return () => {
      const modToRemove = listRef.current[idx as number];
      const newList = RoseModule.remove(listRef.current, modToRemove);
      onChange(newList);
    };
  }

  function move(idx: number, target: 'prev' | 'next') {
    return () => {
      const modToMove = listRef.current[idx as number];
      const newList = listRef.current
        .flatMap((mod, index) => {
          if (index === idx) {return undefined;}

          if (target === 'next' && index === idx + 1) {return [mod, modToMove];}

          if (target === 'prev' && index === idx - 1) {return [modToMove, mod];}

          return mod;
        })
        .filter((mod) => mod !== undefined);
      onChange(newList);
    };
  }

  function composeMoveOptions(idx: number) {
    const moveNext = {
      title: 'Move After',
      icon: <IoArrowDown style={{marginRight: 10}} />,
      onClick: move(idx, 'next')
    };
    const movePrev = {
      title: 'Move Before',
      icon: <IoArrowUp style={{marginRight: 10}} />,
      onClick: move(idx, 'prev')
    };

    if (idx === 0 && list.length > 1) {return [moveNext];}

    if (idx === 0 && list.length <= 1) {return [];}

    if (idx === list.length - 1) {return [movePrev];}

    return [movePrev, moveNext];
  }

  return (
    <StyledListContainer ref={forwardedRef}>
      {list.map((mod, idx) =>
        children(mod, {
          addNewItem: addNewItem(idx),
          removeItem: removeItem(idx),
          options: [...composeMoveOptions(idx)]
        }, idx)
      )}
      <ModuleLayout
        key="upload-module"
        width="100%"
        isReadOnly={isReadOnly}
        view={view}
      >
        <ModuleLayout.Input id="upload-module">
          <UploadCover />
          <UploadArea direction="row" onAddNewItem={addNewItem(list.length - 1)} />
        </ModuleLayout.Input>
      </ModuleLayout>

    </StyledListContainer>
  );
});

const StyledListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  column-gap: 0;
  max-width: 1265px;
  padding-bottom: 20px;
`;
