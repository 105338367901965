/* eslint-disable promise/no-nesting */
/* eslint-disable promise/always-return */
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Card, message} from 'antd';
import {MailOutlined, LockOutlined, UserOutlined} from '@ant-design/icons';
import {useNavigate, useLocation, Navigate, Link} from 'react-router-dom';

import UserService from '@utils/apis/UserService';
import UserContainer from '@utils/state/userContainer';
import {trackError} from '@utils/createSentry';
import HelmetWrapper from '@utils/HelmetWrapper';
import {
  Spinner,
  Form,
  Input,
  InputPassword,
  Button,
  Typography
} from '@components/common';
import {RoseUser} from '@types';

type UserRegistration = {
  firstname: string
  lastname: string
  username: string
  email: string
  password: string
  passwordConfirm: string
}

export default (): JSX.Element => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [, forceUpdate] = useState({});
  const [form] = Form.useForm();
  const {user, setUser} = UserContainer.useContainer();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [registration, setRegistration] = useState<UserRegistration>({
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    password: '',
    passwordConfirm: ''
  });
  const {
    firstname,
    lastname,
    username,
    email,
    password,
    passwordConfirm
  } = registration;

  useEffect(() => {
    forceUpdate({});
  }, []);

  const onHandleChange = (name: string) => (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    setRegistration({
      ...registration,
      [name]: event.currentTarget.value
    });
    setErrorMessage('');
  };

  const onClickRegister = (values: any) => {
    setIsLoading(true);
    // eslint-disable-next-line promise/catch-or-return
    UserService.register(values)
      .then((result) => {
        if (result?.data?.message) {
          message.success(result?.data?.message);
        }

        UserService.login(values)
          .then(() => UserService.get().then((user_) => {
            setUser(user_);
            const redirectParam = queryParams.get('redirect');
            if (redirectParam) {
              /**
               * This is especially important for when logged-out users at chat.rose.ai want to sign up and be immediately redirected back to chat.rose.ai instead of landing somewhere within rose.ai, which would be unfamiliar.
               */
              console.log(`Redirecting to ${redirectParam}...`);
              window.location.href = redirectParam;
            } else {
              let redirectURL = '/dashboard';
              navigate(redirectURL);
            }
          }))
          .catch(() => {
            console.error('invalid email or password');
            setIsLoading(false);
          });
      })
      .catch((error: any) => {
        if (error?.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          console.error('error in user registration: ', error);
          trackError(error);
        }
      })
      .finally(() => setIsLoading(false));
  };

  if (!RoseUser.isGuest(user)) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <HelmetWrapper
        title="Register"
        description="Make your account"
        pathname="/register"
      />
      <StyledContentsContainer style={{justifyContent: 'center'}}>
        <Container>
          <Link to="/">
            <StyledImg src="/img/new_logo/svg/Roselogo-gradient.svg" alt="Rose Logo" />
          </Link>
          <StyledCard>
            <Header forwardedAs="h1" variant="secondary">
              Create an Account
            </Header>
            <Subheader forwardedAs="h2" variant="primary" size="large">
              Sign Up with Email
            </Subheader>
            <Form
              form={form}
              layout="vertical"
              name="login_form"
              initialValues={{remember: true}}
              onFinish={onClickRegister}
            >
              <FormFirstRow>
                <InlineFormItem
                  name="first_name"
                  label={<label style={{color: 'white'}}>First Name</label>}
                  rules={[
                    {required: true, message: 'Please provide a first name'}
                  ]}
                >
                  <Input
                    value={firstname}
                    placeholder="First Name"
                    onChange={onHandleChange('firstname')}
                  />
                </InlineFormItem>
                <FormFirstRowSpace />
                <InlineFormItem
                  name="last_name"
                  label={<label style={{color: 'white'}}>Last Name</label>}
                  rules={[
                    {required: true, message: 'Please provide a last name'}
                  ]}
                >
                  <Input
                    value={lastname}
                    placeholder="Last Name"
                    onChange={onHandleChange('lastname')}
                  />
                </InlineFormItem>
              </FormFirstRow>
              <Form.Item
                name="username"
                label={<label style={{color: 'white'}}>Username</label>}
                rules={[{required: true, message: 'Please provide a username'}]}
              >
                <Input
                  value={username}
                  placeholder="Username"
                  prefix={<UserOutlined />}
                  onChange={onHandleChange('username')}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label={<label style={{color: 'white'}}>Email</label>}
                rules={[
                  {
                    type: 'email',
                    message: 'Please provide a valid email address'
                  },
                  {required: true, message: 'Please provide an email address'}
                ]}
              >
                <Input
                  value={email}
                  placeholder="Email"
                  prefix={<MailOutlined />}
                  onChange={onHandleChange('email')}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label={<label style={{color: 'white'}}>Password</label>}
                rules={[{
                  required: true, min: 8, message: 'Password must be a minimum of 8 characters'
                }]}
              >
                <InputPassword
                  value={password}
                  prefix={<LockOutlined />}
                  placeholder="Password"
                  onChange={onHandleChange('password')}
                />
              </Form.Item>
              <Form.Item
                name="passwordConfirm"
                label={<label style={{color: 'white'}}>Confirm Password</label>}
                dependencies={['password']}
                rules={[
                  {required: true, message: 'Please confirm your password'},
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!'
                        )
                      );
                    }
                  })
                ]}
              >
                <InputPassword
                  value={passwordConfirm}
                  prefix={<LockOutlined />}
                  placeholder="Confirm Password"
                  onChange={onHandleChange('passwordConfirm')}
                />
              </Form.Item>
              {errorMessage && errorMessage.length ?
                <ErrorMessage>{errorMessage}</ErrorMessage> :
                null}
              <Form.Item shouldUpdate>
                {() =>
                  <StyledButton
                    type="primary"
                    size="large"
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form.getFieldsError().filter(({errors}) => errors.length)
                        .length ||
                      !firstname ||
                      !lastname ||
                      !username ||
                      !email ||
                      !password ||
                      !passwordConfirm
                    }
                  >
                    {isLoading ? <Spinner size={24} /> : 'Create Account'}
                  </StyledButton>
                }
              </Form.Item>
            </Form>
            <BelowCard style={{color: '#6B7280'}}>
              <Typography size="small">{'Have an account? '}<Link to="/login">Sign In</Link></Typography>
            </BelowCard>
          </StyledCard>
        </Container>
      </StyledContentsContainer>
    </>
  );
};

const StyledContentsContainer = styled.div`
  height: 100vh;
  justify-content: center;
  overflow: auto;
  background: #111928;
`;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #111928;
`;

const StyledCard = styled(Card)`
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  max-width: 500px;
  background: #111928;
  border-color: #111928;

  .ant-card-body {
    width: 100%;
  }
`;

const StyledImg = styled.img`
  width: 66px;
  margin-bottom: 20px;
`;

const Header = styled(Typography)`
  text-align: center;
  color: white;
  font-size: 40px;
`;

const Subheader = styled(Typography)`
  text-align: center;
  margin-bottom: 20px;
  color: white;
  font-size: 16px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const FormFirstRowSpace = styled.span`
  display: inline-flex;
  width: 20px;
`;

const ErrorMessage = styled.p`
  margin-bottom: 24px;
  font-size: 14px;
  color: red;
  text-align: center;
`;

const InlineFormItem = styled(Form.Item)`
  display: inline-flex;
  width: calc(50% - 10px);
  margin: 0;
`;

const FormFirstRow = styled(Form.Item)`
  display: inline-flex;
  width: 100%;
`;

const BelowCard = styled.div`
  display: flex;
  flex-direction: column;
`;
