import { PopupButton } from "@typeform/embed-react";
import UserContainer from "@utils/state/userContainer";
import React from "react";
import { Link } from "react-router-dom";

const LandingFooter = ({ altHeader = "Let's get started on something great." }) => {
  const { user } = UserContainer.useContainer();

  return (
    <div className="text-white py-5 container mt-52 mx-auto px-4 md:px-10">
      <div className="flex flex-col items-center gap-3">
        <img
          className="h-8"
          src="/img/new_logo/svg/RoseAIlogo-gradient.svg"
          alt="Rose.ai logo"
        />
        <div className="text-[32px] md:text-[36px] font-bold text-center">
          {altHeader}
        </div>
        <div className="text-[20px] md:text-[24px] text-center">
          Join over 80,000 users using Rose.
        </div>
        <PopupButton id="hSNDAHge" className="px-10 py-2 bg-primary rounded-3xl">
          Request a Demo
        </PopupButton>
      </div>
      <div className="flex justify-between py-16 flex-col md:flex-row gap-8">
        <div>© 2023 Rose AI. All rights reserved.</div>
        <div className="flex gap-3 flex-col md:flex-row">
          <Link to={"/terms"} className="!text-white">
            Terms
          </Link>
          <Link to={"/privacy"} className="!text-white">
            Privacy
          </Link>
          <Link to={"/"} className="!text-white">
            Cookies
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingFooter;
