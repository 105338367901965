import {HttpClient} from './HttpClient';

/**
 * API client for the `sharing` services
 * @singleton
 * @extends HttpClient
 */
class ActorAPI extends HttpClient {
  private static _instance: ActorAPI;

  private constructor() {
    super('/actors');
  }

  public static get instance(): ActorAPI {
    if (!ActorAPI._instance) {
      ActorAPI._instance = new ActorAPI();
    }

    return ActorAPI._instance;
  }

  public search = <T = any, P = any>(searchTerm: P): Promise<T> => this.instance.get(`?search_term=${searchTerm}`);

  public getAllActors = () => this.instance.get('/');

  public getBankAccount = () => this.instance.get('/accounts/bank');

  public getApiKeys = () => this.instance.get('/external/apikeys');

  public getCC = () => this.instance.get('/accounts/cc');
  
  public getAskroseUsage = () => this.instance.get('/askrose/usage');

  public updateBankAccount = <T = any, P = any>(form: P): Promise<T> => this.instance.post('/accounts/bank', form);

  public updateApiKey = <T = any, P = any>(form: P): Promise<T> => this.instance.post('/external/apikeys', form);

  public updateCC = <T = any, P = any>(token: P): Promise<T> => this.instance.post(
    '/accounts/cc',
    {token}
  );

  public removeCC = () => this.instance.delete('/accounts/cc');
}

const ActorService = ActorAPI.instance;

export default ActorService;
