import React, {PropsWithChildren, ReactNode} from 'react';
import styled from 'styled-components';

import {useDashboardScroll} from '../Containers';

type Px = `${number}px`

export type LayoutStickyHeaderProps = PropsWithChildren<{
  header: ReactNode
  top: Px
}>

export function LayoutStickyHeader(
  props: LayoutStickyHeaderProps
): JSX.Element {
  const {header, top} = props;
  const {isScrollActived} = useDashboardScroll();

  return (
    <HeaderContainer top={top} isScrollActived={isScrollActived}>
      {header}
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div<{ top: Px; isScrollActived: boolean }>`
  position: sticky;
  top: ${(p) => p.top};
  z-index: 1;
  background-color: var(--rose-primary-bg);
  min-height: 60px;
  border-bottom: ${(p) =>
    p.isScrollActived ? '.5px solid var(--rose-border)' : 'none'};
  transition: 0.3s all ease-in-out;
  padding-bottom: 0;
  padding-top: 10px;
  transform: ${(p) => p.isScrollActived ? 'translateY(-20px)' : null};
  display: table;
  overflow-wrap: anywhere;
`;
