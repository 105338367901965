import {AxiosResponse} from 'axios';
import Papa from 'papaparse';
import Worker from 'web-worker';

import {RoseMap} from '../../types/RoseMap';
import {cleanFileName} from './helpers';

export const toRoseMap = (data: string, code: string): RoseMap | undefined => {
  const tempCode = cleanFileName(code);
  const csv = Papa.parse(data as string, {header: true, skipEmptyLines: true}).data || [];
  if (!csv.length) {
    return undefined;
  }

  return {
    type: 'map',
    code: tempCode,
    values: {
      columns: Object.keys(csv[0]),
      data: csv.map((row) =>
        Object.values(row))
    },
    warnings: []
  };
};

export const toJson = (data: any, type = 'timeseries'): Promise<any> =>
  new Promise((resolve, reject) => {
    try {
      const url = new URL('./toJsonWorker.js', import.meta.url);
      const oldPathname = url.pathname;
      url.pathname = '/static'.concat(url.pathname);

      if (url.hostname.includes('localhost')) {
        url.pathname = oldPathname;
      }

      const worker = new Worker(url, {type: 'module'});
      worker.addEventListener('message', (e) => {
        resolve(e.data);
      });

      worker.postMessage([data, type]);

    } catch (error) {
      reject(error);
    }
  });

export const trans = (a: any) => a && a.length && a[0].map && a[0]
  .map((_: any, c: any) => a.map((r: any) => r[c])) || [];

export const checkType = (result: AxiosResponse | string) => {
  // data was pushed successfully
  if (typeof result === 'object') {
    return result.data;
  }

  return result;
};
