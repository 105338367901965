import React, {useReducer, useState} from 'react';
import styled from 'styled-components';
import {UploadOutlined} from '@ant-design/icons';
import {RcFile} from 'antd/lib/upload';
import {Button, Spinner, Form, Input, Alert, Upload} from '@components/common';
import ObjectService from '@utils/apis/ObjectService';
import {checkRoseCode, cleanFileName} from '@utils/helpers/helpers';
import {cleanRoseCode} from '@helpers';
import {RoseModule} from '@types';

export type UploadTabProps = {
  visible?: boolean;
  children?: React.ReactNode;
  onClose?: any;
  onAddModule: (partialMod: Partial<RoseModule>) => void;
};

export const initialState = {
  loading: false,
  error: '',
  success: ''
};

export type State = {
  loading: boolean
  success: string
  error: string
}

export enum ActionType {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  RESET = 'RESET',
}

export type Action = {
  type: ActionType
  payload?: any
}

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
  case 'LOADING':
    return {...state, loading: action.payload};
  case 'ERROR':
    return {...state, loading: false, error: action.payload};
  case 'SUCCESS':
    return {...state, loading: false, success: action.payload};
  case 'RESET':
    return {...state, loading: false, success: action.payload, error: action.payload};
  default:
    return state;
  }
};

// eslint-disable-next-line complexity
export const UploadTab = ({onAddModule, onClose}: UploadTabProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [codeToAddToNotebook, setCodeToAddToNotebook] = useState('');
  const [form] = Form.useForm();

  const beforeUpload = (file: RcFile) => {
    form.setFieldsValue({code: cleanFileName(file.name)});
    setCodeToAddToNotebook('');
    return false;
  };

  const onRemove = () => {
    form.resetFields();
  };

  const onSubmit = async () => {
    dispatch({type: ActionType.LOADING, payload: true});
    const values = form.getFieldsValue();
    const isImage = values.file.file.type.includes('image');

    try {
      const formData = new FormData();
      formData.append('file', values.file.file);
      formData.append('code', values.code);
      formData.append('type', isImage ? 'image' : 'file');
      formData.append('metas', '{}');

      const result = await ObjectService.pushFile(formData);

      dispatch({type: ActionType.LOADING, payload: false});
      dispatch({type: ActionType.SUCCESS, payload: typeof result === 'string' ? result : 'Success'});
      setCodeToAddToNotebook(values.code);

      setTimeout(() => {
        form.resetFields();
        dispatch({type: ActionType.RESET, payload: ''});
      }, 4000);

    } catch (error) {
      dispatch({type: ActionType.ERROR, payload: error?.response?.data?.message ?? error?.message ?? 'Error'});

    }
  };

  // Ensures that all codes will be in lowercase
  const handleInputChangeLowerCase = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.currentTarget.name;
    const value = cleanRoseCode(e.currentTarget.value); // e.currentTarget.value.toLowerCase().replace(/[^\w.]|_/g, '.');
    form.setFieldsValue({
      [key]: value
    });
  };

  return (
    <StyledContent>
      <Alert
        message=""
        description="Image formats supported: PNG and JPEG/JPG."
        type="info"
        showIcon
        style={{marginBottom: '25px'}}
      />
      <Form
        layout="vertical"
        onFinish={() => onSubmit()}
        form={form}
      >
        <Form.Item
          label="File"
          name="file"
          rules={[{required: true, message: 'File required'}]}
        >
          <Upload
            beforeUpload={beforeUpload}
            onRemove={onRemove}
            multiple={false}
            maxCount={1}
            variant="alternative"
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Rose Code Name"
          name="code"
          rules={[
            {
              required: true, message: 'Rose code name required'
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject();
                }

                if (checkRoseCode(value)) {
                  return Promise.reject('Rose code cannot contain special characters');
                }

                return Promise.resolve();
              }
            })
          ]}
        >
          <Input
            allowClear
            type="text"
            size="middle"
            placeholder="Rosecode name"
            name="code"
            onChange={handleInputChangeLowerCase}
          />
        </Form.Item>
        {state.loading && <Spinner size={36} />}
        {state.success && <>
          <StyledAlert message={state.success} type="success" />

        </>
        }
        {state.error && <StyledAlert message={state.error} type="error" />}
        <RightContainerButtons>
          <Form.Item>
            <Button type="ghost" onClick={() => {onClose(false);}}>Close</Button>
          </Form.Item>
          {
            !!codeToAddToNotebook && <Form.Item>
              <Button onClick={() => {
                onAddModule({type: 'image', textBox: codeToAddToNotebook});
                setCodeToAddToNotebook('');
                onClose();
              }}>
              Add {codeToAddToNotebook} to Notebook
              </Button>
            </Form.Item>
          }
          <Form.Item shouldUpdate>
            {({getFieldsValue, getFieldsError}) => {
              const {file, code} = getFieldsValue();
              const formIsComplete =
                    !!file &&
                    !!code &&
                    !getFieldsError().filter(({errors}) => errors.length).length;
              return <>
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={!formIsComplete || state.loading}
                >
                      Upload
                </Button>
              </>

              ;
            }}
          </Form.Item>
        </RightContainerButtons>
      </Form>
    </StyledContent>
  );
};

const StyledContent = styled.div`
  margin-top: 25px;
`;

const RightContainerButtons = styled.div`
  padding-top: 20px;
  justify-content: flex-end;
  display: flex;
  flex-direction: 'row';
  gap: 10px;
`;

const StyledAlert = styled(Alert)`
  text-align: center;
`;
