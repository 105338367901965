import styled from 'styled-components';
import {IoAdd} from '@react-icons/all-files/io5/IoAdd';

import {device} from '@utils/breakpoints';
import {ThemeVarNames} from '@theme';

const StyledError = styled.span`
  color: red;
`;

const ResultContainer = styled.div<{ parentWidth: number | undefined }>`
  grid-area: result-container;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  position: relative;
  max-width: ${(p) => p.parentWidth ? `${p.parentWidth}px` : '100%'};

  ${device.desktop} {
    max-width: 100%;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45px;
  position: relative;
`;

const IconContainer = styled.span`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const AddIcon = styled(IoAdd)`
  fill: ${(p: { color: string }) => p.color};

  :hover {
    cursor: pointer;
  }
`;

const StyledToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  flex-direction: row-reverse;

  ${device.desktop} {
    flex-direction: row;
  }
`;

const ConnectionSelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  i {
    display: flex;
    color: var(${ThemeVarNames.SecondaryText});
  }
`;

const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;
export {
  StyledError,
  ToolbarContainer,
  ConnectionSelectContainer,
  StyledToolbar,
  AddIcon,
  IconContainer,
  OptionsContainer,
  ResultContainer
};
