import React, { useState, PropsWithChildren, ReactNode, memo } from "react";
import styled from "styled-components";
import { ErrorBoundary } from "react-error-boundary";
import { noop } from "lodash";

import {
  ModuleLayout,
  DownloadButton,
  Button,
  ViewMode,
} from "@components/common";
import {
  ChartSettings,
  RoseChart,
  RoseModule,
  RoseModuleChartSettings,
  RoseModuleCodeSettings,
  RoseObject,
  RoseTimeseriesGroup,
} from "@types";

import { ThemeVarNames } from "@theme";
import { DetailsButton } from "./DetailsButton";
import { Tree } from "./Tree/index";
import { Chart } from "./Chart";
import { TimeseriesGroup } from "./TimeseriesGroup";
import { MapTable } from "./MapTable";
import { Decision } from "./Decision";
import { WarningsContainer } from "./Warnings/WarningsContainer";

export type ResultProps = {
  result: RoseObject;
  mod?: RoseModule;
  showWarnings?: boolean;
  addModule: (partialMod: Partial<RoseModule>) => void;
  onModuleChanged?: (newMod: RoseModule) => void;
  chartSettingsMap?: RoseModuleChartSettings;
  onChartSettingsChanged?: (
    id: string,
    newChartSettings: ChartSettings
  ) => void;
  visible?: boolean;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  view: ViewMode;
  isTimeseriesTableShown?: boolean;
};

export const ResultComponent = memo(
  (props: ResultProps) => {
    const {
      result,
      addModule,
      showWarnings = true,
      onModuleChanged = noop,
      mod,
      chartSettingsMap,
      onChartSettingsChanged,
      view,
      isTimeseriesTableShown = false,
    } = props;
    const [tree, setTree] = useState(null);
    const roseCodeDownloadable: Array<RoseObject["type"]> = [
      "map",
      "notebook",
      "query",
    ];

    // Needs refactor
    const Toolbar =
      view === "view" || result.type === "timeseries-group" ? null : (
        <>
          <DetailsButton metas={result?.metas} modalTitle={result?.code} />
          <Button
            type={tree ? "ghost" : "primary"}
            disabled={
              result?.metas?.tree === undefined || result?.metas?.tree === null
            }
            onClick={() => setTree(tree ? null : result?.metas?.tree)}
          >
            {tree ? "Close tree" : "Tree"}
          </Button>
          {roseCodeDownloadable.includes(result.type) ? (
            <DownloadButton
              roseCode={result?.code}
              actorId={result?.actor}
              mod={mod}
            />
          ) : null}
        </>
      );
    function ErrorFallback({ error }: { error: Error }) {
      return (
        <div className="!text-[--rose-primary-text] py-5" role="alert">
          <p className="!text-[--rose-primary-text] mb-0">
            {"Something went wrong trying to render "}
            <span className="font-bold ">
              {
                // eslint-disable-next-line react/prop-types
                result.type !== "timeseries-group" ? result?.code : ""
              }
            </span>
          </p>
          <p className="!text-[--rose-primary-text]">{error.message}</p>
        </div>
      );
    }

    const ComponentMap: ComponentMap = {
      map: MapTable,
      notebook: MapTable,
      package: MapTable,
      function: MapTable,
      query: MapTable,
      chart: Chart,
      decision: Decision,
      "timeseries-group": TimeseriesGroup,
      timeseries: null,
    };

    const RoseObjectComponent = ComponentMap[result.type];
    console.log("chartSettingsMap", chartSettingsMap);
    console.log("result", result);
    return (
      <ContainerWithToolbar toolbar={Toolbar}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {result.type === "timeseries-group" ? (
            <>
              {showWarnings ? (
                <ModuleLayout.HiddenInReadOnly>
                  <WarningsContainer result={result} />
                </ModuleLayout.HiddenInReadOnly>
              ) : null}
              <TimeseriesGroup
                roseObject={result}
                addModule={addModule}
                onModuleChanged={onModuleChanged}
                chartSettings={getChartSettings(chartSettingsMap, result)}
                mod={mod}
                onChartSettingsChanged={(c) => {
                  const codes = result.data.map((d) => d.code);
                  const id = RoseModuleCodeSettings.composeId(codes);
                  onChartSettingsChanged(id, c);
                }}
                view={view}
                isTableShown={isTimeseriesTableShown}
              />
            </>
          ) : null}
          {result.type === "chart" ? (
            <>
              {showWarnings ? (
                <ModuleLayout.HiddenInReadOnly>
                  <WarningsContainer result={result} />
                </ModuleLayout.HiddenInReadOnly>
              ) : null}
              <Chart
                roseObject={result}
                chartSettings={getChartSettings(chartSettingsMap, result)}
                onChartSettingsChanged={(c) => {
                  const code = result.code;
                  const id = RoseModuleCodeSettings.composeId([code]);
                  onChartSettingsChanged(id, c);
                }}
                mod={mod}
                view={view}
              />
            </>
          ) : null}
          {RoseObjectComponent &&
          result.type !== "timeseries-group" &&
          result.type !== "chart" ? (
            <>
              {showWarnings ? (
                <ModuleLayout.HiddenInReadOnly>
                  <WarningsContainer result={result} />
                </ModuleLayout.HiddenInReadOnly>
              ) : null}
              <RoseObjectComponent roseObject={result} />
            </>
          ) : null}
          {tree && <Tree treeData={tree} autoFocus addModule={addModule} />}
        </ErrorBoundary>
      </ContainerWithToolbar>
    );
  },
  (prevProps, nextProps) =>
    prevProps.result === nextProps.result &&
    prevProps.view === nextProps.view &&
    prevProps.isTimeseriesTableShown === nextProps.isTimeseriesTableShown &&
    prevProps.chartSettingsMap === nextProps.chartSettingsMap &&
    prevProps.visible === nextProps.visible &&
    prevProps.mod === nextProps.mod
);
ResultComponent.displayName = "ResultComponent";

export const Result = ResultComponent;

type ComponentMap = Record<RoseObject["type"], RenderRoseObjectFunction>;

type RenderRoseObjectFunction = ({
  roseObject,
}: {
  roseObject: RoseObject;
}) => JSX.Element;

type ContainerWithToolbarProps = PropsWithChildren<{
  toolbar: ReactNode;
}>;

function ContainerWithToolbar({
  children,
  toolbar: ComponentToolbar,
}: ContainerWithToolbarProps) {
  return (
    <Container>
      <Toolbar>{ComponentToolbar}</Toolbar>
      {children}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`;

// HELPERS
function getChartSettings(
  chartSettingsMap: Record<string, ChartSettings>,
  group: RoseTimeseriesGroup | RoseChart
): ChartSettings {
  switch (group.type) {
    case "timeseries-group":
      const codes = group.data.map((d) => d.code);
      const id = RoseModuleCodeSettings.composeId(codes);
      return (
        RoseModuleCodeSettings.getChartSettingsById(id, chartSettingsMap) ??
        ChartSettings.create(codes)
      );
    case "chart":
      return RoseModuleCodeSettings.getChartSettingsById(
        group.code,
        chartSettingsMap
      );
  }
}
