/* eslint-disable camelcase */
import _ from 'lodash';
import {RoseActor} from './RoseActor';

export type Connection = {
  type: 'connection'
  code: string
  dbType: string
  id: number
  keepCalculated: boolean
  searchVector: string
  actorId: number
  actor: RoseActor
}

export const Connection = {
  deserialize(data: any): Connection {
    if (_.isEmpty(data) || _.isUndefined(data)) {
      return undefined;
    }

    return {
      type: data.type,
      code: data.code,
      dbType: data.db_type,
      id: data.id,
      keepCalculated: data.keep_calculated,
      searchVector: data.search_vector,
      actorId: data.actor_id,
      actor: RoseActor.deserialize(data.actor)
    };
  },
  serialize(connection: Connection) {
    if (_.isEmpty(connection) || _.isUndefined(connection) || _.isUndefined(connection.id)) {
      return undefined;
    }

    return {
      type: connection.type,
      code: connection.code,
      db_type: connection.dbType,
      id: connection.id,
      keep_calculated: connection.keepCalculated,
      search_vector: connection.searchVector,
      actor_id: connection.actorId,
      actor: RoseActor.serialize(connection.actor)
    };
  }
};
