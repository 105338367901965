import React, {useState, useEffect} from 'react';
import {v4 as uuidv4} from 'uuid';
import styled from 'styled-components';
import {BiErrorAlt} from '@react-icons/all-files/bi/BiErrorAlt';
import {useQuery} from '@tanstack/react-query';
import tauist from 'tauist';

import ObjectService from '@utils/apis/ObjectService';
import {Spinner, InputSearchWithTags, Button, Empty} from '@components/common';

import {ThemeVarNames} from '@theme';
import {RoseModuleType} from '@types';
import {ResultPagination, ResultList} from './common';

export type ObjectsTabProps = {
  setVisible: (value: boolean) => void
  onAdd: (code: string, moduleType?: RoseModuleType) => void
  isActive: boolean
}

export function ObjectsTab(props: ObjectsTabProps): JSX.Element {
  const {setVisible, onAdd, isActive} = props;
  const [searchLength, setSearchLength] = useState(0);
  const [inputs, setInputs] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [checkedCodes, setCheckedCodes] = useState([]);
  const pageSize = 10;

  const {data = {}, status, error, isFetching} = useQuery<any, Error>(
    [inputs, pageNumber],
    () => inputs.length === 0 ?
      ObjectService.getPopularObjects()
        .then(({data}: any) => {
          const result = data.objects.map((e: any) => ({
            ...e,
            key: uuidv4()
          }));
          return {result, length: data.length};
        })
        .catch((err) => {
          throw err;
        }) :
      ObjectService.search(inputs, pageNumber - 1, pageSize, 'rose_object')
        .then(({data}: any) => {
          const result = data.objects.map((e: any) => ({
            ...e,
            key: uuidv4()
          }));
          return {result, length: data.length};
        })
        .catch((err) => {
          throw err;
        }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      staleTime: tauist.ms.oneMinute
    }
  );

  const {result: roseResult = [], length: roseResultLength = 0} = data;

  useEffect(() => {
    if (pageNumber === 1) {
      setSearchLength(roseResultLength);
    }
  }, [pageNumber, roseResultLength]);

  useEffect(() => {
    setCheckedCodes([]);
    setPageNumber(1);
  }, [inputs]);

  const addCell = (codes: string | string[], moduleType: RoseModuleType = 'code') => {
    let codeInput = '';

    if (moduleType === 'code' && Array.isArray(codes)) {
      codeInput = codes.join(', ');
    } else if (typeof codes === 'string') {
      codeInput = codes;
    }

    onAdd(codeInput, moduleType);
    setVisible(false);
    setCheckedCodes([]);
  };

  const changePageNumber = (newNumber: number) => {
    setPageNumber(newNumber);
  };

  const CheckControls =
    <StyledCheckControlsContainer visible={checkedCodes.length > 0}>
      <Button type="ghost" onClick={() => setCheckedCodes([])}>
        Clear
      </Button>
      <Button
        type="primary"
        onClick={() => {
          const codes: any = [];
          checkedCodes.forEach((checkedCode) => {
            codes.push(checkedCode.code);
          });
          addCell(codes);
        }}
      >
        Add
      </Button>
    </StyledCheckControlsContainer>
    ;

  return <Container id="object-search-container">
    <InputSearchWithTags
      autoFocus={isActive}
      onChange={(values) => setInputs(values)}
    />
    <ResultContainer>
      {status === 'error' ?
        <Empty
          image={<BiErrorAlt size={48} />}
          description={error?.message}
        /> :
        isFetching ?
          <Spinner size={64} padding="16px" /> :
          roseResult.length === 0 ?
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
            roseResult.length > 0 ?
              <>
                <StickyContainer>
                  <ResultPagination
                    onChangePage={changePageNumber}
                    pageNumber={pageNumber}
                    total={searchLength}
                  />
                  {CheckControls}
                </StickyContainer>
                <ResultList
                  loading={isFetching}
                  checkedCodes={checkedCodes}
                  items={roseResult}
                  onAdd={addCell}
                  setCheckedCodes={setCheckedCodes}
                />
              </> :
              null}
    </ResultContainer>
  </Container>;
}

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(${ThemeVarNames.SecondaryBg});
  border-bottom: 1px solid var(${ThemeVarNames.Border});
  padding-bottom: 6px;
`;

const StyledCheckControlsContainer = styled.div<{visible: boolean}>`
  width: 100%;
  z-index: 1;
  padding: 4px;
  display: flex;
  gap: 10px;
  justify-content: center;
  transform: ${(p) => p.visible ? 'translateY(0)' : 'translateY(-100%)'};
  transition: 200ms ease-in-out;
  position: ${(p) => p.visible ? 'relative' : 'absolute'};
  background-color: inherit;

  & > * {
    flex: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
