const abbreviateNumber = (value) => {
  let newValue = value;
  if (value >= 1000 || value <= -1000) {
    const suffixes = ['', 'k', 'm', 'b', 't'];
    const suffixNum = Math.floor((('' + value).length - 1) / 3);
    let shortValue = '';
    for (let precision = 3; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
      const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 3) {
        break;
      }
    }

    if (shortValue % 1 !== 0) {shortNum = shortValue.toFixed(2);}

    newValue = shortValue + suffixes[suffixNum];
  }

  return newValue;
};

export { abbreviateNumber };
export default { abbreviateNumber };
