import React, {ChangeEvent, KeyboardEvent, useRef, useEffect, CSSProperties} from 'react';
import styled from 'styled-components';
import {IoPlay} from '@react-icons/all-files/io5/IoPlay';

import {InputTextArea} from '@components/common';
import {ThemeVarNames} from '@theme';

export type InputContainerProps = {
  color: string;
  value: string;
  spellCheck?: boolean;
  autoFocus?: boolean;
  currentMod?: string;
  onRun: () => void;
  updateCurrentModKey: (modKey: string) => void;
  onChange: (value: string) => void;
  customStyles: CSSProperties;
};

export function Input(props: InputContainerProps): JSX.Element {
  const {
    color,
    onRun,
    value,
    spellCheck = false,
    autoFocus = false,
    currentMod,
    updateCurrentModKey,
    onChange,
    customStyles
  } = props;

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    inputRef.current?.focus?.();
  }, []);

  const handleOnKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      onRun();
    }
  };

  const onFocus = () => {
    updateCurrentModKey(currentMod);
  };

  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  const handleOnKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
    }
  };

  return (
    <TextContainer>
      <StyledInput
        data-id="code"
        variant="alternative"
        style={customStyles}
        value={value}
        onChange={handleOnChange}
        onKeyPress={handleOnKeyPress}
        onKeyDown={handleOnKeyDown}
        spellCheck={spellCheck}
        autoFocus={autoFocus}
        autoSize={{minRows: 2, maxRows: 20}}
        onFocus={onFocus}
        ref={inputRef}
      />
      <RunIcon data-id="run" color={color} size={18} onClick={() => onRun()} />
    </TextContainer>
  );
}

const TextContainer = styled.div`
  grid-area: input;
  width: 100%;
  height: auto;
  min-height: 80px;
  display: flex;
  background: var(${ThemeVarNames.InputAlternative});
  border-radius: 10px;
  border: 1px solid;
  border-color: var(${ThemeVarNames.Border});
  justify-content: center;
  align-items: center;

  textarea {
    border: none;

    :focus {
      box-shadow: none;
    }

    :hover {
      border: none;
    }
  }
`;

const RunIcon = styled(IoPlay)`
  fill: ${(p: any) => p.color};
  margin-right: 10px;

  :hover {
    cursor: pointer;
  }
`;

const StyledInput = styled(InputTextArea)`
  width: 100%;
  display: block;
  resize: none;
  padding: 10px;
  border: none;
  border-radius: 10px;
  white-space: pre-line;
  overflow: auto;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;
