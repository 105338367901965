import * as React from 'react';
import styled from 'styled-components';

import {Drawer, Tabs} from '@components/common';

import {RoseModuleType} from '@types';
import {ObjectsTab} from './ObjectsTab';
import {NotebooksTab} from './NotebooksTab';

export type SearchProps = {
  visible: boolean
  setVisible: any
  onAdd: (code: string, moduleType?: RoseModuleType) => void
  children: React.ReactNode
}

export const Search = (props: SearchProps): JSX.Element => {
  const {visible, setVisible, onAdd, children} = props;
  const [activeKey, setActiveKey] = React.useState('1');

  return (
    <StyledDrawer
      placement="left"
      closable
      onClose={() => {
        setVisible(false);
      }}
      visible={visible}
      contentWrapperStyle={{
        width: '100%',
        maxWidth: '450px',
        position: 'absolute'
      }}
      getContainer={false}
    >
      <StyledContainer>
        {children}
        <Tabs activeKey={activeKey} size="large" onChange={setActiveKey}>
          <Tabs.TabPane tab="Objects" key="1">
            <ObjectsTab
              setVisible={setVisible}
              onAdd={onAdd}
              isActive={visible && activeKey === '1'}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Notebooks" key="2">
            <NotebooksTab isActive={visible && activeKey === '2'} />
          </Tabs.TabPane>
        </Tabs>
      </StyledContainer>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)`
.ant-drawer-body {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-bottom: 0;
}
`;

const StyledContainer = styled.div`
  align-items: center;
  justify-content: left;
  padding-left: 40px;
  padding-top: 10px;
  clear: both;
  width: 100%;
`;
