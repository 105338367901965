/* eslint-disable react/display-name */
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Button, Card, Form, Input, Modal, Empty, ModalPropsWithChildren} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {LockOutlined} from '@ant-design/icons';
import {FcExpired} from '@react-icons/all-files/fc/FcExpired';

import {Spinner} from '@components/common';
import UserService from '@utils/apis/UserService';
import HelmetWrapper from '@utils/HelmetWrapper';

type TokenStatus = 'checking' | 'valid' | 'invalid'

export default (): JSX.Element => {
  const navigate = useNavigate();
  const {token} = useParams<{ token: string }>();
  const [modalMessage, setModalMessage] = useState('');
  const [tokenStatus, setTokenStatus] = useState<TokenStatus>('checking');
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);

  useEffect(() => {
    UserService.checkToken(token)
      .then(() => setTokenStatus('valid'))
      .catch(() => setTokenStatus('invalid'));
  }, []);

  const onClickContinue = async (values: any) => {
    if (isChangingPassword) {return;}

    setIsChangingPassword(true);
    try {
      await UserService.changePasswordFromToken(token, values.password);
      setIsChangingPassword(false);
      navigate('/login');
    } catch (e) {
      setIsChangingPassword(false);
      setModalMessage(e.data.message);
    }
  };

  if (tokenStatus === 'checking') {
    return <Spinner size={64} padding="40px" />;
  }

  if (tokenStatus === 'invalid') {
    return (
      <StyledErrorContainer>
        <HelmetWrapper
          title="Reset"
          description="Reset Token"
          pathname="/reset"
        />
        <Empty
          image={<FcExpired size={96} />}
          description="This reset token has expired"
        >
          <Button type="primary" onClick={() => navigate('/forgot')}>
            Try again
          </Button>
        </Empty>
      </StyledErrorContainer>
    );
  }

  return (
    <Container>
      <a href="/">
        <StyledImg src="/img/new_logo/svg/Roselogo-gradient.svg" alt="Rose Logo" />
      </a>
      <StyledCard>
        <p>Create your new password:</p>
        <Form
          name="login_form"
          initialValues={{remember: true}}
          onFinish={onClickContinue}
        >
          <Form.Item
            name="password"
            rules={[{required: true, min: 8, message: 'Password must be a minimum of 8 characters'}]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="passwordConfirm"
            dependencies={['password']}
            rules={[
              {required: true, message: 'Please confirm your password'},
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  );
                }
              })
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Confirm Password"
            />
          </Form.Item>
          <StyledButton
            type="primary"
            size="large"
            htmlType="submit"
            loading={isChangingPassword}
          >
            Reset Password
          </StyledButton>
        </Form>
      </StyledCard>
      <StyledModal
        title="Error occurred"
        visible={!!modalMessage.length}
        onOk={() => setModalMessage('')}
        onCancel={() => setModalMessage('')}
        footer={[
          <Button key="ok" onClick={() => setModalMessage('')}>
            OK
          </Button>
        ]}
      >
        <p>{modalMessage}</p>
      </StyledModal>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

const StyledCard = styled(Card)`
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  max-width: 500px;
  width: 100%;

  .ant-card-body {
    width: 100%;
  }
`;

const StyledImg = styled.img`
  width: 66px;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledModal = styled<React.FC<ModalPropsWithChildren>>(Modal)`
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;
