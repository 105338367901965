import * as React from 'react';
import {FaLinkedin} from '@react-icons/all-files/fa/FaLinkedin';

import {StyledButton} from './style';

export type LinkedInButtonProps = {
  colored?: boolean
}

export function LinkedInButton({colored}: LinkedInButtonProps): JSX.Element {
  return (
    <StyledButton
      colored={colored}
      target="_blank"
      href="https://www.linkedin.com/company/rose-technology/"
    >
      <FaLinkedin />
    </StyledButton>
  );
}
