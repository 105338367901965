/**
 * @see https://stackoverflow.com/a/1144788
 *
 * @param {string} string
 */
export function escapeRegExp(string: string): string {
  // $& means the whole matched string.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
}

/**
 * @see https://stackoverflow.com/a/1144788
 *
 * @param {string} str
 * @param {string} find
 * @param {string} replace
 */

export function replaceAll(str: string, find: string, replace: string): string {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export function strSplice(
  str: string,
  insert_str: string,
  rem: number,
  idx: number
): string {
  return str.slice(0, idx) + insert_str + str.slice(idx + Math.abs(rem));
}

/* Returns date in rose string format */
export function dateInRoseFormat(): string {
  const d = new Date();
  const year = d.getFullYear().toString();
  const month = (d.getMonth() + 1).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  const date = d
    .getDate()
    .toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
  return year + month + date;
}

/*
  Returns, i think, 9 digit has of a string. Used to hash sql code strings
*/
export function hashCode(str: string): number {
  let hash = 0;
  if (str.length == 0) {
    return hash;
  }

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }

  return Math.abs(hash);
}

/**
 *
 * @param code : Rose Code
 *
 * remove :refresh and :update
 */
export function cleanRoseCode(code: string): string {
  const functionNamesToClean = /(:refresh\(\)|:refresh|:update\(\)|:update)/g;
  return code.replace(functionNamesToClean, '');
}
