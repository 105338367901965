/* eslint-disable */
/* eslint-disable @typescript-eslint/no-var-requires, max-lines-per-function */
import Highcharts, { YAxisOptions } from "highcharts/highstock";
import "highcharts/modules/exporting";

import _ from "lodash";
import "d3plus-text";
import "d3";

import { Theme, ThemeVarNames } from "@theme";
import {
  ChartSettings,
  RoseModuleChartSettings,
  RoseModuleCodeSettings,
  RoseTimeseriesGroup,
} from "@types";
import { abbreviateNumber } from "./helpers";
import { ChartEditorEvent, EE } from "../events";
import { dynamicPositioning } from "../helpers";
import { whiteLogoBase64, blackLogoBase64 } from "../../../theme/watermark";
import { isMobile } from "react-device-detect";
import { darkPoweredByRose, lightPoweredByRose } from "../../../poweredByRose";
import { sources } from "webpack";

/**
 * export image in iframe instead to avoid the `beforeunload` alert
 *
 * https://github.com/highcharts/highcharts/issues/2981
 */
Highcharts.wrap(
  Highcharts,
  "post",
  function (proceed, url, data, formAttributes) {
    var frame;
    if (!Highcharts.exportFrame) {
      frame = document.createElement("iframe");
      frame.id = "hc-export-frame";
      frame.style.display = "none";
      document.body.appendChild(frame);
      Highcharts.exportFrame = frame;
    }
    formAttributes = Highcharts.merge(
      { target: "hc-export-frame" },
      formAttributes
    );
    proceed.call(this, url, data, formAttributes);
  }
);

export type ChartOptionsParams = {
  roseObject: RoseTimeseriesGroup;
  theme: Theme;
  chartSettings: ChartSettings;
  getChartSettings: () => ChartSettings;
  onChartSettingsChanged?: (value: ChartSettings) => void;
  rangeShown: boolean;
  modSettings: RoseModuleCodeSettings;
  isViewMode: boolean;
};

export function composeChartOptions(
  chartOptionsParams: ChartOptionsParams
): any {
  const {
    roseObject,
    theme,
    chartSettings,
    getChartSettings,
    onChartSettingsChanged,
    rangeShown,
    modSettings,
    isViewMode,
  } = chartOptionsParams;
  const themeValuesMap = Theme.themesMap[theme];
  const { data } = roseObject;
  const watermark = theme === "light" ? blackLogoBase64 : whiteLogoBase64;
  const poweredByRose =
    theme === "light" ? lightPoweredByRose : darkPoweredByRose;

  // used to adjust credit font and box size
  let previousSource = getChartSettings().source;
  let maxCreditLength = 150;
  let maxCreditBoxHeight = 40;
  let previousChartWidth = 0;
  let previousWidthFactor = 1;
  let previousRender = this;
  var cutoff = 0;
  let boundingBox: number, navigatorAdjustment;

  let widthFactor = 1;
  switch (modSettings?.width) {
    case "66%":
      widthFactor = 0.66;
      break;
    case "50%":
      widthFactor = 0.5;
      break;
    case "33%":
      widthFactor = 0.33;
  }

  const titleLength: number = getChartSettings().title?.text?.length;
  let titleFontSize = 28;
  let legendFontSize = 18;
  if (titleLength > 45 * widthFactor) {
    titleFontSize = 18;
    legendFontSize = 14;
  } else if (titleLength > 30 * widthFactor) {
    titleFontSize = 23;
    legendFontSize = 16;
  }

  // init with a `this` getter to access properties in object during definition
  let chartData: any = {
    getThis: function () {
      return this;
    },
  };

  chartData = {
    ...chartData,
    colors: Object.values(getChartSettings().datasets).map(
      (value) => value.color
    ),
    title: {
      ...getChartSettings().title,
      floating: true,
      style: {
        color: themeValuesMap[ThemeVarNames.PrimaryText],
        fontSize: titleFontSize,
        fontWeight: 500,
      },
    },
    subtitle: {
      text: "",
      style: {
        color: themeValuesMap[ThemeVarNames.SecondaryText],
        fontSize: 20,
      },
    },
    credits: {
      enabled: true,
      text: getChartSettings().source,
      // by default the href is set to highcharts.com, we dont want users to be able to click
      href: null,
      position: getMetasPosition(widthFactor, !!getChartSettings().source)
        .credits,
      style: {
        cursor: "initial",
        fontSize: "11px",
      },
    },
    navigator: {
      enabled: rangeShown,
      maskFill: "rgba(102, 130, 188, 0.3)",
      margin: widthFactor < 0.66 ? 2 : 0,
    },
    rangeSelector: {
      enabled: rangeShown,
      floating: true,
      inputEnabled: rangeShown,
      selected: 5,
      inputStyle: {
        backgroundColor: themeValuesMap[ThemeVarNames.InputPrimary],
        color: themeValuesMap[ThemeVarNames.PrimaryText],
      },
      labelStyle: {
        color: themeValuesMap[ThemeVarNames.SecondaryText],
      },
      buttonTheme: {
        fill: themeValuesMap[ThemeVarNames.SecondaryBg],
        stroke: themeValuesMap[ThemeVarNames.Border],
        style: {
          color: themeValuesMap[ThemeVarNames.SecondaryText],
        },
      },
      inputBoxBorderColor: themeValuesMap[ThemeVarNames.Border],
    },
    exporting: {
      menuItemDefinitions: {
        // Custom definition
        chartEditor: {
          onclick: function () {
            EE.emit(ChartEditorEvent + roseObject.key, {
              isOpen: true,
              chartData,
            });
          },
          text: "Chart Editor",
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            "chartEditor",
            "separator",
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
          ],
        },
      },
      enabled: rangeShown,
      enableImages: true,
      sourceWidth: 1200,
      chartOptions: {
        credits: {
          // credits source was always out of date, so using `this` getter to access data at export time
          ...chartData.getThis().credits,
          position: {
            align: "right",
            verticalAlign: "bottom",
            x: -10,
            y: -30,
          },
        },
        navigator: {
          enabled: false,
          margin: 0,
        },
        rangeSelector: {
          enabled: false,
          inputEnabled: false,
        },
        chart: {
          events: {
            render() {
              const logoSizeMult = 5;
              const logoSizeX = 30 * logoSizeMult;
              const logoSizeY = 30 * logoSizeMult;
              const logoPosX = this.chartWidth / 2 - logoSizeX / 2;
              const logoPosY =
                this.plotTop + this.plotHeight / 2 - logoSizeY / 2;
              const poweredByRosePos = {
                x: 20,
                y: 450,
              };
              const poweredByRoseSize = {
                x: 170,
                y: 28,
              };
              if (!_.isUndefined(this.watermark)) {
                this.watermark.destroy();
              }
              if (!_.isUndefined(this.poweredByRose)) {
                this.poweredByRose.destroy();
              }
              if (getChartSettings().watermark) {
                this.watermark = this.renderer
                  .image(watermark, logoPosX, logoPosY, logoSizeX, logoSizeY)
                  .attr({ opacity: 0.1 })
                  .add();
              }
              this.poweredByRose = this.renderer
                .image(
                  poweredByRose,
                  poweredByRosePos.x,
                  poweredByRosePos.y,
                  poweredByRoseSize.x,
                  poweredByRoseSize.y
                )
                .add();

              let lastElementY = 0;
              if (this.title.textStr !== "") {
                this.title.attr({
                  y: this.title.alignOptions.height, // 10 the is additional space between range selector and title
                });
                lastElementY = this.title.alignOptions.height;
              }

              this.update(
                {
                  chart: {
                    marginTop: lastElementY + 10 + this.legend.legendHeight,
                  },
                },
                false,
                false,
                false
              );
              this.legend.group.attr({
                transform: [
                  "translate(",
                  this.legend.group.translateX,
                  ",",
                  lastElementY,
                  ")",
                ].join(""),
              });
            },
          },
        },
      },
    },
    navigation: {
      menuStyle: {
        backgroundColor: themeValuesMap[ThemeVarNames.SecondaryBg],
      },
      menuItemStyle: {
        color: themeValuesMap[ThemeVarNames.SecondaryText],
      },
      buttonOptions: {
        align: "right",
        verticalAlign: "top",
        y: 0,
        theme: {
          fill: themeValuesMap[ThemeVarNames.PrimaryBg],
          stroke: themeValuesMap[ThemeVarNames.Border],
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 585,
          },
        },
      ],
    },
    legend: {
      enabled: true,
      align: "center",
      verticalAlign: "top",
      floating: true,
      adjustChartSize: false,
      maxHeight: 90,
      margin: 0,
      padding: 10,
      itemStyle: {
        fontSize: legendFontSize,
        color: themeValuesMap[ThemeVarNames.SecondaryText],
        fontWeight: "normal",
      },
      itemHoverStyle: {
        color: themeValuesMap[ThemeVarNames.HoverText],
      },
      navigation: {
        activeColor: themeValuesMap[ThemeVarNames.Action],
        inactiveColor: themeValuesMap[ThemeVarNames.Disabled],
        style: {
          color: themeValuesMap[ThemeVarNames.PrimaryText],
          fontSize: 20,
        },
      },
    },
    plotOptions: {
      series: {
        animation: false,
        showInNavigator: true,
        connectNulls: false,
        dataGrouping: {
          enabled: true,
          groupPixelWidth: 1,
          approximation: "average",
        },
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      split: true,
      valueDecimals: 4,
      shared: true,
    },
    yAxis: getChartSettings().yAxis?.map((axis: YAxisOptions, idx: number) => {
      return {
        ...axis,
        labels: {
          ...axis.labels,
          formatter() {
            return abbreviateNumber(this.value);
          },
        },
        showEmpty: false,
        visible: true,
        opposite: axis?.opposite || false,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        alignTicks: false,
        // tickAmount: ifLabels(getChartSettings()) > 0 ? 5 : undefined
        tickAmount: 5,
      };
    }) || [
      {
        title: {
          text: "",
          style: {
            color: "",
          },
        },
        id: "__rose_temp__",
        min: null,
        max: null,
      },
    ],
    yAxisLocation: {
      None: 0,
    },
    yAxisUnits: {
      0: "None",
    },
    xAxis: {
      type: "datetime",
      tickColor: themeValuesMap[ThemeVarNames.PrimaryText],
      labels: {
        style: {
          color: themeValuesMap[ThemeVarNames.PrimaryText],
          fontSize: 14,
        },
      },
      crosshair: true,
      events: {
        afterSetExtremes({ trigger, ...data }: any) {
          if (
            ["rangeSelectorInput", "navigator", "rangeSelectorButton"].includes(
              trigger
            )
          ) {
            const newChartSettings = {
              ...getChartSettings(),
              xAxis: {
                min: data.userMin,
                max: data.userMax,
              },
            };
            onChartSettingsChanged(newChartSettings);
          }
        },
      },
    },
    chart: {
      backgroundColor: themeValuesMap[ThemeVarNames.PrimaryBg],
      borderRadius: 5,
      animation: false,
      zoomType: "x",
      marginBottom: isViewMode ? 130 : 90,
      events: {
        load() {
          setTimeout(() => {
            if (
              !!getChartSettings().xAxis.min &&
              !!getChartSettings().xAxis.max
            ) {
              this.xAxis?.[0].setExtremes(
                getChartSettings().xAxis.min,
                getChartSettings().xAxis.max
              );
            }

            var source = getChartSettings().source || "";
            cutoff = source.length;
            var chart = this;

            this.credits.update({
              text:
                source.length !== 0
                  ? cutoff != source.length || source.length > maxCreditLength
                    ? `source: ${source.substring(0, cutoff)}...`
                    : `source: ${source}`
                  : "",
              style: {
                whiteSpace: "normal",
                width:
                  chart.chartWidth -
                  (chart.poweredByRose.renderer.plotBox.x + 200),
                overflowWrap: "break-word",
                wordWrap: "break-word",
                wordBreak: "break-all",
                WebkitHyphens: "auto",
                MozHyphens: "auto",
                msHyphens: "auto",
                hyphens: "auto",
              },
            });

            navigatorAdjustment = 15;
            boundingBox = this.credits.element.getBoundingClientRect().height;
            var navigatorTop =
              boundingBox +
                this.credits.element.getBoundingClientRect().y +
                navigatorAdjustment || 0;

            if (widthFactor >= 0.66) {
              if (
                source.length > maxCreditLength &&
                boundingBox < maxCreditBoxHeight
              ) {
                //base cutoff on credit length
                cutoff = maxCreditLength - 100;
              } else {
                // base cutoff on bounding box
                cutoff =
                  boundingBox >= 100
                    ? 10
                    : boundingBox >= 50
                    ? 25
                    : boundingBox >= 35
                    ? 50
                    : /* default value */ source.length;
              }
            } else {
              if (widthFactor == 0.5) {
                cutoff = Math.min(source.length, 60);
              } else {
                cutoff = Math.min(source.length, 10);
              }
            }

            if (widthFactor >= 0.66) {
              if (boundingBox >= 35) {
                navigatorAdjustment -= 10;
              }
              if (boundingBox > 50) {
                navigatorAdjustment -= 5;
              }
              if (boundingBox > 100) {
                navigatorAdjustment -= 5;
              }
            }

            this.credits.update({
              text:
                source.length !== 0
                  ? cutoff != source.length || source.length > maxCreditLength
                    ? `source: ${source.substring(0, cutoff)}...`
                    : `source: ${source}`
                  : "",
              style: {
                whiteSpace: "normal",
                width:
                  chart.chartWidth -
                  (chart.poweredByRose.renderer.plotBox.x + 200),
                overflowWrap: "break-word",
                wordWrap: "break-word",
                wordBreak: "break-all",
                WebkitHyphens: "auto",
                MozHyphens: "auto",
                msHyphens: "auto",
                hyphens: "auto",
              },
            });

            navigatorTop =
              widthFactor >= 0.66
                ? this.credits.element.getBBox().height +
                  this.credits.element.getBBox().y +
                  navigatorAdjustment
                : chart.poweredByRose.element.y.animVal.value + 40;

            chart.update({
              navigator: {
                top:
                  source.length == 0
                    ? chart.poweredByRose.element.y.animVal.value + 40
                    : navigatorTop,
              },
            });

            this.redraw();
          }, 100);
        },
        render() {
          const logoSizeMult = 5;
          const logoSizeX = 30 * logoSizeMult;
          const logoSizeY = 30 * logoSizeMult;
          const logoPosX = this.chartWidth / 2 - logoSizeX / 2;
          const logoPosY = this.plotTop + this.plotHeight / 2 - logoSizeY / 2;
          const { poweredBy } = getMetasPosition(
            widthFactor,
            !!getChartSettings().source,
            +this.chartHeight
          );
          const poweredByRoseSize = {
            x: 170,
            y: 28,
          };
          if (!_.isUndefined(this.watermark)) {
            this.watermark.destroy();
          }
          if (!_.isUndefined(this.poweredByRose)) {
            this.poweredByRose.destroy();
          }
          if (getChartSettings().watermark) {
            this.watermark = this.renderer
              .image(watermark, logoPosX, logoPosY, logoSizeX, logoSizeY)
              .attr({ opacity: 0.1 })
              .add();
          }
          this.poweredByRose = this.renderer
            .image(
              poweredByRose,
              poweredBy.x,
              poweredBy.y,
              poweredByRoseSize.x,
              poweredByRoseSize.y
            )
            .add();

          // Code that dynamically positions the rangeSelector, title, and labels depending on multiple factors
          //--------------------------------------------------//
          const positions = dynamicPositioning(
            this.rangeSelector,
            this.margin,
            this.title,
            this.legend,
            this.chartWidth,
            isViewMode
          );
          var currentSource = getChartSettings().source || "";

          var currentChartWidth = this.chartWidth;
          if (this.title.textStr !== "") {
            this.title.attr({
              y: positions.titleY,
            });
          }

          if (
            !(previousRender == getChartSettings()) ||
            previousChartWidth != currentChartWidth
          ) {
            if (previousChartWidth != currentChartWidth) {
              previousChartWidth = this.chartWidth;
            }

            if (!(previousRender == getChartSettings())) {
              previousRender = getChartSettings();
            }

            if (previousSource !== currentSource) {
              previousSource = currentSource;
            }

            var chart = this;
            cutoff = currentSource.length;

            this.credits.update({
              text:
                currentSource.length !== 0
                  ? cutoff != currentSource.length ||
                    currentSource.length > maxCreditLength
                    ? `source: ${currentSource.substring(0, cutoff)}...`
                    : `source: ${currentSource}`
                  : "",
              style: {
                whiteSpace: "normal",
                width:
                  chart.chartWidth -
                  (chart.poweredByRose.renderer.plotBox.x + 200),
                overflowWrap: "break-word",
                wordWrap: "break-word",
                wordBreak: "break-all",
                WebkitHyphens: "auto",
                MozHyphens: "auto",
                msHyphens: "auto",
                hyphens: "auto",
              },
            });

            navigatorAdjustment = 15;
            boundingBox = this.credits.element.getBoundingClientRect().height;
            var navigatorTop =
              boundingBox +
                this.credits.element.getBoundingClientRect().y +
                navigatorAdjustment || 0;

            if (widthFactor >= 0.66) {
              if (
                currentSource.length > maxCreditLength &&
                boundingBox < maxCreditBoxHeight
              ) {
                //base cutoff on credit length
                cutoff = maxCreditLength - 100;
              } else {
                // base cutoff on bounding box
                cutoff =
                  boundingBox >= 100
                    ? 10
                    : boundingBox >= 50
                    ? 25
                    : boundingBox >= 35
                    ? 50
                    : /* default value */ currentSource.length;
              }
            } else {
              if (widthFactor == 0.5) {
                cutoff = Math.min(currentSource.length, 60);
              } else {
                cutoff = Math.min(currentSource.length, 10);
              }
            }

            this.credits.update({
              text:
                currentSource.length !== 0
                  ? cutoff != currentSource.length ||
                    currentSource.length > maxCreditLength
                    ? `source: ${currentSource.substring(0, cutoff)}...`
                    : `source: ${currentSource}`
                  : "",
              style: {
                whiteSpace: "normal",
                width:
                  chart.chartWidth -
                  (chart.poweredByRose.renderer.plotBox.x + 200),
                overflowWrap: "break-word",
                wordWrap: "break-word",
                wordBreak: "break-all",
                WebkitHyphens: "auto",
                MozHyphens: "auto",
                msHyphens: "auto",
                hyphens: "auto",
              },
            });

            boundingBox = this.credits.element.getBoundingClientRect().height;
            if (widthFactor >= 0.66) {
              if (boundingBox >= 35) {
                navigatorAdjustment -= 10;
              }
              if (boundingBox > 50) {
                navigatorAdjustment -= 5;
              }
              if (boundingBox > 100) {
                navigatorAdjustment -= 5;
              }
            }

            navigatorTop =
              widthFactor >= 0.66
                ? this.credits.element.getBBox().height +
                  this.credits.element.getBBox().y +
                  navigatorAdjustment
                : chart.poweredByRose.element.y.animVal.value + 40;

            chart.update({
              navigator: {
                top:
                  currentSource.length == 0
                    ? chart.poweredByRose.element.y.animVal.value + 40
                    : navigatorTop,
              },
            });
          }

          this.update(
            {
              chart: {
                marginTop: positions.marginTop,
              },
            },
            false,
            false,
            false
          );

          this.legend.group.attr({
            transform: positions.legendTranslate,
          });
        },
        //--------------------------------------------------//

        selection(evt: any) {
          if (evt.type === "selection") {
            const xAxis = evt?.xAxis?.[0];
            const newChartSettings = {
              ...getChartSettings(),
              xAxis: {
                min: xAxis?.min ?? null,
                max: xAxis?.max ?? null,
              },
            };
            onChartSettingsChanged(newChartSettings);
          }
        },
      },
    },
    series: [],
  };

  const chartOptions = chartData;

  let intersection = data[0].code.split(".");
  for (let i = 1; i < data.length; i++) {
    intersection = _.intersection(intersection, data[i].code.split("."));
  }

  for (let i = 0; i < data.length; i++) {
    data[i].name = data[i].code;
    const difference = _.difference(data[i].code.split("."), intersection).join(
      "."
    );
    if (difference.length > 0) {
      data[i].name = difference;
    }
    updateChart(data[i], getChartSettings().datasets[data[i].code], i);
  }

  function getUnits(dataset: any) {
    return (
      dataset.metas.units_short ||
      dataset.metas.Unit ||
      dataset.metas.units ||
      dataset.metas.Denom ||
      dataset.metas.displayUnit ||
      dataset.metas.Units ||
      dataset.metas.UNITS ||
      dataset.metas.QUOTE_UNITS ||
      "units"
    );
  }

  function updateChart(dataset_data: any, chartSettings: any, idx: number) {
    chartOptions.loading = true;
    const units = getUnits(dataset_data);

    if (getChartSettings().source?.length > 0) {
      chartOptions.credits.text = `source: ${getChartSettings().source}`;
    } else {
      chartOptions.credits.text = "";
    }

    const chartMapKey = RoseModuleCodeSettings.composeId(
      roseObject.data.map(({ code }) => code)
    ) as keyof RoseModuleChartSettings;

    const onlyHasTempAxis =
      chartOptions.yAxis?.length === 1 &&
      chartOptions.yAxis?.[0].id === "__rose_temp__";
    // generate yAxes if the chart object doesn't exist in module settings
    if (
      !modSettings?.charts?.[chartMapKey] ||
      !modSettings?.charts?.[chartMapKey]?.yAxis?.length ||
      onlyHasTempAxis
    ) {
      chartOptions.yAxis.push({
        title: {
          text: null,
        },
        labels: {
          formatter() {
            return abbreviateNumber(this.value);
          },
          style: {
            color: chartSettings.color,
            fontSize: 14,
          },
        },
        showEmpty: false,
        visible: true,
        opposite: chartOptions.yAxis?.length % 2 != 0,
        defaultLabel:
          chartOptions.yAxis?.length % 2 != 0
            ? `right axis ${Math.floor(chartOptions.yAxis?.length / 2) + 1}`
            : `left axis ${Math.floor(chartOptions.yAxis?.length / 2) + 1}`,
        initialDataset: dataset_data.code,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        alignTicks: false,
        tickPixelInterval: 100,
        index: idx,
      });
    }

    let series: any = {
      code: dataset_data.code,
      actor: dataset_data.actor,
      name: chartSettings.name || dataset_data.code,
      metas: dataset_data.metas,
      yAxis: idx,
      showInLegend: !!chartSettings?.name,
      lineWidth: 2,
    };
    series.data = _.zip(
      _.map(_.keys(dataset_data.values), (date: string) =>
        new Date(
          Date.UTC(
            +date.split(/[^0-9]/)[0],
            +date.split(/[^0-9]/)[1] - 1,
            +date.split(/[^0-9]/)[2],
            +date.split(/[^0-9]/)[3],
            +date.split(/[^0-9]/)[4],
            +date.split(/[^0-9]/)[5]
          )
        ).getTime()
      ),
      _.values(dataset_data.values)
    );
    chartOptions.series.push(series);

    chartOptions.loading = false;
    fixAxes(chartOptions, theme);
    fixColors(chartOptions, theme);
  }

  return chartOptions;
}

export function fixAxes(chartOptions: any, theme: Theme) {
  // array of unique axes as listed in the drop down menu
  const uniqueAxes = [];
  // loop through all axes, and add non duplicates to the unique axes array
  for (const i in chartOptions.series) {
    const dataset = chartOptions.series[i];
    if (
      uniqueAxes.indexOf(dataset.yAxis) === -1 &&
      dataset.yAxis !== undefined
    ) {
      uniqueAxes.push(dataset.yAxis);
    }
  }
  // ???
  // let opposite_axis = false;

  // loop through each axis in the array of axes
  // also recalculate the default labels
  let leftCounter = 1;
  let rightCounter = 1;
  for (let i = 0; i < chartOptions.yAxis?.length; i++) {
    // recalc default label
    if (chartOptions.yAxis[i].opposite) {
      chartOptions.yAxis[i].defaultLabel = `Right Axis ${rightCounter}`;
      rightCounter++;
    } else {
      chartOptions.yAxis[i].defaultLabel = `Left Axis ${leftCounter}`;
      leftCounter++;
    }

    // if the i'th axis is not unique, it should not be visible; otherwise show the axis, and put it on the other side
    chartOptions.yAxis[i].alignTicks = false;
    // tickAmount: ifLabels(getChartSettings()) > 0 ? 5 : undefined
    chartOptions.yAxis[i].tickAmount = 5;
    if (uniqueAxes.indexOf(i) === -1) {
      chartOptions.yAxis[i].visible = false;
    } else {
      chartOptions.yAxis[i].visible = true;
      // chartOptions.yAxis[i].opposite = opposite_axis;
      // opposite_axis = !opposite_axis;
    }
  }

  return chartOptions;
}

export function fixColors(chartOptions: any, theme: Theme) {
  const themeValuesMap = Theme.themesMap[theme];
  const yAxisCounts = {};

  for (let i = 0; i < chartOptions.series.length; i++) {
    const yAxis = chartOptions.series[i].yAxis;
    if (!yAxisCounts[yAxis]) {
      yAxisCounts[yAxis] = [i];
    } else {
      yAxisCounts[yAxis].push(i);
    }
  }

  // Loop through all y-axes in the dictionary and assign colors
  Object.keys(yAxisCounts).forEach((axis) => {
    const seriesIndices = yAxisCounts[axis];
    const axisOptions = chartOptions.yAxis?.[axis];
    const colorIndex = seriesIndices.length > 1 ? -1 : seriesIndices[0];

    if (axisOptions) {
      axisOptions.gridLineColor = "transparent";
      axisOptions.labels = {
        ...axisOptions.labels,
        style: {
          ...axisOptions.labels?.style,
          color:
            colorIndex === -1
              ? themeValuesMap[ThemeVarNames.TimeseriesNeutral]
              : chartOptions.series[colorIndex]?.color ||
                chartOptions.colors[colorIndex],
        },
      };
      axisOptions.title = {
        ...axisOptions.title,
        style: {
          ...axisOptions.title?.style,
          color:
            colorIndex === -1
              ? themeValuesMap[ThemeVarNames.TimeseriesNeutral]
              : chartOptions.series[colorIndex]?.color ||
                chartOptions.colors[colorIndex],
        },
      };
    }
  });

  return chartOptions;
}

function getMetasPosition(width: number, hasSource: boolean, height?: number) {
  if (width < 0.66 && hasSource) {
    return {
      credits: {
        align: "right",
        verticalAlign: "bottom",
        x: 0,
        y: -85,
      },
      poweredBy: {
        x: 20,
        y: height - 100,
      },
    };
  } else {
    return {
      credits: {
        align: "right",
        verticalAlign: "bottom",
        x: -10,
        y: -75,
      },
      poweredBy: {
        x: 20,
        y: height - 95,
      },
    };
  }
}
