import React from "react";

const backers = [
  { filename: "portage.png", alt: "portage ventures logo", href: "https://portageinvest.com/" },
  { filename: "streamlined.png", alt: "streamlined logo", href: "https://www.streamlined.vc/" },
  { filename: "box-group.png", alt: "box group logo", href: "https://www.boxgroup.com/" },
  { filename: "sound-ventures.png", alt: "sound ventures logo", href: "https://www.soundventures.com/" },
  { filename: "soma-capital.png", alt: "soma capital logo", href: "https://somacap.com/" },
  { filename: "great-oaks.png", alt: "great oaks logo", href: "https://www.greatoaksvc.com/" },
  { filename: "angel-list.png", alt: "angel list logo", href: "https://www.angellist.com/" },
  { filename: "uv.png", alt: "unpopular ventures logo", href: "https://www.unpopular.vc/" },
]

const BackedBySection = () => {
  return (
    <div className="container flex flex-col items-center mx-auto px-4 md:px-10 my-20 gap-5">
      <div className="text-white text-[32px] md:text-[36px] font-bold my-5">Backed By</div>
      <div className="flex flex-row flex-wrap justify-evenly gap-10 max-w-4xl">
        {backers.map((backer, index) => <a key={index} href={backer.href} target="_blank" className="flex justify-center hover:scale-105">
          <img className="object-scale-down max-h-8 opacity-75 hover:opacity-100"
            src={`/static/img/vc_logos/${backer.filename}`}
            alt={backer.alt}
          />
          </a>)}
      </div>
    </div>
  );
};

export default BackedBySection;
