import styled from 'styled-components';

import {device} from '@utils/breakpoints';
import {ThemeVarNames} from '@theme';

interface ColorProps {
  active: boolean
}

interface MobileProps {
  showMobile: boolean
  primary: boolean
  border: boolean
}

const HeaderContainerSticky = styled.div`
  width: 100%;
  min-height: 70px;
  position: sticky;
  top: 0;
  justify-content: center;
  align-items: center;
  background-color: var(${ThemeVarNames.SecondaryBg});
  border-bottom: 0.5px solid var(${ThemeVarNames.Border});
  color: var(${ThemeVarNames.PrimaryText});
  z-index: 10;
`;

const MobileHeaderContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(${ThemeVarNames.Border});
  transition: 0.2s all ease-in-out;
  z-index: 100;
`;

const DesktopWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1300px;
  padding: 0 24px;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(${ThemeVarNames.PrimaryText});
`;

const Logo = styled.img`
  height: 30px;
  width: 30px;
`;

const LinkContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  & > button:last-child {
    margin-left: 10px;
  }

  @media screen and (max-width: 959px) {
    display: none;
  }
`;

const Link = styled.a`
  line-height: 40px;
  padding: 10px;
  text-align: center;
  color: var(${(p: ColorProps) =>
    p.active ? ThemeVarNames.Action : ThemeVarNames.TertiaryText}) !important;
  font-weight: 600;
  font-size: 15px;
  transition: 0.2s all ease-in-out;
  border-radius: 10px;

  :hover {
    background-color: var(${ThemeVarNames.Hover});
    cursor: pointer;
  }
`;

const LinkLogo = styled.a`
  line-height: 40px;
  padding: 10px;
  text-align: center;
  color: var(${(p: ColorProps) =>
    p.active ? ThemeVarNames.Action : ThemeVarNames.TertiaryText}) !important;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
`;

const ProfileContainer = styled.div`
  background-color: var(${ThemeVarNames.TertiaryBg});
  padding: 8px 16px;

  * {
    color: var(${ThemeVarNames.SecondaryText});
  }
`;

const Mobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  cursor: pointer;

  @media screen and (min-width: 959px) {
    display: none;
  }
`;
const ScrollContainerFixed = styled.div<Pick<MobileProps, 'showMobile'>>`
  height: 100vh;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: ${(p) => p.showMobile ? '0px' : '-200vh'};
  opacity: ${(p) => p.showMobile ? 1 : 0};
  transition: 0.2s all ease-in-out;
  z-index: 1000;
`;

const MobileContainer = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 100;
  padding-bottom: 5vh;
  transition: 0.3s all ease-in-out;
  overflow: auto;
  background-color: var(${ThemeVarNames.TertiaryBg});
`;

const MobileLinkContainer = styled.div<Pick<MobileProps, 'border'>>`
  display: flex;
  width: 100%;
  border-bottom: ${(p) => p.border ? '1px solid' : 'none'};
  border-color: var(${ThemeVarNames.Border});
`;

const MobileLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 24px;
`;

const MobileLink = styled.a<Pick<MobileProps, 'primary'>>`
  height: 70px;
  border: 1px solid var(${ThemeVarNames.Border});
  color: var(${(p) => p.primary ? ThemeVarNames.ActionContrast : ThemeVarNames.PrimaryText});
  width: 100%;
  border-radius: 10px;
  text-align: center;
  line-height: 70px;
  margin: 10px 0;
  font-size: 1.3em;
  font-weight: 600;
  background-color: var(${(p) => p.primary ? ThemeVarNames.Action : ThemeVarNames.PrimaryBg});
  text-decoration: none;

  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
  
  :visited {
    color: var(${(p) => p.primary ? ThemeVarNames.ActionContrast : ThemeVarNames.PrimaryText});
  }
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export {
  MobileLink,
  MobileLinkWrapper,
  MobileLinkContainer,
  ScrollContainerFixed,
  Mobile,
  ProfileContainer,
  Link,
  LinkLogo,
  LinkContainer,
  Logo,
  HeaderContent,
  HeaderWrapper,
  DesktopWrapper,
  MobileHeaderContainer,
  HeaderContainerSticky,
  MobileContainer,
  StyledItem
};
