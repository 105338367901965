import * as React from 'react';
import {Radio as AntdRadio, RadioProps as AntdRadioProps} from 'antd';
import styled from 'styled-components';

import {ThemeVarNames} from '@theme';

type ExtraProps = {
  variant?: 'primary' | 'alternative'
};

export type RadioProps = AntdRadioProps & ExtraProps

export function Radio(props: RadioProps) {
  const {variant, className, ...restProps} = props;

  return (
    <StyledRadio
      {...restProps}
      className={`${className} ${variant ?? 'primary'}`}
    />
  );
}

Radio.Group = styled(AntdRadio.Group)`
  /* when the radio is focused a box-shadow is painted
   * around it. This is just to avoid the box-shadow is not cut
   */
  padding: 0 1px;
`;

export const StyledRadio = styled(AntdRadio)`
  --bg: var(${ThemeVarNames.InputPrimary});

  &.alternative {
    --bg: var(${ThemeVarNames.InputAlternative});
  }

  .ant-radio-inner {
    background-color: var(--bg);
    border-color: var(${ThemeVarNames.Border});
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: var(${ThemeVarNames.ShadowFocus});
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: var(${ThemeVarNames.Action});
    }
  }

  span {
    color: var(${ThemeVarNames.PrimaryText});
  }
`;
