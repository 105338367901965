import * as React from 'react';
import PrivacyTermsHero from '@components/common/PrivacyTermsHero/PrivacyTermsHero';
import {Footer} from '@components/common/Footer';
import PrivacyContent from '@components/landing/Privacy/components/PrivacyContent/PrivacyContent';
import {LandingContainer} from '@components/common/Containers';
import HelmetWrapper from '@utils/HelmetWrapper';
import {LandingHeader} from '@components/common';

const Privacy = (): JSX.Element => <>
  <LandingHeader colored={false} />

  <LandingContainer>
    <HelmetWrapper
      title="Privacy"
      description="Privacy Policy"
      pathname="/privacy"
    />
    <PrivacyContent />
    <Footer />
  </LandingContainer>
</>
  ;

export default Privacy;
