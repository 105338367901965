import * as React from 'react';
import styled from 'styled-components';
import {BsFiles} from '@react-icons/all-files/bs/BsFiles';
import {Tooltip} from 'antd';

import {UseMutationResult} from '@tanstack/react-query';
import {Button, PrivateButton} from '@components/common';

import {RoseNotebook} from '@types';
import {MutationType} from '@service/useNotebook';
import {CreateNewNotebookModal} from './CreateNewNotebookModal';

export type ButtonDuplicateProps = {
  color: string
  notebook: RoseNotebook
  mutation: UseMutationResult<RoseNotebook, any, MutationType, unknown>
}

export function ButtonDuplicate(props: ButtonDuplicateProps): JSX.Element {
  const {color, notebook, mutation} = props;
  const [showModal, setShowModal] = React.useState<boolean>(false);

  function handleOnClick() {
    setShowModal(true);
  }

  return (
    <>
      <Tooltip title="Duplicate notebook" placement="right">
        <Button
          id="duplicate-notebook-button"
          type="text"
          shape="circle"
          color={color}
          onClick={handleOnClick}
          disabled={notebook.code === '' || notebook.code === 'Untitled'}
        >
          <BsFiles size={24} />
        </Button>
      </Tooltip>
      <CreateNewNotebookModal
        visible={showModal}
        setVisible={setShowModal}
        code={notebook.code}
        type="duplicate"
        notebook={notebook}
        mutation={mutation}
      />
    </>
  );
}

const StyledPrivateButton = styled(PrivateButton)`
  background-color: transparent;
  color: ${(p) => p.color};
`;
