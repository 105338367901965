import {Button as AntdButton, ButtonProps as AntdButtonProps} from 'antd';
import styled from 'styled-components';

import {ThemeVarNames} from '@theme';

export type ButtonProps = AntdButtonProps;

const StyledButton = styled(AntdButton)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(${ThemeVarNames.PrimaryBg});
  color: var(${ThemeVarNames.Action});
  border-color: var(${ThemeVarNames.Action});

  :hover,
  :active {
    border-color: var(${ThemeVarNames.ActionDark});
    color: var(${ThemeVarNames.ActionDark});
    background-color: var(${ThemeVarNames.PrimaryBg});
  }

  :hover:focus {
    border-color: var(${ThemeVarNames.ActionDark});
    color: var(${ThemeVarNames.ActionDark});
    background-color: var(${ThemeVarNames.PrimaryBg});
  }

  :focus {
    border-color: var(${ThemeVarNames.ActionDark});
    color: var(${ThemeVarNames.ActionDark});
    background-color: var(${ThemeVarNames.PrimaryBg});
  }

  :disabled,
  :disabled:hover {
    background-color: var(${ThemeVarNames.Disabled});
    color: var(${ThemeVarNames.PrimaryText});
    border-color: var(${ThemeVarNames.Disabled});
  }

  svg {
    color: var(${ThemeVarNames.Action});
  }

  &.ant-btn-dangerous {
    border-color: var(${ThemeVarNames.Error});
    color: var(${ThemeVarNames.Error});

    :hover {
      border-color: var(${ThemeVarNames.ErrorLight});
      color: var(${ThemeVarNames.ErrorLight});
      background: inherit;
    }
  }

  &.ant-btn-primary {
    background-color: var(${ThemeVarNames.Action});
    color: var(${ThemeVarNames.ActionContrast});
    border-color: var(${ThemeVarNames.Action});

    svg {
      color: var(${ThemeVarNames.ActionContrast});
    }

    :hover {
      background-color: var(${ThemeVarNames.ActionDark});
    }

    :disabled,
    :disabled:hover {
      background-color: var(${ThemeVarNames.Disabled});
      color: var(${ThemeVarNames.PrimaryText});
      border-color: var(${ThemeVarNames.Disabled});
    }

    &.ant-btn-dangerous {
      background-color: var(${ThemeVarNames.ErrorDark});
      border-color: var(${ThemeVarNames.Error});

      :hover {
        background-color: var(${ThemeVarNames.Error});
      }
    }
  }

  &.ant-btn-ghost {
    background-color: transparent;
    color: var(${ThemeVarNames.PrimaryText});
    border-color: var(${ThemeVarNames.Border});

    svg {
      color: var(${ThemeVarNames.PrimaryText});
    }

    :hover {
      color: var(${ThemeVarNames.Action});
      border-color: var(${ThemeVarNames.Action});

      svg {
        color: var(${ThemeVarNames.Action});
      }
    }

    :disabled {
      background-color: inherit;
      color: var(${ThemeVarNames.Disabled});
      border-color: var(${ThemeVarNames.Disabled});

      svg {
        color: var(${ThemeVarNames.Disabled});
      }
    }

    :hover:disabled {
      background-color: inherit;
      color: var(${ThemeVarNames.DisabledDark});
      border-color: var(${ThemeVarNames.DisabledDark});

      svg {
        color: var(${ThemeVarNames.DisabledDark});
      }
    }
  }

  &.ant-btn-text {
    color: var(${ThemeVarNames.Action});
    background-color: transparent;
    border-color: transparent;

    :hover {
      color: var(${ThemeVarNames.ActionDark});
    }

    :disabled {
      color: var(${ThemeVarNames.Disabled});

      svg {
        color: var(${ThemeVarNames.Disabled});
      }
    }

    :hover:disabled {
      color: var(${ThemeVarNames.DisabledDark});
      background-color: transparent;
      border: none;
    }
  }
`;

export const Button = StyledButton;
