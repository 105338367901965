import {message} from 'antd';
import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {
  Spinner,
  Button,
  Form,
  InputPassword,
  Checkbox,
  Typography
} from '@components/common';
import UserService from '@utils/apis/UserService';
import {device} from '@utils/breakpoints';
import UserContainer from '@utils/state/userContainer';

type ContactResetFormProps = {
  password: string
  confirmPassword: string
  checkbox: boolean
}

export const ContactResetForm: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {setUser} = UserContainer.useContainer();
  const {token} = useParams<{ token: string }>();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invitee, setInvitee] = useState({
    firstName: '',
    lastName: '',
    username: ''
  });
  const [inviter, setInviter] = useState({
    firstName: '',
    lastName: ''
  });
  const [activation, setActivation] = useState<ContactResetFormProps>({
    password: '',
    confirmPassword: '',
    checkbox: false
  });
  const {password, confirmPassword, checkbox} = activation;

  const onHandleChange = (name: string) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setActivation({
      ...activation,
      [name]: e.currentTarget.value
    });
    setErrorMessage('');
  };

  const checkboxChange = (e: any) => {
    setActivation({
      ...activation,
      checkbox: e.target.checked
    });
    setErrorMessage('');
  };

  useEffect(() => {
    UserService.getInvitee(token).then((res: any) => {
      setInvitee(res?.data?.invitee);
      setInviter(res?.data?.inviter);
    });
  }, []);

  const onFinish = async (values: any) => {
    setIsLoading(true);
    try {
      await UserService.changeInviteePassword(token, values.password).then(
        (res) => {
          if (res?.data?.message) {
            message.success(res?.data?.message, 3);
            setTimeout(async () => {
              const newUser = await UserService.get();
              setUser(newUser);
              navigate('/dashboard/rose.intro');
            }, 1000);
          }
        }
      );
    } catch (e) {
      setErrorMessage(e.data.message);
      setIsLoading(false);
    }

    form.resetFields();
  };

  const headerMessage = `Hello ${invitee.firstName}, your account has been created by
  ${inviter.firstName} ${inviter.lastName}, and you need to create a
  password to activate.`;

  return (
    <FormContainer>
      <MessageContainer>
        <StyledTitle forwardedAs="h1" variant="secondary">
          Set your account password
        </StyledTitle>
        <StyledMessage forwardedAs="p" variant="primary">
          {headerMessage}
        </StyledMessage>
        <StyledMessage forwardedAs="p" variant="primary">
          Your username:
          <b> {invitee.username} </b>
        </StyledMessage>
      </MessageContainer>
      <StyledFormContainer>
        <StyledForm
          name="activation_form"
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            label="New Password"
            name="password"
            rules={[{required: true, min: 8, message: 'Password must be a minimum of 8 characters'}]}
          >
            <InputPassword
              value={password}
              onChange={onHandleChange('password')}
            />
          </Form.Item>
          <Form.Item
            label="Repeat New Password"
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              {required: true, message: 'Please input password'},
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  );
                }
              })
            ]}
          >
            <InputPassword
              value={confirmPassword}
              onChange={onHandleChange('confirmPassword')}
            />
          </Form.Item>
          {errorMessage && errorMessage.length ?
            <ErrorMessage
              forwardedAs="p"
              size="small"
              variant="warning"
            >
              {errorMessage}
            </ErrorMessage> :
            null}
          <Form.Item
            name="termsAndConditions"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ?
                    Promise.resolve() :
                    Promise.reject(
                      new Error('Please accept Terms and Conditions')
                    )
              }
            ]}
          >
            <Checkbox value={checkbox} onChange={(e) => checkboxChange(e)}>
              By activating my account, I accept the
              <a href="/terms"> Terms & Conditions</a>
            </Checkbox>
          </Form.Item>
          <Form.Item shouldUpdate>
            <StyledButton
              type="primary"
              htmlType="submit"
              size="large"
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({errors}) => errors.length)
                  .length ||
                !password ||
                !confirmPassword ||
                !checkbox
              }
            >
              {isLoading ? <Spinner size={24} /> : 'Activate Account'}
            </StyledButton>
          </Form.Item>
        </StyledForm>
      </StyledFormContainer>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  padding: 16px;
`;
const StyledFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledForm = styled(Form)`
  max-width: 600px;
  width: 100%;
  position: relative;
  padding: 20px;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07),
    0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 8px;
`;

const MessageContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
`;
const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 24px;

  ${device.desktop} {
    font-size: 40px;
  }
`;
const StyledMessage = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 24px;
`;
const StyledButton = styled(Button)`
  width: 100%;
`;
const ErrorMessage = styled(Typography)`
  margin-bottom: 24px;
  font-size: 14px;
  color: red;
  text-align: center;
`;
