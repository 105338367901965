import React, {useState} from 'react';
import styled from 'styled-components';
import {Button, Card, List} from 'antd';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';

import ActorService from '@utils/apis/ActorService';
import {ThemeVarNames, Theme, useTheme} from '@theme';

import CreditCardModal from './components/CreditCardModal';

const stripePromise = loadStripe('pk_live_wyaUNHb1x0HPnKjLjj0qdemd');

const SettingsContainer = styled.div`
  /* Styles for big screens */
  display: flex;
  flex-direction: row;
  overflow: hidden;

  @media (max-width: 1050px) {
    /* Styles for small screens */
    flex-direction: column;
  }
`;

const CardsContainer = styled.div`
  padding-bottom: 10px;

  .StripeElement,
  .StripeElement .InputElement {
    background: var(${ThemeVarNames.PrimaryBg});
    color: var(${ThemeVarNames.PrimaryText});
  }
`;
const SettingsMenuContainer = styled.div`
  width: 250px;
  flex-shrink: 0;
  height: 100%;
  overflow-y: auto;
  padding-left: 20px;
  padding-top: 70px;
  color: var(${ThemeVarNames.PrimaryText});
  ul.menu li {
    white-space: nowrap;
    padding: 5px;
  }

  ul.menu li.selected {
    background-color: var(${ThemeVarNames.Hover});
  }

  ul.menu li:hover {
    cursor: pointer;
    background-color: var(${ThemeVarNames.Hover});
  }

  @media (max-width: 1050px) {
    /* Styles for small screens */
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;

    ul.menu {
      display: flex;
      listStyle: none;
      padding: 10px;
    }
  }
`;
const StyledCard = styled(Card)`
  background-color: var(${ThemeVarNames.TertiaryBg});
  border: none;
  margin-top: 40px;
  margin-left: 90px;

  .ant-card-head {
    color: var(${ThemeVarNames.PrimaryText});
    boarder: none;
    font-size: 25px;
  }

  .ant-card-body {
    color: var(${ThemeVarNames.PrimaryText});
    boarder: none;
  }

  .ant-list-item {
    color: var(${ThemeVarNames.PrimaryText});
    boarder: none;
  }

  @media (max-width: 1050px) {
    /* Styles for small screens */
    margin-left: 20px;
    margin-top: 0px;
  }
`;
const ThemeSettingsCard = styled(StyledCard)`
  .ant-card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-list-item {
    padding-left: 0;
    padding-right: 0;
  }
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;
const PasswordCard = styled(StyledCard)`
  display: flex;
  flex-direction: column;
`;
const UsageCard = styled(StyledCard)`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    /* If the viewport is 768px or wider, set the width to 700px */
    width: 768px;
  }
`;
const BankAccountCard = styled(StyledCard)``;
const CreditCardCard = styled(StyledCard)`
  .ant-card-body {
    padding-bottom: 12px;
    padding-top: 0;
  }

  .ant-list-item {
    padding-left: 0;
    padding-right: 0;
  }
`;
const StyledElement = styled(CardElement)`
  border: 1px solid var(${ThemeVarNames.Border});
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 2px;
`;
const UsageRow = styled(List.Item)`
  display: flex;
  padding: 12px;
  justify-content: space-between;
  border-color: var(${ThemeVarNames.Border}) !important;
  border-bottom: solid 1px white;
`;
const CreditCardButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const UpdateCreditCardButton = styled(Button)`
  margin-right: 20px;
  margin-bottom: 12px;
  width: 164px;
`;
const RemoveCreditCardButton = styled(Button)`
  margin-bottom: 12px;
  width: 164px;
`;
const StyledListItem = styled(List.Item)`
  display: flex;
  justify-content: space-between;
  border-color: var(${ThemeVarNames.Border}) !important;
`;
const StyledForm = styled.form`
  margin-bottom: 0;
`;
enum ModalState {
  Hidden,
  Loading,
  Success,
  Error,
}
const CheckoutForm = ({onRemoveCreditCard, getCreditCardInfo}: any) => {
  const [creditCardModalState, setCreditCardModalState] = useState(
    ModalState.Hidden
  );
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();
  const themeValuesMap = Theme.themesMap[theme];

  const stripe = useStripe();
  const elements = useElements();

  const submitStripe = async (event: any) => {
    // Block native form submission.
    event.preventDefault();
    setCreditCardModalState(ModalState.Loading);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    });
    if (error) {
      setCreditCardModalState(ModalState.Error);
      setErrorMessage(error.message);
    } else {
      try {
        await stripe.createToken(cardElement).then((result) => {
          ActorService.updateCC(result.token.id);
        });
        setCreditCardModalState(ModalState.Success);
        const data = {
          last4: paymentMethod.card.last4,
          expMonth: paymentMethod.card.exp_month,
          expYear: paymentMethod.card.exp_year,
          addressZip: paymentMethod.billing_details.address.postal_code
        };
        await getCreditCardInfo(data);
      } catch (e) {
        setErrorMessage(e.data?.message);
        setCreditCardModalState(ModalState.Error);
      }
    }
  };

  return (
    <>
      <StyledForm onSubmit={submitStripe}>
        <StyledElement
          options={{
            style: {
              base: {
                padding: '20px',
                color: themeValuesMap[ThemeVarNames.SecondaryText],
                '::placeholder': {
                  color: themeValuesMap[ThemeVarNames.TertiaryText]
                }
              }
            }
          }}
        />
        <CreditCardButtons>
          <UpdateCreditCardButton htmlType="submit">
            Update Credit Card
          </UpdateCreditCardButton>
          <RemoveCreditCardButton onClick={onRemoveCreditCard}>
            Remove Credit Card
          </RemoveCreditCardButton>
        </CreditCardButtons>
      </StyledForm>
      <CreditCardModal
        state={creditCardModalState}
        onClose={() => setCreditCardModalState(ModalState.Hidden)}
        errorMessage={errorMessage}
      />
    </>
  );
};

export {
  ModalState,
  StyledForm,
  StyledListItem,
  RemoveCreditCardButton,
  UpdateCreditCardButton,
  CreditCardButtons,
  StyledElement,
  CreditCardCard,
  BankAccountCard,
  PasswordCard,
  CardsContainer,
  stripePromise,
  CheckoutForm,
  ThemeSettingsCard,
  FormContainer,
  SettingsMenuContainer,
  SettingsContainer,
  UsageCard,
  UsageRow
};
