/* eslint-disable */

import React, { useRef, useEffect } from 'react'
import {v4 as uuidv4} from 'uuid';
import * as d3 from 'd3'
import * as d3dag from 'd3-dag'
import { textWidth } from 'd3plus-text';

import { RoseModule } from '@types'
import { TreeContainer } from './styles.ts'

const NewTree = ({ treeData, addModule }) => {
  const treeRef = useRef(null)
  const addModuleRef = useRef(null)

  useEffect(() => {
    addModuleRef.current = addModule
  })

  useEffect(() => {
    const drawEntireTree = function (treeDataLower) {
      d3.select(treeRef.current).selectAll('svg').remove()
      // Sets standard width of svg, which is what d3 graphs are built on.

      // var svgWidth = window.innerWidth > 960 ? (window.innerWidth - 500) * (parseInt(scope.moduleWidth) / 100) : window.innerWidth - 140 * (parseInt(scope.moduleWidth) / 100)

      const svgWidth = treeRef.current.clientWidth
      // Math that dynamically calculates width of the div; 960 is the cut off between desktop and mobile screens;
      // -100 to fit within the Rose white space; 80% to fit within the space under each module
      const svgHeight = 500 // standard
      const margin = {
        // standard
        top: 25,
        bottom: 20,
        left: 20,
        right: 10,
      }
      const svgTreeWidth = svgWidth + margin.left + margin.right
      const svgTreeHeight = svgHeight + margin.top + margin.bottom

      /*
            The code below will convert the tree structure provided from the backend into a structure that lists each nodes parents
            The reason we do this is because Rose does not run on trees but rather on DAGs, the difference being that DAG nodes can have multiple parents
            */
      let cleanedNodesIndex = {}
      let cleanedNodes = []
      let nodes = []
      let root = null
      const fillNodes = function (node, parent) {
        node.uuid = uuidv4()
        let nodeSections = node.code.split(':')

        if (node.type == 'file_location') {
          nodeSections = [node.code]
          node.codeToShow = node.code
        } else {
          node.transformations = nodeSections
            .slice(1, nodeSections.length)
            .join(':')
          node.codeToShow = node.showFull ? node.code : nodeSections[0]
          nodeSections = node.codeToShow.split(':')
        }

        const isValidNode =
          !parent
          || (parent && !parent.code.includes(node.codeToShow))
          || (parent && parent.code.split(':')[0]!==node.codeToShow.split(':')[0])

        if (isValidNode && cleanedNodesIndex[node.codeToShow] == undefined) {
          let longestSection = nodeSections[0]
          for (var i = 0; i < nodeSections.length; i++) {
            if (
              getNodeWidthInPixels(nodeSections[i]) >
              getNodeWidthInPixels(longestSection)
            ) {
              longestSection = nodeSections[i]
            }
          }
          if (longestSection != nodeSections[0]) {
            longestSection = ` :${longestSection}`
          }
          const height =
            getNodeHeightInPixels(nodeSections[0]) * nodeSections.length +
            (nodeSections.length - 1) * 10

          const nodeData = {
            uuid: node.uuid,
            fullCode: node.code,
            code: node.codeToShow,
            is_logic: node.is_logic,
            type: node.type,
            value: node.value,
            date: node.date,
            parentIds: [],
            width: getNodeWidthInPixels(longestSection) + 20,
            height: height + 20,
            lastUpdatedAt: node.updated_at,
            lastUpdatedBy: node.updated_by,
            hasTransformations: nodeSections.length > 1,
            connection: node.connection,
          }
          cleanedNodesIndex[node.uuid] = cleanedNodes.length
          cleanedNodes.push(nodeData)
        }

        if (
          cleanedNodesIndex[node.uuid] &&
          node.codeToShow == node.code
        ) {
          cleanedNodes[cleanedNodesIndex[node.uuid]].type = node.type
        }

        if (
          isValidNode &&
          parent != null &&
          cleanedNodes[cleanedNodesIndex[node.uuid]].parentIds.indexOf(
            parent.uuid,
          ) == -1
        ) {
          cleanedNodes[cleanedNodesIndex[node.uuid]].parentIds.push(
            parent.uuid,
          )
        }
        if (node.children && node.children.length > 0) {
          for (var i = 0; i < node.children.length; i++) {
            if (isValidNode) {
              fillNodes(node.children[i], node)
            } else {
              fillNodes(node.children[i], parent)
            }
          }
        }
      }

      function setupNodes() {
        cleanedNodesIndex = {}
        cleanedNodes = []
        nodes = []
        root = null
        fillNodes(treeDataLower, null)
        /**/

        nodes = d3dag.dagStratify()
          .id(function (node) {
            return node.uuid
          })(cleanedNodes) // Creates DAG structure

        root = nodes.descendants()[0] // root is the first item in the array
        // A field for the collapse/expand functionality. If a datum has no ._children it should collapse. The children are then
        // stored in the field and then when clicked again, it exapnds.
        root.descendants().forEach(function (d, i) {
          d._children = d.children
          // d.children = []
        })
      }

      const nodePadding = 10 * 2
      const nodeText = 10
      const nodeMargin = 10
      const nodeHeight = nodePadding + nodeText + nodeMargin

      const tree = d3dag.sugiyama()
        .layering(d3dag.layeringSimplexEnsureSiblingsInSameLayer())
        .coord(d3dag.coordCenter())
        .nodeSize([
          function (node) {
            return nodeHeight
          },
          function (node) {
            return node.data ? node.data.width * (6 / 10) : 0
          },
        ])
        .layerFunc(function (nodes) {
          const nodesWithValues = _.filter(nodes, function (node) {
            return node.data && node.data.value
          })
          const nodesThatAreNotDummyNodes = _.filter(nodes, function (node) {
            return node.data != undefined
          })

          const layerMin = nodesThatAreNotDummyNodes[0].x
          const layerMax =
            nodesThatAreNotDummyNodes[nodesThatAreNotDummyNodes.length - 1].x
          const layerMidPoint = (layerMax + layerMin) / 2

          const paddingBetweenNodes = 10

          let totalLayerHeight = 0

          for (let i = 0; i < nodesThatAreNotDummyNodes.length - 1; i++) {
            totalLayerHeight =
              totalLayerHeight +
              nodesThatAreNotDummyNodes[i].data.height +
              paddingBetweenNodes
          }
          totalLayerHeight +=
            nodesThatAreNotDummyNodes[nodesThatAreNotDummyNodes.length - 1].data
              .height

          let newLayerCounter = layerMidPoint - totalLayerHeight / 2

          for (let i = 0; i < nodesThatAreNotDummyNodes.length - 1; i++) {
            nodesThatAreNotDummyNodes[i].x = newLayerCounter
            newLayerCounter =
              newLayerCounter +
              nodesThatAreNotDummyNodes[i].data.height / 2 +
              nodesThatAreNotDummyNodes[i + 1].data.height / 2 +
              paddingBetweenNodes
          }
          nodesThatAreNotDummyNodes[
            nodesThatAreNotDummyNodes.length - 1
          ].x = newLayerCounter

          // feature-x feature-y, original-x, original-y
          // we are adding spacing between groups by adjust node.x
          // for now, we assume groups dont overlap
          /**/

          const howMuchSpace = 100

          // groupedByParent is an object of Arrays (groups in layer) whose elements are nodes

          // group all nodes (not just ones by value) by parents
          const groupedByParentAllNodes = _.groupBy(
            nodesThatAreNotDummyNodes,
            function (node) {
              if (Object.keys(cleanedNodesIndex).indexOf(node.id) != -1) {
                // Ignore dummy nodes
                if (
                  cleanedNodes[cleanedNodesIndex[node.id]].parentIds.length > 0
                ) {
                  // Make sure they have parents
                  return cleanedNodes[
                    cleanedNodesIndex[node.id]
                  ].parentIds.join(', ')
                }
                return 1
              }
            },
          )

          // give each group its max and min x
          let groupArray = []
          let totalNumberOfGroupsWithChildren = 0 // just a counter for the loop
          for (const key in groupedByParentAllNodes) {
            const group = groupedByParentAllNodes[key]
            let tempMin = Number.POSITIVE_INFINITY
            for (let i = 0; i < group.length; i++) {
              const node = group[i]
              if (node.x < tempMin) {
                tempMin = node.x
              }
            }
            group.minX = tempMin
            groupArray.push(group)
            if (group.length > 1) {
              totalNumberOfGroupsWithChildren += 1
            }
          }
          groupArray = _.sortBy(groupArray, 'minX')

          // doing a similar thing as with nodeWithCorrectGroupedValues
          // but with adjusted x coords
          // nodeWithCorrectGroupedXs gets passed on below
          let groupOrderIndex = 0
          let lastGroupHadChildren = true
          const something = false
          const nodeWithCorrectGroupedXs = _.mapValues(
            _.keyBy(
              _.flatten(
                _.map(groupArray, function (group) {
                  if (group.length > 1 && !lastGroupHadChildren) {
                    groupOrderIndex += 1
                  }
                  const result = _.map(group, function (node) {
                    node['group-x'] =
                      -1 * node.x -
                      (groupOrderIndex -
                        Math.floor(totalNumberOfGroupsWithChildren / 2)) *
                        howMuchSpace
                    return node
                  })
                  lastGroupHadChildren = group.length > 1
                  if (group.length > 1) {
                    groupOrderIndex += 1
                  }
                  return result
                }),
              ),
              'id',
            ),
            function (node) {
              return node['group-x']
            },
          )

          // var layerKeys = Object.keys(nodeWithCorrectLayeredValues)
          // var groupKeys = Object.keys(nodeWithCorrectGroupedValues)
          const groupXKeys = Object.keys(nodeWithCorrectGroupedXs)
          return _.map(nodes, function (node) {
            node.x = nodeWithCorrectGroupedXs[node.id] // Flip x value to mirror DAG on viz
            node['original-x'] = node.x

            // if (layerKeys.indexOf(node.id) != -1) {
            //   node['layer-value'] = nodeWithCorrectLayeredValues[node.id]
            // }
            // if (groupKeys.indexOf(node.id) != -1) {
            //   node['group-value'] = nodeWithCorrectGroupedValues[node.id]
            // }
            if (groupXKeys.indexOf(node.id)) {
              node['group-x'] = nodeWithCorrectGroupedXs[node.id]
            }
            return node
          })
        }) // This is the function that takes in the d3 hierarchy object with children. Sets specifc x/y values for a tidy tree (see d3 documentation on hierarchy for more info)

      // The three following functions are for intial centering and creating a zoomable svg.
      // They are used to create or called by the d3 zoom object.
      function zoomed(event) {
        g.attr('transform', event.transform)
      }
      // D3 always starts with creating an svg like this. After appending the svg,
      // I set height and width and other style attributes. Finally, I call the above
      // zoom function, which makes it always zoomable by scrolling and I disable
      // zoom in on double click, so that it can be used for creating new modules
      // of the code contained in the node.
      const svg = d3
        .select(treeRef.current)
        .append('svg')
        .attr('width', svgTreeWidth)
        .attr('height', svgTreeHeight)
        .style('margin', '0 auto')
        // .style('margin-top', '2.5%')
        .style('display', 'block')
        .style('overflow', 'scroll')
        .style('position', 'relative')
        .on('dblclick.zoom', null) // so that you can add a module on double click

      var g = svg.append('g') // In d3, within an svg, it is convention to store everything within a g.

      // Setting up the groups that will hold the links (arrows) and the nodes. I am not binding data here,
      // but instead initalizing them at a more global level than where I do bind data, so they can
      // accessed across functions.

      let firstLoad = true
      const colorMatching = {
        type: typeColor,
        value: valueColor,
        date: dateColor,
      }
      const currentColorScheme = 'value'

      function draw(source) {
        // Building the intial tree. This does not create visuals, it creates the dataset with the x,y coordiates that will be used

        setupNodes()
        if (source == undefined) {
          source = root
        }
        nodes = tree(root)

        let minTreeX = 0
        let maxTreeX = 0
        let minTreeY = 0
        let maxTreeY = 0
        nodes.descendants().forEach(function (d) {
          minTreeX = Math.min(minTreeX, d.x)
          maxTreeX = Math.max(maxTreeX, d.x)
          minTreeY = Math.min(minTreeY, d.y)
          maxTreeY = Math.max(maxTreeY, d.y)
        })
        const treeHeight = maxTreeX - minTreeX
        const treeWidth = maxTreeY - minTreeY
        const minScale = Math.min(svgWidth/treeWidth * 0.8, svgHeight/treeHeight * 0.8, 1)
        const zoomFun = d3
          .zoom()
          .scaleExtent([minScale,2]) // Svg can scale from 0.8 of its size to 2 times its size
          .on('zoom', zoomed)

        svg.call(zoomFun)

        if (firstLoad) {
          svg.call(zoomFun.transform, function () {
            return d3.zoomIdentity
              .translate(svgWidth / 2 - nodes.y, svgHeight / 2 - nodes.x)
              .scale(1) // .scale(scaleRatio);
          }) // Centers the tree on inital load
          root.x0 = nodes.x
          root.y0 = nodes.y
          firstLoad = false
        }

        const links = root.links() // a d3 function that returns all the links below a node. this returns all links in the tree

        // creating all nodes as points at their source location. When the buildout gets to their spot
        // they will expand to stretch from there source to target.
        const link = g.selectAll('.link').data(links, function (d) {
          return d.target.id
        })

        link
          .enter()
          .append('line')
          .style('stroke', '#bdc3c7')
          .attr('class', 'link')
          .attr('x1', function (d) {
            return (source.y0 || 0) + source.data.width / 2
          })
          .attr('y1', function (d) {
            return source.x0 || 0
          })
          .attr('x2', function (d) {
            return (source.y0 || 0) + source.data.width / 2
          })
          .attr('y2', function (d) {
            return source.x0 || 0
          })
          .transition()
          .duration(400)
          .attr('x1', function (d) {
            return d.source.y + d.source.data.width / 2
          })
          .attr('y1', function (d) {
            return d.source.x
          })
          .attr('x2', function (d) {
            return d.target.y - d.target.data.width / 2
          })
          .attr('y2', function (d) {
            return d.target.x
          })
        link
          .transition()
          .duration(400)
          .attr('x1', function (d) {
            return d.source.y + d.source.data.width / 2
          })
          .attr('y1', function (d) {
            return d.source.x
          })
          .attr('x2', function (d) {
            return d.target.y - d.target.data.width / 2
          })
          .attr('y2', function (d) {
            return d.target.x
          })
        link
          .exit()
          .transition()
          .duration(400)
          .attr('x1', function (d) {
            return source.y + d.source.data.width / 2
          })
          .attr('y1', function (d) {
            return source.x
          })
          .attr('x2', function (d) {
            return source.y + d.target.data.width / 2
          })
          .attr('y2', function (d) {
            return source.x
          })
          .remove()

        const nodeList = nodes.descendants()

        const node = g.selectAll('.node').data(nodeList, function (d) {
          return d.id
        }) // DATABIND. we are attaching an array of all the ndoes we will use.

        const nodeEnter = node
          .enter()
          .append('g') // enter() returns anything in the data that does not have a corresponding object within the group.
          // This allows us to append things to all of the new data (which is all of our data) we have just bound
          .attr('class', function (d) {
            return `node${d.children ? ' node--internal' : ' node--leaf'}`
          })
          .attr('class', 'node')
          .attr('id', function (d, i) {
            return `node-${i}`
          })

        // Finally attaching rectangles. The spot within the svg is already determined. these
        // rectangles will be children to to the g nodes.
        nodeEnter
          .append('rect')
          .attr('class', 'nodeB')
          .attr('id', function (d, i) {
            return `nodeRect-${i}`
          })
          .transition()
          .duration(400)
          .attr('rx', 6) // rounded corners
          .attr('ry', 6)
          .attr('height', function (d) {
            return d.data.height
          })
          .attr('width', function (d) {
            return d.data.width // this value requires size 14 font: PADDING
          })
          .attr('stroke-width', 1)
          .attr('stroke', '#fff')
          .attr('fill', colorMatching[currentColorScheme])
          .attr('y', function (d) {
            return (-1 * d.data.height) / 2
          }) // 12.5 is half of 25, this just puts it in the center
          .attr('x', function (d, i) {
            return (-1 * d.data.width) / 2
          })

        nodeEnter
          .append('text')
          .attr('class', 'nodeText')
          .attr('id', function (d, i) {
            return `nodeText-${i}`
          })
          .style('visibility', 'visible')
          .style('text-anchor', 'start') // centers the code
          .style('fill', 'white')
          .transition()
          .duration(400)
          .attr('font-size', 14) // This is harded coded in and other hard coded values rely on it.

        nodeEnter
          .append('rect')
          .attr("id", function(d, i) {
            return "metdataRect-" + i
          })
          .attr('rx', 6) // rounded corners
          .attr('ry', 6)
          .attr('height', function(d) {
            return 40
          })
          .attr('width', function(d) {
            return 230 // this value requires size 14 font: PADDING
          })
          .attr('stroke', 'transparent')
          .attr('fill', 'transparent')
          .attr('y', function(d) {
            return (d.data.height) / 2
          }) // 17.5 is half of 25, this just puts it in the center
          .attr('x', function(d, i) {
            return -1 * (d.data.width) / 2
          })
          .attr("pointer-events", "none")
        nodeEnter
          .append('text')
          .attr('class', 'metadataText')
          .attr("id", function(d, i) {
            return "metadataText-" + i
          })
          .attr('y', function(d) {
            return  ((d.data.height) / 2) + 25
          }) // Move the text to be centered in the tooltip rectangle (lower than node rectangle)
          .attr('x', function(d, i) {
            return (-1 * (d.data.width) / 2) + 5
          })
          .style('opacity', '0')
          .style('text-anchor', 'start') // centers the code
          .style('fill', 'black')
          .attr('font-size', 14) // This is harded coded in and other hard coded values rely on it.
          .attr("pointer-events", "none")


        nodeEnter
          .transition()
          .duration(400)
          .attr('transform', function (d) {
            return `translate(${d.y},${d.x})`
          })

        const insertLinebreaksInNode = function (d) {
          const el = d3.select(this)

          let sections = d.data.code.split(':')

          if (d.data.type == 'file_location') {
            sections = [d.data.code]
          }
          const x = (-1 * d.data.width) / 2 + 20 / 2
          const y = (-1 * d.data.height) / 2 + 20
          const dy = 20
          insertLinebreaks(el, sections, x, y, dy, ' :')
        }
        // var insertLinebreaksInMetadata = function(d) {
        //   var el = d3.select(this);
        //
        //   var sections = []
        //   sections.push("updated at: " + d.data['lastUpdatedAt'])
        //   sections.push("updated by: " + d.data['lastUpdatedBy'])
        //
        //   var longestSection = ''
        //   for (var i = 0; i < sections.length; i++) {
        //     if (sections[i].length > longestSection.length) {
        //       longestSection = sections[i]
        //     }
        //   }
        //
        //   var x = (d.data['width'] / 2)
        //   var y = d.data['height'] / 2 + 20
        //   var dy = 20
        //
        //   insertLinebreaks(el, sections, x, y, dy, '')
        // }

        var insertLinebreaks = function (el, sections, x, y, dy, separator) {
          el.text('')
          el.attr('x', x).attr('y', y)

          for (let i = 0; i < sections.length; i++) {
            let word = sections[i]
            if (i > 0) {
              word = separator + word
            }
            const tspan = el.append('tspan').text(word)
            if (i > 0) {
              tspan.attr('x', x).attr('dy', dy)
            }
          }
        }

        svg.selectAll('.nodeText').each(insertLinebreaksInNode)

        // collapse/expand functionality. When a node is clicked, its children nodes and links are selected
        // and moved into the parent (for nodes only) and their visibility is set to hidden. This is faster
        // than typical loops becasue of d3 functionality.

        let wait = null
        nodeEnter.on('click', function (event, d) {
          var nodeId = d3.select(this).attr('id')
          var metadataTextId = nodeId.replace('node-', 'metadataText-')
          var metdataRectId = nodeId.replace('node-', 'metdataRect-')

          function traverseAndChange(node, nodeToFlip) {
            if (node.uuid.includes(nodeToFlip)) {
              node.showFull = !node.showFull
            }
            if (node.children) {
              for (let i = 0; i < node.children.length; i++) {
                traverseAndChange(node.children[i], nodeToFlip)
              }
            }
          }
          if (!wait && (d.data.code!=d.data.fullCode | d.data.code.includes(':'))) {
            wait = window.setTimeout(
              (function () {
                return function () {
                  traverseAndChange(treeDataLower, d.data.uuid)
                  d3.select('#' + metdataRectId)
                    .transition()
                    .duration(200)
                    .style('fill', 'transparent')
                    .attr('stroke', 'transparent')

                  d3.select('#' + metadataTextId)
                    .transition()
                    .duration(200)
                    .style('opacity', 0)
                  draw(d)
                  wait = null
                }
              })(),
              250,
            )
          }
        })

        nodeEnter.on('dblclick', function (event, d) {
          if (d['data']['type']==='sql') {
            addModuleRef.current(RoseModule.create(
              { type: 'query', textBox: d.data.code,
                modulesettings: { connection: { code: d.data.connection } }
              })
            )
          } else {
            addModuleRef.current(RoseModule.create({ type: 'code', textBox: d.data.code }))
          }

          if (wait) {
            window.clearTimeout(wait)
            wait = null
          }
        })


        nodeEnter
          .on('mouseover', function (event, d) {
            d3.select(this).raise()
            d3.select(this).style('cursor', 'pointer')

            var nodeId = d3.select(this).attr('id')
            var metadataTextId = nodeId.replace('node-', 'metadataText-')
            var metdataRectId = nodeId.replace('node-', 'metdataRect-')
            var lastupdatedMeta = d.data['lastUpdatedAt']
            //Remove decimal values from time ex: 2021-06-14 17:56:42.337841 to 2021-06-14 17:56:42
            lastupdatedMeta = lastupdatedMeta.split(".")[0]

            d3.select(this).selectAll('#' + metdataRectId)
              .attr('width', function(d) {
                //if there is no metadata for last updated by, change the size of the box
                if (lastupdatedMeta == "None") {
                  return 140
                }
                return 258 // this value requires size 14 font: PADDING
              })
              .transition()
              .duration(400)
              .style('fill', 'white')
              .attr('stroke', '#bdc3c7')

            d3.select(this).selectAll('#' + metadataTextId)
              .transition()
              .duration(400)
              .style('opacity', 1)
              .text("Last Updated: " + lastupdatedMeta)
          })
          .on('mouseout', function (event, d) {
            d3.select(this).style('cursor', 'default')
            if (d._children.length == 0) {
              d3.select(this).lower()
            }
              var nodeId = d3.select(this).attr('id')
              var metadataTextId = nodeId.replace('node-', 'metadataText-')
              var metdataRectId = nodeId.replace('node-', 'metdataRect-')

              d3.select(this).selectAll('#' + metdataRectId)
                .transition()
                .duration(400)
                .style('fill', 'transparent')
                .attr('stroke', 'transparent')

              d3.select(this).selectAll('#' + metadataTextId)
                .transition()
                .duration(400)
                .style('opacity', 0)
          })

        const nodeUpdate = node
          .transition()
          .duration(400)
          .attr('transform', function (d) {
            return `translate(${d.y},${d.x})`
          })

        node
          .exit()
          .select('text')
          .transition()
          .duration(400)
          .attr('font-size', 0)

        node
          .exit()
          .select('rect')
          .transition()
          .duration(400)
          .attr('width', 0)
          .attr('height', 0)
          .attr('x', 0)
          .attr('y', 0)

        node
          .exit()
          .transition()
          .duration(400)
          .attr('transform', function (d) {
            return `translate(${
              source.y + getNodeWidthInPixels(source.id) / 2
            },${source.x})`
          })
          .remove()

        const firstColorChange = true

        nodes.descendants().forEach(function (d) {
          d.x0 = d.x
          d.y0 = d.y
        })
      }
      const expandAll = function () {
        function findAllNodes(node) {
          node.descendants().forEach(function (d, i) {
            if (d._children.length > 0) {
              d.children = d._children
              d._children = []
              for (var i = 0; i < d.children.length; i++) {
                findAllNodes(d.children[i])
              }
            }
          })
        }
        findAllNodes(root)
        draw(root)
      }
      draw(root)
    }

    function getNodeWidthInPixels(code) {
      return (
        textWidth(code, {
          'font-size': 14,
          'font-weight': 700,
        }) +
        (code.split('/').length - 1) * 6
      )
    }

    function getNodeHeightInPixels(code) {
      return 10
    }

    // Helpers for color functions
    function exponentialPct(pct) {
      return (
        (Math.log(Math.pow(2, 5) * Math.max(1 / 16, pct)) / Math.log(2) - 1) / 4
      )
    }

    function typeColor(d) {
      if (d.data.type == 'timeseries') return '#2196f3'
      if (d.data.type == 'map') return '#2dad31'
      if (d.data.type == 'file_location') {
        const extension = d.data.code.split('.')[
          d.data.code.split('.').length - 1
        ]
        if (extension.indexOf('xls') != -1) {
          return '#1B6C40' // Excel colors
        }
        if (extension.indexOf('py') != -1) {
          return '#F6CE41' // Python colors
        }
      } else return '#f1c40f'
    }

    // the math for what color a node should be based on value. takes a data point,
    // called in watch function. The buttons now just change the value of a color field,
    // if that value changes, d3 runs the calucations, so that everything remains in the  directive
    function valueColor(d) {
      const rgbToHex = function (rgb) {
        let hex = Number(rgb).toString(16)
        if (hex.length < 2) {
          hex = `0${hex}`
        }
        return hex
      }

      const fullColorHex = function (r, g, b) {
        const red = rgbToHex(r)
        const green = rgbToHex(g)
        const blue = rgbToHex(b)
        return `#${red}${green}${blue}`
      }

      const avg = 0
      const min = -1
      const max = 1
      const neutral_color = {
        red: 189,
        green: 195,
        blue: 199,
      }
      const bad_color = {
        red: 192,
        green: 57,
        blue: 43,
      }
      const good_color = {
        red: 41,
        green: 128,
        blue: 185,
      }
      let { value } = d.data

      if (value == undefined || d.data.type == 'map') {
        return typeColor(d)
      }
      if (value > max) {
        value = max
      }
      if (value < min) {
        value = min
      }
      const my_color = {
        red: null,
        green: null,
        blue: null,
      }
      const colors = ['red', 'green', 'blue']
      if (value == undefined) {
        return fullColorHex(
          neutral_color.red,
          neutral_color.green,
          neutral_color.blue,
        )
      }
      for (let j = 0; j < colors.length; j++) {
        var color = colors[j]
        let destination_color = bad_color
        let comparison_point = min
        if (value > avg) {
          destination_color = good_color
          comparison_point = max
        }
        const range = neutral_color[color] - destination_color[color]
        let range_pct = Math.abs(value / (comparison_point - avg))
        range_pct = exponentialPct(range_pct)
        const range_partial = parseInt(range * range_pct)
        my_color[color] = neutral_color[color] - range_partial
      }
      color = fullColorHex(my_color.red, my_color.green, my_color.blue)

      return color
    }

    function dateColor(d) {
      const neutral_date = 0
      const old_date = 180

      const neutral_color = {
        red: 189,
        green: 195,
        blue: 199,
      }
      const old_color = {
        red: 192,
        green: 57,
        blue: 43,
      }

      const date = new Date(d.data.date)
      const today = new Date()

      const dateDiff = function (date, today) {
        const todayTime = today.getTime()
        const dateTime = date.getTime()
        return parseInt((todayTime - dateTime) / (24 * 3600 * 1000))
      }

      let daysDiff = dateDiff(date, today)
      if (daysDiff > old_date) {
        daysDiff = old_date
      }
      if (daysDiff < neutral_date) {
        daysDiff = neutral_date
      }
      const my_color = {
        red: null,
        green: null,
        blue: null,
      }

      if (!('date' in d.data)) {
        color = fullColorHex(
          neutral_color.red,
          neutral_color.green,
          neutral_color.blue,
        )
      } else {
        const colors = ['red', 'green', 'blue']
        for (let j = 0; j < colors.length; j++) {
          var color = colors[j]
          const destination_color = old_color
          const comparison_point = old_date

          const range = neutral_color[color] - destination_color[color]
          let range_pct = Math.abs(daysDiff / (comparison_point - neutral_date))
          range_pct = exponentialPct(range_pct)
          const range_partial = parseInt(range * range_pct)
          my_color[color] = neutral_color[color] - range_partial
        }
        color = fullColorHex(my_color.red, my_color.green, my_color.blue)
        highlight = color

        if (d.data.type == 'map') {
          color = fullColorHex(
            neutral_color.red,
            neutral_color.green,
            neutral_color.blue,
          )
          highlight = fullColorHex(
            neutral_color.red,
            neutral_color.green,
            neutral_color.blue,
          )
        } else {
          color = fullColorHex(my_color.red, my_color.green, my_color.blue)
          highlight = color
        }
      }
      return color
    }

    // An equality funtion for links.
    function linkEquals(arr1, arr2) {
      return (
        arr1.source.x == arr2.source.x &&
        arr1.source.y == arr2.source.y &&
        arr1.target.x == arr2.target.x &&
        arr1.target.y == arr2.target.y
      )
    }

    drawEntireTree(treeData)
  }, [treeData])

  return <TreeContainer className={treeData.code} ref={treeRef} />
}

export default NewTree
