export const config = {
  backend: {
    url: process.env.REACT_APP_BACKEND_URL || 'https://api-uat.rose.ai',
    port: process.env.REACT_APP_BACKEND_PORT || '443',
  },
  vision: {
    url: process.env.REACT_APP_VISION_URL || 'https://api-uat.rose.ai',
  },
} as const;

export const getBackendUrl = () => config.backend.url;
export const getBackendPort = () => config.backend.port;
export const getVisionUrl = () => config.vision.url; 