import * as React from 'react';
import {Switch as AntdSwitch, SwitchProps as AntdSwitchProps} from 'antd';
import styled from 'styled-components';

import {ThemeVarNames} from '@theme';

type ExtraProps = {
  variant?: 'primary' | 'alternative'
};

export type SwitchProps = AntdSwitchProps & ExtraProps

export function Switch(props: SwitchProps) {
  const {variant, className, ...restProps} = props;

  return (
    <StyledSwitch
      {...restProps}
      className={`${className} ${variant ?? 'primary'}`}
    />
  );
}

export const StyledSwitch = styled(AntdSwitch)`
  --bg: var(${ThemeVarNames.InputPrimary});

  .ant-switch {
    border-color: var(${ThemeVarNames.Border});
  }
  
  &.alternative {
    --bg: var(${ThemeVarNames.InputAlternative});
  }
  background-color: var(${ThemeVarNames.InputAlternative});
  color: var(${ThemeVarNames.PrimaryText});

  &.ant-switch-focused {
    box-shadow: var(${ThemeVarNames.ShadowFocus});
  }

  &:not(.ant-switch-customize-input) .ant-switch {
    background-color: var(--bg);

    :hover {
      border-color: var(${ThemeVarNames});
    }
  }

  .ant-switch-selection-placeholder {
    color: var(${ThemeVarNames.TertiaryText});
  }

  .ant-switch-arrow {
    color: var(${ThemeVarNames.PrimaryText});
  }
`;
