import * as React from 'react';
import styled from 'styled-components';

import {SellingPackage, useSelling} from '@service/useSelling';
import {Spinner, Alert, Pagination, Empty} from '@components/common';
import {device} from '@utils/breakpoints';

import {Item} from './Item';

const PAGE_SIZE = 10;

export function ListPackages(): JSX.Element {
  const listContainerRef = React.useRef<HTMLUListElement>(null);
  const [page, setPage] = React.useState<number>(0);
  const {data, status, error, isFetching} = useSelling(page, PAGE_SIZE);

  if (status === 'loading') {
    return <Spinner size={48} padding="20px" />;
  }

  if (status === 'error') {
    return <Alert type="error" message={error.message} />;
  }

  const handleOnPageChange = (pageNumber: number) => {
    // we need (-1) and (+1) in current prop in the Pagination
    // because the pagination in the api start by 0
    setPage(pageNumber - 1);

    if (listContainerRef) {
      listContainerRef.current.scrollIntoView();
    }
  };

  const renderList = () => {
    if (data.items.length === 0) {
      return <Empty />;
    }

    return data.items.map((sellingPackage) =>
      <StyledItemContainer key={composeKey(sellingPackage)}>
        <Item pck={sellingPackage} />
      </StyledItemContainer>
    );
  };

  return (
    <>
      <StyledListContainer ref={listContainerRef}>
        {renderList()}
        {isFetching ?
          <StyledLoadingBackdrop>
            <Spinner size={48} />
          </StyledLoadingBackdrop> :
          null}
      </StyledListContainer>
      <StyledPaginationContainer>
        <Pagination
          onChange={handleOnPageChange}
          total={data.totalItems}
          current={page + 1}
          defaultPageSize={PAGE_SIZE}
          pageSize={PAGE_SIZE}
        />
      </StyledPaginationContainer>
    </>
  );
}

const StyledListContainer = styled.ul`
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  /* This has to be bigger than the dashboard header height
   * to see the full item after the scrollIntoView is triggered
   */
  scroll-margin-top: 80px;

  ${device.desktop} {
    gap: 0;
  }
`;

const StyledItemContainer = styled.li`
  position: relative;
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const StyledLoadingBackdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: hsl(0 0% 100% / 0.2);
`;

const StyledPaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;

function composeKey(pck: SellingPackage): string {
  return `${pck.code}-${pck.interval}`;
}
