import React, { useState, useLayoutEffect } from "react";
import { IoMenu } from "@react-icons/all-files/io5/IoMenu";
import { RiCloseFill } from "@react-icons/all-files/ri/RiCloseFill";
import { Link, useSearchParams } from "react-router-dom";
import UserContainer from "@utils/state/userContainer";
import { RoseBudBanner } from "./RoseBudBanner";

const LandingHeader = (): JSX.Element => {
  const { user } = UserContainer.useContainer();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [searchParams] = useSearchParams();
  const getUtm = (): string => localStorage.getItem("utm");

  // if utm_source exists, set it, but if empty, remove it
  if (searchParams.get("utm_source")) {
    localStorage.setItem("utm", searchParams.get("utm_source"));
  } else {
    localStorage.removeItem("utm");
  }

  useLayoutEffect(() => {
    const handleResize = () => {
      if (showMobileMenu && window.innerWidth > 959) {
        setShowMobileMenu(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return function cleanUpEvent() {
      window.removeEventListener("resize", handleResize);
    };
  }, [showMobileMenu]);

  return (
    <div className="fixed top-0 left-0 z-10 w-full backdrop-blur-sm">
      {/* Main Header */}
      <div className="py-3 px-5 md:px-10 flex items-center w-full">
        <div>
          <Link to={"/"} className="no-underline block">
            <img
              className="h-8"
              src="/img/new_logo/svg/RoseAIlogo-gradient.svg"
              alt="Rose.ai logo"
            />
          </Link>
        </div>

        <div className="!hidden md:!flex items-center text-white font-semibold ml-14 gap-8">
          <Link
            to={"/"}
            className="p-2 !text-white hover:!text-primary transition-all"
          >
            Home
          </Link>
          <Link
            to={"/solutions"}
            className="p-2 !text-white hover:!text-primary transition-all"
          >
            Solutions
          </Link>
          <a
            href="https://www.campbellramble.ai/"
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 !text-white hover:!text-primary transition-all"
          >
            Blog
          </a>
        </div>

        <div className="grow"></div>

        <div className="items-center text-white font-bold !hidden md:!flex">
          {!user?.id && (
            <Link
              to={"/login"}
              className="px-10 py-2 rounded-3xl bg-transparent !text-white hover:text-primary transition-all"
            >
              Log in
            </Link>
          )}
          {!user?.id ? (
            <Link
              to={"/register"}
              className="px-10 py-2 rounded-3xl bg-primary hover:opacity-90 transition-all !text-white"
            >
              Sign up
            </Link>
          ) : (
            <Link
              to="/dashboard"
              className="py-2 px-10 bg-white text-primary rounded-3xl"
            >
              Workspace
            </Link>
          )}
        </div>
        <button
          className="p-1 !block md:!hidden"
          onClick={() => setShowMobileMenu((prev) => !prev)}
        >
          {showMobileMenu ? (
            <RiCloseFill className="text-white text-[24px]" />
          ) : (
            <IoMenu className="text-white text-[24px]" />
          )}
        </button>
        {showMobileMenu && (
          <div className="h-screen w-full fixed top-0 left-0 bg-black -z-10">
            <div
              style={{
                background: 'url("/img/mobile-menu-background.png")',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              className="h-full w-full pt-20 flex flex-col pb-10 px-10"
            >
              <div className="text-white flex flex-col gap-5 mt-10 font-bold text-[32px]">
                <Link to={"/"} className="!text-white">
                  Home
                </Link>
                <Link to={"/blogs"} className="!text-white">
                  Blog
                </Link>
                <Link to={"/solutions"} className="!text-white">
                  Solutions
                </Link>
              </div>
              <div className="px-5 flex gap-4 flex-col mt-auto text-white font-bold">
                {!user?.id && (
                  <Link
                    to={"/login"}
                    className="bg-black rounded-3xl py-2 w-full text-center !text-white"
                  >
                    Log in
                  </Link>
                )}
                {!user?.id ? (
                  <Link
                    to={"/register"}
                    className="bg-primary rounded-3xl py-2 w-full text-center !text-white"
                  >
                    Sign up
                  </Link>
                ) : (
                  <Link
                    to={"/dashboard"}
                    className="!text-primary bg-white rounded-3xl py-2 w-full text-center"
                  >
                    Workspace
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
     
     <div
        className="my-2"
      >
      <RoseBudBanner/>
      </div>
    </div>
  );
};

export default LandingHeader;