import * as React from 'react';
import {Checkbox as AntdCheckbox, CheckboxProps as AntdCheckboxProps} from 'antd';
import styled from 'styled-components';

import {ThemeVarNames} from '@theme';

type ExtraProps = {
  variant?: 'primary' | 'alternative'
};

export type CheckboxProps = AntdCheckboxProps & ExtraProps

export function Checkbox(props: CheckboxProps) {
  const {variant, className, ...restProps} = props;

  return (
    <StyledCheckbox
      {...restProps}
      className={`${className} ${variant ?? 'primary'}`}
    />
  );
}

export const StyledCheckbox = styled(AntdCheckbox)`
  --bg: var(${ThemeVarNames.InputPrimary});

  &.alternative {
    --bg: var(${ThemeVarNames.InputAlternative});
  }

  .ant-checkbox-inner {
    background-color: var(--bg);
    border-color: var(${ThemeVarNames.Border});
    border: 0.5px solid var(${ThemeVarNames.TertiaryText}); 
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(${ThemeVarNames.Action});
    border-color: var(${ThemeVarNames.Action});
  }
`;
