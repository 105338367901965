import * as React from 'react';
import styled from 'styled-components';
import {Alert, Button, Input, Modal, Typography} from '@components/common';
import {Interval, MarketplacePackage} from '@types';
import {useBuyingPackage} from '@service/useBuying';
import PackageService from '@utils/apis/PackageService';

export type BuyButtonProps = {
  pck: MarketplacePackage
}

type PromoCodeResult = {
  status: 'success' | 'error';
  message: string
}

export function BuyButton({pck}: BuyButtonProps): JSX.Element {
  const [visble, setVisible] = React.useState<boolean>(false);
  const [code, setCode] = React.useState<MarketplacePackage>(pck);
  const [promocode, setPromoCode] = React.useState<string>('');
  const [promoResult, setPromoResult] = React.useState<PromoCodeResult>({
    status: 'error',
    message: ''
  });
  const [promoDisabled, setPromoDisabled] = React.useState<boolean>(false);
  const mutation = useBuyingPackage();

  React.useEffect(() => {
    if (!promocode) {
      setPromoResult({status: 'error', message: ''});
      setPromoDisabled(false);
    }
  }, [promocode]);

  const handleOnBuyClick = () => {
    setVisible(true);
  };

  const handleOnConfirmationClick = () => {
    mutation.mutate({...pck, promocode});
  };

  const close = () => {
    setVisible(false);
    setCode(pck);
    setPromoResult({status: 'error', message: ''});
  };

  const renderFooter = () => {
    if (mutation.status === 'error' || mutation.status === 'success') {
      return <Button onClick={close}>Close</Button>;
    }

    return [

      // using index for key since they won't change order
      <Button key={0} onClick={close}>Cancel</Button>,
      <Button
        key={1}
        type="primary"
        onClick={handleOnConfirmationClick}
        loading={mutation.isLoading}
        disabled={promoDisabled}
      >
        Buy
      </Button>
    ];
  };

  const handlePromocodeInput = (e: any) => {
    setPromoCode(e.target.value);
  };

  const checkPromoCode = async (promocode: string) => {
    try {
      const result: any = await PackageService.promocodeCheck(pck.price, code.actorId, code.code, promocode);

      if (result.data.promoBool) {
        setCode({...code, price: result.data.packagePrice});
        setPromoResult({status: 'success', message: result.data.message});
      } else {
        setPromoResult({status: 'error', message: result.data.message});
        setPromoDisabled(true);
        if (!promocode) {setPromoDisabled(false);}
      }
    } catch (e) {
      console.error('error', e);
    }
  };

  return (
    <>
      <Button type="primary" onClick={handleOnBuyClick}>
        Buy
      </Button>
      <Modal
        destroyOnClose
        title={`Are you sure you want to buy the data in package ${code.code}?`}
        okText="Buy"
        onOk={handleOnConfirmationClick}
        closable={false}
        visible={visble}
        onCancel={close}
        confirmLoading={mutation.status === 'loading'}
        footer={renderFooter()}
      >
        {promoResult.message.length > 0 ?
          <Alert
            type={promoResult.status}
            message={promoResult.message}
          /> : null
        }
        {mutation.status === 'success' ?
          <Alert
            type="success"
            message={`You will have access to all codes in package: ${code.code}`}
          /> :
          mutation.status !== 'error' ?
            <Typography variant="primary" as="p">
              You will be charged {` ${currencyFormatter.format(code.price)} `}{`${Interval.toFriendlyText(code.interval)}`}
            </Typography> :
            <Alert
              type="error"
              message={mutation.error?.response?.data?.message ?? 'Unknown error'}
            />
        }
        <StyledPromoContainer>
          <Input
            allowClear
            variant="alternative"
            placeholder="Promocode"
            onChange={(e: any) => handlePromocodeInput(e)}
            style={{width: '250px'}}
            disabled={code.price === 0}
          />
          <Button type="ghost" onClick={() => checkPromoCode(promocode)} disabled={code.price === 0}>
            Apply
          </Button>
        </StyledPromoContainer>
      </Modal>
    </>
  );
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const StyledPromoContainer = styled.div`
  padding-top: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
`;
