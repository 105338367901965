import React from "react";
import { CustomerQuotes } from "./data";

const CustomerQuotesSection = () => {
  return (
    <div 
      style={{ 
        background: 'url("/img/platform-background.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }} 
      className="text-white flex flex-col items-center py-20 gap-5 relative">
      <div className="text-white text-[32px] md:text-[36px] font-bold my-5" >User Testimonials</div>
      <div className="text-white max-w-3xl flex flex-col gap-16 mx-auto">
        {CustomerQuotes.map((customer, index) => (
          <div key={index} className="flex flex-col gap-2 items-center px-3">
            <div className="text-[22px] font-medium text-center">
              {customer.text}
            </div>
            <div className="flex gap-2">
              {new Array(customer.star).fill(0).map((_, index) => (
                <img
                  src="/img/icons/star-blue-fill.svg"
                  alt="star"
                  key={index}
                />
              ))}
            </div>
            <div className="flex flex-col text-center">
              <span className="text-[18px] font-bold">{customer.name}</span>
              {customer?.title?.length && customer.title.map((title, index) => <div key={index} className="text-[18px]">{title}</div> )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerQuotesSection;
