import * as React from 'react';
import {Upload as AntdUpload} from 'antd';
import styled from 'styled-components';
import {UploadProps as AntdUploadProps} from 'antd/es/upload/interface';

import {ThemeVarNames} from '@theme';

type ExtraProps = {
  variant?: 'primary' | 'alternative'
  children?: React.ReactNode
};

export type UploadProps = AntdUploadProps & ExtraProps;

export function Upload(props: UploadProps) {
  const {variant, className, children, ...restProps} = props;

  return (
    <StyledUpload
      {...restProps}
      className={`${className} ${variant ?? 'primary'}`}
    >
      {children}
    </StyledUpload>
  );
}

const StyledUpload = styled(AntdUpload)`

  .ant-upload-list-item {
    color: var(${ThemeVarNames.PrimaryText});
    background: var(${ThemeVarNames.PrimaryBg});
    margin-top: 20px;
    }
  }

  :hover {
    .ant-upload-list-item {
      .ant-upload-list-item-info {
        border: .5px solid var(${ThemeVarNames.Action});
        background: transparent;
      }
    }
  }
  

  .ant-upload-list-item {
    .ant-upload-text-icon 
      svg {
      color: var(${ThemeVarNames.PrimaryText});
    }
  }

    .ant-upload-list-item {
      .ant-upload-list-item-card-actions {
        .ant-btn svg {
          color: var(${ThemeVarNames.PrimaryText});
        }
      }
    }
  
`;
