import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';

import UserContainer from '@utils/state/userContainer';
import DashboardContainer from '@utils/state/dashboardContainer';
import UserService from '@utils/apis/UserService';
import HelmetWrapper from '@utils/HelmetWrapper';
import {RoseLogoWithProgressBar} from '@components/common/Loading';
import Home from '@components/landing/Home/Home';
import Terms from '@components/landing/Terms/Terms';
import Privacy from '@components/landing/Privacy/Privacy';
import Forgot from '@components/authentication/Forgot/ForgotPassword';
import Login from '@components/authentication/Login/Login';
import Register from '@components/authentication/Register/Register';
import Reset from '@components/authentication/Reset/Reset';
import Dashboard from '@components/dashboard/Dashboard';
import {ThemeAndOverriders} from '@theme';
import {NewContact} from '@components/dashboard/NewContact/NewContact';
import {ContactReset} from '@components/authentication/Reset/ContactReset';
import Solutions from '@components/landing/Solutions';
import './tailwind.css';
import ExternalRedirect from '@components/common/ExternalRedirect';

export default function App(): JSX.Element {
  const {user, setUser} = UserContainer.useContainer();
  const [loading, setLoading] = useState(true);
  
  // scroll to top when switching between location paths
  const location = useLocation();
  useLayoutEffect(() => {
    document.body.scrollTo(0,0)
  }, [location.pathname]);

  // always scroll to top when rendering these paths, even if already on this path
  if (location.pathname === "/" || location.pathname === "/solutions") document.body.scrollTo(0,0)

  useEffect(() => {
    UserService.get()
      .then(setUser)
      .catch(() => setUser(null))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    // @ts-ignore
    window.intercomSettings = {
      email: user?.email,
      username: user?.code,
      askrose: user?.askrose,
      app_id: 'l2eh5w43' // TODO: Pull this out to an environment variable.
    };
  }, [user]);

  if (loading) {return <RoseLogoWithProgressBar />;}

  return (
    <>
      <HelmetWrapper title="Rose" description="Rose Technology" />
      <ThemeAndOverriders />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/login" element={user ? <Navigate to={`/dashboard${window.location.search}`} /> : <Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset/:token" element={<Reset />} />
        <Route path="/activate/:token" element={<ContactReset />} />
        <Route path="/client-create-form" element={
          <DashboardContainer.Provider>
            <NewContact />
          </DashboardContainer.Provider>
        } />
        <Route path="/dashboard/*" element={
          <DashboardContainer.Provider>
            <Dashboard />
          </DashboardContainer.Provider>
        } />
        <Route path="/dashboard" element={
          <DashboardContainer.Provider>
            <Dashboard />
          </DashboardContainer.Provider>
        } />
        <Route 
          path="/viz"
          element={<ExternalRedirect url={process.env.REACT_APP_VISION_URL} />}
        />
      </Routes>
    </>
  );
}
