import {useEffect} from 'react';
import {reduce, replace} from 'lodash';
import {useQuery, UseQueryOptions, UseQueryResult} from '@tanstack/react-query';
import ObjectService, {RoseObjectError} from '@utils/apis/ObjectService';
import {cleanRoseCode} from '@helpers';
import splitByCharacterOuterLevel from '@utils/helpers/run/splitByCharacterOuterLevel';
import {RoseObject, RoseTimeseriesGroup} from '../types';

export type UseCodeResult = Omit<UseQueryResult, 'data' | 'refetch'> & {
  roseObjects: RoseObject[]
  errors: RoseObjectError[]
  rawCode: string
  refetch: () => void
}

type RoseObjectsAndErrors = {
  objects: RoseObject[]
  errors: RoseObjectError[]
  successCodes?: any[]
}

type RefinedRoseObject = Exclude<RoseObject, RoseTimeseriesGroup>

export type UseCodeOptions = {
  onLogicsCreated?: (rawCode: string) => void,
  staleTime?: number,
  cacheTime?: number
}

export function useCode(
  moduleKey: string,
  code: string,
  options?: UseCodeOptions
): UseCodeResult {
  const KEY = ['object', moduleKey];
  const queryOptions: UseQueryOptions<RoseObjectsAndErrors> = {
    enabled: false,
    retry: false,
    keepPreviousData: false,
    staleTime: options.staleTime ?? 0,
    cacheTime: options.cacheTime ?? 0
  };
  const {data, status, error, isFetching, refetch, ...rest} = useQuery(
    KEY,
    async () => {
      // pull out blocks and logic from the module text
      const lines = parseLines(code);
      const flattedBlocks = lines.flatMap((line) => line.blocks);
      const logics = flattedBlocks.filter((block) => Logic.isLogic(block.logic));

      let failedCodes : string[] = [];
      let errors = [];
      if (logics.length > 0) {
        [failedCodes, errors] = await pushLogics(logics);
      }

      let cleanedLines = [];

      cleanedLines = lines.map((line) => {
        const filteredBlocks = line.blocks.filter((block) => {
          const blockIncluded = failedCodes.includes(block.raw);
          return !blockIncluded;
        });

        const cleanedLine = {
          ...line,
          blocks: filteredBlocks
        };
        return cleanedLine;
      });

      const response = await getObjects(cleanedLines);

      // const stringCodes = transformInputWithLogicsCreated(lines);

      // looks through the array of successful push codes and replaces the functions with their respected rosecode name
      const reducedCodes = reduce(
        response.successCodes, (alteredString, code) => replace(alteredString, code.old, code.new), code);

      // update the module text with the logic applied, passes back the entire line as a string
      const transformedInput = cleanRoseCode(
        reducedCodes
      );
      options?.onLogicsCreated?.(transformedInput);
      response.errors = response.errors.concat(errors);
      return response;
    },
    queryOptions
  );

  useEffect(() => {
    if (code !== '') {
      refetch();
    }
  }, []);

  return {
    rawCode: '',
    errors: data?.errors ?? [],
    roseObjects: data?.objects ?? [],
    status,
    error,
    isFetching,
    refetch,
    ...rest
  };
}

// HELPERS

type Line = {
  id: number
  raw: string
  blocks: Block[]
}

type Block = {
  lineId: number
  raw: string
  logic?: Logic
}

type Logic = string[]

const Logic = {
  getCode(logic: Logic): string {
    return logic[0];
  },
  getValue(logic: Logic): string {
    return logic[1];
  },
  isLogic(data: unknown): boolean {
    return data !== undefined && Array.isArray(data) && data.length === 2;
  }
};

// push the logic to the server
function pushLogics(blocks: Block[]) {
  return Promise.allSettled(
    blocks.map(({logic}) =>
      ObjectService.pushLogic(Logic.getCode(logic), Logic.getValue(logic))
    )
  ).then((results) => {
    const failedCodes = [];
    const errors = [];

    for (let i = 0; i < results.length; i++) {
      if (results[i].status !== 'fulfilled') {
        failedCodes.push(blocks[i].raw);
        if (results[i].status === 'rejected') {
          errors.push(results[i].reason.response.data);
        }
      }
    }

    return [failedCodes, errors]; // This return is now inside a then callback and won't work as expected.
  }).catch((error) => [[], []]);
}

async function getObjects(lines: Line[]): Promise<RoseObjectsAndErrors> {
  return lines.reduce(
    async (roseObjectsAndErrors, line) =>
      line.blocks
        .reduce(
          async (roseObjectsAndErrorsByLine, block) => {
            // get the rosecode or logic name
            const code = Logic.isLogic(block.logic) ?
              Logic.getCode(block.logic) :
              block.raw;

            // get the object from the server
            // then add the resulting object or error to RoseObjectsAndErrors
            const arr = await roseObjectsAndErrorsByLine;
            try {
              const rosecode = await ObjectService.get(code) as RefinedRoseObject;
              arr.objects.push(rosecode);
              if (Logic.isLogic(block.logic)) {
                arr.successCodes.push({old: block.raw, new: rosecode.code});
              } else {
                arr.successCodes.push({old: code, new: rosecode.code});
              }
            } catch (error) {
              arr.errors.push(error);
            }

            return arr;
          },

          // the initial value must be a promise
          Promise.resolve({errors: [], objects: [], successCodes: []} as RoseObjectsAndErrors)
        )
        .then(async (arr) => {
          const totalArr = await roseObjectsAndErrors;

          // add the objects/errors to the accumulator object
          return {

            // assemble charts with multiple timeseries
            objects: totalArr.objects.concat(
              arr.objects.reduce(composeTimeseriesGroup, [] as RoseObject[])
            ),
            errors: totalArr.errors.concat(arr.errors),
            successCodes: totalArr.successCodes.concat(arr.successCodes)
          };
        }),

    // the initial value must be a promise
    Promise.resolve({errors: [], objects: [], successCodes: []} as RoseObjectsAndErrors)
  );
}

// assemble charts with multiple timeseries
function composeTimeseriesGroup(
  acc: RoseObject[],
  current: RoseObject
): RoseObject[] {
  const copy = [...acc];
  if (current?.type === 'timeseries') {
    const lastItem = copy.pop();
    if (lastItem?.type === 'timeseries-group') {
      copy.push(RoseTimeseriesGroup.add(lastItem, current));
    } else {
      if (lastItem) {
        copy.push(lastItem);
      }

      copy.push(RoseTimeseriesGroup.create([current]));
    }
  } else {
    copy.push(current);
  }

  return copy;
}

// convert lines into a single string with logic already applied
function transformInputWithLogicsCreated(lines: Line[]): string {
  return lines.reduce(
    (acc, current) => {
      const lineRaw = current.blocks
        .map((block) =>
          Logic.isLogic(block.logic) ? Logic.getCode(block.logic) : block.raw
        )
        .join(', ');
      return acc === '' ? acc + lineRaw : `${acc}\n${lineRaw}`;
    },
    '' // initial value
  );
}

function parseLines(code: string): Line[] {
  if (code.trim() === '') {return [];} // the line is empty

  return code
    .split('\n') // create an array of lines
    .filter((line) => line.trim() !== '') // remove empty lines
    .map((line, idx) => {
      // create an array of blocks
      const blocks: Block[] = splitByCharacterOuterLevel(line, ',') // create an array of rosecodes
        .map(parseBlock) // convert each rosecode to a block
        .map((block) => ({...block, lineId: idx})) // attach the line number
        .filter((block) => block.raw !== ''); // remove empty blocks
      const raw = blocks.map((b) => b.raw).join(','); // save string version
      return {raw, blocks, id: idx};
    });
}

// Convert a line segment (rosecode) into a block
function parseBlock(block: string): Pick<Block, 'raw' | 'logic'> {
  const logic = splitByCharacterOuterLevel(block, '=');
  return {
    raw: block,
    logic: logic.length <= 1 ? undefined : logic
  };
}
