/* eslint-disable camelcase */
import {Interval, MarketplacePackage} from '@types';
import {HttpClient} from './HttpClient';

/**
 * API client for the `package` services
 * @singleton
 * @extends HttpClient
 */
class PackageAPI extends HttpClient {
  private static _instance: PackageAPI;

  private constructor() {
    super('/packages');
  }

  public static get instance(): PackageAPI {
    if (!PackageAPI._instance) {
      PackageAPI._instance = new PackageAPI();
    }

    return PackageAPI._instance;
  }

  public get selling() {
    return {
      all: (pageNumber: number, pageSize: number): Promise<any> => this.instance.get(
        `/selling?page_number=${pageNumber}&page_size=${pageSize}`
      ),
      remove: (code: string, interval: Interval): Promise<any> => this.instance.delete(
        `/selling/${code}/interval/${interval}`
      ),
      list: (pckg: {
        code: string
        price: string
        interval: Interval
      }): Promise<any> => this.instance.post(
        `/selling/${pckg.code}`,
        {price: pckg.price, interval: pckg.interval}
      )
    };
  }

  public get buying() {
    return {
      search: (
        keywords: string[],
        pageNumber: number,
        pageSize: number
      ): Promise<any> => this.instance.get(
        `/buying?keywords=${JSON.stringify(
          keywords
        )}&page_number=${pageNumber}&page_size=${pageSize}`
      ),
      buy: (code: string, interval: string, promoCode: string): Promise<any> => this.instance.post(
        `/buying/${code}/interval/${interval}`,
        {promo_code: promoCode}
      )
    };
  }

  public get subscribed() {
    return {
      all: (): Promise<any> => this.instance
        .get('/subscribed')
        .then((res) => res.data)
        .catch((err) => {
          throw err;
        })
    };
  }

  public promocodeCheck = (originalPackagePrice: number, packageCreatedBy: number, packageCode: string, promoCode: string) => this.instance.get('/promocode', {
    params: {
      original_package_price: originalPackagePrice,
      package_created_by: packageCreatedBy,
      package_code: packageCode,
      promo_code: promoCode
    }
  });

}

const PackageService = PackageAPI.instance;

export default PackageService;
