import * as React from 'react';
import {useQuery} from '@tanstack/react-query';
import tauist from 'tauist';

import ObjectService from '@utils/apis/ObjectService';
import {FunctionMap, toFunctionMap} from '@utils/helpers/roseFunctions';

export function useFunctionMap(): FunctionMap {
  const {data} = useQuery(['LinkedList'], () => ObjectService.get('functions'), {
    keepPreviousData: true,
    staleTime: tauist.ms.oneHour
  });

  const functionMap = React.useMemo(() => toFunctionMap([data]), [data]);

  return functionMap;
}
