import React, {
  PureComponent,
  FC,
  MouseEvent,
  ReactNode,
  Fragment
} from 'react';
import {match, __} from 'ts-pattern';
import styled from 'styled-components';
import {List, Space, Typography as AntdTypography} from 'antd';
import {ListItemMetaProps} from 'antd/lib/list/Item';
import {ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons';
import {
  FunctionMap,
  FunctionMeta,
  FunctionCategory,
  FunctionContext,
  FunctionParam,
  CATEGORIES,
  getFunctionsByCategory,
  getFunctionContext,
  getTypeExample
} from '@utils/helpers/roseFunctions';
import {Typography} from '@components/common';

import {ThemeVarNames} from '@theme';
import {scrollItemTop} from './helpers';

type LinkDescriptionProps = {
  content: ReactNode;
};

const LinkDescription: FC<LinkDescriptionProps> = ({content}) =>
  <>
    {content}
    <ArrowRightOutlined />
  </>
;
const FunctionSignature: FC<{ context: FunctionContext }> = ({context}) =>
  <>
    <Typography variant="secondary">Signature</Typography>
    <br />
    <Typography variant="primary" size="small">
      <Param>
        <span>fred.wtd:</span>
        {context.name}(
        {context.parameters.map((p, i) =>
          <Fragment key={i}>
            {i !== 0 ? ',' : ''}
            {` ${p.optional !== undefined ? '[' : ''}${
              p.unlimited ? '...' : ''
            }${p.name}: `}
            <span>{p.type.join(' | ')}</span>
            {p.optional !== undefined ? ']' : ''}
          </Fragment>
        )}
        )
      </Param>
    </Typography>
  </>
;

const FunctionExample: FC<{ context: FunctionContext }> = ({context}) =>
  <>
    <Typography variant="secondary">Example</Typography>
    <br />
    <Typography variant="primary" size="small">
      <Param>
        <span>fred.wtd:</span>
        {context.name}(
        {context.parameters.map((p) => getTypeExample(p.type[0])).join(', ')})
      </Param>
    </Typography>
  </>
;

interface LinkedListProps {
  value: string;
  cursor: number;
  onValue: (v: string) => void;
  onCursor: (v: number) => void;
}

interface Props extends LinkedListProps {
  functionMap: FunctionMap;
  category: string | undefined;
  setCategory: (c: string | undefined) => void;
  index: number;
  setIndex: (i: number) => void;
}

export class LinkedListComponent extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleFunctionClick = this.handleFunctionClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.value !== prevProps.value) {
      this.props.setCategory(undefined);
      this.props.setIndex(-1);
    }
  }

  handleClick(e: MouseEvent<HTMLDivElement>): void {
    scrollItemTop();
    this.props.setIndex(-1);
    this.props.setCategory(
      e.currentTarget.attributes.getNamedItem('title')?.value
    );
  }

  handleFunctionClick(e: MouseEvent<HTMLDivElement>): void {
    this.props.onValue(e.currentTarget.attributes.getNamedItem('title')?.value);
    this.props.setIndex(-1);
    this.props.setCategory(undefined);
  }

  handleBackClick(): void {
    this.props.setIndex(-1);
    this.props.setCategory(undefined);
  }

  render(): ReactNode {
    const {functionMap, value, cursor, category, index} = this.props;
    const functionContext = getFunctionContext(value, cursor, functionMap);

    return match(functionContext)
      .with(undefined, () => null)
      .with(true, () =>
        category ?
          <StyledList
            id="ll-category"
            bordered
            header={
              <>
                <AntdTypography.Link onClick={this.handleBackClick}>
                  <ArrowLeftOutlined /> Back
                </AntdTypography.Link>
                <br />
                <Level>{category}</Level>
              </>
            }
            dataSource={getFunctionsByCategory(category, functionMap)}
            renderItem={({name, description}: FunctionMeta, rowIndex) =>
              <StyledListItem
                title={name}
                onClick={this.handleFunctionClick}
                tabIndex={rowIndex}
              >
                <StyledFunctionListItemMeta
                  className={index === rowIndex ? 'active' : undefined}
                  title={name}
                  description={<LinkDescription content={description} />}
                />
              </StyledListItem>
            }
          /> :
          <StyledList
            id="ll-categories"
            bordered
            header={<Typography variant="secondary">What do you want to do?</Typography>}
            dataSource={CATEGORIES}
            renderItem={([title, items]: FunctionCategory, rowIndex) =>
              <StyledListItem
                title={title}
                onClick={this.handleClick}
                tabIndex={rowIndex}
              >
                <StyledListItemMeta
                  className={index === rowIndex ? 'active' : undefined}
                  title={title}
                  description={
                    <LinkDescription
                      content={<Typography variant="inherit">{items.join(', ')}</Typography>}
                    />
                  }
                />
              </StyledListItem>
            }
          />

      )
      .with([{name: __.string, description: __.string}], () =>
        <StyledList
          id="ll-all-functions"
          bordered
          header={<Typography variant="secondary">All Functions</Typography>}
          dataSource={functionContext as FunctionMeta[]}
          renderItem={({name, description}: FunctionMeta, rowIndex) =>
            <StyledListItem
              title={name}
              onClick={this.handleFunctionClick}
              tabIndex={rowIndex}
            >
              <StyledFunctionListItemMeta
                className={index === rowIndex ? 'active' : undefined}
                title={name}
                description={<LinkDescription content={description} />}
              />
            </StyledListItem>
          }
        />
      )
      .with({name: __.string, paramIndex: __.number}, () => {
        const context = functionContext as FunctionContext;
        return (
          <StyledList
            id="ll-parameters"
            bordered
            header={
              <>
                <Level>Parameters</Level>
                <div>
                  <Typography variant="secondary" size="small">
                    <strong>{context.name}</strong>: {context.description}
                  </Typography>
                  <Details>
                    <Typography variant="primary" as="summary" size="small">Usage Details</Typography>
                    <Space direction="vertical">
                      <FunctionSignature context={context} />
                      <FunctionExample context={context} />
                    </Space>
                  </Details>
                </div>
              </>
            }
            dataSource={context.parameters}
            renderItem={(
              {name, type, unlimited, optional, ...rest}: FunctionParam,
              index
            ) =>
              <StyledListItem title={name}>
                <StyledFunctionListItemMeta
                  className={
                    index === context.paramIndex ? 'current' : undefined
                  }
                  title={name}
                  description={
                    <>
                      {rest.enum ?
                        <>
                          <strong>enum</strong>: {rest.enum.join(', ')}
                        </> :
                        <>
                          <strong>{type.join(', ')}</strong>
                        </>
                      }
                      {unlimited ? ' One or More' : null}
                      {unlimited && optional !== undefined ? ', ' : ''}
                      {optional !== undefined ?
                        ` Optional, Default=${
                          optional === '' ? 'None' : optional
                        }` :
                        null}
                    </>
                  }
                />
              </StyledListItem>
            }
          />
        );
      })
      .otherwise(() => null);
  }
}

const Level = styled.div`
  & {
    padding-top: 0.5em;
    font-weight: bold;
    text-transform: uppercase;
    color: var(${ThemeVarNames.SecondaryText});
  }
`;

const Details = styled.details`
  & {
    padding: 1em;

    summary {
      cursor: pointer;
    }
  }
`;

const StyledList = styled(List)`
  & {
    background: var(${ThemeVarNames.SecondaryBg});
    overflow-y: scroll;
    max-height: 19em;
    width: 100%;
    position: absolute;
    z-index: 99;
    border-top: 0;
    box-shadow: var(${ThemeVarNames.Shadow});
    border-color: var(${ThemeVarNames.Border});

    > .ant-list-header {
      border-color: var(${ThemeVarNames.PrimaryBg});
    }
  }
`;

const StyledListItem = styled(List.Item)`
  & {
    cursor: pointer;
    padding: 0 24px;
    border-bottom: none !important;
  }
`;

const Param = styled.span`
  & {
    font-weight: bold;
  }
`;

const StyledListItemMeta: FC<ListItemMetaProps> = styled(List.Item.Meta)`
  & {
    border-radius: 2px;
    position: relative;
    padding: 0.4em;

    .ant-list-item-meta-title {
      color: var(${ThemeVarNames.Action});
    }

    .ant-list-item-meta-description {
      padding-left: 1em;
      color: var(${ThemeVarNames.PrimaryText});
    }

    .anticon {
      visibility: hidden;
      position: absolute;
      right: 2em;
      top: 37%;
    }

    &:hover,
    &.active {
      border-radius: 6px;
      background-color: var(${ThemeVarNames.ActionDark});

      .ant-list-item-meta-title,
      .ant-list-item-meta-description,
      .ant-list-item-meta-description a {
        color: var(${ThemeVarNames.ActionContrast});
      }

      .anticon {
        visibility: visible;
      }
    }
  }
`;

const StyledFunctionListItemMeta: FC<ListItemMetaProps> = styled(List.Item.Meta)`
  & {
    border-radius: 2px;
    border: 1px solid transparent;
    position: relative;
    padding: 0.4em;

    .ant-list-item-meta-title {
      color: var(${ThemeVarNames.Action});
    }

    .ant-list-item-meta-description {
      padding-left: 1em;
      color: var(${ThemeVarNames.PrimaryText});

      span {
        margin-right: 1em;
      }

      span:last-of-type {
        margin-right: 0;
      }
    }

    .ant-list-item-meta-title,
    .ant-list-item-meta-description {
      display: inline-block;
    }

    .anticon {
      visibility: hidden;
      position: absolute;
      right: 2em;
      top: 27%;
    }

    &:hover,
    &.active {
      border-radius: 6px;
      background-color: var(${ThemeVarNames.ActionDark});

      .ant-list-item-meta-title,
      .ant-list-item-meta-description {
        color: var(${ThemeVarNames.ActionContrast});
      }

      .anticon {
        visibility: visible;
      }
    }

    &.current {
      border-color: var(${ThemeVarNames.Border});
    }
  }
`;
