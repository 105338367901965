import React, {useState} from 'react';
import {message} from 'antd';
import {Modal, Alert, Input, Button} from '@components/common';
import AdminAPI from '@utils/apis/AdminAPI';

export const InviteUser = ({refetch}: {refetch: Function}): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [invitee, setInvitee] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => {
    setError(null);
    setInvitee('');
    setOpen((open) => !open);
  };

  const inviteUser = async () => {
    setError(null);
    setLoading(true);
    try {
      await AdminAPI.inviteUser(invitee);
      message.success(`User ${invitee} has been added to your organization`);
      setLoading(false);
      setInvitee('');
      toggleModal();
      refetch();
    } catch (err) {
      setError(err.response.data.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Button style={{margin: 'auto', marginRight: '25px'}} onClick={toggleModal}>
        Add user to organization
      </Button>
      <Modal
        title="Add User"
        visible={open}
        onOk={inviteUser}
        onCancel={toggleModal}
        confirmLoading={loading}
        okText="Add"
        cancelText="Cancel"
      >
        <Input placeholder={'username'} value={invitee} onChange={(e) => setInvitee(e.target.value)} autoFocus/>
        {error ?
          <Alert
            type="error"
            message={error}
            style={{textAlign: 'center', marginTop: '25px'}}
          /> : null
        }
      </Modal>
    </>
  );
};
