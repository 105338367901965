import React from "react";
import HeroSection from "./HeroSection";
import Capabilties from "./Capabilties";
import { LandingHeader } from "@components/common";
import LandingFooter from "@components/common/LandingFooter/LandingFooter";
import CustomersSection from "./CustomerSection/CustomerSection";
import InstitutionalSolutions from "./InstitutionalSolutions";

const Solutions = () => {
  return (
    <div className="bg-[#0C0F14]">
      <LandingHeader />
      <main>
        <HeroSection />
        <CustomersSection />
        <Capabilties />
        <InstitutionalSolutions />
      </main>
      <LandingFooter />
    </div>
  );
};

export default Solutions;
