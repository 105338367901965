import {trackError} from '@utils/createSentry';
import {RoseNotebook} from './RoseNotebook';
import {RoseMap} from './RoseMap';
import {RoseTimeseries, RoseTimeseriesGroup} from './RoseTimeseries';
import {RoseChart} from './RoseChart';
import {RoseQuery} from './RoseQuery';
import {RosePackage} from './RosePackage';
import {RoseFunction} from './RoseFunction';
import {RoseDecision} from './RoseDecision';

export type RoseObject =
  | RoseMap
  | RoseTimeseries
  | RoseNotebook
  | RoseTimeseriesGroup
  | RoseChart
  | RoseQuery
  | RosePackage
  | RoseFunction
  | RoseDecision

export type RoseObjectType = 'timeseries-group' | 'chart'

export const RoseObject = {
  deserialize(data: Record<string, any>): RoseObject {
    switch (true) {
    case RoseMap.isMap(data):
      return RoseMap.deserialize(data);

    case RoseTimeseries.isTimeseries(data):
      return RoseTimeseries.deserialize(data);

    case RoseChart.isRoseChart(data):
      return RoseChart.deserialize(data);

    case RoseNotebook.isNotebook(data):
      return RoseNotebook.deserialize(data);

    case RoseQuery.isRoseQuery(data):
      return RoseQuery.deserialize(data);

    case RosePackage.isRosePackage(data):
      return RosePackage.deserialize(data);

    case RoseFunction.isRoseFunction(data):
      return RoseFunction.deserialize(data);

    case RoseDecision.isRoseDecision(data):
      return RoseDecision.deserialize(data);

    default:
      trackError('RoseObject not found.');
      throw Error('Invalid Rose Code, data not found.');
    }
  },
  getActorId(roseObject: RoseObject): number | null {
    switch (roseObject.type) {
    case 'timeseries-group':
    case 'function':
      return null;
    default:
      return roseObject.actorId ?? null;
    }
  }
};
