import {useEffect} from 'react';
import {
  useQuery,
  useMutation,
  UseMutationResult,
  useQueryClient
} from '@tanstack/react-query';
import axios, {AxiosError} from 'axios';

import ObjectService, {ExistReturnType} from '@utils/apis/ObjectService';
import {RoseNotebook} from '@types';
import UserContainer from '@utils/state/userContainer';

import * as sessionStorageAPI from './sessionStorage';

export type UseNotebookResult = {
  notebook: RoseNotebook | undefined
  status: 'idle' | 'error' | 'loading' | 'success'
  error: unknown
  mutation: UseMutationResult<RoseNotebook, any, MutationType>
  permission: string
}

export type MutationType = Create | Rename | Duplicate | Replace | ReplaceModuleSave | ReplaceModuleNoSave | Explore

export type Create = {
  action: 'create'
  notebook: RoseNotebook
  optimistic?: boolean
}

export type Rename = {
  action: 'rename'
  notebook: RoseNotebook
  currentCode: string
  newCode: string
  optimistic?: boolean
}

export type Duplicate = {
  action: 'duplicate'
  notebook: RoseNotebook
  optimistic?: boolean
}

export type Replace = {
  action: 'replace'
  notebook: RoseNotebook
  optimistic?: boolean
}

export type ReplaceModuleSave = {
  action: 'replaceModule_save'
  notebook: RoseNotebook
  optimistic?: boolean
}

export type ReplaceModuleNoSave = {
  action: 'replaceModule_noSave'
  notebook: RoseNotebook
  optimistic?: boolean
}

export type Explore = {
  action: 'duplicate'
  notebook: RoseNotebook
  optimistic?: boolean
}

export function useNotebook(code: string): UseNotebookResult {
  const QUERY_KEY = composeQuery(code);
  const queryClient = useQueryClient();
  const {user} = UserContainer.useContainer();

  const {data: notebook, status, error, refetch} = useQuery<RoseNotebook>(
    QUERY_KEY,
    () => {
      if (code === '') {
        return Promise.resolve(sessionStorageAPI.getNotebook(user?.askrose, false));
      }

      return ObjectService.get(code)
        .then((res) => res as RoseNotebook)
        .catch((err) => {
          throw err;
        });
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
      keepPreviousData: true
    }
  );
  let permission: string;
  if (notebook == null) {
    permission = 'write';
  } else {
    permission = notebook.permission;
  }

  const mutation = useMutation<RoseNotebook, any, MutationType>(
    async (mutationType) => {
      const newNotebook = {...mutationType.notebook};
      if (newNotebook.code.trim() === '') {
        sessionStorageAPI.saveNotebook(newNotebook);
        return Promise.resolve(newNotebook);
      }

      const {modules, metas, type, code} = RoseNotebook.serialize(newNotebook);

      if (mutationType.action === 'duplicate') {
        return ObjectService.exist(code, user)
          .then((result) => {
            if (result.reason === 'no-exist') {
              return ObjectService.create(code, modules, metas, type);
            }

            throw composeExistingRoseObjectError(result);
          })
          .then(() => newNotebook)
          .catch((err) => {
            throw err;
          });
      }

      if (permission === 'write') {
        if (mutationType.action === 'create') {
          return ObjectService.exist(code, user)
            .then((result) => {
              if (result.reason === 'no-exist') {
                return ObjectService.create(code, modules, metas, type);
              }

              throw composeExistingRoseObjectError(result);
            })
            .then(() => sessionStorageAPI.clearNotebook())
            .then(() => newNotebook)
            .catch((err) => {
              throw err;
            });
        }

        if (mutationType.action === 'replace') {
          return ObjectService.create(code, modules, metas, type)
            .then(() => newNotebook)
            .catch((err) => {
              throw err;
            });
        }

        if (mutationType.action === 'replaceModule_save') {
          const [newMod] = mutationType.notebook.modules;
          const newNotebookModules = notebook.modules.map((m) => m.key === newMod.key ? newMod : m);

          // serializing new updated list of modules. moving module updating here to avoid stale data being copied
          // from within components
          const {modules} = RoseNotebook.serialize({...notebook, modules: newNotebookModules});
          const objectIdList = newNotebookModules.flatMap((m) => m.modulesettings.objects);
          return ObjectService.create(code, modules, metas, type, JSON.stringify(objectIdList))
            .then(() => newNotebook)
            .catch((err: any) => {
              throw err;
            });
        }

        if (mutationType.action === 'rename') {
          const {currentCode, newCode} = mutationType;
          return ObjectService.exist(newCode, user)
            .then((result) => {
              if (result.reason === 'no-exist') {
                return ObjectService.rename(currentCode, newCode);
              }

              throw composeExistingRoseObjectError(result);
            })
            .then(() => newNotebook)
            .catch((err: Error | AxiosError) => {
              if (axios.isAxiosError(err)) {
                if (err.response.status === 404) {
                  throw new Error('notebook already exist');
                }
              }

              throw err;
            });
        }
      }

      return Promise.resolve(newNotebook);

    },
    {
      async onMutate({notebook: newNotebook, action, optimistic = true}) {
        if (optimistic === false) {
          const previousNotebook = queryClient.getQueryData(QUERY_KEY);
          return {previousNotebook};
        }

        const nextNotebook = newNotebook;
        if (action === 'replaceModule_save' || action === 'replaceModule_noSave') {
          const [newMod] = nextNotebook.modules;
          const newNotebookModules = notebook.modules.map((m) => m.key === newMod.key ? newMod : m);
          nextNotebook.modules = newNotebookModules;
        }

        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(QUERY_KEY);

        // Snapshot the previous value
        const previousNotebook = queryClient.getQueryData(QUERY_KEY);

        // Optimistically update to the new value
        queryClient.setQueryData<RoseNotebook>(QUERY_KEY, () => nextNotebook);

        // Return a context object with the snapshotted value
        return {previousNotebook};
      },
      onError(err, {optimistic}, context: any) {
        if (!optimistic) {return;}

        queryClient.setQueryData(QUERY_KEY, context?.previousNotebook);
      },
      onSettled(notebook_, error_, {optimistic}) {
        if (!optimistic) {return;}

        queryClient.invalidateQueries(QUERY_KEY);
      },
      onSuccess(notebookSaved, mutationType, context) {
        if (mutationType.optimistic) {return;}

        if (
          mutationType.action === 'rename' ||
          mutationType.action === 'duplicate'
        ) {
          queryClient.setQueryData<RoseNotebook>(
            composeQuery(notebookSaved.code),
            () => notebookSaved
          );
        }
      }
    }
  );

  useEffect(() => {
    refetch();
  }, [code]);

  return {
    notebook: notebook ?? RoseNotebook.create(user?.askrose),
    mutation,
    status,
    error,
    permission
  };
}

function composeQuery(code: string): string[] {
  return ['notebook', code];
}

function composeExistingRoseObjectError(result: ExistReturnType): Error {
  const error = new Error();
  switch (result.reason) {
  case 'user-has-the-notebook':
    error.name = 'user-has-the-notebook';
    error.message =
        'A notebook you own already exists with the same name. Overwrite existing notebook?';
    break;
  case 'notebook-exist-by-another-user':
    error.name = 'notebook-exist-by-another-user';
    error.message =
        'A notebook shared with you has the same name. If you save you will no longer have access to the shared notebook. Continue?';
    break;
  case 'user-has-the-rose-object':
    error.name = 'user-has-the-rose-object';
    error.message =
        "There's an existing timeseries with the same rosecode. Saving the notebook will overwrite the existing datasets. Are you sure?";
    break;
  case 'rose-object-exist-by-another-user':
    error.name = 'rose-object-exist-by-another-user';
    error.message =
        "There's an existing timeseries with the same rosecode. Saving the notebook will overwrite the existing datasets. Are you sure?";
    break;
  default:
    break;
  }

  return error;
}
