import React, {useState, useEffect} from 'react';

interface Props {
  data: BillingFormData;
  setData: (data: BillingFormData) => void;
}

export type BillingFormData = {
  company: string;
  address: string;
  zip: string;
  country: string;
}

const CountryData = [
  {name: 'United States', code: 'US'},
  {name: 'Canada', code: 'CA'}

  // Add more countries here
];

const StatesData = [
  {name: 'Alabama', code: 'AL'},
  {name: 'Alaska', code: 'AK'}

  // Add more states here
];

function BillingModal({data, setData}: Props) {
  const {company, address, country, zip} = data;

  const handleTextChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;
    setData({...data, [name]: value});
  };

  return (
    <>
      <div style={styles.container}>
        <div style={styles.paymentMethod}>
          <h2 style={styles.sectionTitle}>Billing Address</h2>
          <input name="company" type="text" style={styles.input} placeholder="Company (optional)" value={company} onChange={handleTextChange} />
          <input name="address" type="text" style={styles.input} placeholder="Address" value={address} onChange={handleTextChange} />
          <input name="country" type="text" style={styles.input} placeholder="Country" value={country} onChange={handleTextChange} />
          <input name="zip" type="text" style={styles.input} placeholder="Zip Code" value={zip} onChange={handleTextChange} />
          {/* <select
            style={styles.input}
            defaultValue=""
            onChange={(e) => console.log(e.target.value)}
          >
            <option value="" disabled hidden>Select Country</option>
            {CountryData.map((country) =>
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            )}
          </select>
          <select
            style={styles.input}
            defaultValue=""
            onChange={(e) => console.log(e.target.value)}
          >
            <option value="" disabled hidden>Select State</option>
            {StatesData.map((state) =>
              <option key={state.code} value={state.code}>
                {state.name}
              </option>
            )}
          </select> */}
        </div>
        <div style={styles.plan}>
          <h2 style={styles.sectionTitle}>Plan</h2>
          <div style={styles.planDetails}>
            <div style={styles.planTop}>
              <p>Rose Pro</p>
              <p>Monthly</p>
            </div>
            <div style={styles.orderSummary}>
              <h4 style={styles.orderTop}>Order Summary</h4>
              <div style={styles.product}>
                <div style={styles.description}>
                  <p>$14.99/month</p>
                  <p>Cap at 100 queries per day</p>
                </div>
              </div>
              <div style={styles.total}>
                <p>Total</p> <p>$14.99/month</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch', // Add this line
    justifyContent: 'space-between',
    width: '100%'
  },
  title: {
    fontSize: '24px',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: '32px'
  },
  sectionTitle: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '12px',
    textAlign: 'left',
    marginBottom: '12px'
  },
  option: {
    marginBottom: '10px',
    borderRadius: '8px',
    border: 'solid 1px #FFFFFF33',
    padding: '20px'
  },
  label: {
    textAlign: 'left',
    fontSize: '12px'
  },
  input: {
    backgroundColor: 'transparent',
    borderRadius: '8px',
    color: 'white',
    fontSize: '12px',
    padding: '12px',
    width: '100%',
    marginTop: '8px',
    border: '0.5px solid rgba(255, 255, 255, 0.3)'
  },
  inputHalf: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '8px',
    opacity: 0.3,
    color: 'white',
    fontSize: '12px',
    padding: '8px',
    marginTop: '8px',
    border: '0.5px solid #FFFFFF',
    width: '45%'
  },
  paymentMethod: {
    width: '50%',
    paddingRight: '20px'
  },
  creditCardFields: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardBottom: {
    display: 'flex'
  },
  plan: {
    width: '50%',
    paddingLeft: '20px'
  },
  planDetails: {
    marginBottom: '20px'
  },
  planTop: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '12px'
  },
  orderSummary: {
    border: '0.5px solid rgba(255, 255, 255, 0.3)',
    borderRadius: '8px',
    padding: '18px',
    color: 'white',
    marginBottom: '10px',
    fontSize: '12px'
  },
  orderTop: {
    color: 'white'
  },
  product: {
    display: 'flex',
    marginTop: '20px',
    marginBottom: '20px',
    justifyContent: 'space-between'
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '20px',
    paddingBottom: '6px',
    borderTop: 'solid 1px rgba(255, 255, 255, 0.3)'
  },
  completePaymentButton: {
    backgroundColor: 'white',
    padding: '10px 20px',
    borderRadius: '8px',
    color: '#29313F',
    cursor: 'pointer',
    width: '100%',
    height: '57px',
    fontSize: '12px'
  }
};

export default BillingModal;
