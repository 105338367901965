import {RoseWarning} from './RoseWarning';

export type RosePackage = {
  type: 'package'
  id: number
  code: string
  actorId: number
  actor: string
  values: {
    columns: any[]
    data: any[][]
  }
  metas: any
  warnings: RoseWarning
}

export const RosePackage = {
  deserialize(data: any): RosePackage {
    return {
      type: 'package',
      id: data.id,
      code: data.code,
      actorId: data.actor_id,
      actor: data.actor,
      values: data.values,
      metas: data.metas,
      warnings: RoseWarning.deserialize(data.metas.warning)
    };
  },
  serialize(rosePackage: RosePackage): Record<string, any> {
    return {
      type: 'package',
      id: rosePackage.id,
      code: rosePackage.code,
      actor_id: rosePackage.actorId,
      actor: rosePackage.actor,
      values: rosePackage.values,
      metas: rosePackage.metas
    };
  },
  isRosePackage(data: any): boolean {
    return data?.type === 'package';
  }
};
