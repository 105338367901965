import {CancelToken} from 'axios';
import {HttpClient} from './HttpClient';

/**
 * API client for the `file` services
 * @singleton
 * @extends HttpClient
 */
class FileAPI extends HttpClient {
  private static _instance: FileAPI;

  private constructor() {
    super('/file');
  }

  public static get instance(): FileAPI {
    if (!FileAPI._instance) {
      FileAPI._instance = new FileAPI();
    }

    return FileAPI._instance;
  }

  // allowing use of cancellation token here since it is only used in a form with a manual cancellation token
  public formatPdfToMap = (
    form: any,
    cancelToken?: CancelToken
  ) => this.instance
    .post('', form, {cancelToken})
    .then((res) => res.data)
}

const FileService = FileAPI.instance;

export default FileService;
