import {RcFile} from 'antd/lib/upload';
import React, {useReducer} from 'react';
import styled from 'styled-components';
import {InboxOutlined} from '@ant-design/icons';
import {Alert, Button, Form, Input, Spinner} from '@components/common';
import ObjectService from '@utils/apis/ObjectService';
import {checkRoseCode, cleanFileName} from '@utils/helpers/helpers';
import {cleanRoseCode} from '@helpers';
import {RoseModule} from '@types';
import {Dragger} from '@components/common/DataEntry/Upload/Dragger';
import {ThemeVarNames} from '@theme';
import {reducer, initialState, ActionType} from './UploadTab';

type UploadAreaProps = {
  direction: 'row' | 'col'
  onAddNewItem: (partialMod: Partial<RoseModule>) => void;
};

export function UploadArea({direction, onAddNewItem}: UploadAreaProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [form] = Form.useForm();

  const beforeUpload = (file: RcFile) => {
    form.setFieldsValue({code: cleanFileName(file.name)});
    return false;
  };

  const onRemove = () => {
    form.resetFields();
  };

  const onSubmit = async () => {
    dispatch({type: ActionType.LOADING, payload: true});
    const values = form.getFieldsValue();
    const isImage = values.file.file.type.includes('image');

    try {
      const formData = new FormData();
      formData.append('file', values.file.file);
      formData.append('code', values.code);
      formData.append('type', isImage ? 'image' : 'file');
      formData.append('metas', '{}');

      const result = await ObjectService.pushFile(formData);

      dispatch({type: ActionType.LOADING, payload: false});
      dispatch({type: ActionType.SUCCESS, payload: typeof result === 'string' ? result : 'Success'});
      if (isImage) {
        onAddNewItem({
          type: 'image',
          textBox: values.code
        });
      } else {
        onAddNewItem({
          type: 'code',
          textBox: values.code
        });
      }

      form.resetFields();
      dispatch({type: ActionType.RESET, payload: ''});
    } catch (error) {
      dispatch({type: ActionType.ERROR, payload: error?.response?.data?.message ?? error?.message ?? 'Error'});
    }
  };

  // Ensures that all codes will be in lowercase
  const handleInputChangeLowerCase = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.currentTarget.name;
    const value = cleanRoseCode(e.currentTarget.value); // e.currentTarget.value.toLowerCase().replace(/[^\w.]|_/g, '.');
    form.setFieldsValue({
      [key]: value
    });
  };

  return (
    <StyledContent>
      <Form
        layout={direction === 'col' ? 'vertical' : 'horizontal'}
        onFinish={() => onSubmit()}
        form={form}
      >
        <Form.Item
          name="file"
          rules={[{required: true, message: 'File required'}]}
        >
          <Dragger
            className="relative w-full"
            multiple={false}
            beforeUpload={beforeUpload}
            onRemove={onRemove}
            maxCount={1}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload an image (.png, .jpeg)</p>
          </Dragger>
        </Form.Item>
        <div className="flex justify-center w-full">
          <div className="flex flex-row items-center justify-around w-full max-w-lg space-x-2">
            <Form.Item
              className="grow"
              name="code"
              rules={[
                {
                  required: true, message: 'Rose code name required'
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject();
                    }

                    if (checkRoseCode(value)) {
                      return Promise.reject('Rose code cannot contain special characters');
                    }

                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input
                allowClear
                type="text"
                size="middle"
                placeholder="Rosecode name"
                name="code"
                onChange={handleInputChangeLowerCase}
              />
            </Form.Item>
            <Form.Item shouldUpdate>
              {({getFieldsValue, getFieldsError}) => {
                const {file, code} = getFieldsValue();
                const formIsComplete =
                    !!file &&
                    !!code &&
                    !getFieldsError().filter(({errors}) => errors.length).length;
                return <>
                  <Button
                    htmlType="submit"
                    type="primary"
                    disabled={!formIsComplete || state.loading}
                  >
                      Upload
                  </Button>
                </>
                ;
              }}
            </Form.Item>
          </div>
        </div>

        {state.loading && <Spinner size={36} />}
        {state.success && <>
          <StyledAlert message={state.success} type="success" />

        </>
        }
        {state.error && <StyledAlert message={state.error} type="error" />}
      </Form>
    </StyledContent>
  );
}

const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 25px;
  color: var(${ThemeVarNames.PrimaryText}) !important;

  .ant-form-item {
    color: var(${ThemeVarNames.PrimaryText}) !important;
  }

  .ant-upload-list, .ant-upload-list-text {
    color: var(${ThemeVarNames.PrimaryText}) !important;
  }

  .ant-upload-list-item {
    color: var(${ThemeVarNames.PrimaryText}) !important;
    background: var(${ThemeVarNames.PrimaryBg}) !important;
    margin-top: 20px !important;
    }

  :hover {
    .ant-upload-list-item {
      .ant-upload-list-item-info {
        background: transparent !important;
      }
    }
  }
  

  .ant-upload-list-item {
    .ant-upload-text-icon 
      svg {
      color: var(${ThemeVarNames.PrimaryText}) !important;
    }
  }

  .ant-upload-list-item {
    .ant-upload-list-item-card-actions {
      .ant-btn svg {
        color: var(${ThemeVarNames.PrimaryText}) !important;
      }
    }
  }
`;

const StyledAlert = styled(Alert)`
  text-align: center;
`;
