import {Form as AntdForm, FormProps as AntdFormProps} from 'antd';
import styled from 'styled-components';

import {ThemeVarNames} from '@theme';

export type FormProps = AntdFormProps;

export const Form = AntdForm;

Form.Item = styled(AntdForm.Item)`
  label {
    color: var(${ThemeVarNames.SecondaryText});
  }

  .ant-form-item-explain-success {
    color: var(${ThemeVarNames.TertiaryText});
  }

  &.ant-form-item-explain-error,
  .ant-form-item-explain-error {
    color: var(${ThemeVarNames.Error});
  }

  &.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    background-color: var(${ThemeVarNames.InputPrimary});
    border-color: var(${ThemeVarNames.Error});
  }
`;
