import * as React from 'react';
import styled from 'styled-components';

import {device} from '@utils/breakpoints';

import {Typography} from '@components/common';
import {InputPackage} from './InputPackage';
import {ListPackages} from './ListPackages';
import {ColumnHeaders} from './ColumnHeaders';

export function Selling(): JSX.Element {
  return (
    <StyledLayout>
      <StyledHeader>
        <Typography variant="primary" as="h2" size="xx-large">
          Selling Platform
        </Typography>
      </StyledHeader>
      <ColumnHeaders titles={['Package', 'Price', 'Interval', '']} />
      <InputPackage />
      <ListPackages />
    </StyledLayout>
  );
}

const StyledLayout = styled.div`
  --grid-template-columns: 1fr 150px 160px 140px;
  --gap: 16px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;

  ${device.desktop} {
    padding: 20px;
  }
`;

const StyledHeader = styled.div`
  padding: 0;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-size: 20px;
`;
