import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {match} from 'ts-pattern';
import {useEffect} from 'react';
import SharingService from '@utils/apis/SharingService';
import {queryClient} from '..';

export function useGenericDatasets(
  organizationId: number,
  query: string[],
  page: number,
  cacheType: string,
  enabled = true
): UseQueryResult<any, Error> {
  const result = useQuery<any, Error, any, [string, string, number, Array<string>, number]>(
    ['sharing-center', cacheType, organizationId, query, page],
    ({queryKey, signal}) =>
      match(cacheType)
        .with('to-share', () =>
          SharingService.searchDatasetsNotSharedWithActor(queryKey[2], queryKey[3], queryKey[4], 10, signal)
        )
        .with('to-revoke', () =>
          SharingService.searchDatasetsSharedWithActor(queryKey[2], queryKey[3], queryKey[4], 10, signal)
        )
        .with('my-datasets', () =>
          SharingService.getOwnedNotebooks(queryKey[3], queryKey[4], 10, signal)
        )
        .otherwise(() => null),
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      staleTime: 5000,
      enabled: enabled || cacheType === 'my-datasets'
    }
  );

  useEffect(() => () => {
    queryClient.cancelQueries(['sharing-center', cacheType, organizationId, query, page]);
  }, [cacheType, organizationId, query, page]);

  return {...result};
}
