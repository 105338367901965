import React, {useState, useMemo, ChangeEvent, ChangeEventHandler} from 'react';
import styled from 'styled-components';
import {Layout, Spin} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import _ from 'lodash';

import {
  Input,
  Table,
  Typography,
  Tabs
} from '@components/common';
import {AdminPortalView} from '@service/sessionStorage';
import HelmetWrapper from '@utils/HelmetWrapper';
import {device} from '@utils/breakpoints';
import UserContainer from '@utils/state/userContainer';
import useGetCompanyUsers from '@service/adminService/useGetCompanyUsers';
import {ActionsMenu} from './components/ActionsMenu';
import {InviteUser} from './components/InviteUser';

const Helmet = <HelmetWrapper title="Admin Portal" description="Manage your Organization" />;

const EndOfTabBar = ({refetch, debouncedInputChange}:
  {refetch: Function; debouncedInputChange: ChangeEventHandler<HTMLInputElement>}) =>
  <StyledExtendedTabBar>
    <InviteUser refetch={refetch}/>
    <Input
      allowClear
      variant="alternative"
      size="large"
      prefix={<SearchOutlined />}
      placeholder="Search"
      onChange={debouncedInputChange}
      id="input-search"
    />
  </StyledExtendedTabBar>;

export function AdminPortal(): JSX.Element {
  const [inputValue, setInputValue] = useState<string>('');
  const [view, setView] = useState<AdminPortalView>(
    AdminPortalView.defaultValue()
  );
  const {user} = UserContainer.useContainer();

  const {status, users, refetch} = useGetCompanyUsers(inputValue);

  const debouncedInputChange = useMemo(
    () => _.debounce((e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value), 300),
    []
  );

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'User',
      dataIndex: 'code',
      key: 'code',
      responsive: ['md']
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      responsive: ['md']
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Actions',
      dataIndex: 'code',
      key: 'button',
      align: 'right',
      fixed: 'right',
      // eslint-disable-next-line react/display-name
      render: (thing: string) => <ActionsMenu username={thing} refetchTable={refetch}/>
    }
  ];

  const resultTable = (columns: any, datasource: typeof users) =>
    status !== 'loading' ?
      <Table
        key="all-users"
        columns={columns}
        dataSource={datasource}
        pagination={{
          position: ['topRight'],
          showTotal: (total, range) => `Results ${range[0]} - ${range[1]} of ${total}`
        }}
        scroll={{x: 300}}
        tableLayout="fixed"
      /> :
      <StyledSpin size="large" />
    ;

  const company = user.actor.code;

  return (
    <>
      {Helmet}
      <StyledHeader>
        <Typography variant="primary" as="h1" size="x-large" id="page-title">
          {company.charAt(0).toUpperCase() + company.slice(1)} Admin Portal
        </Typography>
      </StyledHeader>
      <Container>
        <StyledTabs
          centered
          activeKey={view}
          size="large"
          tabPosition="top"
          destroyInactiveTabPane
          tabBarExtraContent={<EndOfTabBar refetch={refetch} debouncedInputChange={debouncedInputChange} />}
        >
          <Tabs.TabPane tab="All Users" key="all-users" >
            {resultTable(tableColumns, users)}
          </Tabs.TabPane>
        </StyledTabs>
      </Container>
    </>
  );
}

const {Header} = Layout;

const StyledSpin = styled(Spin)`
 width: 100%;
 padding-top: 100px;
`;

const StyledTabs = styled(Tabs)`
  margin-bottom: 100px;

  .ant-tabs-nav {
    display: inline;

    ${device.desktop} {
      display: flex;
    }
  }
`;

const Container = styled.div`
    background-color: inherit;
    margin: 0px 20px;
`;

const StyledHeader = styled(Header)`
  background-color: inherit;
  text-align: center;
  margin: 15px 0px 15px 0px;
  font-size: 1.6rem;
`;

const StyledExtendedTabBar = styled.div`
  display: flex;
  flex-direction: row;
  flex: wrap;
`;
