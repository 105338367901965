import * as React from 'react';
import styled from 'styled-components';

import {ThemeVarNames} from '@theme';

// LANDING
export const LandingContainer = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: #111928;
`;

// DASHBOARD

export function DashboardContainer({
  children,
  style
}: React.PropsWithChildren<{style?: React.CSSProperties}>): JSX.Element {
  return (
    <StyledDashboardScrollContainer style={style}>
      {children}
    </StyledDashboardScrollContainer>
  );
}

export type UseDashboardScrollResult = {
  isScrollActived: boolean;
};

export function useDashboardScroll(): UseDashboardScrollResult {
  const [offset, setOffset] = React.useState<number>(0);
  const isScrollActived = offset > 0;

  const onScroll = () => {
    setOffset(window.scrollY);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return {
    isScrollActived
  };
}

const StyledDashboardScrollContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(${ThemeVarNames.PrimaryBg});
`;
