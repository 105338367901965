import * as React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {device} from '@utils/breakpoints';
import HelmetWrapper from '@utils/HelmetWrapper';
import {DashboardContainer} from '@components/common';
import {Logo} from '@components/common/Logo';
import {ContactResetForm} from '@components/authentication/Reset/ContactResetForm';

export const ContactReset: React.FC = () =>
  <>
    <DashboardContainer>
      <HelmetWrapper
        title="Change Contact Password"
        description="Change Password"
        pathname="/activate/"

        //   pathname="/contact-reset/:token"
      />
      <HeaderWrap>
        <Link to="/">
          <Logo imageColor="gradient" />
        </Link>
      </HeaderWrap>
      <StyledContainer>
        <ImageContainer
          src="/img/unsplash_baII27W6z7k.png"
          alt="Contact Change Password Background"
        />
        <Container>
          <ContactResetForm />
        </Container>
      </StyledContainer>
    </DashboardContainer>
  </>;

const HeaderWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 1400px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 16px;
`;

const StyledContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;

  ${device.desktop} {
    flex-direction: row;
    overflow: hidden;
  }
`;
const ImageContainer = styled.img`
  height: 100px;

  ${device.desktop} {
    height: 100vh;
    flex: 1;
  }
`;
const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
