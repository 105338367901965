import {CardCvcElement, CardElement, CardExpiryElement, CardNumberElement, useElements} from '@stripe/react-stripe-js';
import * as React from 'react';
import styled from 'styled-components';
import {Typography} from '@components/common';
import {Theme, ThemeVarNames} from '../../../theme/types';
import {useTheme} from '../../../theme/useTheme';

type Props = {
  data: PaymentFormData;
  setData: (data: PaymentFormData) => void;
}

export type PaymentFormData = {
  name: string;
  cardNumber: string;
  expDate: string;
  cvc: string;
}

function PaymentModal({data, setData}: Props) {
  const {name, cardNumber, expDate, cvc} = data;
  const theme = useTheme();
  const themeValuesMap = Theme.themesMap[theme];

  // const elements = useElements();

  // const CardNumber = elements.create('cardNumber', {
  //   style: {
  //     base: {
  //       color: themeValuesMap[ThemeVarNames.SecondaryText],
  //       '::placeholder': {
  //         color: themeValuesMap[ThemeVarNames.TertiaryText]
  //       }
  //     }
  //   }
  // });
  // const CardExpiry = elements.create('cardExpiry', {
  //   style: {
  //     base: {
  //       color: themeValuesMap[ThemeVarNames.SecondaryText],
  //       '::placeholder': {
  //         color: themeValuesMap[ThemeVarNames.TertiaryText]
  //       }
  //     }
  //   }
  // });

  // const CardCvc = elements.create('cardCvc', {
  //   style: {
  //     base: {
  //       color: themeValuesMap[ThemeVarNames.SecondaryText],
  //       '::placeholder': {
  //         color: themeValuesMap[ThemeVarNames.TertiaryText]
  //       }
  //     }
  //   }
  // });

  const handleTextChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;
    setData({...data, [name]: value});
  };

  return (
    <>
      <Typography size="medium">Payment</Typography>
      <div style={styles.container}>
        <div style={styles.paymentMethod}>
          <Typography size="small">Your payment method</Typography>
          <div style={styles.option}>
            <label htmlFor="creditCard" style={styles.label}>Credit Card</label>
            <div style={styles.creditCardFields}>
              <input type="text" name="name" style={styles.input} placeholder="Name on card" value={name} onChange={handleTextChange} />
              <StyledCardNumberElement options={{
                style: {
                  base: {
                    padding: '20px',
                    color: themeValuesMap[ThemeVarNames.SecondaryText],
                    '::placeholder': {
                      color: themeValuesMap[ThemeVarNames.TertiaryText]
                    }
                  }
                }
              }} />
              <StyledCardExpiryElement options={{
                style: {
                  base: {
                    padding: '20px',
                    color: themeValuesMap[ThemeVarNames.SecondaryText],
                    '::placeholder': {
                      color: themeValuesMap[ThemeVarNames.TertiaryText]
                    }
                  }
                }
              }}/>
              <StyledCardCvcElement options={{
                style: {
                  base: {
                    padding: '20px',
                    color: themeValuesMap[ThemeVarNames.SecondaryText],
                    '::placeholder': {
                      color: themeValuesMap[ThemeVarNames.TertiaryText]
                    }
                  }
                }
              }} />
            </div>
          </div>
        </div>
        <div style={styles.plan}>
          <Typography size="medium">Plan</Typography>
          <div style={styles.planDetails}>
            <div style={styles.planTop}>
              <p>Rose Pro</p>
              <p>Monthly</p>
            </div>
            <div style={styles.orderSummary}>
              <Typography size="small">Order Summary</Typography>
              <div style={styles.product}>
                <div>
                  <p>$14.99/month</p>
                  <p>Cap at 100 queries per day</p>
                </div>
              </div>
              <div style={styles.total}>
                <p>Total</p> <p>$14.99/month</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

const StyledCardNumberElement = styled(CardNumberElement)`
background-color: transparent;
border-radius: 8px;
color: white;
font-size: 12px;
padding: 12px;
width: 100%;
margin-top: 8px;
border: 0.5px solid var(${ThemeVarNames.Border});
placeholder: {
  color: var(${ThemeVarNames.SecondaryText});
}
`;
const StyledCardCvcElement = styled(CardCvcElement)`
background-color: transparent;
border-radius: 8px;
color: white;
font-size: 12px;
padding: 12px;
width: 100%;
margin-top: 8px;
border: 0.5px solid var(${ThemeVarNames.Border});
`;
const StyledCardExpiryElement = styled(CardExpiryElement)`
background-color: transparent;
border-radius: 8px;
color: white;
font-size: 12px;
padding: 12px;
width: 100%;
margin-top: 8px;
border: 0.5px solid var(${ThemeVarNames.Border});
`;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch', // Add this line
    justifyContent: 'space-between',
    width: '100%'
  },
  title: {
    fontSize: '24px',
    color: ThemeVarNames.PrimaryText,
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: '32px'
  },
  sectionTitle: {
    color: ThemeVarNames.PrimaryText,
    fontWeight: 'bold',
    fontSize: '12px',
    textAlign: 'left',
    marginBottom: '12px'
  },
  option: {
    color: ThemeVarNames.PrimaryText,
    marginBottom: '10px',
    borderRadius: '8px',
    border: 'solid 1px #FFFFFF33',
    padding: '20px'
  },
  label: {
    color: ThemeVarNames.PrimaryText,
    textAlign: 'left',
    fontSize: '12px'
  },
  input: {
    backgroundColor: 'transparent',
    borderRadius: '8px',
    color: ThemeVarNames.PrimaryText,
    fontSize: '12px',
    padding: '12px',
    width: '100%',
    marginTop: '8px',
    border: `0.5px solid var(${ThemeVarNames.Border})`
  },
  inputHalf: {
    backgroundColor: 'transparent',
    borderRadius: '8px',
    color: ThemeVarNames.PrimaryText,
    fontSize: '12px',
    padding: '12px',
    marginTop: '8px',
    border: `0.5px solid var(${ThemeVarNames.Border})`,
    width: '45%'
  },
  paymentMethod: {
    color: ThemeVarNames.PrimaryText,
    width: '50%',
    paddingRight: '20px'
  },
  creditCardFields: {
    color: ThemeVarNames.PrimaryText,
    display: 'flex',
    flexDirection: 'column'
  },
  cardBottom: {
    color: ThemeVarNames.PrimaryText,
    display: 'flex'
  },
  plan: {
    color: ThemeVarNames.PrimaryText,
    width: '50%',
    paddingLeft: '20px'
  },
  planDetails: {
    color: ThemeVarNames.PrimaryText,
    marginBottom: '20px'
  },
  planTop: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '12px'
  },
  orderSummary: {
    color: ThemeVarNames.PrimaryText,
    border: `0.5px solid var(${ThemeVarNames.Border})`,
    borderRadius: '8px',
    padding: '18px',
    marginBottom: '10px',
    fontSize: '12px'
  },
  orderTop: {
    color: ThemeVarNames.PrimaryText
  },
  product: {
    color: ThemeVarNames.PrimaryText,
    display: 'flex',
    marginTop: '20px',
    marginBottom: '20px',
    justifyContent: 'space-between'
  },
  total: {
    display: 'flex',
    color: ThemeVarNames.PrimaryText,
    justifyContent: 'space-between',
    paddingTop: '20px',
    paddingBottom: '6px',
    borderTop: `solid 1px var(${ThemeVarNames.Border})`
  }
};

export default PaymentModal;
