import * as React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import {PopupButton} from '@typeform/embed-react';
import {
  LinkedInButton,
  TwitterButton
} from '@components/common/LandingSocialButtons';
import {device} from '@utils/breakpoints';
import {RedditButton} from '../LandingSocialButtons/RedditButton';
import {DiscordButton} from '../LandingSocialButtons/DiscordButton';

export function Footer(): JSX.Element {
  const utm = localStorage.getItem('utm');
  return (
    <>
      <FootWarper>
        <CompanyList className="flex flex-col flex-wrap items-start justify-center sm:items-center sm:flex-row ">
          <div className="m-[16px] mr-12">
            <img className="h-10 " src="/img/new_logo/svg/RoseAIlogo-gradient.svg" alt="Rose.ai logo" />
          </div>
          <div className="flex flex-row items-start justify-around">
            <ButtonList>
              <FooterLinkTitle>Company</FooterLinkTitle>
              <ButtonItem>
                <StyledA
                  target="_blank"
                  href="https://angel.co/company/rose-technology/jobs"
                >
                Careers
                </StyledA>
              </ButtonItem>
              <ButtonItem>
                <StyledLink
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={!!utm ? `/terms/?utm_source=${utm}` : '/terms'}
                >
                Terms of Service
                </StyledLink>
              </ButtonItem>
              <ButtonItem>
                <StyledLink
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={!!utm ? `/privacy/?utm_source=${utm}` : '/privacy'}
                >
                Privacy Policy
                </StyledLink>
              </ButtonItem>
            </ButtonList>
            <ButtonList>
              <FooterLinkTitle>Support</FooterLinkTitle>
              <ButtonItem>
                <StyledA target="_blank" href="https://docs.rose.ai">
                Documentation
                </StyledA>
              </ButtonItem>
              <ButtonItem>
                <StyledTypeFormB size={95} id="hSNDAHge" transitiveSearchParams={true}>
                  Contact Us
                </StyledTypeFormB>
              </ButtonItem>
            </ButtonList>
          </div>
        </CompanyList>
      </FootWarper>
      <footer className="z-10 flex flex-col items-center justify-center w-screen px-8 py-6 h-fit md:flex-row md:justify-between">
        <div className="flex flex-row items-center justify-start grow">
          <p className="text-white">Rose © 2023 All rights reserved.</p>
        </div>
        <div className="flex flex-row items-center justify-end md:pr-10 grow">
          <LinkedInButton />
          <TwitterButton/>
          <RedditButton />
          <DiscordButton />
        </div>
      </footer>
    </>
  );
}

const StyledTypeFormB = styled(PopupButton)`
  font-size: 14px;
  font-weight: normal;
  color: #fff !important;

  &:hover {
    color: #2096f3 !important;
    cursor: pointer;
  }
`;

const FootWarper = styled.ul`
  position: relative;
  padding: 48px 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  max-width: 1100px;
  margin: 0 auto;
`;

const CompanyList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-left: 0;
  z-index: 10;
`;

const FooterLinkTitle = styled.h1`
  font-size: 14px;
  height: 16px;
  margin-bottom: 16px;
  color: #fff;
  font-weight: 700;
`;
const ButtonList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  ${device.tablet} {
    justify-content: space-around;
    padding: 0;
    margin: 16px;
  }

  ${device.desktop} {
    padding: 0;
    max-width: 960px;
    justify-content: space-around;
    margin: 16px;
  }
`;

const ButtonItem = styled.li`
  height: 16px;
  list-style: none;
  color: #000;
  margin-bottom: 0.5rem;
  font-size: smaller;
  text-decoration: none;
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: normal;
  color: #fff !important;

  &:hover {
    color: #2096f3 !important;
    cursor: pointer;
  }
`;

const StyledA = styled.a`
  font-size: 14px;
  font-weight: normal;
  color: #fff !important;

  &:hover {
    color: #2096f3 !important;
    cursor: pointer;
  }
`;
