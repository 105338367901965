import {Table as AntdTable} from 'antd';
import styled from 'styled-components';

import {ThemeVarNames} from '@theme';

export const Table = styled(AntdTable)`
  thead th {
    background-color: var(${ThemeVarNames.SecondaryBg});
    color: var(${ThemeVarNames.SecondaryText});
    border-color: var(${ThemeVarNames.Border});
  }

  tbody td {
    background-color: var(${ThemeVarNames.PrimaryBg});
    color: var(${ThemeVarNames.PrimaryText});
    border-color: var(${ThemeVarNames.Border});
  }

  tbody tr:hover td {
    background-color: var(${ThemeVarNames.TertiaryBg}) !important;
  }

  .ant-empty-description {
    color: var(${ThemeVarNames.PrimaryText});
  }
  .ant-pagination-total-text {
    margin-right: auto;
    font-size: 1rem;
    color: var(${ThemeVarNames.PrimaryText});
    background-color: transparent;
    @media screen and (max-width: 420px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  
  .ant-table-pagination-right {
    @media screen and (max-width: 420px) {
      justify-content: center;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    color: var(${ThemeVarNames.PrimaryText});;

    .ant-pagination-item-link {
      color: inherit;
      background-color: inherit;
      border-color: var(${ThemeVarNames.Border});

      :hover {
        color: var(${ThemeVarNames.Action});
        border-color: var(${ThemeVarNames.Action});
      }

      :disabled {
        color: var(${ThemeVarNames.Disabled});
        border-color: var(${ThemeVarNames.Disabled});
      }

      :disabled:hover {
        color: var(${ThemeVarNames.DisabledDark});
        border-color: var(${ThemeVarNames.DisabledDark});
      }
    }
  }

  .ant-pagination-item {
    border-color: var(${ThemeVarNames.Border});
    background-color: inherit;

    a {
      color: var(${ThemeVarNames.PrimaryText});;
    }

    :hover {
      border-color: var(${ThemeVarNames.Action});

      a {
        color: var(${ThemeVarNames.Action});
      }
    }
  }

  .ant-pagination-item-active {
    border-color: var(${ThemeVarNames.Action});
    background-color: var(${ThemeVarNames.SecondaryBg});

    :hover a {
      color: var(${ThemeVarNames.Action});
    }
  }

  .ant-pagination-jump-next {
    .ant-pagination-item-ellipsis {
      color: var(${ThemeVarNames.SecondaryText});
    }

    :hover {
      border-color: var(${ThemeVarNames.Action});

      .ant-pagination-item-ellipsis {
        color: var(${ThemeVarNames.SecondaryText});
      }

      .ant-pagination-item-container {
        border: 1px solid var(${ThemeVarNames.Action});
      }
    }
  }
`;
