export type RoseActor = {
  actorId: number | null
  code: string
  connectedAccountId: string
  customerId: string
  id: number
  keepCalculated: boolean
  searchVector: string
  type: string
}

export const RoseActor = {
  deserialize(data: any): RoseActor {
    return {
      type: data.type,
      code: data.code,
      id: data.id,
      actorId: data.actor_id,
      connectedAccountId: data.connected_account_id,
      customerId: data.customer_id,
      keepCalculated: data.keep_calculated,
      searchVector: data.search_vector
    };
  },
  serialize(roseActor: RoseActor): Record<string, any> {
    return {
      type: roseActor.type,
      code: roseActor.code,
      id: roseActor.id,
      actor_id: roseActor.actorId,
      connected_account_id: roseActor.connectedAccountId,
      customer_id: roseActor.customerId,
      keep_calculated: roseActor.keepCalculated,
      search_vector: roseActor.searchVector
    };
  }
};
