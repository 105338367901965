import React, {useState} from 'react';
import styled from 'styled-components';

import {Spinner, Alert, Empty, Pagination} from '@components/common';
import {useBuying} from '@service/useBuying';
import {device} from '@utils/breakpoints';
import {useSubscribedPackages} from '@service/useSubscribedPackages';

import {Item} from './Item/index';

type ResultProps = {
  keywords: string[]
}

const PAGE_SIZE = 9;

export function Result({keywords}: ResultProps): JSX.Element {
  const listContainerRef = React.useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(0);
  const {data, status, error} = useBuying(keywords, page, PAGE_SIZE);
  const subscribedPackages = useSubscribedPackages();

  if (status === 'loading') {
    return <Spinner size={64} />;
  }

  if (status === 'error') {
    return <Alert type="error" message={error.message} />;
  }

  if (data.totalItems === 0) {
    return <Empty />;
  }

  const {items = [], totalItems} = data;

  const handleOnPageChange = (pageNumber: number) => {
    // we need (-1) and (+1) in current prop in the Pagination
    // because the pagination in the api start by 0
    setPage(pageNumber - 1);

    if (listContainerRef) {
      listContainerRef.current.scrollIntoView();
    }
  };

  const resultsFrom = page * PAGE_SIZE + 1;
  const resultsTo = Math.min(totalItems, page * PAGE_SIZE + PAGE_SIZE);

  return (
    <StyledContainer>
      <StyledInfo>
        {`Results ${resultsFrom} - ${resultsTo} of ${totalItems}`}
      </StyledInfo>
      <StyledItemsContainer ref={listContainerRef}>
        {items.map((result: any) =>
          <Item
            isCurrentUserSubscribed={subscribedPackages.isSubscribed(
              result.code
            )}
            key={`${result.code}:${result.interval}`}
            rosePackage={result}
          />
        )}
      </StyledItemsContainer>
      <StyledPaginationContainer>
        <Pagination
          onChange={handleOnPageChange}
          total={totalItems}
          current={page + 1}
          defaultPageSize={PAGE_SIZE}
          pageSize={PAGE_SIZE}
        />
      </StyledPaginationContainer>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledInfo = styled.span``;

const StyledPaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;

const StyledItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  /* This has to be bigger than the dashboard header height
   * to see the full item after the scrollIntoView is triggered
   */
  scroll-margin-top: 80px;

  ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  ${device.desktop} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${device['big-desktop']} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
