import React, {useState, useEffect, useMemo, useRef} from 'react';
import {IoTrash} from '@react-icons/all-files/io5/IoTrash';
import {IoServerOutline} from '@react-icons/all-files/io5/IoServerOutline';
import {BiHide} from '@react-icons/all-files/bi/BiHide';
import {BiShow} from '@react-icons/all-files/bi/BiShow';
import {AiOutlineColumnWidth} from '@react-icons/all-files/ai/AiOutlineColumnWidth';
import {Divider} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {Spinner, Select, SelectOption, ModuleLayout, ViewMode, Button} from '@components/common';
import {Connection, RoseModule} from '@types';
import {useRoseQuery} from '@service/useRoseQuery';

import {Result} from '../../Result';
import {changeWidth} from '../common/ChangeWidth';
import {Input} from '../common/Input';
import {OptionMenu} from '../common/Base';
import {HelperText} from '../common/HelperText';
import {Options} from '../common/Options';
import {
  ConnectionSelectContainer,
  StyledToolbar,
  OptionsContainer,
  IconContainer,
  AddIcon,
  ToolbarContainer,
  StyledError,
  ResultContainer
} from './QueryStyle';
import {ConnectionModal} from './ConnectionModal';

const COLOR = '#f08080';

export type QueryProps = {
  mod: RoseModule
  connections: Connection[]
  defaultConnection: string
  onAddModule: (mod: Partial<RoseModule>) => void
  onRemoveModule: () => void
  onModuleChanged: (newMod: RoseModule) => void
  onNotebookMetasChanged: (metas: any) => void
  options: OptionMenu[]
  updateCurrentModKey: (modKey: string) => void
  isReadOnly?: boolean
  autoFocus?: boolean
  view?: ViewMode
}

export function Query(props: QueryProps): JSX.Element {
  const {
    mod,
    connections = [],
    onModuleChanged,
    onAddModule,
    onRemoveModule,
    options,
    updateCurrentModKey,
    isReadOnly,
    autoFocus,
    view,
    defaultConnection,
    onNotebookMetasChanged
  } = props;
  const [query, setQuery] = useState<{value: string; shouldRefetch: boolean}>(
    {
      value: mod.textBox,
      shouldRefetch: false
    }
  );
  const [showResults, setShowResults] = useState(true);
  const [connectionCode, _setConnectionCode] = useState<string | undefined>(
    mod.modulesettings?.connection?.code ||
    defaultConnection
  );
  const [connectionModalVisible, setConnectionModalVisible] = useState<boolean>(false);

  const setConnectionCode = (connectionCode: string) => {
    onNotebookMetasChanged({
      defaultConnection: connectionCode
    });
    _setConnectionCode(connectionCode);
  };

  const connection = useMemo(
    () => connections.find((c) => c.code === connectionCode),
    [connectionCode, connections]
  );

  const {data, status, refetch, isFetching, error} = useRoseQuery(
    query.value,
    connection,
    {
      onSuccess(data_) {
        const newMod = {...mod};
        newMod.textBox = data_.code;
        newMod.modulesettings = {
          ...newMod.modulesettings,
          connection: data_.connection
        };
        onModuleChanged(newMod);
      }
    }
  );

  useEffect(
    () => {
      if (
        mod.runOnLoad &&
        mod.modulesettings?.connection?.code &&
        mod.textBox !== ''
      ) {
        refetch();
      }
    },
    [connection, mod.modulesettings, mod.textBox]
  );

  /**
   * The ant design table inside the Result component
   * is not respecting the max-width
   * and it's ruining the responsiveness in mobile. That's why I am getting
   * the ResultLayout dimensions and set manually the max-width
   * to the ResultContainer in mobile
   */
  const [resultLayoutRect, serResultLayoutRect] = useState<DOMRect>();
  const resultLayoutRef = useRef<HTMLDivElement>();
  useEffect(
    () => {
      if (resultLayoutRef?.current) {
        serResultLayoutRect(resultLayoutRef.current.getBoundingClientRect());
      }
    },
    [resultLayoutRef]
  );

  useEffect(() => {
    setQuery({value: mod.textBox, shouldRefetch: false});
  }, [mod]);

  useEffect(() => {
    if (query.shouldRefetch) {
      refetch();
    }
  }, [query]);

  const handleOnRun = () => {
    setQuery((state) => ({...state, shouldRefetch: true}));
    onModuleChanged({...mod, textBox: query.value});
  };

  function handleOnAddModule() {
    onAddModule(RoseModule.create({type: 'query'}));
  }

  function handleOnChangeWidthClick(width: '33%' | '50%' | '66%' | '100%') {
    return changeWidth({
      width,
      mod,
      textBox: query.value,
      onModuleChanged
    });
  }

  const handleOnChange = (value: string) => {
    setQuery({value, shouldRefetch: false});
  };

  const mergedOptions: OptionMenu[] = [
    ...options,
    {
      title: 'Delete cell',
      icon: <IoTrash style={{marginRight: 10}} />,
      onClick: onRemoveModule
    },
    {
      hidden: true,
      title: 'Width',
      icon: <AiOutlineColumnWidth style={{marginRight: 10}} />,
      options: [
        {
          title: '100%',
          onClick: handleOnChangeWidthClick('100%')
        },
        {
          title: '66%',
          onClick: handleOnChangeWidthClick('66%')
        },
        {
          title: '50%',
          onClick: handleOnChangeWidthClick('50%')
        },
        {
          title: '33%',
          onClick: handleOnChangeWidthClick('33%')
        }
      ]
    }
  ];

  if (status === 'success') {
    mergedOptions.push({
      title: showResults ? 'Hide' : 'Show',
      icon: showResults ? <BiHide /> : <BiShow />,
      onClick: () => setShowResults(!showResults)
    });
  }

  const ConnectionSelect =
    <ConnectionSelectContainer>
      <i>
        <IoServerOutline />
      </i>
      <Select
        value={connectionCode}
        size="small"
        onChange={(value) => setConnectionCode(value as string)}
        bordered={false}
        dropdownMatchSelectWidth={false}
        dropdownRender={(menu) =>
          <>
            {menu}
            <Divider style={{margin: '8px 0'}} />
            <Button type="text" icon={<PlusOutlined />} onClick={() => setConnectionModalVisible(true)}>
                Add connection
            </Button>
          </>
        }

      >
        {connections.map((connection_) =>
          <SelectOption key={connection_.code} value={connection_.code}>
            {connection_.code}
          </SelectOption>
        )}
      </Select>
    </ConnectionSelectContainer>
    ;

  return (
    <ModuleLayout
      key={mod.key}
      width={RoseModule.getWidth(mod)}
      isReadOnly={isReadOnly}
      view={view}
    >
      <ModuleLayout.ToolbarTop>
        <StyledToolbar>{ConnectionSelect}</StyledToolbar>
      </ModuleLayout.ToolbarTop>
      <ModuleLayout.Input id={mod.key}>
        <Input
          autoFocus={autoFocus}
          onChange={handleOnChange}
          value={query.value}
          onRun={handleOnRun}
          color={COLOR}
          currentMod={mod.key}
          updateCurrentModKey={updateCurrentModKey}
        />
      </ModuleLayout.Input>
      { isReadOnly || view === 'view' ? null :
        <ModuleLayout.Options>
          <OptionsContainer>
            <Options options={mergedOptions} color={COLOR} />
            <IconContainer onClick={handleOnAddModule}>
              <AddIcon color={COLOR} size={24} />
            </IconContainer>
          </OptionsContainer>
        </ModuleLayout.Options>
      }
      <ModuleLayout.ToolbarBottom>
        <ToolbarContainer>
          <HelperText />
        </ToolbarContainer>
      </ModuleLayout.ToolbarBottom>
      <ModuleLayout.RoseObject>
        {isFetching ?
          <Spinner size={64} width="100%" /> :
          status === 'error' ?
            <StyledError>{error.message}</StyledError> :
            showResults && data?.roseObject ?
              <ResultContainer parentWidth={resultLayoutRect?.width}>
                <Result result={data.roseObject} addModule={onAddModule} mod={mod} view={view} />
              </ResultContainer> :
              null}
      </ModuleLayout.RoseObject>
      <ConnectionModal visible={connectionModalVisible} onClose={() => setConnectionModalVisible(false)}/>
    </ModuleLayout>
  );
}
