export const CustomerQuotes = [
  {
    text: `"Rose has allowed me to keep up with markets and macro since I left Bridgewater. It's an amazing product!"`,
    name: "Daniel Saedi",
    title: ["Founder, Minerva Data"],
    star: 5,
  },
  {
    text: `"Rose AI is a phenomenal tool. @abcampbell and his team have a unique product here."`,
    name: "Andy Constan",
    title: ["CEO/CIO at Damped Spring Advisors", "Former Chief Strategist at Brevan Howard"],
    star: 5,
  },
  {
    text: `"Bottom bucket analysts bout to get automated out with this"`,
    name: "Litquidity",
    star: 5,
  },
  {
    text: `"As a former consultant, something like Rose would have been truly magical — I'm imagining dozens of hours each week that teams could have eliminated."`,
    name: "Aiyappa",
    title: ["McKinsey Consulting"],
    star: 5,
  },
  {
    text: `"My friends @rosedotai provide an interface to the data geyser we all face. I am thankful for their partnership and use their tech in my Substack posts."`,
    name: "Paul Podolsky",
    title: ["Founder, Still Press & Kate Capital","Former Bridgewater Senior Portfolio Strategist"],
    star: 5,
  },
  {
    text: `"I've been waiting my whole life for something like this to exist."`,
    name: "Anna-Sophia Lesiv",
    title: ["Founders Fund, 8VC"],
    star: 5,
  },
];
