import styled from 'styled-components';

import {ThemeVarNames} from '@theme';

const TreeContainer = styled.div`
  width: 100%;
  border: 1px solid var(${ThemeVarNames.Border});
  border-radius: 8px;
  overflow: hidden;
  margin-top: 25px;

  svg {
    width: 100% !important;
  }
`;

export {TreeContainer};
