import React from "react";

/**
 * Temporary persistent banner for chat.rose.ai announcement
 */
export function RoseBudBanner() {
    return (
        <div className="font-semibold text-white text-center p-3 bg-[#9739E9]">
            Try out our latest release,{" "}
            <a
                // Note: Maybe this should be `process.env.REACT_APP_VISION_URL` instead of hard-coded.
                href="https://chat.rose.ai"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-blue-400 visited:text-white !underline"
            >
                {/* This should probably come from an environment variable. */}
                Rose Bud
            </a>
            !
        </div>
    )
}