/* eslint-disable react/display-name */
import * as React from 'react';
import styled from 'styled-components';
import {Card} from 'antd';

import {Typography} from '@components/common';
import {ThemeVarNames} from '@theme';
import {Interval, MarketplacePackage} from '@types';
import UserContainer from '@utils/state/userContainer';

import {BuyButton} from './BuyButton';
import {PreviewModal} from './PreviewModal';
import {DetailsButton} from './DetailsButton';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

type Props = {
  rosePackage: any;
  isCurrentUserSubscribed: boolean;
};

export default (props: Props): JSX.Element => {
  const {rosePackage, isCurrentUserSubscribed} = props;
  const {user} = UserContainer.useContainer();

  return (
    <StyledCard
      size="small"
      title={
        <Typography as="h3" variant="secondary">
          {rosePackage.code}
        </Typography>
      }
    >
      <Typography variant="primary" as="p">
        <b>Owner: </b>
        {rosePackage.actor.code}
      </Typography>
      <Typography variant="primary" as="p">
        <b>Price: </b>
        {currencyFormatter.format(rosePackage.price)}
      </Typography>
      <Typography variant="primary" as="p">
        <b>Interval: </b>
        {Interval.toFriendlyText(rosePackage.interval)}
      </Typography>
      <CardButtons>
        <LeftButtons>
          <DetailsButton pck={rosePackage} />
          <PreviewModal code={rosePackage.code} title={rosePackage.code} />
        </LeftButtons>
        {MarketplacePackage.isOwner(user, {
          code: rosePackage.code,
          price: rosePackage.price,
          interval: rosePackage.interval,
          actorId: rosePackage.actor_id
        }) ?
          <OwnerStyle variant="primary">Owner</OwnerStyle> :
          isCurrentUserSubscribed ?
            <SubscribedBox>Purchased</SubscribedBox> :
            <BuyButton
              pck={{
                code: rosePackage.code,
                price: rosePackage.price,
                interval: rosePackage.interval,
                actorId: rosePackage.actor_id
              }}
            />
        }
      </CardButtons>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  background-color: var(${ThemeVarNames.SecondaryBg});
  border-color: var(${ThemeVarNames.Border});

  .ant-card-head {
    border-color: var(${ThemeVarNames.Border});

    .ant-card-head-title {
      font-weight: bold;
    }
  }

  @media (max-width: 960px) {
    flex: 1 1 100%;
  }
`;

const CardButtons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;

const LeftButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const OwnerStyle = styled(Typography)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubscribedBox = styled.div`
  line-height: 1.5715;
  font-weight: 400;
  padding: 4px 0;
  border-radius: 2px;
  font-size: 14px;
  height: 32px;
  color: hsl(207, 100%, 63%);
`;
