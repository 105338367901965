import * as React from 'react';
import styled from 'styled-components';
import {debounce} from 'lodash';

import {device} from '@utils/breakpoints';
import {
  InputSearch as BaseInputSearch,
  InputSearchProps as BaseInputSearchProps
} from './Input';

import {Tag, TagContainer} from './Tag';

export type InputSearchWithTagsProps = {
  onChange: (newValues: string[]) => void
  autoFocus: boolean
  inputProps?: Omit<BaseInputSearchProps, 'onChange' | 'ref' | 'value' | 'onSearch'>
}

const updateFocusTag = (setFocus: (s: string) => void, values: string[]) =>
  values.length ? setFocus(values[values.length - 1]) : setFocus(undefined);

export function InputSearchWithTags(props: InputSearchWithTagsProps): JSX.Element {
  const {onChange, autoFocus, inputProps = {}} = props;
  const [searchValues, setSearchValues] = React.useState<string[]>([]);
  const [inputValue, setInputValue] = React.useState<string>('');
  const [highlight, setHighlight] = React.useState<string | null>(null);
  const [focusedTag, setTagFocus] = React.useState<string | null>(undefined);

  // I should pass InputSearch here instead of Input but I am getting an error
  // I have to understand more how antd types works @haderman
  const inputRef = React.useRef<any>();

  const debouncedOnChange = React.useMemo(() => debounce(onChange, 150), []);
  const updateSearch = (v: string[]) => {
    setSearchValues(v);
    debouncedOnChange(v);
  };

  React.useEffect(
    () => {
      if (autoFocus) {
        inputRef.current.focus();
      }
    },
    [autoFocus]
  );

  React.useEffect(
    () => {
      let id: ReturnType<typeof setTimeout>;
      if (highlight !== null) {
        id = setTimeout(() => setHighlight(null), 1000);
      }

      return function clear() {
        clearTimeout(id);
      };
    },
    [highlight]
  );

  const handleOnSearch = () => {
    if (inputValue === '') {
      return;
    }

    if (searchValues.includes(inputValue)) {
      setHighlight(inputValue);
      return;
    }

    updateSearch([...searchValues, inputValue]);
    setInputValue('');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    setInputValue(input.value);
    setTagFocus(undefined);
  };

  const handleOnRemove = (valueToRemove: string) => () => {
    const newSearchValues = searchValues.filter(
      (value) => value !== valueToRemove
    );
    updateSearch(newSearchValues);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    if (input.value) {
      return;
    }

    if (e.key === 'Backspace') {
      e.preventDefault();

      if (focusedTag) {
        updateSearch(searchValues.filter((v) => v !== focusedTag));
      }

      updateFocusTag(setTagFocus, searchValues);
    }
  };

  return <StyledInputContainer>
    <StyledInput
      ref={inputRef}
      value={inputValue}
      onChange={handleChange}
      onSearch={handleOnSearch}
      onKeyDown={handleOnKeyDown}
      spellCheck={false}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      {...inputProps}
    />
    {searchValues.length === 0 ? null :
      <TagContainer>
        {searchValues.map((value) =>
          <Tag
            value={value}
            key={value}
            onRemove={handleOnRemove(value)}
            highlight={highlight === value}
            focused={focusedTag === value}
          />
        )}
      </TagContainer>
    }
  </StyledInputContainer>;
}

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
`;

const StyledInput = styled(BaseInputSearch)`
  width: 100%;

  ${device.desktop} {
    max-width: 400px;
  }
`;
