import React, {useState} from 'react';

import {Space, Spin} from 'antd';

import {
  Button,
  Modal,
  Radio,
  Typography
} from '@components/common';
import SharingService from '@utils/apis/SharingService';
import {trackError} from '@utils/createSentry';

enum ModalState {
  Confirm,
  Loading,
  Success,
  Error,
}

const ShareQueryModal = ({
  isVisible,
  closeModal,
  object,
  organization,
  onShareSuccess
}: any) => {
  const [selectedRadio, setSelectedRadio] = useState(1);
  const [modalState, setModalState] = useState(ModalState.Confirm);
  const [errorMessage, setErrorMessage] = useState('');

  const shareObject = async () => {
    const depth = () => {
      switch (selectedRadio) {
      case 1:
        return 0;
      case 2:
        return 1;
      default:
        return 0;
      }
    };

    try {
      setModalState(ModalState.Loading);
      await SharingService.shareObjectWithActor(
        object,
        organization,
        depth(),
        false
      );
      setModalState(ModalState.Success);
      onShareSuccess();
    } catch (e) {
      setModalState(ModalState.Error);
      setErrorMessage(e.message);
      trackError(e);
    }
  };

  const onSelectRadio = (e: any) => {
    setSelectedRadio(e.target.value);
  };

  const ModalMessage = () => {
    switch (modalState) {
    case ModalState.Confirm:
      return (
        <>
          <Typography variant="primary" as="p">
              You are about to share the rose {object.type} <b>{object.code}</b>{' '}
              with <b>{organization.code}</b>.
          </Typography>
          <Typography variant="primary" as="p">
              Would you like to share how your data was constructed?
          </Typography>
          <Radio.Group onChange={onSelectRadio} value={selectedRadio}>
            <Space direction="vertical">
              <Radio value={1}>Don't share the underlying SQL query</Radio>
              <Radio value={2}>Share the underlying SQL query</Radio>
            </Space>
          </Radio.Group>
        </>
      );
    case ModalState.Loading:
      return (
        <Space direction="vertical" align="center">
          <Spin size="large" />
        </Space>
      );
    case ModalState.Success:
      return (
        <Typography variant="primary" as="p">
          {'Successfully shared dataset '}
          <b>{object.code}</b>
        </Typography>
      );
    case ModalState.Error:
      return <Typography variant="primary" as="p">{errorMessage}</Typography>;
    default:
      return null;
    }
  };

  const modalFooter = () => {
    switch (modalState) {
    case ModalState.Confirm:
      return [
        <Button id="cancel" key="cancel" onClick={closeModal}>
            Cancel
        </Button>,
        <Button id="share" key="share" onClick={shareObject}>
            Share
        </Button>
      ];
    case ModalState.Loading:
      return null;
    case ModalState.Success || ModalState.Error:
      return [
        <Button key="ok" onClick={closeModal}>
            OK
        </Button>
      ];
    default:
      return null;
    }
  };

  const getModalTitle = () => {
    switch (modalState) {
    case ModalState.Confirm:
      return 'Share';
    case ModalState.Loading:
      return 'Loading...';
    case ModalState.Success:
      return 'Success';
    case ModalState.Error:
      return 'Error occured';
    default:
      return '';
    }
  };

  return (
    <Modal
      title={getModalTitle()}
      visible={isVisible}
      footer={modalFooter()}
      onCancel={closeModal}
    >
      <ModalMessage />
    </Modal>
  );
};

export default ShareQueryModal;
