import {RoseModule} from '@types';

type Options = {
  width: '33%' | '50%' | '66%' | '100%'
  textBox: string
  mod: RoseModule
  onModuleChanged: (newMod: RoseModule) => void
}

export const changeWidth = ({
  width,
  mod,
  textBox,
  onModuleChanged
}: Options) => (): void =>
  onModuleChanged(
    RoseModule.update(mod, {
      textBox,
      modulesettings: {width}
    })
  );
