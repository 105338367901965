import * as React from 'react';
import styled from 'styled-components';
import {Spin} from 'antd';

import {Button, Modal} from '@components/common';

const StyledSpin = styled(Spin)`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

enum ModalState {
  Hidden,
  Loading,
  Success,
  Error,
}

const BankAccountModal = ({onClose, state, errorMessage}: any) => {
  const ModalMessage = () => {
    switch (state) {
    case ModalState.Loading:
      return <StyledSpin size="large" />;
    case ModalState.Success:
      return <p>Successfully updated bank account</p>;
    case ModalState.Error:
      return <p>{errorMessage || 'A network error has occurred.'}</p>;
    default:
      return null;
    }
  };

  const modalFooter = () => {
    switch (state) {
    case ModalState.Loading:
      return null;
    case ModalState.Success || ModalState.Error:
      return [
        <Button key="ok" type="ghost" onClick={onClose}>
            OK
        </Button>
      ];
    case ModalState.Error:
      return [
        <Button key="ok" type="ghost" onClick={onClose}>
            OK
        </Button>
      ];
    default:
      return null;
    }
  };

  const getModalTitle = () => {
    switch (state) {
    case ModalState.Loading:
      return 'Loading...';
    case ModalState.Success:
      return 'Success';
    case ModalState.Error:
      return 'Error occured';
    default:
      return '';
    }
  };

  return (
    <Modal
      title={getModalTitle()}
      visible={state !== ModalState.Hidden}
      footer={modalFooter()}
      onCancel={onClose}
    >
      <ModalMessage />
    </Modal>
  );
};

export default BankAccountModal;
