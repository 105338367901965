import React, {useState, useEffect} from 'react';
import {v4 as uuidv4} from 'uuid';
import styled from 'styled-components';
import {BiErrorAlt} from '@react-icons/all-files/bi/BiErrorAlt';
import {useQuery} from '@tanstack/react-query';
import tauist from 'tauist';

import ObjectService from '@utils/apis/ObjectService';
import {Spinner, InputSearchWithTags, Empty} from '@components/common';
import {ThemeVarNames} from '@theme';

import {ResultPagination, ResultList} from './common';

export type NotebooksTabProps = {
  isActive: boolean
}

export function NotebooksTab({isActive}: NotebooksTabProps): JSX.Element {
  const [inputs, setInputs] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchLength, setSearchLength] = useState(0);

  const pageSize = 10;
  const QUERY_KEY = [inputs, pageNumber, pageSize, 'notebook'];
  const {data = {}, status, error, isFetching} = useQuery<any, Error>(
    QUERY_KEY,
    () => ObjectService.search(inputs, pageNumber - 1, pageSize, 'notebook')
      .then(({data}: any) => {
        const result = data.objects.map((e: any) => ({
          ...e,
          key: uuidv4()
        }));
        return {result, length: data.length};
      })
      .catch((err) => {
        throw err;
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      staleTime: tauist.ms.oneMinute
    }
  );

  const {result: roseResult = [], length: roseResultLength = 0} = data;

  useEffect(() => {
    if (pageNumber === 1) {
      setSearchLength(roseResultLength);
    }
  }, [pageNumber, roseResultLength]);

  useEffect(() => {
    setPageNumber(1);
  }, [inputs]);

  const changePageNumber = (newNumber: number) => {
    setPageNumber(newNumber);
  };

  return <Container id="notebook-search-container">
    <InputSearchWithTags
      autoFocus={isActive}
      onChange={(values) => setInputs(values)}
    />
    <ResultContainer>
      {status === 'error' ?
        <Empty
          image={<BiErrorAlt size={48} />}
          description={error?.message}
        /> :
        isFetching ?
          <Spinner size={64} padding="16px" /> :
          roseResult.length === 0 ?
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
            roseResult.length > 0 ?
              <>
                <StickyContainer>
                  <ResultPagination
                    onChangePage={changePageNumber}
                    pageNumber={pageNumber}
                    total={searchLength}
                  />
                </StickyContainer>
                <ResultList loading={isFetching} items={roseResult} />
              </> :
              null}
    </ResultContainer>
  </Container>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(${ThemeVarNames.SecondaryBg});
  border-bottom: 1px solid var(${ThemeVarNames.Border});
  padding-bottom: 6px;
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
