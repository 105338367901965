import * as React from 'react';
import {IoCloseCircle} from '@react-icons/all-files/io5/IoCloseCircle';
import styled from 'styled-components';
import {ThemeVarNames} from '@theme';

export type TagProps = {
  value: string
  onRemove: () => void
  highlight?: boolean
  focused?: boolean
}

export function Tag(props: TagProps): JSX.Element {
  const {value, onRemove, highlight = false, focused = false} = props;

  return (
    <Container highlight={highlight} focused={focused}>
      <Input>{value}</Input>
      <CloseIcon onClick={onRemove} />
    </Container>
  );
}

export const TagContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const Container = styled.div<{ highlight: boolean; focused: boolean }>`
  gap: 4px;
  max-width: 300px;
  padding: 2px 2px;
  display: flex;
  border: 0.5px solid var(${ThemeVarNames.TertiaryText});
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  background-color: ${(p) =>
    p.highlight || p.focused ?
      `var(${ThemeVarNames.InfoLight})` :
      `var(${ThemeVarNames.PrimaryBg})`};
  transition: ${(p) =>
    p.highlight ? 'background-color 200ms ease-in-out' : 'none'};
`;

const Input = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  margin-left: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CloseIcon = styled(IoCloseCircle)`
  height: 24px;
  width: 24px;
  fill: var(${ThemeVarNames.TertiaryText});

  :hover {
    cursor: pointer;
    fill: var(${ThemeVarNames.SecondaryText});
  }
`;
