import * as React from 'react';
import styled from 'styled-components';

import {device} from '@utils/breakpoints';
import {Typography} from '@components/common';

export type ColumnHeadersProps = {
  titles: string[]
}

export function ColumnHeaders({titles}: ColumnHeadersProps): JSX.Element {
  return (
    <StyledColumnHeaders>
      {titles.map((title) =>
        <Typography variant="primary" as="h3" key={title}>
          {title}
        </Typography>
      )}
    </StyledColumnHeaders>
  );
}

export const StyledColumnHeaders = styled.div`
  display: none;

  ${device.tablet} {
    display: grid;
    grid-template-columns: var(--grid-template-columns);
    gap: var(--gap);
  }
`;
