import {Drawer as AntdDrawer} from 'antd';
import styled from 'styled-components';

import {ThemeVarNames} from '@theme';

export const Drawer = styled(AntdDrawer)`
  .ant-drawer-header,
  .ant-drawer-header-no-title {
    background-color: var(${ThemeVarNames.PrimaryBg});
    border-bottom-color: var(${ThemeVarNames.Border});
    border-radius: unset;
  }

  .ant-drawer-title {
    color: var(${ThemeVarNames.SecondaryText});
  }

  .ant-drawer-close {
    color: var(${ThemeVarNames.PrimaryText});
  }

  .ant-drawer-close:hover {
    color: var(${ThemeVarNames.TertiaryText});
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    padding-bottom: 0;
    background-color: var(${ThemeVarNames.SecondaryBg});
  }

  .ant-tabs {
    width: 100%;
  }

  .ant-tabs-content-holder {
    overflow: auto;
  }
`;
