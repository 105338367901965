import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import UserContainer from '@utils/state/userContainer';
import UserService from '@utils/apis/UserService';
import {RoseUserSettings} from '@types';
import {Theme} from '@theme';

export type UseUserSettingsResult = {
  changeSettings: (settings: RoseUserSettings) => void
  settings: RoseUserSettings
}

export function useUserSettings(): UseUserSettingsResult {
  const {user, setUser} = UserContainer.useContainer();

  const mutation = useMutation<void, AxiosError, RoseUserSettings>(
    (settings) => UserService.changeSettings(settings),
    {
      onMutate: async (newSettings) => {
        setUser({
          ...user,
          settings: newSettings
        });
      }
    }
  );

  return {
    changeSettings: (settings: RoseUserSettings) => {
      mutation.mutate(settings);
    },
    settings: user?.settings ?? {theme: Theme.getSystemTheme()}
  };
}
