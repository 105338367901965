import * as React from 'react';
import styled from 'styled-components';
import {device} from '@utils/breakpoints';
import HelmetWrapper from '@utils/HelmetWrapper';
import {DashboardContainer} from '@components/common/Containers';
import {Header} from '../Header/Header';
import {NewContactForm} from './NewContactForm';

type NewContactProps = {

  // props
}

export const NewContact: React.FC<NewContactProps> = () =>
  <>
    <DashboardContainer>
      <HelmetWrapper
        title="Contact Form"
        description="New Contact Form"
        pathname="/client-create-form"
      />
      <Header />
      <StyledContainer>
        <ImageContainer
          src="/img/unsplash_baII27W6z7k.png"
          alt="Contact Form Background"
        />
        <Container>
          <NewContactForm />
        </Container>
      </StyledContainer>
    </DashboardContainer>
  </>
  ;

const StyledContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;

  ${device.desktop} {
    flex-direction: row;
    overflow: hidden;
  }
`;
const ImageContainer = styled.img`
  height: 100px;

  ${device.desktop} {
    height: 100vh;
    width: 50%;
    flex: 1;
  }
`;
const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  ${device.desktop} {
    overflow: auto;
  }
`;
