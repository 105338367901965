import {HttpClient} from './HttpClient';

/**
 * API client for the `sharing` services
 * @singleton
 * @extends HttpClient
 */
class SharingAPI extends HttpClient {
  private static _instance: SharingAPI;

  private constructor() {
    super('/sharing');
  }

  public static get instance(): SharingAPI {
    if (!SharingAPI._instance) {
      SharingAPI._instance = new SharingAPI();
    }

    return SharingAPI._instance;
  }

  public getActors = (
    keyword: string,
    pageNumber: number,
    pageSize: number,
    signal = new AbortController().signal
  ) =>
    this.instance.get(
      `/actors?keywords=${keyword}&page_number=${pageNumber}&page_size=${pageSize}`,
      {signal}
    )

  public searchDatasetsSharedWithActor = (
    actorId: number,
    keywords: Array<string>,
    pageNumber: number,
    pageSize: number,
    signal = new AbortController().signal
  ) =>
    this.instance.get(
      `/actors/${actorId}?keywords=${JSON.stringify(
        keywords
      )}&page_number=${pageNumber}&page_size=${pageSize}`,
      {signal}
    )

  public searchDatasetsNotSharedWithActor = (
    actorId: number,
    keywords: Array<string>,
    pageNumber: number,
    pageSize: number,
    signal = new AbortController().signal
  ) =>
    this.instance.get(
      `/actors/${actorId}/shareable?keywords=${JSON.stringify(
        keywords
      )}&page_number=${pageNumber}&page_size=${pageSize}`,
      {signal}
    )

  public getOwnedNotebooks = (
    keywords: Array<string>,
    pageNumber: number,
    pageSize: number,
    signal = new AbortController().signal
  ) =>
    this.instance.get(
      `/actors/owned?keywords=${keywords.join('')}&page_number=${pageNumber}&page_size=${pageSize}`,
      {signal}
    );

  public shareCompanyWithActor = (
    actor: any,
    signal = new AbortController().signal
  ) => this.instance.post(
    `/actors/${actor.id}/all`,
    {signal}
  )

  public shareKeywordsWithActor = (
    keywords: any,
    actor: any,
    depth: any,
    signal = new AbortController().signal
  ) => this.instance.post(
    `/actors/${actor.id}`,
    {keywords, depth, signal}
  )

  public shareObjectWithActor = (
    object: any,
    actor: any,
    depth: any,
    underlying: any,
    signal = new AbortController().signal
  ) => this.instance.post(
    `/objects/${object.id}/actors/${actor.id}`,
    {depth, underlying, signal}
  )

  public revokeCompanyFromActor = (
    actor: any,
    signal = new AbortController().signal
  ) =>
    this.instance.delete(`/actors/${actor.id}/all`,
      {signal}
    )

  public revokeKeywordsFromActor = (
    keywords: any,
    actor: any,
    signal = new AbortController().signal
  ) => this.instance.delete(
    `/actors/${actor.id}?keywords=${JSON.stringify(
      keywords
    )}`, {signal}
  )

  public revokeObjectFromActor = (
    object: any,
    actor: any,
    signal = new AbortController().signal
  ) => this.instance.delete(
    `/objects/${object.id}/actors/${actor.id}`,
    {signal}
  )

  public getCompanyShareFromActor = (
    actor: any,
    signal = new AbortController().signal
  ) => this.instance.get(`/actors/${actor.id}/all`, {signal})
}

const SharingService = SharingAPI.instance;

export default SharingService;
