import React, {useRef, useEffect} from 'react';

import {RoseModule} from '@types';
import Tree from './Tree.jsx';

export type TreeProps = {
  autoFocus?: boolean
  treeData: any
  addModule: (mod: Partial<RoseModule>) => void
}

export default function WrappedTree(props: TreeProps): JSX.Element {
  const {treeData, autoFocus = false, addModule} = props;
  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    if (autoFocus) {
      ref.current.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, []);

  return (
    <div data-area="tree" ref={ref}>
      <Tree treeData={treeData} addModule={addModule} />
    </div>
  );
}
