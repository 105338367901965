import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import lodash from 'lodash';

import {Alert, Button, Modal} from '@components/common';
import {RoseTimeseriesGroup} from '@types';
import {useCodePreview} from '@service/useCodePreview';
import {Spinner} from '@components/common/Loading';
import {ResultComponent} from '@components/dashboard/Notebook/Result';

export type PreviewModalProps = {
  code: string
  title: string
}

export function PreviewModal({code, title}: PreviewModalProps): JSX.Element {
  const [visible, setVisible] = useState(false);
  const {
    data: roseObject,
    status,
    error,
    prefetch
  } = useCodePreview(code, {
    enabled: visible
  });

  const errorButtons = [
    <Button key="back" onClick={() => setVisible(false)}>
      Close
    </Button>
  ];
  const previewButtons = [
    <Button key="back" onClick={() => setVisible(false)}>
      Close
    </Button>,
    <Button key="submit" type="primary">
      <Link to={`/dashboard?datasets=${title}:preview`} target="_blank">
        See Detailed Preview
      </Link>
    </Button>
  ];

  const renderModalContent = () => {
    switch (status) {
    case 'error':
      return <Alert type="error" message={error.message} />;
    case 'loading':
      return <Spinner size={48} padding="40px" />;
    case 'success':
      return <ResultComponent
        showWarnings={false}
        result={
          roseObject.type === 'timeseries' ?
            RoseTimeseriesGroup.create([roseObject]) :
            roseObject
        }
        addModule={lodash.noop}
        view={'view'}
      />;
    default:
      return null;
    }
  };

  return (
    <>
      <Button onClick={() => setVisible(true)} onMouseEnter={prefetch}>
        Preview
      </Button>
      <Modal
        centered
        closable={false}
        maskClosable
        keyboard
        destroyOnClose
        title={`Preview ${title}`}
        style={{top: 20}}
        visible={visible}
        width={1000}
        footer={!error ? previewButtons : errorButtons}
      >
        {renderModalContent()}
      </Modal>
    </>
  );
}
