import {Alert as AntdAlert} from 'antd';
import styled from 'styled-components';

import {ThemeVarNames} from '@theme';

export const Alert = styled(AntdAlert)`
  .ant-alert-message {
    color: var(${ThemeVarNames.SecondaryText});
  }
  .ant-alert-description {
    color: var(${ThemeVarNames.SecondaryText});
  }

  &.ant-alert-warning {
    background-color: var(${ThemeVarNames.WarningLight});
    border-color: var(${ThemeVarNames.Warning});

    .anticon {
      color: var(${ThemeVarNames.WarningDark});
    }
  }

  &.ant-alert-error {
    background-color: var(${ThemeVarNames.ErrorDark});
    border-color: var(${ThemeVarNames.Error});
    color: var(${ThemeVarNames.PrimaryText});

    .anticon {
      color: var(${ThemeVarNames.ErrorDark});
    }
  }

  &.ant-alert-info {
    background-color: var(${ThemeVarNames.InfoLight});
    border-color: var(${ThemeVarNames.Info});

    .anticon {
      color: var(${ThemeVarNames.InfoDark});
    }
  }

  &.ant-alert-success {
    background-color: var(${ThemeVarNames.SuccessLight});
    border-color: var(${ThemeVarNames.Success});

    .anticon {
      color: var(${ThemeVarNames.SuccessDark});
    }
  }
`;
