/* eslint-disable max-statements */
/* eslint-disable */
import Highcharts from "highcharts/highstock";
import "d3plus-text";
import "d3";
import { ChartSettings, DatasetsSettings, RoseChart, RoseModuleCodeSettings } from "@types";
import { ChartEditorEvent, EE } from "../events";
import {Theme, ThemeVarNames } from '@theme';
import { whiteLogoBase64, blackLogoBase64 } from '../../../theme/watermark';
import { darkPoweredByRose, lightPoweredByRose } from '../../../poweredByRose';
import _ from "lodash";

export type NonTimeseriesChartOptionsProps = {
  roseObject: RoseChart;
  theme: Theme;
  chartSettings: ChartSettings;
  getChartSettings: () => ChartSettings;
  onChartSettingsChanged?: (value: ChartSettings) => void;
  modSettings: RoseModuleCodeSettings;
  isViewMode: boolean
};

export function composeChartOptions(nonTimeseriesChartOptions: NonTimeseriesChartOptionsProps) {
  const {
    roseObject,
    theme,
    chartSettings, 
    getChartSettings, 
    modSettings,
    isViewMode
  } = nonTimeseriesChartOptions;
  const themeValuesMap = Theme.themesMap[theme];
  const watermark = theme === 'light' ? blackLogoBase64 : whiteLogoBase64
  const poweredByRose = theme === 'light' ? lightPoweredByRose : darkPoweredByRose
  const colorPalette: string[] = []
  Object.values(getChartSettings().datasets).map((value: DatasetsSettings) => {
    colorPalette.push(value.color);
  });

  let widthFactor = 1;
  switch (modSettings?.width) {
  case '66%':
    widthFactor = 0.66;
    break;
  case '50%':
    widthFactor = 0.5;
    break;
  case '33%':
    widthFactor = 0.33;
  }

  const titleLength: number = getChartSettings().title?.text?.length;
  let titleFontSize = 28;
  let legendFontSize = 18;
  if (titleLength > 45 * widthFactor) {
    titleFontSize = 18;
    legendFontSize = 14;
  } else if (titleLength > 30 * widthFactor) {
    titleFontSize = 23;
    legendFontSize = 16;
  }

  // init with a `this` getter to access properties in object during definition
  let chartData: any = {
    getThis: function() {
      return this;
    }
  };

  chartData = {
    ...chartData,
    colors: colorPalette,
    credits: {
      enabled: true,
      text: getChartSettings().source,
      // by default the href is set to highcharts.com, we dont want users to be able to click
      href: null,
      position: getMetasPosition(widthFactor).credits,
      style: {
        cursor: 'initial',
        fontSize: '11px'
      }
    },
    title: {
      ...getChartSettings().title,
      x: 0,
      y: 45,
      style: {
        color: themeValuesMap[ThemeVarNames.PrimaryText],
        fontSize: titleFontSize,
        fontWeight: 500
      }
    },
    subtitle: {
      text: null,
      style: {
        color: themeValuesMap[ThemeVarNames.SecondaryText],
        fontSize: 20
      }
    },
    xAxis: {
      title: {
        enabled: true,
        text: null,
      },
      labels: {
        style: {
          textOverflow: widthFactor <= .66 ? 'ellipsis' : null,
          whiteSpace: widthFactor <= .66 ? 'nowrap' : null,
          width: widthFactor <= .66 ? 50 : null,
        }
      }
    },
    yAxis: {
      title: {
        text: null,
      },
      gridLineWidth: 0,
      minorGridLineWidth: 0,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 585,
          },
          chartOptions: {
            legend: {
              y: 50,
            },
          },
        },
      ],
    },
    chart: {
      type: "column",
      backgroundColor: themeValuesMap[ThemeVarNames.PrimaryBg],
      borderRadius: 5,
      animation: false,
      spacingBottom: widthFactor < .66 ? 48 : 33, // poweredBy.y + spacing between poweredby and xaxis (5 or 20)
      events: {
        render() {
          const logoSizeMult = 5;
          const logoSizeX = 30 * logoSizeMult;
          const logoSizeY = 30 * logoSizeMult;
          const logoPosX = this.chartWidth/2 - logoSizeX/2;
          const logoPosY = this.plotTop + this.plotHeight/2 - logoSizeY/2;
          const {poweredBy, credits} = getMetasPosition(widthFactor, this.plotTop, this.plotHeight, this.axisOffset[2])
          var creditsMain = this.credits;
          creditsMain.attr({
            y: credits.y
          });
          const poweredByRoseSize = {
            x: 170,
            y: 28
          }
          if (!_.isUndefined(this.watermark)){
            this.watermark.destroy();
          };
          if (!_.isUndefined(this.poweredByRose)) {
            this.poweredByRose.destroy();
          }
          this.watermark = this.renderer.image(watermark, logoPosX, logoPosY, logoSizeX, logoSizeY)
            .attr({ opacity : 0.1 })
            .add();
          this.poweredByRose = this.renderer.image(
            poweredByRose,
            poweredBy.x,
            poweredBy.y,
            poweredByRoseSize.x,
            poweredByRoseSize.y
          )
            .add();
        },
      }
    },
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'top',
      floating: false,
      adjustChartSize: false,
      maxHeight: 90,
      y: 0,
      margin: 0,
      padding: 10,
      itemStyle: {
        fontSize: legendFontSize,
        color: themeValuesMap[ThemeVarNames.SecondaryText],
        fontWeight: 'normal'
      },
      itemHoverStyle: {
        color: themeValuesMap[ThemeVarNames.HoverText]
      },
      navigation: {
        activeColor: themeValuesMap[ThemeVarNames.Action],
        inactiveColor: themeValuesMap[ThemeVarNames.Disabled],
        style: {
          color: themeValuesMap[ThemeVarNames.PrimaryText],
          fontSize: 20
        }
      }
    },
    plotOptions: {
      column: {
        borderWidth: 0
      },
      bar: {
        borderWidth: 0
      },
      scatter: {
        animation: false,
        tooltip: {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormat: "{point.x}, {point.y}",
        },
      },
      packedbubble: {
        layoutAlgorithm: {
          splitSeries: false,
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}",
          style: {
            color: "black",
            textOutline: "none",
            fontWeight: "normal",
          },
        },
        minSize: "30%",
        maxSize: "300%",
      },
    },
    exporting: {
      menuItemDefinitions: {
        // Custom definition
        chartEditor: {
          onclick: function () {
            EE.emit(ChartEditorEvent + roseObject.key, {
              isOpen: true,
              chartData,
            });
          },
          text: "Chart Editor",
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            "chartEditor",
            "separator",
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
          ],
        },
      },
      enabled: !isViewMode,
    },
    series: []
  };
  const chartOptions = { ...chartData };

  chartOptions.series = [];
  const seriesAdded: any = {};
  const categories = [];
  const { columns } = roseObject.values;
  chartOptions.chart.type = roseObject.metas.chart_type;

  if(getChartSettings().source?.length > 0) {
    chartOptions.credits.text = `source: ${getChartSettings().source}`
  } else {
    chartOptions.credits.text = ""
  }

  if (["pie", "scatter", "area"].indexOf(chartOptions.chart.type) !== -1) {
    if (chartOptions.chart.type === "scatter") {
      chartOptions.legend.enabled = false;
    }

    for (let i = 0; i < roseObject.values.data.length; i++) {
      const row = roseObject.values.data[i];
      const category = row[0];
      if (categories.indexOf(category) === -1) {
        categories.push(category);
      }
      let seriesName;
      if (columns.length > 2) {
        seriesName = row[2];
      } else {
        seriesName = columns[1];
      }

      if (Object.keys(seriesAdded).indexOf(seriesName) === -1) {
        seriesAdded[seriesName] = chartOptions.series.length;
        chartOptions.series.push({
          name: seriesName,
          data: [],
          color: "#53ADF4",
        });
      }

      chartOptions.series[seriesAdded[seriesName]].data.push([row[0], row[1]]);
    }

    let areNumeric = categories.every(val => !isNaN(val));
    if (!areNumeric) {
      chartOptions.xAxis.categories = categories;
    }
  }
  
  if (["heatmap"].indexOf(chartOptions.chart.type) !== -1) {
    chartOptions.xAxis.categories = [];
    chartOptions.yAxis.categories = [];
    chartOptions.colorAxis = {
      stops: [
        [0, Highcharts.getOptions().colors[2]],
        [0.5, "#ecf0f1"],
        [1, Highcharts.getOptions().colors[1]],
      ],
    };
    chartOptions.series.push({
      name: roseObject.values.columns[2],
      data: [],
    });
    chartOptions.legend.align = "right";
    chartOptions.legend.verticalAlign = "top";
    chartOptions.legend.layout = "vertical";
    const xAxisCategoryMapper: any = {};
    const yAxisCategoryMapper: any = {};
    let xAxisIndex = 0;
    let yAxisIndex = 0;
    for (let i = 0; i < roseObject.values.data.length; i++) {
      const row = roseObject.values.data[i];
      if (Object.keys(xAxisCategoryMapper).indexOf(row[0]) === -1) {
        xAxisCategoryMapper[row[0]] = xAxisIndex;
        xAxisIndex += 1;
        chartOptions.xAxis.categories.push(row[0]);
      }

      if (Object.keys(yAxisCategoryMapper).indexOf(row[1]) === -1) {
        yAxisCategoryMapper[row[1]] = yAxisIndex;
        yAxisIndex += 1;
        chartOptions.yAxis.categories.push(row[1]);
      }

      chartOptions.series[0].data.push([
        xAxisCategoryMapper[row[0]],
        yAxisCategoryMapper[row[1]],
        row[2],
      ]);
    }
  }

  if (
    ["packedbubble", "bar", "column","stacked-bar",'stacked-column'].indexOf(chartOptions.chart.type) !== -1
  ) {
    if(
      chartOptions.chart.type === "stacked-bar" || chartOptions.chart.type === "stacked-column"
    ) {
      chartOptions.chart.type = chartOptions.chart.type.split('-')[1]
      chartOptions.plotOptions.series = {}
      chartOptions.plotOptions.series.stacking = "normal"
      chartOptions.plotOptions.series.dataLabels = {}
      chartOptions.plotOptions.series.dataLabels.enabled = true
      chartOptions.legend.reversed = true
    }
    let lowest;
    let highest;
    for (let j = 1; j < columns.length; j++) {
      const seriesName = columns[j];
      seriesAdded[seriesName] = chartOptions.series.length;
      chartOptions.series.push({
        name: getChartSettings().datasets[seriesName].name,
        data: [],
        color: getChartSettings().datasets[seriesName].color,
      });
    }

    for (let i = 0; i < roseObject.values.data.length; i++) {
      const row = roseObject.values.data[i];
      const category = row[0];
      if (categories.indexOf(category) === -1) {
        categories.push(category);
      }

      for (let j = 1; j < columns.length; j++) {
        if (["packedbubble"].indexOf(chartOptions.chart.type) !== -1) {
          chartOptions.series[seriesAdded[columns[j]]].data.push({
            name: row[0],
            value: row[j],
          });
        } else {
          chartOptions.series[seriesAdded[columns[j]]].data.push(row[j]);
        }
      }

      chartOptions.plotOptions.packedbubble.zMin = lowest;
      chartOptions.plotOptions.packedbubble.zMax = highest;
    }

    chartOptions.xAxis.categories = categories;
  }

  return chartOptions;
}

function getMetasPosition(width: number, plotTop?: number, plotHeight?: number, axisOffset?: number, height?: number) {
  if (width < 0.66) {
    return {
      credits: {
        align: 'left',
        verticalAlign: 'bottom',
        x: 20,
        y: (plotTop + plotHeight + axisOffset) + 15
      },
      poweredBy: {
        x: 20,
        y: (plotTop + plotHeight + axisOffset) + 20
      }
    }
  }
  else {
    return {
      credits: {
        align: 'right',
        verticalAlign: 'bottom',
        x:-10,
        y: (plotTop + plotHeight + axisOffset) + 20
      },
      poweredBy: {
        x: 20,
        y: plotTop + plotHeight + axisOffset + 5
      }
    }
  }
}