import React, {useState, useEffect} from 'react';
import {Space, Spin} from 'antd';

import {Button, Modal, Radio, Typography} from '@components/common';
import SharingService from '@utils/apis/SharingService';
import {trackError} from '@utils/createSentry';

enum ModalState {
  Confirm,
  Loading,
  Success,
  Error,
}

const ShareCompanyModal = ({
  isVisible,
  closeModal,
  organization,
  onShare
}: any) => {
  const [selectedRadio, setSelectedRadio] = useState(1);
  const [modalState, setModalState] = useState(ModalState.Confirm);
  const [errorMessage, setErrorMessage] = useState('');

  const shareCompanyAccess = async () => {
    try {
      setModalState(ModalState.Loading);
      await SharingService.shareCompanyWithActor(organization);
      setModalState(ModalState.Success);
      onShare();
    } catch (e) {
      setModalState(ModalState.Error);
      setErrorMessage(e.message);
      trackError(e);
    }
  };

  const onSelectRadio = (e: any) => {
    setSelectedRadio(e.target.value);
  };

  const ModalMessage = () => {
    switch (modalState) {
    case ModalState.Confirm:
      return (
        <>
          <Typography variant="primary" as="p">
              You are about to share access to <b>your entire company</b> with{' '}
            <b>{organization.code}</b>.
          </Typography>
          <Typography variant="primary" as="p">
              Would you like to share how your data was constructed?
          </Typography>
          <Radio.Group onChange={onSelectRadio} value={selectedRadio}>
            <Space direction="vertical">
              <Radio value={1}>Share no logic</Radio>
              <Radio value={2}>Share all logic</Radio>
            </Space>
          </Radio.Group>
        </>
      );
    case ModalState.Loading:
      return (
        <Space direction="vertical" align="center">
          <Spin size="large" />
        </Space>
      );
    case ModalState.Success:
      return (
        <Typography variant="primary" as="p">
          {'Successfully shared your company with '}
          <b>{organization.code}</b>.
        </Typography>
      );
    case ModalState.Error:
      return <Typography variant="primary" as="p">{errorMessage}</Typography>;
    default:
      return null;
    }
  };

  const modalFooter = () => {
    switch (modalState) {
    case ModalState.Confirm:
      return [
        <Button id="cancel" key="cancel" onClick={closeModal}>
            Cancel
        </Button>,
        <Button id="share" key="share" onClick={shareCompanyAccess}>
            Share
        </Button>
      ];
    case ModalState.Loading:
      return null;
    case ModalState.Success || ModalState.Error:
      return [
        <Button key="ok" onClick={closeModal}>
            OK
        </Button>
      ];
    default:
      return null;
    }
  };

  const getModalTitle = () => {
    switch (modalState) {
    case ModalState.Confirm:
      return 'Share';
    case ModalState.Loading:
      return 'Loading...';
    case ModalState.Success:
      return 'Success';
    case ModalState.Error:
      return 'Error occured';
    default:
      return '';
    }
  };

  useEffect(() => {
    setModalState(ModalState.Confirm);
  }, []);

  return (
    <Modal
      title={getModalTitle()}
      visible={isVisible}
      footer={modalFooter()}
      onCancel={closeModal}
    >
      <ModalMessage />
    </Modal>
  );
};

export default ShareCompanyModal;
