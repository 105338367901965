import * as React from 'react';
import styled from 'styled-components';
import {message} from 'antd';

import {Button, Popconfirm} from '@components/common';
import {SellingPackage, useSellingRemove} from '@service/useSelling';
import {ThemeVarNames} from '@theme';
import {Interval} from '@types';
import {device} from '@utils/breakpoints';

export type ItemProps = {
  pck: SellingPackage
}

export function Item({pck}: ItemProps): JSX.Element {
  return (
    <StyledItem>
      <StyledCode>{pck.code}</StyledCode>
      <StyledPrice>{pck.price}</StyledPrice>
      <StyledInterval>{Interval.toFriendlyText(pck.interval)}</StyledInterval>
      <ButtonRemove pck={pck} />
    </StyledItem>
  );
}

function ButtonRemove({pck}: { pck: SellingPackage }): JSX.Element {
  const mutation = useSellingRemove();
  const handleOnConfirm = () => {
    mutation.mutate(pck, {
      onSuccess() {
        message.success(`${pck.code} was removed`);
      },
      onError(err) {
        const responseData: any = err.response?.data;
        console.error('error removing package: ', err);
        message.error(responseData?.message);
      }
    });
  };

  return (
    <Popconfirm
      title="Are you sure"
      okText="Yes"
      cancelText="No"
      onConfirm={handleOnConfirm}
      okButtonProps={{
        danger: true
      }}
    >
      <StyledButton
        aria-label="Remove"
        type="default"
        danger
        loading={mutation.isLoading}
      >
        Remove
      </StyledButton>
    </Popconfirm>
  );
}

export const StyledItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 8px;
  column-gap: 4px;
  grid-template-areas:
    'Code Code Code'
    'Price Interval .'
    '. . Remove';
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(${ThemeVarNames.Border});
  background-color: var(${ThemeVarNames.SecondaryBg});

  ${device.tablet} {
    display: grid;
    grid-template-columns: var(--grid-template-columns);
    grid-template-areas: 'Code Price Interval Remove';
    align-items: center;
    gap: var(--gap);
    padding: 0;
    background-color: unset;
    border-radius: 0;
    border: none;

    &:hover,
    &:focus-within {
      background-color: var(${ThemeVarNames.SecondaryBg});
    }

    & > span {
      padding: 11px 11px;
      font-size: 16px;
    }

    border-bottom: 1px solid var(${ThemeVarNames.Border});
  }
`;

const StyledCode = styled.span`
  grid-area: Code;
  font-size: 1.125rem;
  font-weight: 600;

  ${device.tablet} {
    font-size: 1rem;
    font-weight: unset;
  }
`;

const StyledPrice = styled.span`
  grid-area: Price;
`;

const StyledInterval = styled.span`
  grid-area: Interval;
  align-self: center;
  justify-self: center;

  ${device.tablet} {
    align-self: unset;
    justify-self: unset;
  }
`;

const StyledButton = styled(Button)`
  grid-area: Remove;
`;
