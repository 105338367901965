import {RoseModule, Percentage} from '@types';

// given the rest of the modules, calculate the width of the module to be added
const calculateModuleWidth = (list: RoseModule[], idx: number): Percentage => {
  if (!list.length) {
    return '100%';
  }

  let newModuleWidth: Percentage;

  // get the width of the module before the module being added
  const moduleWidth: number = Percentage.toNumber(
    RoseModule.getWidth(list[idx])
  );

  // default to fill the row
  newModuleWidth = Percentage.toPercentage(100 - moduleWidth);

  // get the total width of all rows
  let totalWidth = 0;
  for (let i = 0; i <= idx; i += 1) {
    totalWidth += Percentage.toNumber(RoseModule.getWidth(list[i]));
  }

  // if all rows are filled, default to 100%
  if (Math.round(totalWidth) % 100 === 0) {
    newModuleWidth = '100%';
  }

  // if there is only space for a 33%
  if (Math.round(totalWidth) % 100 === 67) {
    newModuleWidth = '33%';
  }

  return newModuleWidth;
};

export {calculateModuleWidth};
