import {Theme} from '@theme';
import {RoseActor} from './RoseActor';

export type RoseUser = {
  type: 'user'
  id: number
  actorId: number | null
  code: string
  licenseId: number | null
  isAdmin: boolean
  email: string
  firstName: string
  lastName: string
  actor?: RoseActor
  settings: RoseUserSettings
  askrose?: boolean,
}

export const RoseUser = {
  deserialize(data: Record<string, any>): RoseUser {
    const user: RoseUser = {
      type: 'user',
      id: data.id,
      actorId: data.actor_id,
      code: data.code,
      email: data.email,
      firstName: data.first_name,
      isAdmin: data.is_admin,
      lastName: data.last_name || '',
      licenseId: data.license_id,
      settings: RoseUserSettings.deserialize(data.settings),
      askrose: data.askrose
    };

    if (data.actor) {
      user.actor = RoseActor.deserialize(data.actor);
    }

    return user;
  },
  serialize(roseUser: RoseUser): Record<string, any> {
    const data: Record<string, any> = {
      type: roseUser.type,
      id: roseUser.id,
      code: roseUser.code,
      actor_id: roseUser.actorId,
      license_id: roseUser.licenseId,
      is_admin: roseUser.isAdmin,
      email: roseUser.email,
      first_name: roseUser.firstName,
      last_name: roseUser.lastName,
      askrose: roseUser.askrose
    };

    if (roseUser.actor) {
      data.actor = RoseActor.serialize(roseUser.actor);
    }

    return data;
  },
  isRoseUser(data: Record<string, any>): boolean {
    return data?.type === 'user';
  },
  getRole(roseUser: RoseUser): 'Admin' | 'Employee' {
    return roseUser?.isAdmin ? 'Admin' : 'Employee';
  },
  composeFullName(roseUser: RoseUser): string {
    return `${roseUser?.firstName} ${roseUser?.lastName}`;
  },
  isGuest(user: RoseUser): boolean {
    return !user?.id;
  },
  /**
   * is a Rose or Snow employee
   */
  isInternalEmployee(user: RoseUser): boolean {
    // TODO: Avoid magic numbers here. Pull these out to environment variables or at least well-labeled constants.
    return user.actorId === 5312138 || user.actorId === 23;
  }
};

/**
 * User Settings
 */
export type RoseUserSettings = {
  theme: Theme
}

export const RoseUserSettings = {
  deserialize(data: Record<string, any>): RoseUserSettings {
    return {
      theme: data?.theme ?? Theme.getSystemTheme()
    };
  },
  getTheme(settings?: RoseUserSettings): Theme {
    return Theme.getAllThemes().includes(settings?.theme) ?
      settings?.theme :
      Theme.getSystemTheme();
  },
  mergeSettings: (
    settings: RoseUserSettings,
    newSettings: Partial<RoseUserSettings>
  ): RoseUserSettings => ({
    ...settings,
    ...newSettings
  })
};
