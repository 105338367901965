import React, {forwardRef} from 'react';

import UserContainer from '@utils/state/userContainer';
import {Button, ButtonProps} from './Button';

/**
 * This Button is going to be shown disabled if the user is not authenticated
 */
export const PrivateButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {disabled, ...restProps} = props;
    const {user} = UserContainer.useContainer();
    const isDisabled = !user || disabled;

    return <Button {...restProps} disabled={isDisabled} />;
  }
);
