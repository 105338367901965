import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Layout, Spin} from 'antd';
import SharingService from '@utils/apis/SharingService';
import {Typography, Button} from '@components/common';

import ShareCompanyModal from '../Modals/ShareCompanyModal';
import RevokeCompanyModal from '../Modals/RevokeCompanyModal';

const {Content} = Layout;

type Props = {
  organization: any
}

enum SharingStatus {
  Loading,
  Shared,
  Revoked,
}

export default ({organization}: Props): JSX.Element => {
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [isRevokeModalVisible, setIsRevokeModalVisible] = useState(false);
  const [sharingStatus, setSharingStatus] = useState(SharingStatus.Loading);

  useEffect(() => {
    const getCompanySharedStatus = async () => {
      try {
        setSharingStatus(SharingStatus.Loading);
        await SharingService.getCompanyShareFromActor(organization);
        setSharingStatus(SharingStatus.Shared);
      } catch (e) {
        setSharingStatus(SharingStatus.Revoked);
      }
    };

    getCompanySharedStatus();
  }, []);

  const shareMessage =
    <>
      <StyledMessageCointainer>
        <Typography variant="primary" as="h2">{`Share company access with ${organization.code}`}</Typography>
        <Typography variant="primary" as="p">
          {'You are about to share your entire company with '}
          <strong>{organization.code}</strong>
          <Typography variant="primary">.</Typography>
        </Typography>
        <Typography variant="primary" as="p">
          {'Are you sure you want to give '}
          <strong>{organization.code}</strong>
          {' access to your company?'}
        </Typography>
      </StyledMessageCointainer>
      <Button onClick={() => setIsShareModalVisible(true)}>
        Share Company
      </Button>
    </>
  ;

  const revokeMessage =
    <>
      <StyledMessageCointainer>
        <Typography as="h2">{`Revoke company access from ${organization.code}`}</Typography>
        <Typography as="p">
          {'You have shared your entire company with '}
          <strong>{organization.code}</strong>
          <Typography>.</Typography>
        </Typography>
        <Typography as="p">Are you sure you want to revoke that access now?</Typography>
      </StyledMessageCointainer>
      <Button onClick={() => setIsRevokeModalVisible(true)}>
        Revoke Company
      </Button>
    </>
  ;

  const shareCompanyMessage = () => {
    switch (sharingStatus) {
    case SharingStatus.Loading:
      return <Spin size="large" />;
    case SharingStatus.Shared:
      return revokeMessage;
    case SharingStatus.Revoked:
      return shareMessage;
    default:
      return null;
    }
  };

  return (
    <StyledContent>
      {shareCompanyMessage()}
      {isShareModalVisible ?
        <ShareCompanyModal
          isVisible={isShareModalVisible}
          closeModal={() => setIsShareModalVisible(false)}
          organization={organization}
          onShare={() => setSharingStatus(SharingStatus.Shared)}
        /> :
        null}
      {isRevokeModalVisible ?
        <RevokeCompanyModal
          isVisible={isRevokeModalVisible}
          closeModal={() => setIsRevokeModalVisible(false)}
          organization={organization}
          onRevoke={() => setSharingStatus(SharingStatus.Revoked)}
        /> :
        null}
    </StyledContent>
  );
};

const StyledContent = styled(Content)`
  background-color: inherit;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 16px 8px;
`;

const StyledMessageCointainer = styled.div``;
