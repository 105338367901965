import {Collapse as AntdCollapse, CollapseProps as AntdCollapseProps} from 'antd';
import styled from 'styled-components';

export type CollapseProps = AntdCollapseProps;

export const Collapse = AntdCollapse;

Collapse.Panel = styled(AntdCollapse.Panel)`
  * {
    background-color: var(--rose-secondary-bg);
    color: var(--rose-secondary-text) !important;
  }
`;
