import * as React from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {Empty, Button} from 'antd';
import {ThemeVarNames} from '@theme';

export type EmptyStateProps = {
  message: string
}
export function EmptyState(props: EmptyStateProps): JSX.Element {
  const {message} = props;
  const navigate = useNavigate();
  const handleOnRedirect = () => {
    navigate('/dashboard');
  };

  return (
    <StyledSection>
      <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description={message}>
        <Button type="primary" onClick={handleOnRedirect} size="large">
          Create new Notebook
        </Button>
      </StyledEmpty>
    </StyledSection>
  );
}

export function EmptyStateNotLoggedIn(props: EmptyStateProps): JSX.Element {
  const {message} = props;
  const navigate = useNavigate();
  const handleOnRedirect = () => {
    localStorage.setItem('redirect', window.location.pathname);
    navigate('/login');
  };

  return (
    <StyledSection>
      <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description={message}>
        <p>If you have access rights, please log in and try again.</p>
        <Button type="primary" onClick={handleOnRedirect} size="large">
          Sign In
        </Button>
      </StyledEmpty>
    </StyledSection>
  );
}

const StyledSection = styled.section`
  padding-top: 150px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 0;
`;

const StyledEmpty = styled(Empty)`
  color: var(${ThemeVarNames.SecondaryText});
`;
