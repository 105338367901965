import {useState} from 'react';
import {createContainer} from 'unstated-next';

import {RoseUser} from '@types';

function useUser() {
  const [user, setUser] = useState<RoseUser | null>(null);

  return {user, setUser};
}

const UserContainer = createContainer(useUser);

export default UserContainer;
