import React, {useMemo, useState} from 'react';
import styled from 'styled-components';

import {Button, Modal, Table} from '@components/common';

type DetailsButtonProps = {
  modalTitle: string;
  metas: Record<string, any>;
};

export function DetailsButton(props: DetailsButtonProps): JSX.Element {
  const {modalTitle, metas} = props;
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const {detailsData, detailsColumns} = useMemo(
    () => ({
      detailsColumns: [
        {title: 'Tags', dataIndex: 'tag', key: 'tag'},
        {title: 'Values', dataIndex: 'value', key: 'value'}
      ],
      detailsData: Object.keys(metas)
        .filter((key) => key !== '#ERROR!' && key !== '' && key !== 'tree')
        .map((metaName, idx) => ({
          key: idx,
          tag: metaName,
          value: metas[metaName]
        }))
    }),
    [metas]
  );

  return (
    <>
      <Button type="ghost" onClick={() => setShowDetails(true)}>
        Details
      </Button>
      <StyledDetailsModal
        title={`${modalTitle} Details`}
        style={{top: 20}}
        centered
        bodyStyle={{padding: 0}}
        visible={showDetails}
        keyboard
        destroyOnClose
        onCancel={() => setShowDetails(false)}
        footer={[
          <Button key="back" onClick={() => setShowDetails(false)}>
            Close
          </Button>
        ]}
      >
        <Table
          dataSource={detailsData}
          columns={detailsColumns}
          pagination={false}
          tableLayout="fixed"
        />
      </StyledDetailsModal>
    </>
  );
}

const StyledDetailsModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-modal-header {
    padding-right: 56px; /* width of close button */
  }
`;
