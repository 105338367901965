/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import * as React from 'react';
import {
  ContentContainer,
  ContentWrapper,
  ContentP,
  ContentH2,
  ContentH4
} from './styles';

const PrivacyContent = (): JSX.Element =>
  <ContentContainer>
    <ContentWrapper>
      <h5 className="text-white">
        <b>Last Updated: January 3rd, 2021</b>
      </h5>

      <ContentP>
          This Notice explains how Rose Technology Inc. and its affiliates and
          subsidiaries (“Rose Technology“, “we“, “our” or “us“) collect, store,
          use, disclose and otherwise process information about you in the
          course of our business, including through our websites that link to
          this Notice (such as, www.rose.ai) (the “Sites“), our SaaS-based
          data-warehousing services (the “Service”) and through other means such
          as our and our partner’s events, sales and marketing activities. It
          also sets out more information about your privacy rights.
      </ContentP>

      <ContentH2>About Our Service</ContentH2>
      <ContentP>
          We provide the Service to our customers under an agreement with them
          and solely for their benefit and the benefit of their personnel and
          other personnel authorized by them as users of the Service
          (“Authorized Users“). Our customers can efficiently store and analyze
          all their data in the Service. Because the Service is SaaS, Authorized
          Users may access and use the Service from all over the world, subject
          to Rose Technology’s agreement with customers and any applicable laws.
      </ContentP>
      <ContentP>
          This Notice does not apply to the content that our customers and their
          Authorized Users upload and store in the Service (“Customer Content”).
          In those circumstances:
      </ContentP>
      <ContentP>
          (a) Rose Technology is processing Customer Content on behalf of a
          customer and that customer is responsible for the collection and use
          of your data; and
      </ContentP>
      <ContentP>
          (b) that customer’s privacy notice will apply to the customer’s
          collection and use of such Customer Content.
      </ContentP>
      <ContentP>
          In such circumstances you should refer to their privacy notice and
          direct any queries to them. The customer’s agreement with Rose
          Technology governs our use of and processing of Customer Content in
          these circumstances. For more information about our data collection
          and use in connection with our Service see the “Information when using
          the Service” Section below.
      </ContentP>

      <ContentH2>Information You Provide</ContentH2>
      <ContentP>
          The personal information we collect when you use the Sites, including
          when you sign up to the Service through our Sites self-service portal
          (“Self-Service”), and the reasons why you are asked to provide it,
          will be made clear to you at the point we ask you to provide your
          personal information. We will let you know prior to collection whether
          the provision of personal information we are collecting is compulsory
          or may be provided on a voluntary basis. When using the Sites: We may
          collect your name, email, postal address, phone number and other
          information you provide via the Sites when, for example, you submit
          inquiries, request more information about our services, complete
          surveys or download Rose Technology collateral, provide feedback about
          our services or register for sales events or marketing activities.
          When you register to use our Service: When you register to use our
          Service, we may collect your personal information (like name and
          contact details), and for Self-Service we collect credit card
          information. We may share such credit card information with our
          third-party service providers, including for payment and billing
          purposes.
      </ContentP>

      <ContentH2>Information We Automatically Collect</ContentH2>
      <ContentP>
          When using the Sites: We automatically collect certain information
          when you visit or use the Sites, including your use of Self-Service.
          This information may reveal your identity and may include information
          about the specific computer or device you are using, such as your
          computer’s operating system and Internet Protocol (IP) address. We
          also automatically collect and store information about your activities
          on our Sites, such as information about how you came to and used our
          Sites, event information (like access times and browser type and
          language) and data collected through cookies and other similar
          technologies that uniquely identify your browser, computer and/or
          other device. For more information on the use of cookies and other
          tracking technologies please see our Cookie Statement. We may share
          information about your computer or other device with our third-party
          service providers for information and network security, including
          fraud detection. When using the Service: We automatically collect
          certain data relating to the performance and configuration of our
          Service and our Customers’ and Authorized Users’ consumption, use of,
          and interaction with the Service (collectively, “Service Data“). While
          Service Data usually does not involve personal information, it may
          include such data in the following instances:
      </ContentP>
      <ContentP>
          technical information, or data obtained from APIs, software or systems
          hosting the products and services and devices accessing these products
          and services, log files generated during such use;
      </ContentP>
      <ContentP>
          data and metadata about an Authorized Users, such as user ID, email,
          IP address, other data about the user’s computer or other device,
          browser and connecting software (e.g., OS and software versions); and
      </ContentP>
      <ContentP>
          data and metadata about an Authorized Users’ activities and behavior
          within our Service, such as click-patterns and feature utilization.
      </ContentP>
      <ContentP>
          Service Data is used by Rose Technology for (a) providing, supporting
          and operating our products and services, (b) network and information
          security and (c) to analyze, develop and improve our products and
          services
      </ContentP>

      <ContentH2>Information We Receive From Third Parties</ContentH2>
      <ContentP>
          From time to time, we may obtain information about you from third
          party sources, such as public databases and websites, resellers and
          distributors, joint marketing or business partners, security and fraud
          detection firms and social media platforms. Examples of the
          information we may receive from other sources include: account
          information; page-view information; contact information from business
          partners with whom we operate co-branded events, services and
          marketing campaigns or joint offerings; search results and links,
          including paid listings (such as sponsored links); and credit history
          information from credit bureaus.
      </ContentP>

      <ContentH2>Third Party Applications</ContentH2>
      <ContentP>
          We may link to certain third-party websites and applications in our
          Service or on our Sites, such as Facebook. In those circumstances, the
          data we collect, use and share via the Sites or the Service will be
          subject to this Privacy Notice. However, your use of the third-party
          websites, applications and any information you instruct us to send to
          the third-party will be subject solely to the third-party’s terms and
          conditions and privacy notice, and will not be subject to any
          agreements you have with us or this Notice.
      </ContentP>

      <ContentH2>Targeted Online advertisements</ContentH2>
      <ContentP>
          We use one or more third party service providers to serve ads on the
          Sites and/or other websites. These third parties may automatically
          collect and use certain information about your online activities,
          either on our Sites or other websites, such your IP address, your ISP
          and the browser you are using. They do this using cookies, clear gifs
          and similar tracking technologies. Information collected may be used,
          alone or in combination with information about you we obtain from
          other sources (like our data partners and offline customer data), to
          among other things, deliver advertising targeted to your interests,
          including serving ads related to our products or services when you
          access and use other websites and to better understand the usage of
          the Sites tracked by these third parties. This Notice does not apply
          to, and we are not responsible for, cookies or clear gifs in third
          party ads, and we encourage you to check the privacy policies of
          advertisers and/or ad services to learn about their use of cookies and
          other technology. If you would like more information about this
          practice and to know your choices about not having this information
          used by these companies, click here:
          https://www.aboutads.info/choices/.
      </ContentP>

      <ContentH2>Use of the Information We Collect</ContentH2>
      <ContentP>
          We use personal information collected via the Sites, our Service and
          through other means (e.g., in person) for purposes described in this
          Notice, including using your information to:
      </ContentP>
      <ContentP>
          operate, audit and improve our Sites, products and services;
      </ContentP>
      <ContentP>provide customer service and support;</ContentP>
      <ContentP>
          provide and to facilitate the delivery of products and services you
          request;
      </ContentP>
      <ContentP>
          send you related information, including confirmations, invoices,
          technical notices, updates, security alerts, training and support and
          administrative messages;
      </ContentP>
      <ContentP>maintain your account;</ContentP>
      <ContentP>
          enhance security, monitor and verify identity or service access,
          combat fraud, spam, malware or other network and/or information
          security risks;
      </ContentP>
      <ContentP>
          detect bugs, report errors and perform activities to maintain the
          quality or safety of our services;
      </ContentP>
      <ContentP>conduct research and development;</ContentP>
      <ContentP>
          understand you and your preferences to enhance and personalize your
          experience and enjoyment when using our Sites, products and services;
      </ContentP>
      <ContentP>
          develop and send you marketing, sales and promotional communications
          (where this is in accordance with your marketing preferences);
      </ContentP>
      <ContentP>
          communicate with you about one of our events or our partner events,
          including webinars and demos;
      </ContentP>
      <ContentP>
          respond to your comments or questions or provide information requested
          by you;
      </ContentP>
      <ContentP>
          link or combine it with other personal information we get from third
          parties, to help understand your needs, provide you with better
          service and to prevent fraud;
      </ContentP>
      <ContentP>process and deliver contest entries and rewards;</ContentP>
      <ContentP>
          display and measure engagement with advertisements across different
          devices and sites;
      </ContentP>
      <ContentP>maintain legal and regulatory compliance;</ContentP>
      <ContentP>
          short-term, transient use, such as customizing content that we or our
          service providers display on the services; and
      </ContentP>
      <ContentP>
          process your information for other legitimate business purposes, such
          as customer surveys, data analysis, audits, collecting and assessing
          feedback, identifying usage trends, determining the effectiveness of
          our marketing campaigns and to evaluate and improve our products,
          services, marketing and customer relationships.
      </ContentP>
      <ContentP>
          We may store and process personal information in the United States and
          other countries.
      </ContentP>

      <ContentH2>Sharing of Personal Information</ContentH2>
      <ContentP>
          We do not share your personal information with third parties other
          than as follows:
      </ContentP>
      <ContentP>
          where it has been de-identified, including through aggregation or
          anonymization;
      </ContentP>
      <ContentP>when you instruct us to do so;</ContentP>
      <ContentP>
          with your consent, for example, when you agree to our sharing your
          information with other third parties for their own marketing purposes
          subject to their separate privacy policies;
      </ContentP>
      <ContentP>
          with Rose Technology affiliates, in such case the information will be
          processed as otherwise described in this Notice;
      </ContentP>
      <ContentP>
          with third party vendors, consultants and other service providers who
          work for us and need access to your information to do that work.
          Examples include vendors and service providers who provide assistance
          with marketing, billing, processing credit card payments, data
          analysis, fraud prevention, network and information security,
          technical support and customer service;
      </ContentP>
      <ContentP>
          with third party business partners, such as distributors, and/or
          referral partners, who are involved in providing services to our
          prospects and/or customers, to fulfill product and information
          requests and to provide customers and prospective customers with
          information about Rose Technology and its products and services. From
          time to time, we may engage in joint sales or product promotions with
          select business partners. If you purchase or specifically express an
          interest in a jointly-offered product, promotion or service, we may
          share relevant personal information with those partner(s). Where you
          have given your consent to do so, these business partners may send you
          marketing communications about their own products and services. Please
          be aware that we do not control our business partners’ use of such
          information. Our partners are responsible for managing their own use
          of the personal information collected in these circumstances. We
          recommend you review the privacy notices of the relevant partner to
          find out more about their handling of your personal information.
      </ContentP>
      <ContentP>
          to comply with laws or to respond to lawful requests and legal
          process, to protect our rights and property and that of our agents,
          customers, members and others including to enforce our agreements,
          policies and terms of use or in an emergency to protect the personal
          safety of any person;
      </ContentP>
      <ContentP>
          in order to protect any individual’s vital interests, but only where
          we believe it necessary in order to protect the vital interests of any
          person; and
      </ContentP>
      <ContentP>
          in connection with or during negotiation of any business transfer,
          merger, financing, acquisition, or dissolution transaction or
          proceeding involving sale, transfer, divestiture or disclosure of all
          or a portion of our business or assets to another company.
      </ContentP>

      <ContentH2>
          Legal Basis for Processing Personal Information (Visitors and Users
          From EEA and Switzerland Only):
      </ContentH2>
      <ContentP>
          If you are a visitor or user based in the European Economic Area
          (“EEA“), Rose Technology Inc. is the data controller of your personal
          information unless the data is collected in circumstances where one of
          our affiliates is running a local event, training session or survey,
          for example, and they collect and control that information. In such
          circumstances, we will endeavor to make this clear to you in a context
          specific notification at the point we collect your personal
          information. Our legal basis for collecting and using the personal
          information described above will depend on the personal information
          concerned and the specific context in which we collect it. However, we
          will normally collect personal information only where we have your
          consent to do so, where we need the personal information to perform a
          contract with you or where the processing is in our legitimate
          interests and not overridden by your data protection interests or
          fundamental rights and freedoms. In some cases, we may also have a
          legal obligation to collect personal information from you. If we ask
          you to provide personal information to comply with a legal requirement
          or to perform a contract with you, we will make this clear at the
          relevant time. For more information on our legal basis for processing
          data, please contact us using the details provided in Section 17 of
          this Notice.
      </ContentP>

      <ContentH2>Security of Your Personal Data</ContentH2>
      <ContentP>
          We take all reasonable and appropriate steps to protect your personal
          information in an effort to prevent loss, misuse, and unauthorized
          access, disclosure, alteration and destruction. We use appropriate
          technical and organizational measures to protect your personal
          information which may include: physical access controls, encryption,
          internet firewalls, intrusion detection and network monitoring
          depending on the nature of the information and the scope of
          processing. Our staff who may have access to your information are
          required to keep that information confidential.
      </ContentP>

      <ContentH2>International Data Transfers</ContentH2>
      <ContentP>
          Your personal information may be transferred to, and processed in,
          countries other than the country in which you are resident. These
          countries may have data protection laws that are different to the laws
          of your country, and in some cases, may not be as protective.
          Specifically, our website servers are located in the U.S. and we may
          process your information in jurisdictions where our
          affiliates/partners and third-party service providers are located.
          However, we have taken appropriate safeguards to require that your
          personal information will remain protected in accordance with this
          Notice. These safeguards include implementing the European
          Commission’s Standard Contractual Clauses for transfers of personal
          information between us and our business affiliates and associates to
          whom we transfer the information which require these companies to
          protect personal information they process from the EEA or Switzerland
          in accordance with European Union data protection law.Our Standard
          Contractual Clauses can be provided on request. We implement similar
          appropriate safeguards with our third-party service providers and
          further details can be provided upon request. Please note we have
          additionally certified to comply with the Privacy Shield Principles
          for limited international data transfers from the EEA and Switzerland,
          where we act as a processor on behalf of business customers based in
          the EEA and Switzerland and where we act as a controller regarding
          Rose Technology human resources and internal business operations data
          from the EEA and Switzerland. For more information, please see our
          Privacy Shield Statement here.
      </ContentP>

      <ContentH2>Your Information Choices and Changes</ContentH2>
      <ContentP>
          You may opt out of receiving promotional emails from us by following
          the instructions in those emails. If you opt out, we may still send
          you non-promotional emails, such as emails about your accounts or our
          ongoing business relations. You may also send requests about your
          contact preferences and changes to your information by emailing
          info@rose.com. If you are looking to exercise your consumer rights
          under the California Consumer Privacy Act (CCPA), please use the form
          found in Section 12 below; if you are looking to exercise your data
          subject rights under the General Data Protection Regulation (GDPR),
          please use the form found in Section 13 below.
      </ContentP>

      <ContentH2>California Users Only</ContentH2>
      <ContentH4>Your California Privacy Rights</ContentH4>
      <ContentP>
          California Consumer Privacy Act: The California Consumer Privacy Act
          of 2018 (“CCPA”), effective as of January 1, 2020, requires businesses
          that collect personal information of California residents to make
          certain disclosures regarding how they collect, use and disclose such
          information. This section addresses those requirements. For a
          description of all of our data collection, use and disclosure
          practices, please read this Privacy Notice in its entirety. Personal
          Information Collection and Sharing: Rose Technology does not sell
          personal information as we understand sale to be defined by the CCPA
          and its implementing regulations. The categories of personal
          information we collect about you and the third parties with whom we
          share that personal information for a business purpose are as follows:
      </ContentP>
      <ContentP>
          Categories of Personal Information We Collect Categories of Third
          Parties with Which We Share Information for Business Purposes
      </ContentP>
      <ContentP>
          Identifiers (such as name, address, email address) Corporate
          affiliates, vendors, service providers and third party business
          partners (as identified above)
      </ContentP>
      <ContentP>
          Any categories of personal information described in subdivision (e) of
          Section 1798.80 (such as address, telephone number, financial
          information) Vendors, service providers, and third party business
          partners (as identified above)
      </ContentP>
      <ContentP>
          Legally Protected Classifications (such as gender and marital status)
          Vendors and service providers
      </ContentP>
      <ContentP>
          Commercial Information (such as transaction data) Vendors, service
          providers, and third party business partners (as identified above)
      </ContentP>
      <ContentP>
          Internet or Other Network or Device Activity (such as browsing history
          or app usage) Vendors and service providers
      </ContentP>
      <ContentP>
          Approximate Location Information (such as location inferred from your
          IP address, city, country) Vendors and service providers
      </ContentP>
      <ContentP>
          Professional or Employment-Related Data (such as the name of your
          employer) Vendors, service providers, and third party business
          partners (as identified above)
      </ContentP>
      <ContentP>
          Education Information (such as degrees and certifications) Vendors and
          service providers
      </ContentP>
      <ContentP>
          Inferences drawn from any of the information identified above N/A
      </ContentP>
      <ContentP>
          Sources of Personal Information: The sources from which we collect
          personal information are described in Sections 1, 2 and 3 above.
          Purposes for Collecting Personal Information: We collect personal
          information identified in the lists above to communicate with you, for
          marketing and promotional purposes, to provide and improve our
          services and other purposes set forth in Section 6 above. California
          Consumer Rights: California law gives California residents the right
          to make the following requests with regard to certain information we
          collect about them, at no charge, two times every 12 months:
      </ContentP>
      <ContentP>
          the right to request a copy of personal information we collected or
          disclosed for a business purpose in the past 12 months;
      </ContentP>
      <ContentP>
          the right to request deletion of personal information we collected,
          subject to certain exemptions (for example, where the information is
          used by us to detect security incidents, debugging or to comply with a
          legal obligation);
      </ContentP>
      <ContentP>
          the right to request that we disclose personal information we collect,
          use and disclose.
      </ContentP>
      <ContentP>
          You can submit a copy, deletion or right-to-know request by contacting
          us at info@rose.ai. Rose Technology will verify that the information
          you submit (which includes first name, last name, email address,
          company, and country/state) matches our records before we fulfill the
          request. You may use an authorized agent to submit a consumer rights
          request on your behalf using the methods above, however Rose
          Technology will require the authorized agent to provide signed
          permission to submit the request on your behalf and may still contact
          you to confirm your identity and that this request was submitted with
          your permission. Rose Technology does not discriminate against you for
          exercising your rights or offer you financial incentives related to
          the use of your personal information.
      </ContentP>

      <ContentH2>EEA, United Kingdom and Switzerland Users Only</ContentH2>
      <ContentH4>Your Privacy Rights</ContentH4>
      <ContentP>
          If you are a resident of the EEA, United Kingdom, or Switzerland you
          have the following data protection rights:
      </ContentP>
      <ContentP>
          if you wish to access, correct, update or request deletion of your
          personal information, you can do so at any time by filling out Rose
          Technology’s GDPR Data Subject Rights Requests Form;
      </ContentP>
      <ContentP>
          you can object to processing of your personal information, ask us to
          restrict processing of your personal information or request
          portability of your personal information by filling out Rose
          Technology’s GDPR Data Subject Rights Requests Form;
      </ContentP>
      <ContentP>
          if we collected and processed your personal information with your
          consent, you can withdraw your consent at any time by filling out Rose
          Technology’s GDPR Data Subject Rights Requests Form. Withdrawing your
          consent will not affect the lawfulness of any processing we conducted
          prior to your withdrawal, nor will it affect processing of your
          Personal Information conducted in reliance on lawful processing
          grounds other than consent; and
      </ContentP>
      <ContentP>
          to submit a complaint to a data protection authority about our
          collection and use of your personal information. Contact details for
          data protection authorities in the EEA are available here, United
          Kingdom here, and Switzerland here.
      </ContentP>
      <ContentH4>
          Your Privacy Rights in Respect to Customer Content
      </ContentH4>
      <ContentP>
          personal information pertaining to you as an individual has been
          submitted to the Service by or on behalf of a Rose Technology customer
          and you wish to exercise any data protection rights you may have in
          respect of that data under applicable law, including (as applicable)
          the right to access, port, correct, amend or delete such data, please
          inquire with the relevant Rose Technology customer directly. We have a
          limited ability to access Customer Content. However, if you wish to
          make your request directly to us, please provide the name of the Rose
          Technology customer who submitted your data to our Service. We will
          refer your request to that Rose Technology customer and will support
          them as needed in responding to your request within a reasonable
          timeframe.
      </ContentP>

      <ContentH2>Data Retention and Deletion</ContentH2>
      <ContentP>
          We retain personal information we collect from you where we have an
          ongoing legitimate business need to do so (for example, to comply with
          applicable legal, tax or accounting requirements, to enforce our
          agreements or comply with our legal obligations). When we have no
          ongoing legitimate business need to process your personal information,
          we will either delete or anonymize it or, if this is not possible (for
          example, because your personal information has been stored in backup
          archives), then we will securely store your personal information and
          isolate it from any further processing, until deletion is possible.
          When processing Customer Content on behalf of our customers, we will
          retain Customer Content for as long as our customer instructs us to
          and/or as required by applicable law.
      </ContentP>

      <ContentH2>Changes to this Notice</ContentH2>
      <ContentP>
          We may change this Notice from time to time. If we make any changes to
          this Notice, we will change the “Last Updated” date above. If such
          changes are material in nature, we will provide you with additional
          notice (such as adding a statement to the main website page or sending
          you an email notification).
      </ContentP>

      <ContentH2>How to Contact Us</ContentH2>
      <ContentP>
          If you have any questions about this Notice, please contact us at
          info@rose.com
      </ContentP>
    </ContentWrapper>
  </ContentContainer>;

export default PrivacyContent;
