import * as React from 'react';
import {FaDiscord} from '@react-icons/all-files/fa/FaDiscord';

import {StyledButton} from './style';

export type DiscordButtonProps = {
    colored?: boolean
}

export function DiscordButton({colored}: DiscordButtonProps): JSX.Element {
  return (
    <StyledButton
      colored={colored}
      target="_blank"
      href="https://discord.gg/GAXTCfkrcM"
    >
      <FaDiscord />
    </StyledButton>
  );
}
