import styled from 'styled-components';
import {Modal} from 'antd';
import {ThemeVarNames} from '@theme';

interface NotebookTitleProps {
  scrollTitle: any
}

const NotebookTitleContainer = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
  background: var(--rose-primary-bg);
  border-bottom: ${(p: NotebookTitleProps) =>
    p.scrollTitle ? '1px solid var(--rose-border)' : 'none'};
  transition: 0.3s all ease-in-out;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const NotebookTitleWrapper = styled.div<{ isReadOnly: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  transition: 0.2s ease-in-out;

  :hover {
    color: var(${ThemeVarNames.SecondaryBg});
    cursor: pointer;
  }
`;
const NotebookTitleWrapperReadOnly = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  transition: 0.2s ease-in-out;
  pointer-events: none;
`;

const SaveModalContainer = styled(Modal)`
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export {
  NotebookTitleContainer,
  NotebookTitleWrapper,
  NotebookTitleWrapperReadOnly,
  SaveModalContainer
};
