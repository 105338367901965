/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import * as React from 'react';
import {
  ContentContainer,
  ContentWrapper,
  ContentP,
  ContentH2,
  ContentH4
} from './styles';

const TermsContent = (): JSX.Element =>
  <ContentContainer>
    <ContentWrapper>
      <ContentP>
          These Terms of Service (“Terms”) applies to your (“User” or “you”)
          access to and use of the Rose platform, and other online products and
          services (collectively, the “Services”) provided by Rose Technology
          Incorporated (“Rose,” “we” or “us).
      </ContentP>

      <ContentP>
          By accessing or using the Services you agree to be bound by these
          Terms. If you do not agree to these Terms, you may not access or use
          our Services.
      </ContentP>

      <ContentH2>1. Services</ContentH2>
      <ContentH4>1.1 Description of Services</ContentH4>
      <ContentP>
          Rose will provide you with access to the Rose Platform, which will
          enable you to create, process and view lines of data using RoseLang,
          and which includes the Rose Marketplace, which will enable you to
          view, buy and sell lines of data created using RoseLang (collectively
          all of the foregoing, the “Services”).
      </ContentP>

      <ContentH4>1.2 Access</ContentH4>
      <ContentP>
          Subject to and conditional upon your compliance with the terms of
          these Terms, Rose grants you anon-transferable, non-exclusive,
          revocable, limited license to use and access the Services solely as
          permitted by these Terms. Rose reserves all rights not granted in this
          Section.
      </ContentP>
      <ContentP>Your license does not include the right to:</ContentP>
      <ContentP>
          license, sell, transfer, assign, distribute, host, or otherwise
          commercially exploit the Services; modify, prepare derivative works
          of, disassemble, decompile, or reverse engineer any part of the
          Services; access the Services in order to build a similar or
          competitive website, product, or service; remove, obscure or alter any
          proprietary notices associated with the Services; or use the Services
          in any manner that could interfere with, disrupt, negatively affect,
          or inhibit other users from fully enjoying the Services or that could
          damage, disable, overburden, or impair the functioning of the Services
          in any manner.
      </ContentP>

      <ContentH4>1.3 Changes to the Services</ContentH4>
      <ContentP>
          We reserve the right to modify, suspend, or discontinue the Services
          (in whole or in part) at any time, with or without notice to you. Any
          future release, update, or other addition to functionality of the
          Services will be subject to these Terms, which may be updated from
          time to time.
      </ContentP>

      <ContentH2>2. Rose Marketplace</ContentH2>
      <ContentP>
          By buying or selling lines of data created using RoseLang (hereinafter
          “RoseLang Transformed Data”) in the Rose Marketplace, you agree to be
          bound by the following:
      </ContentP>

      <ContentH4>2.1 No Off-Platform Sales</ContentH4>
      <ContentP>
          Any sale of RoseLang Transformed Data outside of the Rose Platform is
          grounds for immediate termination of these Terms, at Rose’s sole
          discretion.
      </ContentP>

      <ContentH4>2.2 Buyer and Seller Warranty to Rose</ContentH4>
      <ContentP>
          In any sale of RoseLang Transformed Data that may result in the access
          of non-public, third-party data, Buyer and Seller each warrant to Rose
          that the transfer does not violate any applicable agreement or
          infringe any third-party’s intellectual property or other proprietary
          rights.
      </ContentP>

      <ContentH4>
          2.3 Limitation of Liability Between Buyers and Sellers
      </ContentH4>
      <ContentP>
          Buyer agrees and understands that Seller provides RoseLang Transformed
          Data as-is and makes no representation or warranty of any kind. Buyer
          agrees and understands that Seller shall in no event be liable to a
          Buyer for any incidental, consequential, special or exemplary damages,
          or for any cost of procuring substitute goods or services, any loss of
          use, data or profits, or any interruption of business damages, arising
          out of a sale of RoseLang Transformed Data, and that the aggregate
          liability of a Seller to a Buyer of RoseLang Transformed Data shall
          not exceed the amounts paid by Buyer to Seller for said RoseLang
          Transformed Data during the three (3) months prior to the event
          resulting in liability.
      </ContentP>

      <ContentH4>2.4 Data Permissions</ContentH4>
      <ContentP>
          Any Buyer of RoseLang Transformed Data represents to the Seller of
          said Data that Buyer has permission to access any underlying data in
          said RoseLang Transformed Data.
      </ContentP>

      <ContentH4>2.5 Terms of Sale</ContentH4>
      <ContentP>
          Buyers and Sellers of RoseLang Transformed Data shall abide by any
          applicable terms of sale presented in the Rose Marketplace (“Terms of
          Sale”). In the event a Seller does not fulfill its commitments in the
          Terms of Sale, Rose may, at its sole discretion, reverse all or a
          portion of the sale.
      </ContentP>

      <ContentH2>3. Intellectual Property</ContentH2>
      <ContentH4>3.1 Rose Intellectual Property</ContentH4>
      <ContentP>
          Rose exclusively owns and retains all right, title, and interest in
          and to the Rose Platform, RoseLang and all related software, code,
          services and technology, and all documentation or other related
          materials and intellectual property in connection therewith, and all
          derivative works therefrom, and/or any other Rose application (the
          “Rose Intellectual Property”). Notwithstanding anything else herein,
          nothing in this Section shall be considered a grant to User or any
          other third party of any right, title or interest in or to Rose
          Intellectual Property, subject only to the express licensing terms and
          restrictions set forth herein, as Rose is and shall remain the sole
          owner of such Rose Intellectual Property and all modifications and
          derivative works thereto. No transfer of intellectual or other
          property is intended or shall be effective under these Terms except as
          specifically provided.
      </ContentP>
      <ContentH4>3.2 Your Ideas and Feedback</ContentH4>
      <ContentP>
          Any ideas, suggestions, and feedback about our Services that you
          provide to us are entirely voluntary, and you agree that we may use
          such ideas, suggestions, and feedback without compensation or
          obligation to you.
      </ContentP>

      <ContentH2>4. Confidential Information</ContentH2>
      <ContentH4>4.1 Scope of Confidential Information</ContentH4>
      <ContentP>
          The term “Confidential Information” shall mean any information
          disclosed, directly or indirectly, in writing, orally, or by any other
          means, to a Party to these Terms (the “Receiving Party”) by the other
          Party to these Terms (the “Disclosing Party”) either prior to, on, or
          after the Effective Date. Such Confidential Information includes, but
          is not limited to, Rose data, product proposals, technological
          processes, product forecasts, trade secrets, pre-publication patent
          applications, product designs, pricing information and rate cards,
          software designs, hardware or system designs, technology
          specifications, source code, object code, graphic designs, customer
          data, proprietary financial information, systems architecture, and
          systems functionalities. Confidential Information shall also include
          all copies, summaries and extracts of any Confidential Information.
          Notwithstanding the foregoing, Confidential Information shall not
          include any information which the Receiving Party can document: (A) is
          in the public domain and is readily available at the time of
          disclosure or which thereafter enters the public domain and is readily
          available, through no improper action or inaction by the Receiving
          Party or any employee or independent contractor thereof; (B) was in
          the possession of the Receiving Party or known by it prior to receipt
          from the Disclosing Party; (C) was rightfully disclosed to the
          Receiving Party by a third party without restriction; (D) is
          independently developed by the Receiving Party without access to such
          Confidential Information; (E) is disclosed with the Disclosing Party’s
          prior written consent; or (F) is required by judicial or
          administrative order or subpoena to be disclosed, provided that the
          Receiving Party gives the Disclosing Party prompt written notice of
          such order or subpoena in order to allow the Disclosing Party
          sufficient time to obtain a protective order.
      </ContentP>

      <ContentH4>4.2 Use of Confidential Information</ContentH4>
      <ContentP>
          The Receiving Party agrees: (a) to use the Confidential Information
          only in connection with these Terms; (b) to retain the Confidential
          Information in confidence; (c) to take all necessary actions to
          protect such Confidential Information, including, without limitation
          all actions that the Receiving Party employs with respect to its own
          confidential materials of a similar nature; (d) not to disclose,
          directly or indirectly, any Confidential Information, any evaluation
          of the Confidential Information, or any information derived therefrom
          to any third party; and (e) not to copy, reverse engineer, reverse
          compile, nor attempt to derive the composition or underlying
          information of any Confidential Information.
      </ContentP>

      <ContentH4>4.3 Disclosure to Employees</ContentH4>
      <ContentP>
          Confidential Information shall only be disclosed to the Receiving
          Party’s employees, independent contractors, and financial and legal
          professionals acting under a legal duty not to disclose User
          information, and only to the extent such employees, independent
          contractors, and financial and legal professionals have a specific
          need to know Confidential Information to carry out the purposes and
          intent of these Terms. The Receiving Party will ensure that its
          employees and independent contractors who have access to the
          Confidential Information shall be under obligation, as a condition of
          employment or otherwise, that ensures the use, title and nondisclosure
          obligations of such Confidential Information as set forth herein.
      </ContentP>

      <ContentH4>4.4 Ownership of Confidential Information</ContentH4>
      <ContentP>
          The Disclosing Party shall at all times retain title to, ownership of
          and all rights and control over the Confidential Information. Except
          as provided herein, no right or license to the Confidential
          Information is granted under these Terms. The Receiving Party shall
          upon termination or expiration of the Agreement and at any other time
          upon the written request of the Disclosing Party promptly return or
          destroy all Confidential Information to the Disclosing Party. Neither
          Party shall make any copies of any Confidential Information of the
          other Party except as necessary to perform its obligations under these
          Terms.
      </ContentP>

      <ContentH4>4.5 Equitable Relief</ContentH4>
      <ContentP>
          Each Party acknowledges that the Disclosing Party asserts that the
          Confidential Information is unique and valuable and that disclosure in
          breach of these Terms may result in irreparable injury to the
          Disclosing Party for which monetary damages alone would not be an
          appropriate remedy. The Parties agree that in the event of a breach or
          threatened breach of these Terms, the Disclosing Party shall be
          entitled to seek injunctive or other equitable relief as a remedy for
          any such breach or anticipated breach without being required to post a
          bond or other security. Any such relief shall be in addition to and
          not in lieu of any appropriate relief by of monetary damages.
      </ContentP>

      <ContentH2>5. Termination</ContentH2>
      <ContentH4>5.1 Termination by User</ContentH4>
      <ContentP>
          You may terminate these Terms at any time and for any reason by
          deleting your Account and discontinuing your use of all Services. If
          you stop using the Services without deactivating your Accounts, your
          Accounts may be deactivated due to prolonged inactivity.
      </ContentP>

      <ContentH4>5.2 Termination by Rose</ContentH4>
      <ContentP>
          We may suspend or terminate your Accounts, or ability to access or use
          the Services at any time for any or no reason, including for a
          violation of these Terms.
      </ContentP>

      <ContentH4>5.3 Effect of Termination; Survival</ContentH4>
      <ContentP>
          Sections 2, 3, 4, 6, 7, 8 and 9 will survive termination or expiration
          of these Terms. Termination shall not constitute a waiver or release
          of, or otherwise be deemed to prejudice or adversely affect, any
          rights, remedies or claims, whether for damages or otherwise, which a
          Party may have under these Terms or which may arise out of or in
          connection with such termination or expiration.
      </ContentP>

      <ContentH2>6. Warranty; Disclaimers</ContentH2>
      <ContentH4>6.1 Warranty</ContentH4>
      <ContentP>
          You warrant to Rose that: (A) the execution and delivery by you of
          these Terms and the performance of your obligations hereunder do not
          conflict with your organizational documents or any US or international
          law, regulation, agreement, court order or other obligation applicable
          to you or by which you are bound or subject to; (B) these Terms have
          been duly authorized, executed and delivered by you and is legal,
          valid and binding upon you and is enforceable in accordance with its
          terms; (C) you have the legal right, power and authority to enter into
          these Terms and; (E) at all times, your use of the Services shall
          comply with all applicable laws, regulations and codes of practice.
      </ContentP>

      <ContentH4>6.2 Disclaimer of Warranty</ContentH4>
      <ContentP>
          ALL CODE, MATERIALS, AND SERVICES ARE PROVIDED AS-IS. EXCEPT AS SET
          FORTH EXPLICITLY IN THIS AGREEEMENT, ROSE MAKES NO REPRESENTATION OR
          OTHER WARRANTY OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, INCLUDING
          BUT NOT LIMITED TO WARRANTIES OF ACCURACY, MERCHANTABILITY, FITNESS
          FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT, AND IT
          SPECIFICALLY DISCLAIMS THE SAME. ROSE IS NOT RESPONSIBLE FOR ANY
          DAMAGE, LOSS, USE OR MISUSE OF LOGIN ID’S OR UNAUTHORIZED ACCESS TO
          PASSWORDS UNLESS SPECIFICALLY CAUSED BY ROSE.
      </ContentP>

      <ContentH2>7. Indemnity</ContentH2>
      <ContentH4>7.1 Exclusion of Liability</ContentH4>
      <ContentP>
          Rose will have no liability or obligation under these Terms with
          respect to a claim based on: (A) your breach of these Terms; (B) any
          unauthorized use or other exploitation by you of Rose code, the Rose
          Platform or Services; (C) the combination, operation or use of the
          Rose code, the Rose Platform or the Services by you with other
          products, hardware, software, or materials; (D) use or operation of
          the Rose code, the Rose Platform or Services after your receipt of
          written notice from Rose that you should cease use of the same due to
          the threat of an infringement claim; or (E) use or operation of the
          Rose code, the Rose Platform or the Services after the termination or
          expiration of these Terms. The foregoing clauses (A) to (E) are
          collectively referred to as “User-Generated Claim(s)”.
      </ContentP>

      <ContentH4>7.2 User’s Duty to Indemnify</ContentH4>
      <ContentP>
          You will defend and settle at your own expense, any action or other
          proceeding brought against Rose, to the extent that such action or
          proceeding is based on a: (i) breach of your warranties hereunder;
          (ii) breach of a RoseData Buy-Sell Agreement; (iii) third-party claim
          that Rose’s provision of the Services or your actions violate any laws
          or regulations, privacy policy or any third party’s rights in respect
          of data protection or personal privacy; or (iv) User-Generated Claim.
          User will pay all costs, damages and expenses (including reasonable
          legal fees) incurred by Rose with respect to any such action or
          proceeding attributable to any such claim.
      </ContentP>

      <ContentH4>7.3 Conditions to Indemnification</ContentH4>
      <ContentP>
          The obligation to defend and indemnify provided under Section 7.2
          shall apply only if: (i) Rose notifies User in writing of any
          potential claim within a reasonable time; (ii) Rose provides the User
          with reasonable assistance requested by the User, at the User’s
          expense, for the defense and settlement of any claim; and (iii) Rose
          provides the User with the exclusive right to control and the
          authority to defend and settle any claim.
      </ContentP>

      <ContentH2>8. Limitation of Liability</ContentH2>
      <ContentP>
          IN NO EVENT SHALL ROSE BE LIABLE FOR ANY INCIDENTAL, CONSEQUENTIAL,
          SPECIAL OR EXEMPLARY DAMAGES, OR FOR ANY COSTS OF PROCURING SUBSTITUTE
          GOODS OR SERVICES, ANY LOSS OF USE, DATA OR PROFITS, OR ANY
          INTERRUPTION OF BUSINESS DAMAGES, ARISING OUT OF OR IN CONNECTION WITH
          THESE TERMS, HOWEVER CAUSED, AND WHETHER OR NOT IT HAS BEEN ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGE. ROSE WILL NOT BE RESPONSIBLE FOR ANY
          MALFUNCTIONS, ERRORS, INACCURACIES, OR IMPROPER RESULTS ATTRIBUTABLE
          TO USER’S INCORRECT, UNAUTHORIZED, OR UNSUPPORTED USE OF ANY CODE OR
          SERVICES. NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN, THE
          AGGREGATE LIABILITY OF ROSE WITH RESPECT TO THE SUBJECT MATTER OF
          THESE TERMS SHALL NOT EXCEED THE AMOUNTS PAID BY YOU TO ROSE HEREUNDER
          DURING THE THREE (3) MONTHS PRIOR TO THE EVENT RESULTING IN LIABILITY.
          SAVE IN RESPECT OF ANY LIABILITY WHICH MAY NOT BE LIMITED OR EXCLUDED
          BY LAW AND A PARTY’S INDEMNITY OBLIGATIONS, THE FOREGOING LIMITATIONS
          AND EXCLUSIONS OF LIABILITY WILL APPLY REGARDLESS OF THE FORM OF
          ACTION, WHETHER BASED ON CONTRACT, STRICT LIABILITY, TORT (INCLUDING
          NEGLIGENCE), OR ANY OTHER LEGAL OR EQUITABLE THEORY OF LIABILITY, AND
          WHETHER OR NOT ANY REMEDY SET FORTH HEREIN FAILS OF ITS ESSENTIAL
          PURPOSE.
      </ContentP>

      <ContentH2>9. General Provisions</ContentH2>
      <ContentH4>9.1 Assignment</ContentH4>
      <ContentP>
          Neither Party may assign these Terms, except that either Party may
          assign all of its rights and obligations under these Terms to an
          affiliate or successor-in-interest as a result of a merger or
          consolidation, or in connection with the sale or transfer of all or
          substantially all of it business or assets to which these Terms
          relates. Subject to the above restrictions on assignment, these Terms
          shall inure to the benefit of and bind the successors and assigns of
          the Parties. Any attempted assignment in derogation of this Section
          will be null and void.
      </ContentP>

      <ContentH4>9.2 Further Assurances</ContentH4>
      <ContentP>
          The Parties will execute such documents, render such assistance, and
          take such other actions as reasonably requested to apply for,
          register, perfect, confirm, and protect the other Party’s rights as
          described in Sections 2, 3 and 4.
      </ContentP>

      <ContentH4>9.3 Injunctive Relief</ContentH4>
      <ContentP>
          Actual or threatened breach of Sections 3 or 4 may cause immediate,
          irreparable harm to Rose that may be difficult to calculate and may
          not be remedied by the payment of damages alone. Accordingly, Rose
          shall be entitled to preliminary and permanent injunctive relief and
          other equitable relief for any such breach.
      </ContentP>

      <ContentH4>9.4 Escalation Procedure</ContentH4>
      <ContentP>
          If any dispute arises in connection with these Terms (save in respect
          of a dispute concerning payment of Fees, to which this Section shall
          not apply), each Party shall be entitled to notify the other Party and
          the dispute shall be escalated to senior executive level. The senior
          executives shall meet within seven (7) days in an effort to resolve
          the dispute. If the dispute cannot be resolved by the senior
          executives, the Parties will in good faith attempt to resolve the
          dispute by mediation administered by the American Arbitration
          Association (“AAA”) under its Commercial Mediation Procedures. Unless
          otherwise agreed between the Parties, the mediator will be nominated
          by the AAA. To initiate the mediation, a Party must give notice in
          writing to the other Party, requesting mediation and setting out the
          reasons for the request. Except as set forth in this Section, no court
          proceedings may be filed before such mediation occurs. Nothing in this
          Section shall prevent a Party from seeking immediate injunctive relief
          regarding alleged breach of Sections 3 or 4 of these Terms.
      </ContentP>

      <ContentH4>9.5 Miscellaneous</ContentH4>
      <ContentP>
          The Parties expressly agree that they are independent contractors and
          do not intend for these Terms to be interpreted as a relationship of
          employment, joint venture or partnership. Neither Party’s waiver of
          the breach of any provision shall constitute a waiver of that
          provision in that or any other instance. These Terms may not be
          modified, in whole or in part, except in writing, signed by both
          Parties. These Terms contains the entire understanding of the Parties
          relating to the subject matter and supersedes all prior agreements and
          contemporaneous and understandings, both written and oral, regarding
          such subject matter. If any term of these Terms is held invalid or
          unenforceable for any reason, the remainder of the provision shall
          continue in full force and effect, and the Parties shall substitute a
          valid, enforceable provision with the same intent and economic effect.
          These Terms will be interpreted in accordance with its terms and
          without any strict construction in favor of or against either Party.
          These Terms may be executed by facsimile signature or in any number of
          counterparts each of which when executed shall be an original but all
          the counterparts together shall constitute one and the same
          instrument.
      </ContentP>

      <ContentH4>9.6 Third Party Rights</ContentH4>
      <ContentP>
          The Parties do not intend any third party to have the right to enforce
          any provision of these Terms, except that any member of Rose’s group
          of companies (if applicable) shall have the right to enforce these
          Terms to the extent to which it relates to the Rose Platform and/or
          the provision of the Services.
      </ContentP>

      <ContentH4>9.7 Law and Jurisdiction</ContentH4>
      <ContentP>
          These Terms shall be governed by and construed in accordance with the
          laws of the State of New York without regard to or application of
          conflicts of law rules or principles.
      </ContentP>
    </ContentWrapper>
  </ContentContainer>;

export default TermsContent;
