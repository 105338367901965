import React, {useState} from 'react';
import styled from 'styled-components';

import {InputSearchWithTags, Typography} from '@components/common';
import {device} from '@utils/breakpoints';

import {Result} from './Result';

export function Buying(): JSX.Element {
  const [searchValue, setSearchValue] = useState<string[]>([]);

  return (
    <StyledLayout>
      <StyledHeader>
        <Typography variant="primary" as="h2" size="xx-large">
          Buying Platform
        </Typography>
      </StyledHeader>
      <InputSearchWithTags
        autoFocus
        onChange={setSearchValue}
        inputProps={{
          variant: 'alternative'
        }}
      />
      <div>
        <Result keywords={searchValue} />
      </div>
    </StyledLayout>
  );
}

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;

  ${device.desktop} {
    padding: 20px;
  }
`;

const StyledHeader = styled.div`
  padding: 0;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-size: 20px;
`;
