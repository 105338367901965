import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { get } from "lodash";
import { ChromePicker } from "react-color";
import { Collapse } from "antd";
import { YAxisOptions } from "highcharts";
import { EE, ChartEditorEvent } from "@utils/helpers/events";
import { ChartSettings, RoseChart, RoseTimeseriesGroup } from "@types";
import { Button, Checkbox, Input, Drawer, Form } from "@components/common";

const { Panel } = Collapse;

export type ChartEditorProps = {
  onChange: (value: ChartSettings) => void;
  settings: ChartSettings;
  roseObject: RoseTimeseriesGroup | RoseChart;
};

export function ChartEditor({
  onChange,
  settings,
  roseObject,
}: ChartEditorProps) {
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const timeoutRef = useRef<number>();
  const [visible, setVisible] = useState(false);
  const [chartSettings, setChartSettings] = useState<ChartSettings>(settings);

  useEffect(() => {
    EE.on(ChartEditorEvent + roseObject.key, (value: any) => {
      if (value) {
        setVisible(true);
      }
    });

    () => {
      EE.removeListener(
        ChartEditorEvent + roseObject.key,
        (chartOptions: any) => {
          if (chartOptions.isOpen) {
            setVisible(true);
          }
        }
      );
    };
  }, []);

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({ title: settings.title.text });
    }
  }, [settings]);

  useEffect(() => {
    if (visible && inputRef.current) {
      timeoutRef.current = window.setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [visible]);

  const handleOnTitleChange = (value: string) => {
    const newValue = {
      ...chartSettings,
      title: {
        ...chartSettings.title,
        text: value,
      },
    };
    setChartSettings(newValue);
  };

  const handleOnSourceChange = (value: string) => {
    const newValue = {
      ...chartSettings,
      source: value,
    };
    setChartSettings(newValue);
  };

  const handleOnYAxisChange = (
    newYAxis: Partial<YAxisOptions>,
    idx: number
  ) => {
    const newYAxisOptions = Array.isArray(chartSettings.yAxis)
      ? [...chartSettings.yAxis]
      : [chartSettings.yAxis];
    newYAxisOptions[idx as number] = {
      ...newYAxisOptions[idx as number],
      ...newYAxis,
    };
    const newObj = { ...chartSettings };
    newObj.yAxis = newYAxisOptions;
    setChartSettings(newObj);
  };

  const handleOnLabelChange = (key: string, value: any) => {
    const newObj: any = { ...chartSettings };
    const currDatasets: any = get(newObj, "datasets");
    currDatasets[key] = { ...currDatasets[key], name: value };
    newObj.datasets = { ...currDatasets };

    setChartSettings(newObj);
  };

  const handleColorChange = (color: any, key: any) => {
    const newObj: any = { ...chartSettings };
    const currDatasets: any = get(newObj, "datasets");
    currDatasets[key] = { ...currDatasets[key], color: color.hex };
    newObj.datasets = { ...currDatasets };

    setChartSettings(newObj);
  };

  const watermarkCheckboxChange = (e: any) => {
    const newValue = {
      ...chartSettings,
      watermark: e.target.checked,
    };
    setChartSettings(newValue);
  };

  const handleOnSave = () => {
    onChange(chartSettings);
  };

  const desiredLabels = ["name", "color"];
  return (
    <StyledDrawer
      title="Chart Editor"
      placement="right"
      onClose={() => setVisible(false)}
      closable
      visible={visible}
      contentWrapperStyle={{
        width: "100%",
        maxWidth: "650px",
        position: "absolute",
      }}
    >
      <StyledContent>
        <Form layout="vertical" onFinish={() => handleOnSave()}>
          <Form.Item label="Chart Title">
            <Input
              type="text"
              size="middle"
              value={chartSettings?.title?.text}
              onChange={(e: any) => handleOnTitleChange(e.target.value)}
              ref={inputRef}
              autoFocus
            />
          </Form.Item>
          <Form.Item label="Source">
            <Input
              type="text"
              size="middle"
              value={chartSettings?.source}
              onChange={(e: any) => handleOnSourceChange(e.target.value)}
            />
          </Form.Item>
          <Collapse
            style={{ marginBottom: "24px" }}
            defaultActiveKey={Object.keys(chartSettings?.datasets) || []}
          >
            {Object.entries(chartSettings.datasets).map(([key, values]) => (
              <Panel header={key} key={key}>
                <Form.Item key={key}>
                  {Object.entries(values).map(([field, data]) =>
                    desiredLabels.includes(field) ? (
                      <Form.Item label={field} key={`${key}-${field}`}>
                        {field === "color" ? (
                          <div>
                            <ChromePicker
                              disableAlpha={true}
                              color={data}
                              onChange={(color: any) => {
                                handleColorChange(color, key);
                              }}
                            />
                          </div>
                        ) : (
                          <Input
                            type="text"
                            size="middle"
                            value={data}
                            onChange={(e: any) =>
                              handleOnLabelChange(key, e.target.value)
                            }
                          />
                        )}
                      </Form.Item>
                    ) : null
                  )}
                </Form.Item>
              </Panel>
            ))}
          </Collapse>
          <Collapse style={{ marginBottom: "24px" }}>
            {Array.isArray(chartSettings.yAxis) ? (
              <Panel header="Y Axes" key="yAxes">
                <Collapse>
                  {chartSettings.yAxis.map(
                    (
                      axis // map all settings.yAxis to form items
                    ) =>
                      axis.visible ? (
                        <Panel header={axis.defaultLabel} key={axis.index}>
                          <Form.Item label="Axis Title">
                            <Input
                              type="text"
                              size="middle"
                              value={axis.title.text || ""}
                              onChange={(e: any) =>
                                handleOnYAxisChange(
                                  {
                                    title: {
                                      ...axis.title,
                                      text: e.target.value,
                                    },
                                  },
                                  axis.index
                                )
                              }
                            />
                          </Form.Item>
                          <Form.Item label="Axis Min">
                            <Input
                              type="number"
                              size="middle"
                              value={axis.min}
                              onChange={(e: any) =>
                                handleOnYAxisChange(
                                  {
                                    min: Boolean(e.target.value)
                                      ? Number(e.target.value)
                                      : null,
                                  },
                                  axis.index
                                )
                              }
                            />
                          </Form.Item>
                          <Form.Item label="Axis Max">
                            <Input
                              type="number"
                              size="middle"
                              value={axis.max}
                              onChange={(e: any) =>
                                handleOnYAxisChange(
                                  {
                                    max: Boolean(e.target.value)
                                      ? Number(e.target.value)
                                      : null,
                                  },
                                  axis.index
                                )
                              }
                            />
                          </Form.Item>
                        </Panel>
                      ) : null
                  )}
                </Collapse>
              </Panel>
            ) : null}
          </Collapse>
          <Form.Item
            label="Watermark"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "24px",
            }}
          >
            <Checkbox
              checked={chartSettings.watermark}
              onChange={(e) => watermarkCheckboxChange(e)}
              style={{
                padding: "0 0 12px",
              }}
            />
          </Form.Item>
          <RightContainerButtons>
            <Form.Item>
              <Button
                type="ghost"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Close
              </Button>
            </Form.Item>
            <Form.Item shouldUpdate>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Save
              </Button>
            </Form.Item>
          </RightContainerButtons>
        </Form>
      </StyledContent>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    padding-bottom: 0;
  }
`;

const StyledContent = styled.div`
  position: relative;
  width: 100%;
  padding: 30px 40px;
`;

const RightContainerButtons = styled.div`
  padding-top: 20px;
  justify-content: flex-end;
  display: flex;
  flex-direction: "row";
  gap: 10px;
`;
