import * as React from 'react';
import styled, {keyframes} from 'styled-components';
import {IoSaveOutline} from '@react-icons/all-files/io5/IoSaveOutline';
import {message, Tooltip} from 'antd';
import {UseMutationResult} from '@tanstack/react-query';

import {Button} from '@components/common';
import {MutationType} from '@service/useNotebook';

import {RoseNotebook} from '@types';
import {CreateNewNotebookModal} from './CreateNewNotebookModal';

export type ButtonSaveProps = {
  color: string
  notebook: RoseNotebook
  isReadOnly: boolean
  mutation: UseMutationResult<RoseNotebook, any, MutationType, unknown>
  isGuest: boolean
}

export function ButtonSave(props: ButtonSaveProps): JSX.Element {
  const {color, notebook, mutation, isReadOnly, isGuest} = props;
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const isNewNotebook = notebook.code === '';
  const isButtonDisabled = isReadOnly && notebook.code !== '' || isGuest;

  function handleOnClick() {
    if (isReadOnly) {return;}

    if (isNewNotebook) {
      setShowModal(true);
    } else {
      const newNotebook = {...notebook};
      mutation.mutate(
        {action: 'replace', notebook: newNotebook},
        {
          onSuccess() {
            message.success('Notebook saved');
          }
        }
      );
    }
  }

  const Icon = mutation.isLoading ?
    <IoSaveOutlineLoading size={24} /> :
    <IoSaveOutline size={24} color="inherit" />;

  return (
    <>
      <Tooltip title="Save" placement="right">
        <Button
          id="save-notebook-button"
          type="text"
          shape="circle"
          color={color}
          onClick={handleOnClick}
          disabled={isButtonDisabled}
        >
          {Icon}
        </Button>
      </Tooltip>
      <CreateNewNotebookModal
        visible={showModal}
        setVisible={setShowModal}
        code={notebook.code}
        type="create"
        notebook={notebook}
        mutation={mutation}
      />
    </>
  );
}

const breatheAnimation = keyframes`
  /* stylelint-disable */
  from {
    color: hsl(208 79% 30%);
  }
  to {
    color: hsl(208 79% 80%);
  }
  /* stylelint-enable */
 `;

const IoSaveOutlineLoading = styled(IoSaveOutline)`
  animation-name: ${breatheAnimation};
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;
