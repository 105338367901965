import * as React from 'react';
import styled from 'styled-components';
import {FiExternalLink} from '@react-icons/all-files/fi/FiExternalLink';
import {IoCloudDownloadOutline} from '@react-icons/all-files/io5/IoCloudDownloadOutline';
import {message} from 'antd';

import {saveAs} from 'file-saver';
import {Spinner, Checkbox} from '@components/common';
import {ThemeVarNames} from '@theme';
import ObjectService from '@utils/apis/ObjectService';
import {RoseModuleType} from '@types';

export type ResultListProps = {
  items: any[]
  loading: boolean
  checkedCodes?: string[]
  onAdd?: (items: string | string[], moduleType?: RoseModuleType) => void
  setCheckedCodes?: (codes: any[]) => void
}

export function ResultList(props: ResultListProps): JSX.Element {
  const {items, loading, checkedCodes = [], onAdd, setCheckedCodes} = props;

  const handleOnClick = (item: any) => () => {
    if (item?.type === 'notebook') {
      window.open(`/dashboard/${item.code}`, '_blank');
    } else if (item?.type === 'file') {
      ObjectService.getFile(item?.code)
        .then((res) => {
          const originalFile = res?.data?.metas?.original_filename;
          const fileUrl = String(res?.data?.values?.data[0]);
          saveAs(fileUrl, originalFile);
          message.success('Successfully Downloaded', 3);
          return res;
        })
        .catch((e) => {
          message.error('Error downloading', e);
        });
    } else if (item?.type === 'image') {
      onAdd(item.code, 'image');
    } else {
      onAdd([item.code]);
    }
  };

  const handleOnCheck = (item: any) => (checked: boolean) => {
    if (checked) {
      setCheckedCodes?.([...checkedCodes, item]);
    } else {
      const newCheckCodes = checkedCodes.filter((el) => el !== item);
      setCheckedCodes?.(newCheckCodes);
    }
  };

  if (loading) {
    return <Spinner size={64} padding="16px" />;
  }

  return (
    <ListConainer>
      {items.map((item: any) =>
        <Item
          type={item.type === 'notebook' ? 'link' : item.type === 'file' ? 'download' : 'checkbox'}
          key={item.key}
          item={item}
          onClick={handleOnClick(item)}
          checked={checkedCodes.includes(item)}
          onCheck={handleOnCheck(item)}
        />
      )}
    </ListConainer>
  );
}

type ItemProps = {
  type: 'link' | 'checkbox' | 'download'
  item: any
  onClick: () => void
  onCheck: (value: boolean) => void
  checked: boolean
}

function Item(props: ItemProps): JSX.Element {
  const {type, item, checked, onClick, onCheck} = props;

  const Control =
    type === 'link' ? <StyledFiExternalLink size={24} /> :
      type === 'download' ? <StyledIoCloudDownloadOutline size={24} /> :
        <StyledCheckbox
          checked={checked}
          onChange={(e) => onCheck(e.target.checked)}
        />;

  return (
    <ItemContainer id={item.code}>
      {Control}
      <InfoWrapper onClick={onClick}>
        <Name>{item.code}</Name>
        <Text>{`Owner: ${item?.actor?.code}`}</Text>
        <Text>{`Type: ${item?.type}`}</Text>
      </InfoWrapper>
    </ItemContainer>
  );
}

const ListConainer = styled.ul`
  list-style-type: unset;
  margin: 0;
  padding: 0;
  padding-top: 10px;
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 8px;
  border-radius: 8px;

  :hover {
    cursor: pointer;
    background-color: var(${ThemeVarNames.PrimaryBg});
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  flex: 1;
`;

const Name = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  word-break: break-all;
`;

const Text = styled.p`
  font-size: 12px;
  opacity: 0.7;
  margin: 0;
`;

const StyledCheckbox = styled(Checkbox)`
  width: 24px;
`;

const StyledFiExternalLink = styled(FiExternalLink)`
  color: var(${ThemeVarNames.TertiaryText});
  height: auto;
`;

const StyledIoCloudDownloadOutline = styled(IoCloudDownloadOutline)`
  color: var(${ThemeVarNames.TertiaryText});
  height: auto;
`;
