/**
 * There is not a type for mobile becauase the idea is to implement CSS mobile first
 */

export type Px = `${number}px`

type Size = 'sm' | 'md' | 'lg'

export type Device = 'mobile' | 'tablet' | 'desktop' | 'big-desktop'

const sizes: Record<Size, Px> = {
  sm: '768px',
  md: '1024px',
  lg: '1200px'
};

export const device: Record<Device, string> = {
  mobile: `@media screen and (min-width: ${sizes.sm})`,
  tablet: `@media screen and (min-width: ${sizes.sm})`,
  desktop: `@media screen and (min-width: ${sizes.md})`,
  'big-desktop': `@media screen and (min-width: ${sizes.lg})`
};

export function getDeviceFromWidth(width: number): Device {
  switch (true) {
  case width <= 1024:
    return 'tablet';
  case width <= 1200:
    return 'desktop';
  default:
    return 'big-desktop';
  }
}
