export const extractLogic = (query: string): [string, string | undefined] => {
  const SELECT_INDEX = query.toLowerCase().indexOf('select');
  const EQUAL_INDEX = query.trim().indexOf('=');
  if (SELECT_INDEX !== 0 && EQUAL_INDEX !== 0 && EQUAL_INDEX < SELECT_INDEX) {
    return [
      query.slice(0, query.indexOf('=')).trim(),
      query.slice(query.indexOf('=') + 1).trim()
    ];
  }

  return [query, undefined];
};
