import * as React from 'react';
import styled from 'styled-components';
import {useTable, usePagination, Column} from 'react-table';
import {AiOutlineDoubleRight} from '@react-icons/all-files/ai/AiOutlineDoubleRight';
import {AiOutlineDoubleLeft} from '@react-icons/all-files/ai/AiOutlineDoubleLeft';
import {AiOutlineLeft} from '@react-icons/all-files/ai/AiOutlineLeft';
import {AiOutlineRight} from '@react-icons/all-files/ai/AiOutlineRight';

import _ from 'lodash';
import {Button} from '@components/common';
import {ThemeVarNames} from '@theme';
import {RoseMap, RoseNotebook} from '@types';

export type MapTableProps = {
  roseObject: RoseMap | RoseNotebook
}

export function MapTable({roseObject}: MapTableProps): JSX.Element {
  const {columns, data} = React.useMemo(
    () =>
      mapValues(
        roseObject.values.columns,
        roseObject.values.data as Array<string[]>
      ),
    [roseObject.values]
  );

  return <RoseTable columns={columns} data={data} />;
}

type RoseTableProps = {
  columns: Column<any>[]
  data: any[]
}

function RoseTable({columns, data}: RoseTableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {pageIndex}
  } = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0}
    },
    usePagination
  );

  return (
    <Styles>
      <div className="tableWrap">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) =>
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) =>

                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                )}
              </tr>
            )}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any) =>
                    <td
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <PaginationContainer>
        <Button
          type="ghost"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <AiOutlineDoubleLeft />
        </Button>
        <Button
          type="ghost"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <AiOutlineLeft />
        </Button>
        <Button type="ghost" onClick={() => nextPage()} disabled={!canNextPage}>
          <AiOutlineRight />
        </Button>
        <Button
          type="ghost"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <AiOutlineDoubleRight />
        </Button>
        <span>
          Page
          <strong>
            {' '}
            {pageIndex + 1}
            {' of '}
            {pageOptions.length}
          </strong>
        </span>
      </PaginationContainer>
    </Styles>
  );
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    scrollbar-width: thin;
    border: 1px solid var(${ThemeVarNames.Border});
    border-radius: 10px;

    ::-webkit-scrollbar {
      height: 5px;
      background: unset;
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      display: auto;
      background-clip: padding-box;
      background-color: hsl(0 0% 60%);
      border-radius: 20px;
    }
  }

  table {
    border-spacing: calc(0px * 1);
    width: 100%;

    thead {
      background-color: var(${ThemeVarNames.SecondaryBg});
    }

    tr {
      &:last-child {
        td {
          border-bottom: calc(0px * 1);
        }
      }
    }

    th,
    td {
      padding: 0.5rem;
      border-bottom: 1px solid var(${ThemeVarNames.Border});
      border-right: 1px solid var(${ThemeVarNames.Border});
    }

    th {
      color: var(${ThemeVarNames.SecondaryText});
    }

    td {
      color: var(${ThemeVarNames.PrimaryText});
    }
  }
`;

const PaginationContainer = styled.div`
  gap: 6px;
  display: flex;
  margin-top: 6px;
  margin-bottom: 12px;
  align-items: center;

  span {
    color: var(${ThemeVarNames.SecondaryText});
  }
`;

function mapValues(
  columns: string[],
  data: Array<string[]>
): {columns: Column<any>[]; data: any[]} {
  const normalize = (str: string) => str.replace(/\./g, '_');
  const mappedColumns = columns
    .map<Column<any>>((col, index) => ({
      accessor: _.isEmpty(col) ? `index${index}` : normalize(col),
      Header: _.isEmpty(col) ? `index${index}` : col,
      key: _.isEmpty(col) ? `index${index}` : normalize(col),
      collapse: true,
      Cell: (row) => row.value
    }));

  const mappedData = data.map((points) => points.reduce((out, point, idx) => {
    const key = mappedColumns?.[idx]?.accessor as string;
    return key ? {...out, [key]: point} : out;
  }, {}));

  return {
    columns: mappedColumns,
    data: mappedData
  };
}
