import React, { useState, useEffect } from 'react';
import { RiCloseFill } from '@react-icons/all-files/ri/RiCloseFill';
import { IoBarChart } from "@react-icons/all-files/io5/IoBarChart";
import { IoMenu } from '@react-icons/all-files/io5/IoMenu';
import { IoPerson } from '@react-icons/all-files/io5/IoPerson';
import { IoPeople } from '@react-icons/all-files/io5/IoPeople';
import { IoLogOut } from '@react-icons/all-files/io5/IoLogOut';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Dropdown } from 'antd';

import UserService from '@utils/apis/UserService';
import DashboardContainer from '@utils/state/dashboardContainer';
import UserContainer from '@utils/state/userContainer';
import { Button, Menu, Logo } from '@components/common';
import { RoseUser } from '@types';
import {
  MobileLink,
  MobileLinkWrapper,
  MobileLinkContainer,
  ScrollContainerFixed,
  Mobile,
  ProfileContainer,
  Link as StyledLink,
  LinkLogo as StyledLinkLogo,
  LinkContainer,
  HeaderContent,
  HeaderWrapper,
  DesktopWrapper,
  MobileHeaderContainer,
  HeaderContainerSticky,
  MobileContainer,
  StyledItem
} from './styles';
import { ThemeVarNames } from '@theme';
import { RoseBudBanner } from '@components/common/LandingHeader/RoseBudBanner';

export const Header = React.memo(HeaderComponent, () => true);

function HeaderComponent() {
  const [showMobile, setShowMobile] = useState(false);
  const { dashboard, setDashboard } = DashboardContainer.useContainer();
  const { user, setUser } = UserContainer.useContainer();
  const navigate = useNavigate();

  const handleResize = () => {
    if (showMobile && window.innerWidth > 959) {
      setShowMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return function cleanUpEvent() {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const clearData = () => {
    setUser(null);
    try {
      window.sessionStorage.clear();
      window.localStorage.clear();
    } catch (error) {
      console.error('error cleaning session and local storage: ', error);
    }

    setUser(null);
  };

  const goToLoginPage = () => {
    navigate('/login');
  };

  const logout = () => {
    UserService.logout()
      .then(() => clearData())
      .then(() => goToLoginPage())
      .catch(() => console.error('could not log out'));
  };

  const setDashboardSafe = (path: string) => {
    if (!location.pathname.includes('/dashboard')) { navigate('/dashboard'); }

    setDashboard(path);
  };

  const menu =
    <>
      <ProfileContainer>
        <h3 style={{ fontWeight: 600 }}>{RoseUser.composeFullName(user)}</h3>
        <h3>{user?.email}</h3>
        <hr style={{ borderTop: '1px solid grey', margin: '10px 0' }} /> {/* Line with margin */}
        <h3>{user?.actor?.code}</h3>
      </ProfileContainer>
      <Menu>
        <Menu.Divider />
        {user && RoseUser.isInternalEmployee(user) ?
          <Menu.Item
            onClick={() => {
              window.location.href = process.env.REACT_APP_VISION_URL;
            }}
            style={{ color: `var(${ThemeVarNames.SecondaryText})` }}
          >
            <StyledItem>
              <IoBarChart size={18} />
              <span>Rose Bud</span>
            </StyledItem>
          </Menu.Item> : null}
        <Menu.Item
          onClick={() => {
            setDashboardSafe('settings');
          }}
          style={{ color: `var(${ThemeVarNames.SecondaryText})` }}
        >
          <StyledItem>
            <IoPerson size={18} />
            <span>Settings</span>
          </StyledItem>
        </Menu.Item>
        {user?.isAdmin ?
          <Menu.Item
            onClick={() => {
              setDashboardSafe('admin');
            }}
            style={{ color: `var(${ThemeVarNames.SecondaryText})` }}
          >
            <StyledItem>
              <IoPeople size={18} />
              <span>Admin Portal</span>
            </StyledItem>
          </Menu.Item> : null}
        <Menu.Item onClick={logout} style={{ color: `var(${ThemeVarNames.SecondaryText})` }}>
          <StyledItem>
            <IoLogOut size={18} />
            <span>Logout</span>
          </StyledItem>
        </Menu.Item>
      </Menu>
    </>;

  /**
   * TODO: Pull this out to a separate file.
   */
  function HeaderDesktopComponent() {
    return (
      <HeaderContainerSticky>
        <DesktopWrapper>
          <div className="flex justify-between items-center px-6">
            <StyledLinkLogo
              href="/"
              active={dashboard === 'notebook'}
              onClick={() => {
                setDashboardSafe('notebook');
              }}
            >
              <Logo size="small" />
            </StyledLinkLogo>
            <LinkContainer>
              <StyledLink
                active={dashboard === 'discover'}
                onClick={() => {
                  setDashboardSafe('discover');
                }}
              >
                discover
              </StyledLink>
              <StyledLink
                active={dashboard === 'notebook'}
                onClick={() => {
                  setDashboardSafe('notebook');
                }}
              >
                workspace
              </StyledLink>
              <StyledLink
                active={dashboard === 'security'}
                onClick={() => {
                  setDashboardSafe('security');
                }}
              >
                sharing
              </StyledLink>
              <StyledLink
                active={dashboard === 'marketplace'}
                onClick={() => {
                  setDashboardSafe('marketplace');
                }}
              >
                marketplace
              </StyledLink>
              <StyledLink
                active={false}
                href="https://docs.rose.ai"
                target="_blank"
              >
                documentation
              </StyledLink>
              {RoseUser.isGuest(user) ?
                <Button
                  type="primary"
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  Sign In
                </Button> :
                <Dropdown overlay={menu} trigger={['click']}>
                  <div>
                    <Button
                      type="ghost"
                      shape="circle"
                      className="ant-dropdown-link"
                      onClick={(event) => event.preventDefault()}
                    >
                      <IoPerson />
                    </Button>
                    <span style={{ cursor: 'pointer', marginLeft: '4px' }}>{user.code}</span>
                  </div>
                </Dropdown>
              }
            </LinkContainer>
            <Mobile>
              <IoMenu size={24} onClick={() => setShowMobile(true)} />
            </Mobile>
          </div>
        </DesktopWrapper>
      </HeaderContainerSticky>
    )
  }

  /**
   * TODO: Pull this out to a separate file.
   */
  function HeaderMobileComponent() {
    return (
      <ScrollContainerFixed showMobile={showMobile}>
        <MobileContainer>
          <MobileHeaderContainer>
            <HeaderWrapper>
              <HeaderContent>
                <RouterLink to="/">
                  <Logo showText={false} />
                </RouterLink>
              </HeaderContent>
              <HeaderContent>
                <RiCloseFill
                  size={24}
                  onClick={() => {
                    setShowMobile(false);
                  }}
                />
              </HeaderContent>
            </HeaderWrapper>
          </MobileHeaderContainer>
          <MobileLinkContainer border>
            <MobileLinkWrapper>
              <MobileLink
                primary={false}
                onClick={() => {
                  setDashboardSafe('discover');
                  setShowMobile(false);
                }}
              >
                discover
              </MobileLink>
              <MobileLink
                primary={false}
                onClick={() => {
                  setDashboardSafe('notebook');
                  setShowMobile(false);
                }}
              >
                workspace
              </MobileLink>
              <MobileLink
                primary={false}
                onClick={() => {
                  setDashboardSafe('security');
                  setShowMobile(false);
                }}
              >
                sharing
              </MobileLink>
              <MobileLink
                primary={false}
                onClick={() => {
                  setDashboardSafe('marketplace');
                  setShowMobile(false);
                }}
              >
                marketplace
              </MobileLink>
              <MobileLink
                primary={false}
                target="_blank"
                href="https://docs.rose.ai"
                onClick={() => {
                  setShowMobile(false);
                }}
              >
                documentation
              </MobileLink>
              {user?.isAdmin ? <MobileLink
                primary={false}
                onClick={() => {
                  setDashboardSafe('admin');
                  setShowMobile(false);
                }}
              >
                Admin Portal
              </MobileLink> : null}
              {user ?
                <MobileLink
                  primary={false}
                  onClick={() => {
                    setDashboardSafe('settings');
                    setShowMobile(false);
                  }}
                >
                  settings
                </MobileLink> :
                null}
            </MobileLinkWrapper>
          </MobileLinkContainer>
          <MobileLinkContainer border={false}>
            <MobileLinkWrapper>
              {user ?
                <MobileLink
                  primary
                  onClick={() => {
                    logout();
                    setShowMobile(false);
                  }}
                >
                  Logout
                </MobileLink> :
                <MobileLink
                  primary
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  Login
                </MobileLink>
              }
            </MobileLinkWrapper>
          </MobileLinkContainer>
          <RoseBudBanner />
        </MobileContainer>
      </ScrollContainerFixed>
    )
  }

  return (
    <>
      <HeaderDesktopComponent />
      <div>
        <HeaderMobileComponent />
        <RoseBudBanner />
      </div>
    </>
  );
}
