import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import UserContainer from '@utils/state/userContainer';
import {AskLogin, Tabs} from '@components/common';
import {
  MarketplaceView,
  saveMarketplaceActiveView,
  getMarketplaceActiveView
} from '@service/sessionStorage';
import HelmetWrapper from '@utils/HelmetWrapper';
import {RoseUser} from '@types';

import {Buying} from './components/Buying/index';
import {Selling} from './components/Selling';

export function Marketplace(): JSX.Element {
  const [view, setView] = useState<MarketplaceView>(
    MarketplaceView.defaultValue()
  );
  const {user} = UserContainer.useContainer();

  useEffect(() => {
    setView(getMarketplaceActiveView());
  }, []);

  const onViewChange = (newView: MarketplaceView) => {
    saveMarketplaceActiveView(newView);
    setView(newView);
  };

  const Helmet =
    <HelmetWrapper title="Marketplace" description="Buying and Selling Data" />
    ;

  if (RoseUser.isGuest(user)) {
    return (
      <>
        {Helmet}
        <AskLogin />
      </>
    );
  }

  return (
    <>
      {Helmet}
      <StyledTabs
        centered
        activeKey={view}
        size="large"
        onChange={onViewChange}
        tabPosition="top"
        destroyInactiveTabPane
      >
        <Tabs.TabPane tab="Buy Packages" key="buy">
          <Buying />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Sell Packages" key="sell">
          <Selling />
        </Tabs.TabPane>
      </StyledTabs>
    </>
  );
}

const StyledTabs = styled(Tabs)`
  padding: 20px;
  `;
