import * as React from 'react';
import {Select as AntdSelect, SelectProps as AntdSelectProps} from 'antd';
import styled from 'styled-components';

import {ThemeVarNames} from '@theme';

type ExtraProps = {
  variant?: 'primary' | 'alternative'
};

export type SelectProps = AntdSelectProps<any> & ExtraProps

export function Select(props: SelectProps) {
  const {variant, className, ...restProps} = props;

  return (
    <StyledSelect
      {...restProps}
      className={`${className} ${variant ?? 'primary'}`}
    />
  );
}

export const StyledSelect = styled(AntdSelect)`
  --bg: var(${ThemeVarNames.InputPrimary});

  &.alternative {
    --bg: var(${ThemeVarNames.InputAlternative});
  }

  color: var(${ThemeVarNames.PrimaryText});
  border-radius: 2px;

  &.ant-select-focused {
    box-shadow: var(${ThemeVarNames.ShadowFocus});
  }

  &:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--bg);
    border-color: var(${ThemeVarNames.Border});

    :hover {
      border-color: var(${ThemeVarNames});
    }
  }

  .ant-select-selection-placeholder {
    color: var(${ThemeVarNames.TertiaryText});
  }

  .ant-select-arrow {
    color: var(${ThemeVarNames.PrimaryText});
  }
`;

export const SelectOption = AntdSelect.Option;

export const SelectOptGroup = AntdSelect.OptGroup;
