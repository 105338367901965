import * as React from 'react';
import {Upload} from 'antd';
import styled from 'styled-components';
import {DraggerProps as AntdDraggerProps} from 'antd/es/upload/Dragger';
import {ThemeVarNames} from '@theme';

const {Dragger: AntdDragger} = Upload;

type ExtraProps = {
  variant?: 'primary' | 'alternative'
  children?: React.ReactNode
  className?: string
};

export type DraggerProps = AntdDraggerProps & ExtraProps;

export function Dragger(props: DraggerProps) {
  const {variant, className, children, ...restProps} = props;

  return (
    <StyledDragger
      {...restProps}
      className={`${className} ${variant ?? 'primary'}`}
    >
      {children}
    </StyledDragger>
  );
}

const StyledDragger = styled(AntdDragger)`
  border-radius: 10px !important;
  border: 1px solid var(${ThemeVarNames.Border}) !important;
  background: var(${ThemeVarNames.PrimaryBg});
  color: var(${ThemeVarNames.PrimaryText}) !important;

  :hover {
    border: 1px solid var(${ThemeVarNames.Action}) !important;
  }

  .ant-upload, .ant-upload-drag, .ant-upload-button {
    border-radius: 10px !important;
  }

  .ant-form-item, .ant-upload, ant-upload-drag, .ant-upload-btn {
    background: var(${ThemeVarNames.PrimaryBg});
    color: var(${ThemeVarNames.PrimaryText});
  }

  .ant-upload-drag-container, .ant-upload-text {
    color: var(${ThemeVarNames.PrimaryText}) !important;
  }

  .ant-upload-list, .ant-upload-span, .ant-upload-list-item, .ant-upload-list-item-name, .ant-upload-list-item-name span, ant-upload-list-item, ant-upload-list-item-undefined, ant-upload-list-item-list-type-text {
    color: var(${ThemeVarNames.PrimaryText}) !important;
  }

  .ant-upload.ant-upload-drag p.ant-upload-textp.ant-upload-text {
    color: var(${ThemeVarNames.PrimaryText}) !important;
  }


  .ant-upload-list-item {
    color: var(${ThemeVarNames.PrimaryText}) !important;
    background: var(${ThemeVarNames.PrimaryBg}) !important;
    margin-top: 20px !important;
  }

  :hover {
    .ant-upload-list-item {
      .ant-upload-list-item-info {
        border: .5px solid var(${ThemeVarNames.Action}) !important;
        background: transparent !important;
      }
    }
  }
  

  .ant-upload-list-item {
    .ant-upload-text-icon 
      svg {
      color: var(${ThemeVarNames.PrimaryText}) !important;
    }
  }

  .ant-upload-list-item {
    .ant-upload-list-item-card-actions {
      .ant-btn svg {
        color: var(${ThemeVarNames.PrimaryText}) !important;
      }
    }
  }
`;
