/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, {useLayoutEffect, useRef, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import styled from 'styled-components';
import {IoTrash} from '@react-icons/all-files/io5/IoTrash';
import {IoAdd} from '@react-icons/all-files/io5/IoAdd';
import {IoCode} from '@react-icons/all-files/io5/IoCode';
import {Tooltip} from 'antd';
import {isEmpty} from 'lodash';
import {FaRegThumbsDown} from '@react-icons/all-files/fa/FaRegThumbsDown';
import {FaRegThumbsUp} from '@react-icons/all-files/fa/FaRegThumbsUp';
import {useTheme} from '@theme';

import {
  OptionMenu,
  RoseModule,
  RoseModuleAsk
} from '@types';
import {ModuleLayout, ViewMode} from '@components/common/Layouts';

import AskRoseService from '@utils/apis/AskRoseService';
import {Spinner, Typography, Button} from '@components/common';
import {HelperText} from '../common/HelperText';
import {Options} from '../common/Options';
import {Input} from '../Code/Input';
import {ResultContainer} from '../Query/QueryStyle';

const COLOR = '#1f87e5';

export type ModuleProps = {
  mod: RoseModuleAsk;
  onAddModule: (mod: Partial<RoseModule>) => void;
  onRemoveModule: () => void;
  onModuleChanged: (newMod: RoseModule) => void;
  options: OptionMenu[];
  updateCurrentModKey: (modKey: string) => void;
  isReadOnly: boolean;
  autoFocus?: boolean;
  view: ViewMode;
};

export function Ask(props: ModuleProps) {
  const {
    mod,
    onModuleChanged,
    onAddModule,
    onRemoveModule,
    options,
    updateCurrentModKey,
    isReadOnly,
    autoFocus,
    view
  } = props;
  const [prompt, setPrompt] = useState(mod?.textBox || '');
  const [isMobile, setIsMobile] = useState(false);
  const [cursor, setCursor] = React.useState<number>(0);
  const isMobileRef = useRef<boolean>(isMobile);
  const theme = useTheme();
  const [resultLayoutRect, setResultLayoutRect] = useState<DOMRect>();
  const resultLayoutRef = useRef<HTMLDivElement>();

  // Either going to be set to -1, 0, 1 (bad, meh, good)
  const [feedback, setFeedback] = useState({type: null, message: null, thumb: null});

  const {data: chart, fetchStatus: chartStatus, error: chartError, refetch: fetchChart} = useQuery<any, Error, any, Array<any>>(
    ['chart', prompt],
    async ({queryKey}) => await AskRoseService.ask(queryKey[1], theme),
    {enabled: false, retry: false}
  );

  // the reference to `mod` is out of date for highcharts
  // when passed down, so we need a constantly up-to-date
  // reference to the module
  const moduleRef = useRef(mod);
  moduleRef.current = mod;

  function handleOnAddModule() {
    onAddModule(RoseModule.create({type: 'ask'}));
  }

  function updateMode() {
    if (window.innerWidth <= 480 && !isMobileRef.current) {
      setIsMobile(true);
      isMobileRef.current = true;
    }

    if (window.innerWidth > 480 && isMobileRef.current) {
      setIsMobile(false);
      isMobileRef.current = false;
    }
  }

  async function handleOnRun() {
    onModuleChanged({...mod, textBox: prompt});
    setFeedback({type: null, message: null, thumb: null});
    if (prompt) {
      await fetchChart();
    }

  }

  function onConvertToCode() {
    const roseModuleCode = RoseModule.toCode(mod, {textBox: prompt});
    onModuleChanged(roseModuleCode);
  }

  // Runs ask rose module when notebook is opened for the first time
  // useEffect(() => {
  //   if (mod?.runOnLoad) {fetchChart();}
  // }, []);

  useLayoutEffect(() => {
    if (resultLayoutRef?.current) {
      setResultLayoutRect(resultLayoutRef.current.getBoundingClientRect());
    }
  }, [resultLayoutRef]);

  useLayoutEffect(() => {

    updateMode();
    window.addEventListener('resize', () => updateMode());
    return () => {
      window.removeEventListener('resize', () => updateMode());
    };
  }, []);

  let mergedOptions: OptionMenu[] = [...options];
  if (isReadOnly || view === 'view') {
    mergedOptions = [];
  } else {
    mergedOptions.push({
      title: 'Convert to code',
      icon: <IoCode style={{marginRight: 10}} />,
      onClick: onConvertToCode
    });

    mergedOptions.push({
      title: 'Delete cell',
      icon: <IoTrash style={{marginRight: 10}} />,
      onClick: onRemoveModule
    });
  }

  async function handleOnFeedback(id: string, feedback: number) {
    try {
      const res = await AskRoseService.feedback(id, feedback);
      if (res.status === 200) {
        setFeedback({type: 'success', message: 'Thanks for the feedback!', thumb: feedback});
      } else {
        setFeedback({type: 'error', message: 'Unknown error sending feedback', thumb: null});
      }

    } catch (err) {
      setFeedback({type: 'error', message: 'Unknown error sending feedback', thumb: null});
    }
  }

  return (
    <ModuleLayout
      key={mod.key}
      width={RoseModule.getWidth(mod)}
      isReadOnly={false}
      view={'edit'}
    >
      <ModuleLayout.ToolbarTop />
      <ModuleLayout.Input id={mod.key}>
        <Input
          autoFocus={autoFocus}
          onChange={(value: string) => setPrompt(value)}
          value={prompt}
          onRun={handleOnRun}
          color={COLOR}
          currentMod={mod.key}
          updateCurrentModKey={updateCurrentModKey}
          showPlayIcon={false}
          onCursor={setCursor}
          cursor={cursor}
          onKeyDown={() => null}
          isDropdownOpen={false}
        />
      </ModuleLayout.Input>

      {view === 'view' || isReadOnly ? null :
        <ModuleLayout.Options>
          <OptionsContainer>
            <Options options={mergedOptions} color={COLOR} />
            <IconContainer onClick={handleOnAddModule}>
              <AddIcon color={COLOR} size={24} />
            </IconContainer>
          </OptionsContainer>
        </ModuleLayout.Options>
      }

      <ModuleLayout.ToolbarBottom>
        <ToolbarContainer>
          <HelperText />
        </ToolbarContainer>
      </ModuleLayout.ToolbarBottom>

      <ModuleLayout.RoseObject>
        {chartStatus === 'fetching' ?
          <Spinner size={64} width="100%" /> :
          !isEmpty(chart) ? <ResultContainer parentWidth={resultLayoutRect?.width}>
            <img src={isMobile ? chart.mobile_chart || '' : chart?.desktop_chart || '' } />
            {view !== 'view' ?
              <StyledExploreContainer>
                <StyledFeedbackContainer>
                  { feedback.message === null &&
                    <>
                      <Typography as="p" variant="primary">
                      We would love to hear your feedback...
                      </Typography>
                      <StyledIconContainer>
                        {feedback.thumb === null &&
                        <Button
                          type="text"
                          shape="circle"
                          size="large"
                          onClick={() => handleOnFeedback(chart.id, 1)}
                        >
                          <FaRegThumbsUp size={24} />
                        </Button>
                        }
                        {feedback.thumb === null &&
                        <Button
                          type="text"
                          shape="circle"
                          size="large"
                          onClick={() => handleOnFeedback(chart.id, -1)}
                        >
                          <FaRegThumbsDown size={24} color="#e74c3c"/>
                        </Button>
                        }
                      </StyledIconContainer>
                    </>
                  }
                  {feedback.message !== null &&
                  <>
                    <Typography as="p" variant="primary">
                      {feedback.message}
                    </Typography>
                    <StyledIconContainer>
                      {feedback.thumb === 1 &&
                      <FaRegThumbsUp size={24} color="#1492ff"/>
                      }
                      {feedback.thumb === -1 &&
                      <FaRegThumbsDown size={24} color="#e74c3c"/>
                      }
                    </StyledIconContainer>
                  </>
                  }
                </StyledFeedbackContainer>
                <Tooltip title={!!chart?.access ? null : 'You don\'t have access to any of the data in the chart'} placement="top">
                  <StyledExploreButton
                    disabled={!chart?.access}
                    type="ghost"
                    onClick={() => {
                      const codes = chart.data.map((obj: any) => obj.code).join(', ');
                      onAddModule(RoseModule.create({type: 'code', textBox: codes}));
                    }}>
                    Explore all codes
                  </StyledExploreButton>
                </Tooltip>
              </StyledExploreContainer> :

            // <RoseTableV2
            //   // TODO: Refactor this table stuff
            //   columns={[
            //     {
            //       Header: 'Rosecode',
            //       accessor: 'code',
            //       // eslint-disable-next-line react/display-name
            //       Cell: ({value}: any) => <SafeSpan>
            //         <p>{value}</p>
            //       </SafeSpan>
            //     },
            //     {
            //       Header: 'Organization',
            //       accessor: 'actor',
            //       // eslint-disable-next-line react/display-name
            //       Cell: ({value}: any) => <SafeSpan>
            //         <p>{value}</p>
            //       </SafeSpan>
            //     },
            //     {
            //       Header: 'Actions',
            //       accessor: 'actions',
            //       // eslint-disable-next-line react/display-name
            //       Cell: ({row}: any) => <ActionsContainer>
            //         <Tooltip title={!!chart?.access ? 'Copy to clipboard' : 'You don\'t have access to this data'} placement="top">
            //           <Button
            //             disabled={!chart?.access}
            //             type="text"
            //             onClick={() => navigator.clipboard.writeText(row.original.code)}
            //           >
            //             <AiOutlineLink size={24} />
            //           </Button>
            //         </Tooltip>
            //         <Tooltip title={!!chart?.access ? 'Open in module' : 'You don\'t have access to this data'} placement="top">
            //           <Button
            //             disabled={!chart?.access}
            //             type="text"
            //             onClick={() =>
            //               onAddModule(RoseModule.create({type: 'code', textBox: row.original.code}))
            //             }
            //           >
            //             <MdOutlineOpenInNew size={24} />
            //           </Button>
            //         </Tooltip>
            //       </ActionsContainer>
            //     }
            //   ] as Column<RoseTimeseries | any>[]}
            //   data={chart.data}
            //   shown={true}
            // />
              null}
          </ResultContainer> :
            null
        }
        {chartError?.message ?
          <StyledErrorsContainer>
            <StyledError key={chartError.message + mod.key}>
              {chartError.message}
            </StyledError>
          </StyledErrorsContainer> :
          null
        }
        {/* <TableV2 /> */}
      </ModuleLayout.RoseObject>
    </ModuleLayout>
  );
}

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45px;
  position: relative;
`;

const AddIcon = styled(IoAdd)`
  fill: ${(p: { color: string }) => p.color};

  :hover {
    cursor: pointer;
  }
`;

const IconContainer = styled.span`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledErrorsContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: red;
  word-break: break-all;
`;

const StyledError = styled.li`
  color: red;
  word-break: break-word;
  white-space: pre-wrap;
`;

const StyledExploreContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const StyledExploreButton = styled(Button)`
  border-radius: 6px;
`;

const StyledFeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;
