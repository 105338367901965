import * as React from 'react';
import {IoTrash} from '@react-icons/all-files/io5/IoTrash';
import {AiOutlineColumnWidth} from '@react-icons/all-files/ai/AiOutlineColumnWidth';
import styled from 'styled-components';
import {OptionMenu, RoseModule, RoseModuleImage} from '@types';
import {useImage} from '@service/useImage';
import {ModuleLayout, Spinner, Typography, ViewMode} from '@components/common';
import {HelperText} from './common/HelperText';
import {AddIcon, IconContainer, OptionsContainer, ResultContainer, ToolbarContainer} from './Query/QueryStyle';
import {changeWidth} from './common/ChangeWidth';
import {Options} from './common/Options';

const COLOR = '#50c878';

type ImageModuleProps = {
  code: string
  mod: RoseModuleImage
  onAddModule: (mod: Partial<RoseModule>) => void
  onRemoveModule: () => void
  onModuleChanged: (newMod: RoseModule) => void
  options: OptionMenu[]
  isReadOnly: boolean
  view: ViewMode
}

export function ImageModule({code, mod, onAddModule, onRemoveModule, options, isReadOnly, onModuleChanged, view}: ImageModuleProps) {
  const {data, isFetching, error, status, isError} = useImage(code);

  function handleOnChangeWidthClick(width: '33%' | '50%' | '66%' | '100%') {
    return changeWidth({
      width,
      mod,
      textBox: code,
      onModuleChanged
    });
  }

  function handleOnAddModule() {
    onAddModule(RoseModule.create({type: 'markdown'}));
  }

  const mergedOptions: OptionMenu[] = [
    ...options,
    {
      title: 'Delete cell',
      icon: <IoTrash style={{marginRight: 10}} />,
      onClick: onRemoveModule
    },
    {
      hidden: true,
      title: 'Width',
      icon: <AiOutlineColumnWidth style={{marginRight: 10}} />,
      options: [
        {
          title: '100%',
          onClick: handleOnChangeWidthClick('100%')
        },
        {
          title: '66%',
          onClick: handleOnChangeWidthClick('66%')
        },
        {
          title: '50%',
          onClick: handleOnChangeWidthClick('50%')
        },
        {
          title: '33%',
          onClick: handleOnChangeWidthClick('33%')
        }
      ]
    }
  ];

  /**
     * The ant design table inside the Result component is not respecting
     * the max-width and it's ruining the responsiveness in mobile.
     * That's why I am getting the ResultLayout dimensions
     * and set manually the max-width to the ResultContainer in mobile
     */
  const [resultLayoutRect, serResultLayoutRect] = React.useState<DOMRect>();
  const resultLayoutRef = React.useRef<HTMLDivElement>();
  React.useLayoutEffect(() => {
    if (resultLayoutRef?.current) {
      serResultLayoutRect(resultLayoutRef.current.getBoundingClientRect());
    }
  }, [resultLayoutRef]);

  return <ModuleLayout
    key={mod.key}
    width={RoseModule.getWidth(mod)}
    isReadOnly={isReadOnly}
    view={view}
  >
    <ModuleLayout.ToolbarTop />

    <ModuleLayout.Input id={mod.key} dataArea={isReadOnly || view === 'view' ? 'input-allowed-viewonly' : null}>
      {isFetching ?
        <Spinner size={64} width="100%" /> :
        status !== 'success' ? null : <ResultContainer parentWidth={resultLayoutRect?.width}>
          {isError &&
          <StyledErrorsContainer>
            <StyledError key={error?.message + mod.key}>
              {error?.message}
            </StyledError>

          </StyledErrorsContainer>
          }
          { !!data?.data ?
            <div className="flex flex-col items-start justify-start space-y-2">
              <img className="max-h-[500px]" src={data.data.values.data[0]} alt={code} />
              <Typography as="h5" variant="secondary" size="small">{mod.textBox}</Typography>
            </div> : null}
        </ResultContainer>
      }
    </ModuleLayout.Input>
    {isReadOnly || view === 'view' ? null :
      <ModuleLayout.Options>
        <OptionsContainer>
          <Options options={mergedOptions} color={COLOR} />
          <IconContainer onClick={handleOnAddModule}>
            <AddIcon color={COLOR} size={24} />
          </IconContainer>
        </OptionsContainer>
      </ModuleLayout.Options>
    }
  </ModuleLayout>;
}

const StyledErrorsContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: red;
  word-break: break-all;
`;

const StyledError = styled.li`
  color: red;
`;
