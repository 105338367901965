import {RoseWarning} from './RoseWarning';

export type RoseDecision = {
  type: 'decision'
  id: number
  code: string
  actorId: number
  actor: string
  license: any
  values: Values
  metas: Record<string, any>
  decision: Decision
  warnings: RoseWarning
}

type Values = {
  columns: any[]
  data: any[][]
}

export type Decision = 'yes' | 'no'

const Decision = {
  deserialize(values: Values): Decision {
    const idx = values.columns.indexOf('decision');
    const decision = values.data?.[0]?.[idx];

    if (decision === 'Yes') {return 'yes';}

    if (decision === 'No') {return 'no';}

    return 'no';
  }
};

export const RoseDecision = {
  deserialize(data: any): RoseDecision {
    return {
      type: 'decision',
      id: data.id,
      code: data.code,
      actorId: data.actor_id,
      actor: data.actor,
      license: data?.license ?? null,
      values: data.values,
      metas: data.metas,
      decision: Decision.deserialize(data.values),
      warnings: RoseWarning.deserialize(data.metas.warning)
    };
  },
  serialize(roseDecision: RoseDecision): any {
    return {
      type: roseDecision.type,
      id: roseDecision.id,
      code: roseDecision.code,
      actor_id: roseDecision.actorId,
      actor: roseDecision.actor,
      license: roseDecision.license,
      values: roseDecision.values,
      metas: roseDecision.metas
    };
  },
  isRoseDecision(data: any): boolean {
    return data?.type === 'decision';
  }
};
