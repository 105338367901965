import React, {useState, useMemo, ReactNode} from 'react';
import styled from 'styled-components';
import {Layout, Spin} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import _ from 'lodash';

import {Helmet} from 'react-helmet';
import {useQuery} from '@tanstack/react-query';
import {
  Button,
  Input,
  Table,
  Typography,
  Pagination,
  Tabs,
  AskLogin
} from '@components/common';
import SharingService from '@utils/apis/SharingService';
import HelmetWrapper from '@utils/HelmetWrapper';
import {device} from '@utils/breakpoints';
import UserContainer from '@utils/state/userContainer';
import {RoseUser} from '../../../types/RoseUser';
import Organization from './Organization';
import {Options} from './components/Options';

const {Header, Content} = Layout;

type Dataset = {
  id: number,
  code: string,
  type: string
}

export default (): JSX.Element => {
  const [inputValue, setInputValue] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [currentMenu, setCurrentMenu] = useState('all-actors');
  const [hasNextPage, setHasNextPage] = useState(true);
  const {user} = UserContainer.useContainer();

  const myDatasetsQuery = useQuery({
    queryKey: ['my-datasets', pageNumber, inputValue],
    queryFn: ({signal}) => SharingService.getOwnedNotebooks([inputValue], pageNumber, 10, signal),
    enabled: RoseUser.isRoseUser(user) && currentMenu === 'my-dataset',
    onSuccess: (data) => {
      setHasNextPage(data?.data.objects.length === 10);
    }
  });

  const allActorsQuery = useQuery({
    queryKey: ['all-actors', pageNumber, inputValue],
    queryFn: ({signal}) => SharingService.getActors(inputValue, pageNumber, 10, signal),
    enabled: RoseUser.isRoseUser(user) && currentMenu === 'all-actors',
    onSuccess: (data) => {
      setHasNextPage(data?.data.objects.length === 10);
    }
  });

  const isSearchComplete = useMemo(() => {
    if (currentMenu === 'my-dataset') {
      return myDatasetsQuery.data?.data.objects.length > 0;
    }

    return allActorsQuery.data?.data.objects.length > 0;
  }, [currentMenu, myDatasetsQuery.data, allActorsQuery.data]);

  const datasets = useMemo(() => myDatasetsQuery.data?.data.objects.map((dataset: any) => ({
    key: dataset.code,
    code: dataset.code,
    type: dataset.type,
    id: dataset.id,
    button: dataset
  })), [myDatasetsQuery.data]) || [];

  const actors = useMemo(() => allActorsQuery.data?.data.objects.map((actor: any) => ({
    key: actor.id,
    lowerCode: actor.code.toLowerCase(),
    organization: actor.code,
    datasets: actor.count,
    button: actor
  })), [allActorsQuery.data]) || [];

  const handleInputChange = (e: any) => {
    if (e.key === 'Enter') {
      setInputValue(e.target.value);
      setPageNumber(0);
    }
  };

  const handlePageChange = (page: number) => {
    setPageNumber(page - 1);
  };

  const handleChangeMenu = (key: string) => {
    if (key !== currentMenu) {
      setCurrentMenu(key);
    }

    setPageNumber(0);
    setInputValue('');
  };

  const organizationTable = [
    {
      title: 'User/Organization',
      dataIndex: 'organization',
      key: 'organization'
    },
    {
      title: 'Shared Datasets',
      dataIndex: 'datasets',
      key: 'datasets'
    },
    {
      title: '',
      dataIndex: 'button',
      key: 'button',
      align: 'right' as const,
      // eslint-disable-next-line react/display-name
      render: (button: any) =>
        <Button id="view" onClick={() => setCurrentOrganization(button)}>
          View
        </Button>

    }
  ];

  const datasetType = (dataset: Dataset) => {
    if (dataset.type === 'notebook') {
      return (
        <Button>
          <a className="share" href={`/dashboard/${dataset.code}`} rel="noreferrer" target="_blank">
            View
          </a>
        </Button>
      );
    }

    return (
      <Button>
        <a className="share" href={`/dashboard?datasets=${dataset.code}`} rel="noreferrer" target="_blank">
          View
        </a>
      </Button>
    );

  };

  const datasetColumn = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: '',
      dataIndex: 'button',
      key: 'button',
      align: 'right',
      // eslint-disable-next-line react/display-name
      render: (dataset: Dataset) => <ButtonContainer>
        {dataset?.type ? datasetType(dataset) : null}
        <Options refetchTable={() => myDatasetsQuery.refetch()} dataset={dataset} />
      </ButtonContainer>
    }
  ];

  if (currentOrganization) {
    return (
      <Organization
        organization={currentOrganization}
        goBack={() => setCurrentOrganization(null)}
      />
    );
  }

  const resultTable = (columns: any, datasource: any) => {
    if (allActorsQuery.data?.data.objects.length === 0) {
      if (inputValue.trim().length === 0) {
        return (
          <div>
            <p>Type in a key word to start search</p>
          </div>
        );
      } else {
        return(
          <StyledErrorsContainer>
            <StyledError>No user/organization found</StyledError>
          </StyledErrorsContainer>
        )
      }
    } else if (myDatasetsQuery.data?.data.objects.length === 0) {
      return (
        <StyledErrorsContainer>
          <StyledError>No dataset found</StyledError>
        </StyledErrorsContainer>
      );
    }

    const totalItems = currentMenu === 'my-dataset'
      ? myDatasetsQuery.data?.data.length
      : allActorsQuery.data?.data.length;
    
    return (
      isSearchComplete ?
        <>
          <PageInfo>
            <StyledPagination
              onChange={handlePageChange}
              total={totalItems !== null && totalItems !== undefined ? totalItems : 1000000}  // A large number to keep navigation active
              current={pageNumber + 1}
              showSizeChanger={false}
              disabled={!hasNextPage}
            />
          </PageInfo>
          {isSearchComplete ? <Table
            key="all-users"
            columns={columns}
            dataSource={datasource}
            pagination={false}
            scroll={{x: 300}}
            tableLayout="fixed"
          /> :
            <StyledSpin size="large" />}
        </> :
        <StyledSpin size="large" />
    );

  }
    ;

  const inputBox = () =>
    <Input
      allowClear
      variant="alternative"
      size="large"
      prefix={<SearchOutlined />}
      placeholder="Search"
      onKeyDown={handleInputChange}
      id="input-search"
      key={currentMenu}
      style={{ width: "250px" }}
    />;

  if (RoseUser.isGuest(user)) {
    return (
      <>
        {Helmet}
        <AskLogin />
      </>
    );
  }

  return (
    <StyledLayout>
      <HelmetWrapper title="Sharing" description="Sharing Center" />
      <StyledHeader>
        <Typography variant="primary" as="h1" size="x-large" id="page-title">
          Sharing Center
        </Typography>
      </StyledHeader>
      <StyledContent>
        <StyledTabs activeKey={currentMenu} onChange={handleChangeMenu} tabBarExtraContent={inputBox()}>
          <Tabs.TabPane tab="Share Dataset" key="all-actors">
            {resultTable(organizationTable, actors)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Created by Me" key="my-dataset">
            {resultTable(datasetColumn, datasets)}
          </Tabs.TabPane>
        </StyledTabs>
      </StyledContent>
    </StyledLayout>
  );
};

const StyledPagination = styled(Pagination)`
  .ant-pagination-item {
    display: none;
  }  
  
  .ant-pagination-jump-next {
    display: none;
  }
`;

const StyledLayout = styled(Layout)`
  background-color: inherit;
  margin: 0 20px;
`;

const StyledHeader = styled(Header)`
  background-color: inherit;
  text-align: center;
`;

const StyledContent = styled(Content)`
  background-color: inherit;
`;

const PageInfo = styled.div<{ children?: Array<ReactNode> | ReactNode }>`
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledSpin = styled(Spin)`
  width: 100%;
  padding-top: 100px;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    display: inline;

  .ant-tabs-nav-list {
    margin-bottom: 10px;
  }

  }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

const StyledErrorsContainer = styled.div`
  margin: 0;
  padding: 0;
  color: red;
  word-break: break-all;
`;

const StyledError = styled.p`
  color: red;
  font-size: 16px;
`;
