import React from "react";
import {PopupButton} from '@typeform/embed-react';

const CustomersSection = () => {
  return (
    <div className="container mx-auto my-40 lg:my-52 px-3 md:px-10 text-white">
      <div className="my-14 flex gap-10 items-center justify-between flex-col md:flex-row">
        <div className="max-w-2xl flex flex-col gap-5 items-center md:items-start order-2 md:order-1 mt-10 md:mt-0">
          <div className="text-primary font-bold text-center md:text-left">We serve our customers</div>
          <div className="text-[32px] lg:text-[36px] font-bold text-center md:text-left">
            Purpose-Built Solutions for Your Organization
          </div>
          <div className="text-[20px] md:text-[24px] font-bold text-center md:text-left">
            Harness Rose AI’s deep industry expertise and proven success with
            solutions tailor-made for your organization.
          </div>
          <div className="text-[#D9D9D9] text-center md:text-left">
            By partnering with us, you gain access to a wealth of industry
            expertise, ensuring practical and effective approaches to your
            data-driven challenges. <br /> <br /> Our focus is on delivering
            robust, reliable technology that enhances your decision-making and
            drives your organization towards sustainable success.
          </div>
        </div>
        <div className="w-full md:w-0 grow max-w-[420px] order-1 md:order-2">
          <img
            src="/img/customer-content.svg"
            alt="customer content"
            className="block w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomersSection;
