import * as React from 'react';
import styled from 'styled-components';

import {device} from '@utils/breakpoints';
import {ThemeVarNames} from '@theme';

export type TypographyProps = React.PropsWithChildren<{
  as?: TextElements
  variant?: Variant
  size?: Size
  color?: string
}> &
  React.HTMLAttributes<TextElements>

const StyledElement = styled.div<{variant?: Variant, size?: Size}>`
  color: ${(p) => p.color ? p.color : variantsMap[p.variant]};
  font-size: ${(p) => sizesMap[p.size]};

  &.xxx-large {
    ${device.desktop} {
      font-size: 40px;
    }
  }
`;

export function Typography(props: TypographyProps): JSX.Element {
  const {
    as = 'span',
    size = 'medium',
    variant = 'inherit',
    color,
    children,
    className
  } = props;

  return (
    <StyledElement
      as={as}
      size={size}
      variant={variant}
      color={color}
      className={`${className} ${size}`}
    >
      {children}
    </StyledElement>
  );
}

type TextElements = keyof Pick<
  JSX.IntrinsicElements,
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'span'
  | 'p'
  | 'blockquote'
  | 'caption'
  | 'code'
  | 'label'
  | 'kbd'
  | 'summary'
>

type Variant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'warning'
  | 'accent'
  | 'active'
  | 'inherit'

type Size = 'small' | 'medium' | 'large' | 'x-large' | 'xx-large' | 'xxx-large'

const sizesMap: { [key in Size]: string } = {
  small: '0.8rem',
  medium: '1rem',
  large: '1.2rem',
  'x-large': '1.4rem',
  'xx-large': '1.6rem',
  'xxx-large': '2rem'
};

const variantsMap: { [key in Variant]: string } = {
  primary: `var(${ThemeVarNames.PrimaryText})`,
  secondary: `var(${ThemeVarNames.SecondaryText})`,
  tertiary: `var(${ThemeVarNames.TertiaryText})`,
  warning: `var(${ThemeVarNames.Warning})`,
  accent: 'yellow',
  active: 'cyan',
  inherit: 'inherit'
};
