import Highcharts from 'highcharts/highstock';

import {Theme, ThemeVarNames} from '@theme';

export function setHighchartsTheme(theme: Theme): Highcharts.Options {
  const themeValuesMap = Theme.themesMap[theme];

  return {
    colors: [
      themeValuesMap[ThemeVarNames.Timeseries1],
      themeValuesMap[ThemeVarNames.Timeseries2],
      themeValuesMap[ThemeVarNames.Timeseries3],
      themeValuesMap[ThemeVarNames.Timeseries4],
      themeValuesMap[ThemeVarNames.Timeseries5],
      themeValuesMap[ThemeVarNames.Timeseries6],
      themeValuesMap[ThemeVarNames.Timeseries7],
      themeValuesMap[ThemeVarNames.Timeseries8],
      themeValuesMap[ThemeVarNames.Timeseries9]
    ],
    chart: {
      backgroundColor: themeValuesMap[ThemeVarNames.PrimaryBg]
    },
    title: {
      style: {
        color: themeValuesMap[ThemeVarNames.PrimaryText]
      }
    },
    subtitle: {
      style: {
        color: themeValuesMap[ThemeVarNames.SecondaryText]
      }
    },
    legend: {
      itemStyle: {
        color: themeValuesMap[ThemeVarNames.SecondaryText]
      },
      itemHoverStyle: {
        color: themeValuesMap[ThemeVarNames.HoverText]
      }
    },
    navigation: {
      menuStyle: {
        backgroundColor: themeValuesMap[ThemeVarNames.SecondaryBg]
      },
      menuItemStyle: {
        color: themeValuesMap[ThemeVarNames.SecondaryText]
      },
      buttonOptions: {
        theme: {
          fill: themeValuesMap[ThemeVarNames.PrimaryBg],
          stroke: themeValuesMap[ThemeVarNames.Border]
        }
      }
    },
    rangeSelector: {
      inputStyle: {
        backgroundColor: themeValuesMap[ThemeVarNames.InputPrimary],
        color: themeValuesMap[ThemeVarNames.PrimaryText]
      },
      labelStyle: {
        color: themeValuesMap[ThemeVarNames.SecondaryText]
      },
      buttonTheme: {
        fill: themeValuesMap[ThemeVarNames.SecondaryBg],
        stroke: themeValuesMap[ThemeVarNames.Border],
        style: {
          color: themeValuesMap[ThemeVarNames.SecondaryText]
        }
      },
      inputBoxBorderColor: themeValuesMap[ThemeVarNames.Border]
    },
    yAxis: {
      labels: {
        style: {
          color: themeValuesMap[ThemeVarNames.TimeseriesNeutral]
        }
      }
    },
    xAxis: {
      tickColor: themeValuesMap[ThemeVarNames.PrimaryText],
      labels: {
        style: {
          color: themeValuesMap[ThemeVarNames.PrimaryText]
        }
      }
    }
  };
}
