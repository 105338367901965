import * as React from 'react';
import styled from 'styled-components';

import {Typography} from '@components/common';
import {ThemeVarNames} from '@theme';

export function HelperText(): JSX.Element {
  return (
    <Typography variant="tertiary" size="small">
      {'Press '}
      <HighlightText>
        <kbd>SHIFT</kbd>
        <span>{' + '}</span>
        <kbd>ENTER</kbd>
      </HighlightText>
      {' to execute'}
    </Typography>
  );
}

const HighlightText = styled.span`
  background-color: var(${ThemeVarNames.SecondaryBg});
  color: var(${ThemeVarNames.TertiaryText});
  padding: 2px;
  border-radius: 4px;
  border: var(${ThemeVarNames.Border});
`;
