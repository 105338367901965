import {Empty as AntdEmpty} from 'antd';
import styled from 'styled-components';

import {ThemeVarNames} from '@theme';

const StyledEmpty = styled(AntdEmpty)`
  .ant-empty-image {
    height: auto;
  }

  .ant-empty-image svg {
    color: var(${ThemeVarNames.TertiaryText});
  }

  .ant-empty-description {
    color: var(${ThemeVarNames.PrimaryText});
  }
`;

export const Empty = StyledEmpty;
