import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { Series, YAxisOptions } from "highcharts";
import { fromArrayToMapKeyByIndex, renameKey, transformValue } from "@helpers";
import { Connection, RoseObjectType } from "@types";
import { Percentage } from "./helpers/Units";

export type RoseModuleType = "code" | "markdown" | "query" | "ask" | "image";

export type RoseModule =
  | RoseModuleCode
  | RoseModuleMarkdown
  | RoseModuleQuery
  | RoseModuleAsk
  | RoseModuleImage;

export type RoseModuleBase = {
  key: string;
  textBox: string;
  settings: any;
  runOnLoad?: boolean;
  createdAt: number;
};

export type RoseModuleCode = RoseModuleBase & {
  type: "code";
  modulesettings: RoseModuleCodeSettings;
};

export type RoseModuleMarkdown = RoseModuleBase & {
  type: "markdown";
  modulesettings: RoseModuleCodeSettings;
};

export type RoseModuleImage = RoseModuleBase & {
  type: "image";
  modulesettings: RoseModuleCodeSettings;
};

export type RoseModuleQuery = RoseModuleBase & {
  type: "query";
  modulesettings: RoseModuleCodeSettings;
};

export type RoseModuleAsk = RoseModuleBase & {
  type: "ask";
  modulesettings: RoseModuleCodeSettings;
};

type Value = { columns: string[]; data: any[][] };

export const RoseModule = {
  deserialize({ columns, data }: Value): RoseModule[] {
    const toObject = (arr: any[]): Record<any, any> =>
      arr.reduce(
        (
          outObj: Record<any, any>,
          current: any,
          currentIndex: number
        ): Record<any, any> => ({
          ...outObj,
          [keysMap[currentIndex]]: current,
        }),
        {} // initial value
      );
    const keysMap = fromArrayToMapKeyByIndex(columns);
    return data
      .map(toObject)
      .map((d) => ({
        ...d,
        runOnLoad: true,
        createdAt: Date.now(),
      }))
      .map((d) =>
        renameKey(d, {
          /* eslint-disable camelcase */
          text_box: "textBox",
        })
      )
      .map((d, index) => ({
        ...transformValue(d, {
          settings: (s: any) => JSON.parse(s),
          modulesettings: (s: any) =>
            RoseModuleCodeSettings.deserialize(JSON.parse(s)),
        }),
        key: d?.key ?? `${d.textBox}-${index}`,
      })) as RoseModule[];
  },
  serialize(modules: RoseModule[]): Array<Record<string, any>> {
    return modules.map((mod) => ({
      type: mod.type,
      key: mod.key,
      settings: JSON.stringify(mod.settings),
      modulesettings: JSON.stringify(
        RoseModuleCodeSettings.serialize(mod.modulesettings)
      ),
      /* eslint-disable camelcase */
      text_box: mod.textBox,
      run_on_load: mod.runOnLoad,
    }));
  },
  create(
    data?: Partial<RoseModule>,
    moduleSettings: Record<string, any> = {}
  ): RoseModule {
    return {
      type: data?.type ?? "code",
      key: data?.key ?? uuidv4(),
      textBox: data?.textBox ?? "",
      settings: data?.settings ?? [], // initialize empty array for settings
      modulesettings: {
        width: "100%",
        ...data?.modulesettings,
        ...moduleSettings,
      },
      runOnLoad: data?.runOnLoad ?? false,
      createdAt: Date.now(),
    };
  },
  remove(list: RoseModule[], modToRemove: RoseModule): RoseModule[] {
    return list.filter((m) => m.key !== modToRemove.key);
  },
  replaceModule(list: RoseModule[], modToReplace: RoseModule): RoseModule[] {
    return list.map((m) => (m.key === modToReplace.key ? modToReplace : m));
  },
  update(mod: RoseModule, fieldsToUpdate: Partial<RoseModule>): RoseModule {
    return {
      ...mod,
      ...fieldsToUpdate,
      modulesettings: {
        ...mod.modulesettings,
        ...fieldsToUpdate.modulesettings,
      },
    };
  },
  getWidth(mod: RoseModule, defaultValue: Percentage = "100%"): Percentage {
    const percentage = Percentage.toPercentage(mod?.modulesettings?.width);
    switch (true) {
      case /^33/.test(percentage):
        return "33%";
      case /^50/.test(percentage):
        return "50%";
      case /^66/.test(percentage):
        return "66%";
      default:
        return defaultValue;
    }
  },
  toMarkdown(
    roseModule: RoseModuleCode,
    values: Partial<RoseModuleMarkdown> = {}
  ): RoseModuleMarkdown {
    return {
      ...roseModule,
      ...values,
      type: "markdown",
    };
  },
  toCode(
    roseModule: RoseModule,
    values: Partial<RoseModuleCode> = {}
  ): RoseModuleCode {
    return {
      ...roseModule,
      ...values,
      type: "code",
    };
  },
  toAsk(
    roseModule: RoseModule,
    values: Partial<RoseModuleAsk> = {}
  ): RoseModuleAsk {
    return {
      ...roseModule,
      ...values,
      type: "ask",
    };
  },
  insert(mods: RoseModule[], toInsert: RoseModule, index = 0): RoseModule[] {
    return [...mods.slice(0, index + 1), toInsert, ...mods.slice(index + 1)];
  },
};

export type RoseModuleChartSettings = {
  timeSeries?: Record<string, ChartSettings>;
  charts?: Record<string, ChartSettings>;
};

export type RoseModuleCodeSettings = {
  width?: string | number;
  charts?: RoseModuleChartSettings;
  title?: string;
  units?: any;
  connection?: Connection;
  objects?: string[];
};

export const RoseModuleCodeSettings = {
  deserialize(data: Record<string, any>): RoseModuleCodeSettings {
    return {
      width: data?.width,
      charts:
        _.reduce<ChartSettings, Record<string, ChartSettings>>(
          data?.charts,
          (result, value, key) => ({
            ...result,
            [key]: ChartSettings.deserialize(value),
          }),
          {}
        ) || {},
      connection: Connection.deserialize(data?.connection),

      // remove this later
      title: data?.title,
      units: data?.units,
      objects: data?.objects,
    };
  },
  serialize(settings: RoseModuleCodeSettings): Record<string, unknown> {
    return {
      width: settings?.width || "",
      charts:
        _.reduce<Record<string, unknown>, ChartSettings>(
          settings?.charts,
          (result, value, key) => ({
            ...result,
            [key]: ChartSettings.serialize(value),
          }),
          {}
        ) || {},
      connection: Connection.serialize(settings?.connection),
      units: settings?.units,
      objects: settings?.objects,
    };
  },
  composeId(codes: string[]): string {
    return codes.join("-");
  },
  getChartSettingsById(
    id: string,
    chartSettingsMap: Record<string, ChartSettings>
  ): ChartSettings | undefined {
    return chartSettingsMap?.[id];
  },
};

export type ChartSettings = {
  title?: {
    text?: string;
    align?: "left" | "center" | "right";
    verticalAlign?: "top" | "middle" | "bottom"; // vertical_align
  };
  range?: number;
  xAxis?: {
    // x_axis
    min: number | null;
    max: number | null;
  };
  yAxis?:
    | {
        title?: {
          text?: string;
          style?: {
            color?: string;
          };
        };
        series?: Array<Series>;
        opposite?: boolean;
        min: number | null;
        max: number | null;
      }[]
    | YAxisOptions[];
  source?: string;
  datasets?: Record<string, DatasetsSettings> | any;
  type?: RoseObjectType;
  watermark?: boolean;
};

export const ChartSettings = {
  deserialize(data: Record<string, any>): ChartSettings {
    return {
      title: {
        text: data?.title?.text,
        align: data?.align ?? "center",
        verticalAlign: data?.vertical_align ?? "top",
      },
      range: data?.range,
      xAxis: {
        min: data?.x_axis?.min ?? null,
        max: data?.x_axis?.max ?? null,
      },
      yAxis: data?.y_axis?.length ? data.y_axis : [],
      source: data?.source,
      datasets: _.mapValues(data.datasets, DatasetsSettings.deserialize),
      watermark: data?.watermark,
    };
  },
  serialize(chartSettings: ChartSettings): Record<string, unknown> {
    return {
      title: {
        text: chartSettings?.title?.text ?? "",
        align: chartSettings?.title?.align ?? "center",
        vertical_align: chartSettings?.title?.verticalAlign ?? "top",
      },
      range: chartSettings?.range,
      x_axis: chartSettings?.xAxis,
      y_axis: chartSettings?.yAxis,
      source: chartSettings?.source,
      datasets:
        _.mapValues(chartSettings.datasets, DatasetsSettings.serialize) || {},
      watermark: chartSettings?.watermark,
    };
  },

  // createaChartSettings(btc-eth, [btc, eth])
  create(codes: string[], type?: RoseObjectType): ChartSettings {
    console.log("create", codes, type);
    const createDatasets = (codes: string[]) =>
      _.reduce<string, Record<string, DatasetsSettings>>(
        codes,
        (result, code, index) => ({
          ...result,
          [code]: DatasetsSettings.create(code, index),
        }),
        {}
      );

    return {
      title: {
        text: "",
        align: "center",
        verticalAlign: "top",
      },
      range: undefined,
      xAxis: {
        min: null,
        max: null,
      },
      yAxis: [],
      source: "",
      datasets: createDatasets(codes),
      type: type ?? "timeseries-group",
      watermark: true,

      // datasets: _.reduce<string, Record<string, DatasetsSettings>>(codes, (result, code, index) => ({
      //   ...result,
      //   [code]: DatasetsSettings.create(code, index)
      // }), {})
    };
  },
};

export type DatasetsSettings = {
  name?: string;
  color?: string;
  units?: string;
};

const initialColors = [
  "#3B7FC2",
  "#D94249",
  "#E2B923",
  "#04A225",
  "#F56421",
  "#9F654F",
  "#e67e23",
  "#95a5a6",
  "#19bc9c",
];

export const DatasetsSettings = {
  deserialize(data: Record<string, any>): DatasetsSettings {
    return {
      name: data?.name,
      color: data?.color,
      units: data?.units,
    };
  },
  serialize(datasetSettings: DatasetsSettings): Record<string, any> {
    return {
      name: datasetSettings?.name,
      color: datasetSettings?.color,
      units: datasetSettings?.units,
    };
  },
  create(code: string, index: number): DatasetsSettings {
    return {
      name: code,

      // modulo operator to cycle through colors
      color:
        initialColors[
          index -
            initialColors.length * Math.floor(index / initialColors.length)
        ],
      units: undefined,
    };
  },
};
