import React, {useState} from 'react';
import styled from 'styled-components';
import {message} from 'antd';
import {Modal, Form, Input, Select, SelectOption, InputTextArea, Alert} from '@components/common';
import {useConnectionsAdd} from '@service/useConnections';

type ConnectionFormProps = {
    visible: boolean;
    onClose: () => void
}

export const ConnectionModal = ({visible, onClose}: ConnectionFormProps): JSX.Element => {
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const mutation = useConnectionsAdd();

  const checkJson = (value: string) => {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  };

  const onSubmit = () => {
    setError(null);
    const {code, dbType, connectionString} = form.getFieldsValue();
    const modifiedConnection = checkJson(connectionString);

    mutation.mutate(
      {
        code,
        dbType,
        connectionString: modifiedConnection
      },
      {
        onError(err) {
          const responseData: any = err.response?.data;
          setError(responseData?.message);
        },
        onSuccess() {
          message.success('Successfully added new connection', 5);
          onClose();
        }
      }
    );
  };

  const databaseConnection = ['postgres', 'mysql', 'bigquery', 'redshift'];

  return (
    <Modal
      title="Add Connection"
      visible={visible}
      onCancel={() => {
        onClose();
        form.resetFields();
        setError(null);
      }}
      onOk={() => {
        onSubmit();
      }}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item name="code" label="Connection Name" rules={[{required: true}]}>
          <Input
            allowClear
            type="text"
            size="middle"
            name="code"
          />
        </Form.Item>
        <Form.Item name="dbType" label="Database Type" rules={[{required: true}]}>
          <Select>
            {databaseConnection.map((db) =>
              <SelectOption key={db} value={db}>
                {db}
              </SelectOption>
            )}
          </Select>
        </Form.Item>
        <Form.Item name="connectionString" label="Connection String/Object" rules={[{required: true}]}>
          <InputTextArea
            autoSize={{minRows: 6, maxRows: 20}}
          />
        </Form.Item>
        {error ? <StyledAlert message={error} type="error" /> : null}
      </Form>
    </Modal>
  );
};

const StyledAlert = styled(Alert)`
  text-align: center;
`;
