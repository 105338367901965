import { v4 as uuidv4 } from 'uuid';

import { cleanRoseCode } from '@helpers';
import { RoseWarning } from './RoseWarning';

export type RoseTimeseriesGroup = {
  type: 'timeseries-group'
  key: string
  data: RoseTimeseries[]
  warnings: Array<{ code: string; value: RoseWarning }>
}

export type RoseTimeseries = {
  type: 'timeseries'
  id: number
  code: string
  actor: string
  actorId: number
  values: Record<string, number>
  metas: {
    popularity: number
    lastUpdated: string
    observationStart: string
    title: string
    seasonalAdjustmentShort: string
    seasonalAdjustment: string
    notes: string
    observationEnd: string
    realtimeEnd: string
    frequency: string
    unitsShort: string
    units: string
    realtimeStart: string
    id: string
    frequencyShort: string
    lastUpdatedAt: string
    lastUpdatedBy: string
    length: number
    tree: RoseTimeseriesTree
  }
  name?: string
  warnings: RoseWarning
}

export type RoseTimeseriesTree = {
  type: 'timeseries'
  code: string
  isLogic: boolean
  name: string
  id: number
  actor: string
  actorId?: number
  lastUpdatedAt: string
  lastUpdatedBy: string
  value: number
  date: string
}

export const RoseTimeseries = {
  deserialize(data: any): RoseTimeseries {
    return {
      type: 'timeseries',
      id: data.id,
      code: cleanRoseCode(data.code),
      actor: data.actor,
      actorId: data.actor_id,
      values: data.values,
      metas: data.metas,
      warnings: RoseWarning.deserialize(data.metas.warning)
    };
  },
  isTimeseries(data: any): data is RoseTimeseries {
    return (data as RoseTimeseries)?.type === 'timeseries';
  }
};

export const RoseTimeseriesGroup = {
  isRoseTimeseriesGroup(data: any): data is RoseTimeseriesGroup {
    return data?.type === 'timeseries-group';
  },
  add(
    group: RoseTimeseriesGroup,
    timeseries: RoseTimeseries
  ): RoseTimeseriesGroup {
    const groupCopy = { ...group };
    groupCopy.data.push(timeseries);
    groupCopy.warnings.push({
      code: timeseries.code,
      value: timeseries.warnings
    });
    return groupCopy;
  },
  create(timeseries: RoseTimeseries[] = []): RoseTimeseriesGroup {
    return {
      type: 'timeseries-group',
      key: uuidv4(),
      data: timeseries,
      warnings: timeseries.map((timeseriesSingular) => ({
        code: timeseriesSingular.code,
        value: timeseriesSingular.warnings
      }))
    };
  }
};
