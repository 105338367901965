import * as React from 'react';

import UserContainer from '@utils/state/userContainer';
import {RoseUserSettings} from '@types';
import {Theme} from './types';

/**
 * this should be only used to read the theme but not update it because
 * this function does not update the theme in the backend
 * when setTheme is called, it only updates in memory
 */
export function useTheme(): Theme {
  const {user} = UserContainer.useContainer();
  const [theme, setTheme] = React.useState(() => RoseUserSettings.getTheme(user?.settings));

  React.useEffect(
    () => {
      setTheme(RoseUserSettings.getTheme(user?.settings));
    },
    [user?.settings]
  );
  return theme;
}
