/* eslint-disable security/detect-object-injection */
import {RoseObject, RoseMap} from '@types';

export type InputState = {
  value: string;
  cursor: number;
}

type FunctionParamType =
  | 'int'
  | 'float'
  | 'date'
  | 'rosecode'
  | 'column'
  | 'str'
  | 'column_in_second_map';
export type FunctionParam = {
  name: string;
  type: FunctionParamType[];
  unlimited?: boolean;
  optional?: string | number;
  enum?: string[];
};

export interface FunctionMeta {
  name: string;
  description: string;
  parameters: FunctionParam[];
  worksOn: 'timeseries' | 'all' | 'map';
}

export interface FunctionContext extends FunctionMeta {
  paramIndex: number;
}

export type FunctionMap = {
  [functionKey: string]: FunctionMeta;
};

export type FunctionCategory = [title: string, items: string[]];

export enum StopChars {
  Colon = ':',
  Comma = ',',
  LeftParens = '(',
  RightParens = ')',
}

export type Match = true | FunctionContext | FunctionMeta[];

export const CATEGORIES: FunctionCategory[] = [
  [
    'Most Popular Functions',
    [
      'ts',
      'filter',
      'resample',
      'returns',
      'yoy',
      'transpose',
      'since',
      'until'
    ]
  ],
  [
    'Charting',
    [
      'ts',
      'scatterplot',
      'barchart',
      'areachart',
      'piechart',
      'bubblechart',
      'heatmap'
    ]
  ],
  [
    'Find Data',
    [
      'search',
      'filter',
      'sort',
      'firstoccurence',
      'exists'
    ]
  ],
  [
    'Column Manipulation',
    [
      'pickcolumns',
      'dropcolumns',
      'transpose',
      'pivot',
      'insertcolumn',
      'renamecolumns',
      'replace'
    ]
  ],
  ['Combine', ['splice', 'stitch', 'join', 'merge', 'concatenate', 'append']],
  [
    'Timeseries Manipulation',
    [
      'since',
      'until',
      'resample',
      'eom',
      'seasonalityfactor',
      'sa',
      'tomap',
      'flip',
      'ewm',
      'last',
      'max',
      'min',
      'offset',
      'rescaletolastval',
      'revol'
    ]
  ],
  [
    'Filling in Missing Data',
    ['interpolate', 'bfill', 'ffill', 'filldown', 'filltoday', 'optimaloffset']
  ],
  [
    'Cleaning',
    [
      'renamecolumns',
      'replace',
      'sa',
      'todate',
      'resample',
      'drop',
      'dropna',
      'dropduplicates',
      'cap',
      'floor',
      'max',
      'min'
    ]
  ],
  ['Rate of Change', ['change', 'returns', 'yoy', 'dod', 'mom', 'qoq']],
  [
    'Arithmetic',
    [
      'pow',
      'add',
      'mult',
      'div',
      'sub',
      'mean',
      'round',
      'inv',
      'log',
      'cumsum',
      'cum',
      'abs'
    ]
  ],
  [
    'Stats',
    ['std', 'z', 'replacewithz', 'corr', 'rollcorr', 'skew', 'rollskew']
  ],
  [
    'Financial',
    [
      'annualizedreturn',
      'annualizedvol',
      'divergence',
      'duration',
      'optionpricer',
      'trade',
      'sharpe',
      'sortino',
      'signal',
      'returns',
      'return'
    ]
  ],
  [
    'Smoothing',
    ['ma', 'kalman', 'bucket', 'resample', 'rollz', 'rollskew', 'rollstd']
  ]
];

const TYPE_SAMPLE_MAP = {
  int: '100',
  float: '1.0',
  date: 'yyyy.mm.dd',
  rosecode: 'fred.wtd',
  column: 'some column',
  str: 'value',
  // eslint-disable-next-line camelcase
  column_in_second_map: 'some column'
};

export const getTypeExample = (type: FunctionParamType): string => TYPE_SAMPLE_MAP[type] ? TYPE_SAMPLE_MAP[type] : '';

export const getFunctionsByCategory = (
  category: string,
  functionMap: FunctionMap
): FunctionMeta[] => {
  const functionList =
    CATEGORIES.find(([title]) => title === category)[1] || [];
  return functionList.map((func) => functionMap[func]);
};

export const toFunctionMap = (roseObjects: RoseObject[]): FunctionMap => {
  if (!roseObjects || !roseObjects[0]) {
    return {};
  }

  const map = roseObjects[0] as RoseMap;

  const functions = map.metas.functions as Record<
    string,
    // eslint-disable-next-line camelcase
    {description: string; parameters: FunctionParam[]; works_on: string}
  >;
  return Object.keys(functions).reduce(
    (map, func) => ({
      ...map,
      [func]: {
        name: func,
        description: functions[func].description,
        parameters: functions[func].parameters,
        worksOn: functions[func].works_on
      } as FunctionMeta
    }),
    {}
  );
};

const STOP_CHARS = Object.values(StopChars) as string[];

const getStopIndex = (value: string, startIndex: number): number => {
  const index = value
    .slice(startIndex + 1)
    .split('')
    .findIndex((c) => STOP_CHARS.includes(c));

  return index === -1 ? value.length : startIndex + index + 1;
};

const hasPotentialContext = (value: string, cursorIndex: number): boolean =>
  value && value[cursorIndex] !== StopChars.RightParens;

const isDirectMatch = (matches: FunctionMeta[], chunk: string): boolean =>
  matches.length > 0 && matches[0].name === chunk;

export const getFunctionContext = (
  value: string,
  cursor: number,
  functionMap: FunctionMap
): undefined | Match => {
  const CURSOR_INDEX = cursor - 1;
  if (!hasPotentialContext(value, CURSOR_INDEX)) {
    return undefined;
  }

  const COLON_INDEX = value
    .slice(0, cursor)
    .lastIndexOf(StopChars.Colon);

  if (COLON_INDEX === -1) {
    return undefined;
  }

  const STOP_INDEX = getStopIndex(value, COLON_INDEX);
  const CHUNK = value.slice(COLON_INDEX + 1, STOP_INDEX).replace(/[^a-zA-Z]/g, '');
  if (CHUNK === '') {
    return true;
  }

  const MATCHES = Object.keys(functionMap).reduce((matches, func) =>
    func.indexOf(CHUNK) === 0 ? matches.concat(functionMap[func]) : matches,
    [] as FunctionMeta[]
  );
  if (COLON_INDEX < CURSOR_INDEX && CURSOR_INDEX < STOP_INDEX) {
    return MATCHES;
  }

  if (value[STOP_INDEX] === StopChars.LeftParens && CURSOR_INDEX >= STOP_INDEX) {
    if (isDirectMatch(MATCHES, CHUNK)) {
      return {
        ...MATCHES[0],
        paramIndex: value
          .slice(COLON_INDEX + 1, CURSOR_INDEX + 1)
          .split('')
          .reduce((sum, char) => char === StopChars.Comma ? sum + 1 : sum, 0)
      };
    } else if (MATCHES.length !== 1) {
      return {
        name: '',
        description: '',
        parameters: [],
        worksOn: 'all',
        paramIndex: -1
      };
    }
  }
};

export const shouldAddParenthesis = (functionMeta: FunctionMeta | undefined): boolean => {
  if (!functionMeta) {
    return false;
  }

  return functionMeta.parameters.length > 0;
};

export type ReplacementOptions = {
  addParenthesis: boolean
}

const defaultReplacementOptions: ReplacementOptions = {
  addParenthesis: true
};

export const getReplacement = (
  value: string,
  cursor: number,
  functionName: string,
  options: ReplacementOptions = defaultReplacementOptions
): InputState => {
  const COLON_INDEX = value
    .slice(0, cursor)
    .lastIndexOf(StopChars.Colon);

  const STOP_INDEX = getStopIndex(value, COLON_INDEX);
  const LEFT_VALUE_CHUNK = `${value.substring(0, COLON_INDEX + 1)}${functionName}`;

  if (options.addParenthesis) {
    return {
      value: `${LEFT_VALUE_CHUNK}${value[STOP_INDEX] === StopChars.LeftParens ? '' : '()'}${value.substring(cursor)}`,
      cursor: LEFT_VALUE_CHUNK.length + 1
    };
  }

  return {
    value: `${LEFT_VALUE_CHUNK}${value.substring(cursor)}`,
    cursor: LEFT_VALUE_CHUNK.length
  };
};

