/* eslint-disable react/prop-types */
import React, {useMemo, useRef, useEffect, useState} from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatMap from 'highcharts/modules/heatmap';
import {find, merge} from 'lodash';

import {ChartSettings, RoseChart, RoseModule} from '@types';
import {composeChartOptions} from '@utils/helpers/display/nontimeseries';
import {setHighchartsTheme} from '@utils/helpers/display/highchartsTheme';
import {useTheme} from '@theme';
import {ViewMode} from '@components/common';
import {ChartEditor} from './ChartEditor';

HighchartsMore(Highcharts);
HighchartsHeatMap(Highcharts);

export type ChartProps = {
  roseObject: RoseChart;
  chartSettings: ChartSettings;
  onChartSettingsChanged: (value: ChartSettings) => void;
  mod: RoseModule;
  view: ViewMode
};

export function Chart(props: ChartProps): JSX.Element {
  const {
    roseObject,
    chartSettings,
    onChartSettingsChanged,
    mod,
    view
  } = props;

  const theme = useTheme();
  const chartSettingsRef = useRef(chartSettings);
  chartSettingsRef.current = chartSettings;
  const getChartSettings = () => chartSettingsRef.current;
  const [highchartOptions, setHighchartOptions] = useState<Highcharts.Options>(() => composeChartOptions({
    roseObject,
    theme,
    chartSettings,
    getChartSettings,
    onChartSettingsChanged,
    modSettings: mod?.modulesettings,
    isViewMode: view === 'view'
  }));

  const handleOnChartCustomizationChange = (chartSettings: ChartSettings) => {
    onChartSettingsChanged(chartSettings);

    let widthFactor = 1;
    switch (mod.modulesettings.width) {
    case '66%':
      widthFactor = 0.66;
      break;
    case '50%':
      widthFactor = 0.5;
      break;
    case '33%':
      widthFactor = 0.33;
    }

    const titleLength = chartSettings.title?.text?.length;
    let titleFontSize = 28;
    let legendFontSize = 20;
    if (titleLength > 45 * widthFactor) {
      titleFontSize = 18;
      legendFontSize = 16;
    } else if (titleLength > 30 * widthFactor) {
      titleFontSize = 23;
      legendFontSize = 18;
    }

    const newDataset = Object.entries(chartSettings.datasets).map(([key, value]) => {
      const obj = find(highchartOptions.series, (obj: any) => obj!.code === key);
      const mergedObj = merge(obj, value);
      return mergedObj;
    });

    const legendSettings = {...highchartOptions.legend, itemStyle: {...highchartOptions.legend.itemStyle, fontSize: legendFontSize.toString()}};
    const titleSettings = {...highchartOptions.title, text: chartSettings.title?.text, style: {fontSize: titleFontSize.toString()}};
    const sourceSettings = {...highchartOptions.credits, text: chartSettings.source?.length > 0 ? `source: ${chartSettings.source}` : chartSettings.source};
    setHighchartOptions({...highchartOptions, series: newDataset, legend: {...legendSettings}, title: {...titleSettings}, credits: {...sourceSettings}});

  };

  return highchartOptions ?
    <>
      <ChartEditor
        onChange={handleOnChartCustomizationChange}
        settings={chartSettings}
        roseObject={roseObject}
      />
      <MemoHighchartsReact options={highchartOptions} />
    </> : null;
}

type MemoHighchartsReactProps = {
  options: any;
};

const MemoHighchartsReact = React.memo<MemoHighchartsReactProps>(
  ({options}: MemoHighchartsReactProps) => {
    const chartRef = useRef(null);
    const theme = useTheme();

    // necessary for shortening data values to what we want
    Highcharts.setOptions({
      lang: {
        numericSymbols: ['k', 'm', 'b', 't', 'p', 'e']
      }
    });

    useEffect(() => {
      const themeSettings = setHighchartsTheme(theme);
      chartRef.current.chart.update(themeSettings);
    }, [theme]);

    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
          containerProps={{style: {height: '500px'}}}
        />
      </>
    );
  },
  (prevProps, nextProps) => prevProps.options === nextProps.options
);

MemoHighchartsReact.displayName = 'MemoHighchartsReact';
