import * as React from 'react';
import styled from 'styled-components';

import {RoseWarning} from '@types';
import {ThemeVarNames} from '@theme';
import {Alert, Typography} from '@components/common';

export type WarningsProps = {
  roseCode: string
  warnings: RoseWarning
}

export function Warnings(props: WarningsProps): JSX.Element {
  const {roseCode, warnings = []} = props;

  if (warnings.length === 0) {return null;}

  return (
    <Alert
      showIcon
      type="warning"
      message={composeFriendlyWarningTitle(roseCode)}
      description={
        <StyledUl>
          {warnings.map((txt, idx) =>

            // some txt are the same and they come from the backend
            // that's why I added idx as a key - since this is not going to change the order
            // I think there is no a problem @haderman
            <li key={idx}>
              <Typography variant="primary">{txt}</Typography>
            </li>
          )}
        </StyledUl>
      }
    />
  );
}

function composeFriendlyWarningTitle(code: string): string {
  return `Warnings for ${code}`;
}

const StyledUl = styled.ul`
  margin-bottom: 0;

  li::marker {
    color: var(${ThemeVarNames.PrimaryText});
  }
`;
