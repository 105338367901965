import * as React from 'react';
import {FaRedditAlien} from '@react-icons/all-files/fa/FaRedditAlien';

import {StyledButton} from './style';

export type RedditButtonProps = {
    colored?: boolean
}

export function RedditButton({colored}: RedditButtonProps): JSX.Element {
  return (
    <StyledButton
      colored={colored}
      target="_blank"
      href="https://www.reddit.com/user/rosetechnology/"
    >
      <FaRedditAlien />
    </StyledButton>
  );
}
