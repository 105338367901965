// eslint-disable max-lines-per-function

import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  Card,
  Form,
  Input,
  Checkbox,
  Divider
} from 'antd';
import {MailOutlined, LockOutlined} from '@ant-design/icons';
import {useNavigate, Link} from 'react-router-dom';

import {FcGoogle} from '@react-icons/all-files/fc/FcGoogle';
import {
  Typography,
  Spinner,
  Button
} from '@components/common';
import {RoseUser} from '@types';
import UserService from '@utils/apis/UserService';
import UserContainer from '@utils/state/userContainer';
import HelmetWrapper from '@utils/HelmetWrapper';
import { getBackendUrl } from '@config';

// IMPORTANT: THIS ENTIRE COMPONENT WILL BE REDONE WITH NEW LIBRARY SOON

type User = {
  username: string
  password: string
}

export default (): JSX.Element => {
  const [message, setMessage] = useState('');
  const {user, setUser} = UserContainer.useContainer();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const [userCred, setUserCred] = useState<User>({
    username: '',
    password: ''
  });
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {username, password} = userCred;

  const onHandleChange = (name: string) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setUserCred({
      ...userCred,
      [name]: e.currentTarget.value
    });
    setMessage('');
  };

  const signInWithGoogle = async () => {
    let backendURL = getBackendUrl();
    let withCredentials: boolean;

    if (process.env.NODE_ENV === 'production') {
      withCredentials = false;
    } else {
      backendURL = backendURL;
      withCredentials = true;
    }

    try {

      let redirectURL = backendURL + '/users/auth/google';
      window.location.href = redirectURL;
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setIsLoading(false);
    }
  };

  const onClickSignIn = async (values: { username: string; password: string; }) => {
    setIsLoading(true);
    try {
      await UserService.login(values);
      try {
        const user_ = await UserService.get();
        setUser(user_);
        var redirect = localStorage.getItem('redirect') || '/dashboard';
        localStorage.removeItem('redirect');

        navigate(redirect);
      } catch {
        setMessage('no user found');
        setIsLoading(false);
      }
    } catch {
      setMessage('invalid email or password');
      setIsLoading(false);
    }
  };

  if (!RoseUser.isGuest(user)) {
    navigate(-1);
  }

  return (
    <>
      <HelmetWrapper
        title="Login"
        description="Login To Your Account"
        pathname="/login"
      />
      <ContentsContainer>
        <LoginContainer style={{justifyContent: 'center'}}>
          <StyledCard style={{background: '#111928'}}>
            <LogoContainer>
              <Link to="/">
                <StyledImg src="/img/new_logo/svg/Roselogo-gradient.svg" alt="Rose Logo" />
              </Link>
            </LogoContainer>
            <Header forwardedAs="h1" variant="secondary" size="xx-large">
              Log in
            </Header>
            <Subheader forwardedAs="h2" variant="primary" size="large">
              Welcome to Rose.ai
            </Subheader>
            <GoogleButton
              onClick={signInWithGoogle}
              disabled={isLoading}
              style={{width: '100%'}}
              type="primary"
              size="large"
              icon={<GoogleIcon />}
            >Sign In with Google</GoogleButton>
            <Divider style={{color: '#9CA3AF', borderTopColor: '#9CA3AF'}}>or</Divider>
            <Form
              name="login_form"
              layout="vertical"
              initialValues={{remember: true}}
              onFinish={onClickSignIn}
            >
              <Form.Item
                name="username"
                label={<label style={{color: 'white'}}>Username or email</label>}
                rules={[
                  {
                    required: true,
                    message: 'Please provide your email address'
                  }
                ]}
              >
                <Input
                  value={username}
                  size="large"
                  prefix={<MailOutlined />}
                  placeholder="name@example.com"
                  onChange={onHandleChange('username')}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label={<label style={{color: 'white'}}>Your password</label>}
                rules={[
                  {required: true, message: 'Please provide your password'}
                ]}
              >
                <Input.Password
                  value={password}
                  size="large"
                  prefix={<LockOutlined />}
                  placeholder="•••••••••"
                  onChange={onHandleChange('password')}
                />
              </Form.Item>
              {message ? <Message forwardedAs="p" variant="primary">{message}</Message> : null}
              <Form.Item>
                <SpaceBetween>
                  <Checkbox style={{color: '#9CA3AF'}}>Remember me</Checkbox>
                  <Link to="/forgot">
                    Forgot Password?
                  </Link>
                </SpaceBetween>
              </Form.Item>
              <Button
                style={{width: '100%'}}
                type="primary"
                size="large"
                htmlType="submit"
                disabled={isLoading || !username || !password}
              >
                {isLoading ? <Spinner size={24} /> : 'Log In'}
              </Button>
            </Form>
            <BelowFormButtons style={{color: '#6B7280'}}>
              <Button
                style={{width: '100%', backgroundColor: '#111928'}}
                size="large"
                onClick={() => navigate('/dashboard')}
              >
                {' '}
                As Guest
              </Button>
              <Typography size="small">{"Don't have an account? "}<Link to={`/register${window.location.search}`}>Sign up here</Link></Typography>
            </BelowFormButtons>
          </StyledCard>
        </LoginContainer>
      </ContentsContainer>
    </>
  );
};

const ContentsContainer = styled.div`
  height: 100vh;
  justify-content: center;
  overflow: auto;
  background: #111928;
`;

const SpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const LoginContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  z-index: 5;
  background: #111928;

  @media (max-width: 768px) {
    min-height: 85vh;
    bottom: 0;
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  border: none;

  .ant-card-body {
    width: 100%;
  }
`;
const StyledImg = styled.img`
  width: 66px;
  margin-bottom: 20px;
`;

const Header = styled(Typography)`
  text-align: center;
  color: white;
  margin-bottom: 20px;
`;

const Subheader = styled(Typography)`
  text-align: center;
  margin-bottom: 20px;
  color: white;
`;

const BelowFormButtons = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
`;

const Message = styled(Typography)`
  margin-bottom: 24px;
  font-size: 14px;
  text-align: center;
  color: white;
`;

const GoogleButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GoogleIcon = styled(FcGoogle)`
  margin-right: 15px;
  width: 25px;
  height: 25px;
`;
