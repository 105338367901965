import React from 'react';
import styled from 'styled-components';
import {Layout} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import HelmetWrapper from '@utils/HelmetWrapper';
import {Alert, Button, Spinner, Table, Typography} from '@components/common';
import {useCode as useCodeService} from '@service/useCode';

const {Header} = Layout;

const CODES: CodeTitleType[] = [
  {
    code: 'crypto.data.fundamentals.notebook.v02',
    title: 'Crypto Fundamentals Dashboard'
  },
  {
    code: 'new.user.default.notebook',
    title: 'New User Overview'
  },
  {
    code: 'finding.gas.001.20220311.jd',
    title: 'Finding Gas: Understanding Ethereum Transaction Fees'
  },
  {
    code: 'eth.chain.demo.notebook.yoel.v03.20220913',
    title: 'Querying the Ethereum Chain Overview'
  },
  {
    code: 'covid.19.dashboard.rww',
    title: 'Global COVID-19 Tracking Dashboard'
  },
  {
    code: 'crypto.eth.blockchain.lifetime.value.usd.per.transaction.lifetime.notebook.20221003',
    title: 'Ethereum Lifetime Transaction Value'
  },
  {
    code: 'zillow.fred.zhvi.new.series.rww.20220203',
    title: 'Zillow Home Value Index (ZHVI)'
  },
  {
    code: 'crypto.eth.chain.erc20.token.flows.stablecoin.20221017',
    title: 'ERC20 Ethereum Token Flows'
  },
  {
    code: 'china.conditions.in.lieu.of.gdp.growth.data.20221019',
    title: 'China Conditions Overview'
  },
  {
    code: 'pce.releases..20221028',
    title: 'USA PCE Overview'
  },
  {
    code: 'usa.bond.total.return.drawdown.20221020',
    title: 'USA Government Bond Return Drawdown'
  }
];

interface DataType {
  key: string
  code: string,
  user: string,
  updatedAt: string
}

interface CodeTitleType {
  code: string,
  title: string
}

// helperFunction
const makeRosecodeString = (objArray: CodeTitleType[]) => {
  const tempArr: any[] = [];
  objArray.map((obj:any) => {
    tempArr.push(obj.code);
  });

  return tempArr.join(',');
};

export const Discover = (): JSX.Element => {
  const {roseObjects, status} = useCodeService(
    'discover',
    makeRosecodeString(CODES),
    {
      staleTime: 5000 * 60,
      cacheTime: 5000 * 60
    }
  );

  const columns = [
    {
      title: 'Title',
      dataIndex: 'code',
      key: 'code',
      // eslint-disable-next-line react/display-name
      render: (text: string, record: DataType) =>
        <span style={{fontSize: '16px', fontWeight: 100}}>{text}</span>
    },
    {
      title: 'Author',
      dataIndex: 'user',
      key: 'user',
      align: 'center' as const
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 120
    },
    {
      dataIndex: 'button',
      key: 'button',
      align: 'right' as const,
      width: 100,
      // eslint-disable-next-line react/display-name
      render: (text: string, record: DataType) =>
        <Button>
          <a className="share" href={`/dashboard/${record.key}`} rel="noreferrer" target="_blank">
            View
          </a>
        </Button>
    }
  ];

  const datasource = roseObjects.map((obj: any, index: any) => {
    const fullDate = obj.metas.tree.updated_at.split(' ');
    const date = fullDate[0];

    return {
      key: obj.code,
      code: CODES[index].title,
      user: obj.actor,
      updatedAt: date
    };
  }
  );

  const renderTable = () =>
    roseObjects.length > 0 ?
      <>
        <StyledTable
          key="all-users"
          columns={columns}
          dataSource={datasource}
          pagination={false}
          scroll={{x: 700}}
          tableLayout="fixed"
        />
      </> : <p>No values</p>
    ;

  if (status === 'loading') {return <Spinner size={48} padding="40px" color="#FFFFFF" />;}

  if (status === 'error') {return <StyledAlert message="Problem occured pulling notebooks" type="error" />;}

  return (
    <StyledLayout>
      <HelmetWrapper title="Discover" description="Discover Dashboard" />
      <StyledHeader>
        <Typography variant="primary" as="h1" size="x-large" id="page-title">
            Discover
        </Typography>
      </StyledHeader>
      <StyledContent>
        {renderTable()}
      </StyledContent>
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  background-color: inherit;
  margin: 0 20px;
`;

const StyledHeader = styled(Header)`
  background-color: inherit;
	text-align: center;
`;

const StyledContent = styled(Content)`
  background-color: inherit;
`;

const StyledAlert = styled(Alert)`
  text-align: center;
`;

const StyledTable = styled(Table)`
  .ant-table {
   background: inherit;
  }
`;
