/* eslint-disable camelcase */
import axios, {AxiosError} from 'axios';
import {RoseObject} from '../../types/RoseObject';
import {HttpClient} from './HttpClient';

/**
 * API client for the `sharing` services
 * @singleton
 * @extends HttpClient
 */
class ConnectionAPI extends HttpClient {
  private static _instance: ConnectionAPI;

  private constructor() {
    super('/connections');
  }

  public static get instance(): ConnectionAPI {
    if (!ConnectionAPI._instance) {
      ConnectionAPI._instance = new ConnectionAPI();
    }

    return ConnectionAPI._instance;
  }

  public all = (signal = new AbortController().signal) => this.instance.get('', {signal});

  public runQuery = (
    connectionCode: string,
    query: string,
    signal = new AbortController().signal
  ) => this.instance
    .post(`/${connectionCode}`, {query}, {signal})
    .then((res) => ({
      ...res,
      data: RoseObject.deserialize(res.data)
    }))
    .catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        const responseData: any = err.response?.data;
        throw new Error(responseData?.message ?? 'Error running query');
      } else {
        throw err;
      }
    });

  public create = (
    code: string,
    db_type: string,
    connection_string: string | object,
    signal = new AbortController().signal
  ) => this.instance
    .post('', {code, db_type, connection_string}, {signal})
}

const ConnectionService = ConnectionAPI.instance;

export default ConnectionService;
