import React, { useEffect } from 'react';
import { LandingHeader } from '@components/common/LandingHeader/index';
import HelmetWrapper from '@utils/HelmetWrapper';
import BannerSection from './BannerSection/BannerSection';
import TheProblemSection from './TheProblemSection/TheProblemSection';
import KeyFeaturesSection from './KeyFeaturesSection/KeyFeaturesSection';
import PlatformSection from './PlatformSection/PlatformSection';
import RoseAISection from './RoseAISection/RoseAISection';
import IntegrationsAndDataSection from './IntegrationsAndDataSection/IntegrationsAndDataSection';
import LandingFooter from '@components/common/LandingFooter/LandingFooter';
import CustomerQuotesSection from './CustomerQuotesSection/CustomerQuotesSection';
import BackedBySection from './BackedBySection/BackedBySection';

function Home(): JSX.Element {

  return (
    <div className="bg-[#0C0F14]">
      <HelmetWrapper title="Home" description="Data Made Easy" pathname="/" />
      <LandingHeader />
      <BannerSection />
      <TheProblemSection />
      <PlatformSection />
      <KeyFeaturesSection />
      <RoseAISection />
      <IntegrationsAndDataSection />
      <CustomerQuotesSection />
      <BackedBySection />
      <LandingFooter altHeader={"Discover the difference with Rose AI."} />
    </div>
  );
}

export default Home;
