import * as React from 'react';
import {message} from 'antd';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {UseMutationResult} from '@tanstack/react-query';

import {MutationType} from '@service/useNotebook';
import {RoseNotebook} from '@types';
import {Spinner, Modal, Button, Alert, Input, Form} from '@components/common';

export type CreateNewNotebookModalProps = {
  visible: boolean
  setVisible: (visible: boolean) => void
  code: string
  type: 'create' | 'rename' | 'duplicate'
  notebook: RoseNotebook
  mutation: UseMutationResult<RoseNotebook, any, MutationType, unknown>
}

type Mode = 'idle' | 'typing' | 'ask-to-replace'

type InputError =
  | 'none'
  | 'reserved-name'
  | 'empty-text'
  | 'no-using-correct-format'

export function CreateNewNotebookModal(
  props: CreateNewNotebookModalProps
): JSX.Element {
  const {visible, setVisible, code, type, notebook, mutation} = props;
  const [inputValue, setInputValue] = React.useState<string>(() => type === 'duplicate' ? `${code}.copy` : code);
  const [mode, setMode] = React.useState<Mode>('idle');
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const navigate = useNavigate();

  React.useEffect(
    () => {
      setInputValue(type === 'duplicate' ? `${code}.copy` : code);
    },
    [visible, code, type]
  );

  const inputError = validateTitle(inputValue);
  const canSave = inputError === 'none';

  const handleOnSave = () => {
    if (canSave) {
      if (type === 'rename' && code === inputValue) {
        setVisible(false);
        return;
      }

      const newNotebook = RoseNotebook.update(notebook, {code: inputValue});
      const mutationType: MutationType =
        type === 'rename' ?
          {
            action: 'rename',
            notebook: newNotebook,
            currentCode: code,
            newCode: inputValue,
            optimistic: false
          } :
          {action: type, notebook: newNotebook, optimistic: false};

      mutation.mutate(mutationType, {
        onSuccess() {
          message.success('Notebook saved');
          setVisible(false);
          navigate(`/dashboard/${inputValue}`);
        },
        onError(error: Error) {
          const isExistingRoseObjectError = [
            'user-has-the-notebook',
            'notebook-exist-by-another-user',
            'user-has-the-rose-object',
            'rose-object-exist-by-another-user'
          ].includes(error.name);
          if (isExistingRoseObjectError) {
            setMode('ask-to-replace');
            setErrorMessage(error.message);
          } else {
            // Do we really also need console.error here if we are using `message`?
            // eslint-disable-next-line no-console
            console.error('error saving notebook: ', error);
            message.error(error.message);
          }
        }
      });
    } else {
      message.error('Please enter a valid notebook title');
    }
  };

  const handleOnReplace = () => {
    const newNotebook = RoseNotebook.update(notebook, {code: inputValue});
    mutation.mutate(
      {action: 'replace', notebook: newNotebook, optimistic: false},
      {
        onSuccess() {
          setVisible(false);
          message.success('Notebook saved');
          navigate(`/dashboard/${inputValue}`);
        }
      }
    );
  };

  const addDate = () => {
    const dateObj = new Date();
    const year = dateObj.getFullYear();
    let month = (dateObj.getMonth() + 1).toString();
    let day = dateObj.getDate().toString();

    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }

    const dateString = `.${year}${month}${day}`;
    setInputValue(`${inputValue.replace(dateString, '')}${dateString}`);
  };

  const FormComponent =
    <Form layout="vertical">
      <Form.Item
        validateStatus={canSave || mode === 'idle' ? 'success' : 'error'}
        hasFeedback={mode !== 'idle'}
        help={composeErrorMessage(inputError)}
      >
        <Input
          autoFocus
          placeholder="Please input notebook title"
          value={inputValue}
          spellCheck={false}
          onChange={(e) => {
            setMode('typing');
            setInputValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleOnSave();
            }
          }}
        />
      </Form.Item>
    </Form>;

  const AskToReplace = <Alert type="error" showIcon message={errorMessage} />;

  // using numbers for keys only because these won't change order
  const AskToReplaceFooter = [
    <Button key={0} type="primary" onClick={() => setMode('typing')}>
      Back to edit title
    </Button>,
    <Button key={1} onClick={handleOnReplace}>Save</Button>
  ];

  const FormComponentFooter = [
    <Button key="timestamp" type="text" onClick={() => addDate()}>
      Add Date
    </Button>,
    <Button key="cancel" onClick={() => setVisible(false)}>
      Cancel
    </Button>,
    <Button
      key="save"
      type="primary"
      disabled={!canSave}
      onClick={handleOnSave}
    >
      Save
    </Button>
  ];

  function renderBody() {
    if (mutation.isLoading) {return <Spinner size={48} padding="20px" />;}

    if (mode === 'ask-to-replace') {return AskToReplace;}

    return FormComponent;
  }

  function renderFooter() {
    if (mutation.isLoading) {return null;}

    if (mode === 'ask-to-replace') {return AskToReplaceFooter;}

    return FormComponentFooter;
  }

  return (
    <SaveModalContainer
      destroyOnClose
      visible={visible}
      title="Save Notebook"
      closable={!mutation.isLoading}
      onCancel={() => {
        if (!mutation.isLoading) {
          setVisible(false);
        }
      }}
      okButtonProps={{disabled: !canSave}}
      afterClose={() => {
        setMode('idle');
      }}
      footer={renderFooter()}
    >
      {renderBody()}
    </SaveModalContainer>
  );
}

// Helpers

const INVALID_CHARS = /[\'\"\(\)\[\]\{\}\!\@\#\$\%\^\&\*\;\:\<\>\?\/\\\s]/;

function validateTitle(str: string): InputError {
  if (str.toLowerCase() === 'untitled') {return 'reserved-name';}

  if (str.trim() === '') {return 'empty-text';}

  if (INVALID_CHARS.test(str)) {return 'no-using-correct-format';}

  return 'none';
}

function composeErrorMessage(error: InputError): string {
  switch (error) {
  case 'empty-text':
    return 'Please type a title for your notebook';
  case 'reserved-name':
    return 'Please try another name different of Untitled';
  case 'no-using-correct-format':
    return 'Avoid white spaces (eg: my-first-notebook)';
  default:
    return '';
  }
}

const SaveModalContainer = styled(Modal)`
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`;
export {SaveModalContainer};
