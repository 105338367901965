import React, {useState} from 'react';
import styled from 'styled-components';
import {IoEllipsisVertical} from '@react-icons/all-files/io5/IoEllipsisVertical';
import {IoTrashBin} from '@react-icons/all-files/io5/IoTrashBin';
import {Dropdown} from 'antd';
import {Menu, Modal, Alert} from '@components/common';
import {ThemeVarNames} from '@theme';
import ManageObjectsAPI from '@utils/apis/ManageObjectsAPI';

/*
  Chunking is slowing everything down by a substantial amount
  I recommend reconstructing the parent component to match the
  Admin Portal. It will help with performance, readability and
  consistency. That ticket should be scoped for 8-12 hours.
*/
type Dataset = {
  id: number,
  code: string,
  type: string
}

type OptionsProps = {
  refetchTable: () => Promise<any>;
  dataset: Dataset
}

export const Options = ({refetchTable, dataset}: OptionsProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => {
    setError(null);
    setOpen((open) => !open);
  };

  const removeDataset = async () => {
    setLoading(true);
    setError(null);
    try {
      await ManageObjectsAPI.deleteObject(dataset.code);
      refetchTable();
      setLoading(false);
    } catch (err) {
      setError(err.response.data.message);
      setLoading(false);
    }
  };

  const menu =
    <Container>
      <Menu selectable={false}>
        <Menu.Item key="1" onClick={toggleModal}>
          <IconText><IoTrashBin style={{margin: '5px'}} /> Delete object</IconText>
        </Menu.Item>
        <Modal
          title="Confirmation"
          visible={open}
          onOk={removeDataset}
          onCancel={toggleModal}
          confirmLoading={loading}
          okText="Confim"
          cancelText="Cancel"
        >
          <p>Are you sure you want to delete <strong>{dataset.code}</strong> from Rose?</p>
          {error ?
            <Alert
              type="error"
              message={error}
              style={{textAlign: 'center'}}
            /> : null
          }
        </Modal>
      </Menu>
    </Container>;

  return (

    <Dropdown overlay={menu} placement="topRight" trigger={['click']}>
      <IconContainer>
        <OptionIcon color="#1f87e5" size={24} />
      </IconContainer>
    </Dropdown>

  );
};

const Container = styled.div`
  .ant-menu-item {
    color: var(${ThemeVarNames.SecondaryText});
  }
  border: 1px solid;
  border-color: var(${ThemeVarNames.Border});
  border-radius: 2px;

  .ant-modal-content {
    background: var(${ThemeVarNames.SecondaryBg});
  }
`;

const IconText = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
`;

const IconContainer = styled.span`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const OptionIcon = styled(IoEllipsisVertical)`
  fill: ${(p: { color: string }) => p.color};

  :hover {
    cursor: pointer;
  }
`;

