import React, {useState} from 'react';
import {Dropdown} from 'antd';

import styled from 'styled-components';
import {Button, Typography, Menu} from '@components/common';

import {UnitMenuItem, Space, HiddenButton} from './styles';

export type ChangeUnitsButtonProps = {
  axis: string
  chart: any
  dataset: any
  onSelectUnit: (unitIndex: number, datasetCode: string) => void
  onSetAll: (unitIndex: number) => void
  changeYAxisSide: (axisIndex: number) => void
}

const SetAllButton = styled(Button)<{ hoverColor: string}>`
  &:hover {
    color: ${(props) => props.hoverColor} !important;
    border-color: ${(props) => props.hoverColor} !important;
  }
`;

const CustomButton = styled(Button)<{ hoverColor: string, color?: string}>`
  color: ${(props) => props.color || 'inherit'} !important;

  &:hover {
    color: ${(props) => props.hoverColor} !important;
    border-color: ${(props) => props.hoverColor} !important;
  }
`;

const ChangeUnitsButton = ({
  axis,
  chart,
  dataset,
  onSelectUnit,
  onSetAll,
  changeYAxisSide
}: ChangeUnitsButtonProps) => {
  const [currentMenuItemHover, setCurrentMenuItemHover] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const menu =
    <Menu>
      {(() => {
        let leftCounter = 0; let rightCounter = 0;
        const firstItems = [];
        const otherItems = [];
        Object.values(chart.yAxis).map((
          {title, index, opposite, defaultLabel, visible, initialDataset}: {title: any; index: number, opposite: boolean, defaultLabel: string, visible: boolean, initialDataset: string}
        ) => {
          const currentSide = opposite ? 'Right' : 'Left';
          const currentCounter = currentSide === 'Right' ? ++rightCounter : ++leftCounter;

          // Your condition here
          if (axis === title?.text || axis === defaultLabel) {
            firstItems.push(
              <Menu.Item
                key={index}
                onMouseEnter={() => setCurrentMenuItemHover(index)}
                onMouseLeave={() => setCurrentMenuItemHover(null)}
                onClick={() => changeYAxisSide(index)}
              >
                <UnitMenuItem hoverColor={title?.style?.color}>
                  <Typography className="unit-menu-item-text" variant="primary" size="small">{`Switch the axis to ${!opposite ? 'right' : 'left'} side`}</Typography>
                </UnitMenuItem>
              </Menu.Item>
            );
          } else {
            if (visible || !visible && initialDataset === dataset) {
              otherItems.push(
                <Menu.Item
                  key={index}
                  onMouseEnter={() => setCurrentMenuItemHover(index)}
                  onMouseLeave={() => setCurrentMenuItemHover(null)}
                  onClick={() => onSelectUnit(index, dataset)}
                >
                  <UnitMenuItem hoverColor={title?.style?.color}>
                    <Typography className="unit-menu-item-text" variant="primary" size="small">Move the series to {title?.text || `${currentSide} Axis ${currentCounter}` || `axis ${index}`}</Typography>
                    <Space />
                    {currentMenuItemHover === index ?
                      <SetAllButton
                        type="ghost"
                        hoverColor={title?.style?.color}
                        onClick={() => onSetAll(index)}
                      >
                        Set All
                      </SetAllButton> :
                      <HiddenButton type="ghost">Set All</HiddenButton>
                    }
                  </UnitMenuItem>
                </Menu.Item>
              );
            }
          }
        });

        return [...firstItems, ...otherItems];
      })()}
    </Menu>;

  const series = chart.series.find((series) => series.code === dataset);
  return (
    <Dropdown
      overlay={menu}
      arrow
      placement="topCenter"
      trigger={['click']}
      visible={isDropdownVisible}
      onVisibleChange={setIsDropdownVisible}
    >
      <CustomButton
        hoverColor={chart.yAxis[series.yAxis]?.labels?.style?.color}
        color={isDropdownVisible ? chart.yAxis[series.yAxis]?.labels?.style?.color : undefined}
        type="ghost"
      >
        {axis}
      </CustomButton>
    </Dropdown>
  );
};

export default ChangeUnitsButton;
