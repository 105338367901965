import React, {useState} from 'react';
import {message} from 'antd';
import {IoCloudDownloadOutline} from '@react-icons/all-files/io5/IoCloudDownloadOutline';

import ObjectService from '@utils/apis/ObjectService';
import saveAs from '@libraries/fileSaver';

import {Button} from './Button';
import { RoseModule } from '@types';

export type DownloadButtonProps = {
  roseCode: string
  actorId: string
  mod: RoseModule 
}

type Status = 'idle' | 'loading' | 'success' | 'error'

export function DownloadButton({roseCode, actorId, mod}: DownloadButtonProps): JSX.Element {
  const [status, setStatus] = useState<Status>('idle');

  const handleOnClick = () => {
    setStatus('loading');
    if (!roseCode || roseCode === '') {
      ObjectService.getQueryCSV(mod.modulesettings?.connection?.code, mod.textBox)
        .then((res) => {
          const contentDispositionHeader = res.headers['content-disposition'];
          const filenameMatch = contentDispositionHeader && contentDispositionHeader.match(/filename="(.+)"/);
          const defaultFilename = 'query.csv';
          const filename = filenameMatch ? filenameMatch[1] : defaultFilename;
          const file = new Blob([res.data], {
            type: 'data:text/csv;charset=utf-8,'
          });
          saveAs(file, filename);
          setStatus('success');
        })
        .catch(() => {
          setStatus('error');
          message.error('Error downloading query');
        });
    } else {
      ObjectService.getCSV(roseCode, actorId)
      .then((res) => {
        const file = new Blob([res.data], {
          type: 'data:text/csv;charset=utf-8,'
        });
        saveAs(file, `${roseCode}.csv`);
        setStatus('success');
      })
      .catch(() => {
        setStatus('error');
        message.error(`Error downloading ${roseCode}`);
      });
    }
  };

  return (
    <Button
      download
      type="primary"
      shape="circle"
      onClick={handleOnClick}
      icon={<IoCloudDownloadOutline />}
      loading={status === 'loading'}
    />
  );
}
