import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { IoSearchOutline } from '@react-icons/all-files/io5/IoSearchOutline';
import { IoCode } from '@react-icons/all-files/io5/IoCode';
import { IoServerOutline } from '@react-icons/all-files/io5/IoServerOutline';
import { IoFolderOpenOutline } from '@react-icons/all-files/io5/IoFolderOpenOutline';
import { BsFileText } from '@react-icons/all-files/bs/BsFileText';
import { BsFileEarmarkPlus } from '@react-icons/all-files/bs/BsFileEarmarkPlus';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { UseMutationResult } from '@tanstack/react-query';
import { Elements } from '@stripe/react-stripe-js';
import { device } from '@utils/breakpoints';
import { PrivateButton, Button } from '@components/common';
import { RoseModule, RoseModuleType, RoseNotebook, RoseUser } from '@types';
import UserContainer from '@utils/state/userContainer';
import { MutationType } from '@service/useNotebook';
import { ButtonSave, ButtonDuplicate } from '../common/Save';
import { AskAccessModal } from '../Module/Ask';
import { stripePromise } from '@components/dashboard/Settings/SettingsContainer';
import { TrialModal } from '@components/dashboard/TrialModal/TrialModal';
import { askRoseGuideSteps } from '@helpers';
import { isMobile } from 'react-device-detect';

const COLOR = '#1f87e5';

export type SidebarProps = {
  onAddModule: (defaultProps: Partial<RoseModule>) => void
  onSearch: () => void
  onUploadFile: () => void
  onConvertToAsk: (mod: RoseModule) => void
  notebook: RoseNotebook
  mutation: UseMutationResult<RoseNotebook, any, MutationType, unknown>
  isReadOnly: boolean
  isSearchOpen?: boolean
}

export function Sidebar(props: SidebarProps): JSX.Element {
  const { onAddModule, onSearch, onUploadFile, onConvertToAsk, notebook, mutation, isReadOnly, isSearchOpen } = props;
  const { user, setUser } = UserContainer.useContainer();
  const [askVisible, setAskVisible] = React.useState(false);


  const optimisticallyUpdateIsAskRose = (isAskRose: boolean) => {
    setUser({ ...user, askrose: isAskRose });
  };

  const onClose = () => {
    setAskVisible(false);
  };


  const addRoseCodeCell = (cellType: RoseModuleType) => {

    // step that needs its element target to be updated 
    const roseStep = 1; 

    // check if the last module exists
    let lastModule = notebook.modules[notebook.modules.length - 1];

    // if cell(s) exist(s), convert the last one to an ask-rose
    if (notebook.modules.length >= 1 && isEmpty(lastModule.textBox.trim()) && lastModule.type !== 'ask') {
      onConvertToAsk(lastModule);
    } 
    else {
      // add a fresh cell of ask-rose type when notebook is empty
      if(lastModule.type !== 'ask'){
        onAddModule({type: cellType});
      }
      lastModule = notebook.modules[notebook.modules.length - 1];
    }

  // target the most recent ask-rose cell's button
  // this is combination of the grandparent div's ID and the button type selector
  // if the ID starts with a number, the first number needs to be escaped with a \\3
  // otherwise an error will pop up
  if(!isMobile){
    askRoseGuideSteps[roseStep].target = lastModule.key.charAt(0).match(/\d/) ? 
    `#\\3${lastModule.key[0] + ' ' + lastModule.key.slice(1)}` 
    : '#' + lastModule.key;
    askRoseGuideSteps[roseStep].target += ' button[type="button"]'
    };
  }

  return (
    <SidebarContainer isSearchOpen={isSearchOpen}>
      <SidebarWrapper id="sidebar">
        <ButtonsGroup>
          <Tooltip title="Ask Rose" placement="right">
            <Button
              id="ask-rose-button"
              type="text"
              shape="circle"
              disabled={isReadOnly || RoseUser.isGuest(user)}
              onClick={() => {
                if (user.askrose === true) {
                  const lastModule = notebook.modules[notebook.modules.length - 1];

                  // only convert first module if it's empty or is the only module (aka a fresh notebook)
                  if (notebook.modules.length === 1 && isEmpty(lastModule.textBox.trim()) && lastModule.type !== 'ask') {
                    onConvertToAsk(lastModule);
                  } else {
                    onAddModule({ type: 'ask' });
                  }
                } else {
                  setAskVisible(true);
                }
              }}
            >
              <img src="/img/new_logo/svg/Roselogo-gradient.svg" alt="ask rose logo" height={24} width={24} style={isReadOnly || RoseUser.isGuest(user) ? { filter: 'grayscale(1)' } : { filter: 'none' }} />
            </Button>
          </Tooltip>
          <Tooltip title="Search" placement="right">
            <Button
              id="search-button"
              type="text"
              shape="circle"
              onClick={onSearch}
            >
              <IoSearchOutline size={24} />
            </Button>
          </Tooltip>
        </ButtonsGroup>
        <ButtonsGroup>
          <Tooltip title="Add code" placement="right">
            <Button
              id="add-code-button"
              type="text"
              shape="circle"
              onClick={() => onAddModule({ type: 'code' })}
              disabled={isReadOnly}
            >
              <IoCode size={24} />
            </Button>
          </Tooltip>
          <Tooltip title="Add markdown" placement="right">
            <Button
              id="add-markdown-button"
              type="text"
              shape="circle"
              onClick={() => onAddModule({ type: 'markdown' })}
              disabled={isReadOnly}
            >
              <BsFileText size={24} />
            </Button>
          </Tooltip>
          <Tooltip title="Add SQL" placement="right">
            <Button
              id="add-sql-button"
              type="text"
              shape="circle"
              onClick={() => onAddModule({ type: 'query' })}
              disabled={isReadOnly}
            >
              <IoServerOutline size={24} />
            </Button>
          </Tooltip>
          <Tooltip title="Import/Upload File" placement="right">
            <Button
              id="upload-file-button"
              type="text"
              shape="circle"
              onClick={onUploadFile}
              disabled={isReadOnly || RoseUser.isGuest(user)}
            >
              <IoFolderOpenOutline size={24} />
            </Button>
          </Tooltip>
        </ButtonsGroup>
        <ButtonsGroup>
          <Tooltip title="New notebook" placement="right">
            <Button
              id="new-notebook-button"
              type="text"
              shape="circle"
              disabled={isReadOnly || RoseUser.isGuest(user)}
            >
              <StyledLink to="/dashboard/" target="_blank">
                <BsFileEarmarkPlus size={24} />
              </StyledLink>
            </Button>
          </Tooltip>
          <ButtonSave color={COLOR} notebook={notebook} mutation={mutation} isReadOnly={isReadOnly} isGuest={RoseUser.isGuest(user)} />
          <ButtonDuplicate color={COLOR} notebook={notebook} mutation={mutation} />
        </ButtonsGroup>
        <Elements stripe={stripePromise}>
          <TrialModal
            isModalOpen={askVisible}
            setIsModalOpen={setAskVisible}
            setIsAskRose={optimisticallyUpdateIsAskRose}
            setOnAddModule={addRoseCodeCell}
          />
        </Elements>
      </SidebarWrapper>
    </SidebarContainer>
  );
}


const SidebarContainer = styled.div<{ isSearchOpen: boolean }>`
  justify-content: center;
  align-items: ${(props) => props.isSearchOpen ? 'flex-start' : 'center'};
  position: ${(props) => props.isSearchOpen ? 'relative' : 'sticky'};
  top: ${(props) => props.isSearchOpen ? 'unset' : '130px'};
  left: 0;
  padding-top: ${(props) => props.isSearchOpen ? '106px' : '35px'};
  display: none;

  ${device.tablet} {
    display: flex;
  }
`;

const SidebarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: flex-start;
  flex-direction: column;
  gap: 40px;
  width: 80px;
`;

const ButtonsGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: flex-start;
  flex-direction: column;
  gap: 20px;
`;

const StyledPrivateButton = styled(PrivateButton)`
  background-color: transparent;
  color: ${COLOR};
`;

const StyledLink = styled(Link)`
  color: inherit;
  background-color: inherit;
`;
