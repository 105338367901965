import {RoseNotebook, RoseModule} from '@types';
import { isMobile } from 'react-device-detect';

const DRAFT_NOTEBOOK_KEY = 'draft-notebook';
const MARKETPLACE_ACTIVE_VIEW_KEY = 'marketplace-active-view';
const ADMINPORTAL_VIEW_KEY = 'admin-portal-view';

export function saveNotebook(notebook: RoseNotebook): void {
  try {
    window.sessionStorage.setItem(DRAFT_NOTEBOOK_KEY, JSON.stringify(notebook));
  } catch (error) {
    console.error('error saving draft notebook in session storage: ', error);
  }
}

export function getNotebook(askrose?: boolean, forceModuleAdd?: boolean): RoseNotebook {
  try {
    const rawNotebook = window.sessionStorage.getItem(DRAFT_NOTEBOOK_KEY);
    const notebook: RoseNotebook = JSON.parse(rawNotebook);

    // case where we will insert a module to the top of the user's notebook
    // currently used for when beta-users sign up through mobile 
    if(forceModuleAdd){
      //check if ask-rose module exists in notebook modules
      let hasAskModule = false; 
      let insertPosition = -1;
      if(notebook.modules !== null){
        hasAskModule = notebook.modules.some(module => module.type === 'ask');
      }

      // if user deletes ask-rose module or it doesn't exist in notebook, always re-add it to the top
      // of the notebook in mobile view so it is easy for them to locate it 
      if(!hasAskModule){
        let newNotebook = RoseNotebook.update(notebook, {
          modules: RoseModule.insert(notebook.modules, RoseModule.create({type: 'ask'}), insertPosition)
        });

        // add to storage so when refresh, user sees the ask-rose box 
        window.sessionStorage.setItem(DRAFT_NOTEBOOK_KEY, JSON.stringify(newNotebook));
        return newNotebook;
      }
    }
    return notebook || RoseNotebook.create(askrose);
  } catch (error) {
    console.error('error getting draft notebook from session storage: ', error);
    return RoseNotebook.create();
  }
}

export function clearNotebook(): void {
  try {
    window.sessionStorage.removeItem(DRAFT_NOTEBOOK_KEY);
  } catch (error) {
    console.error(
      `error trying to remove ${DRAFT_NOTEBOOK_KEY} in session storage: `,
      error
    );
  }
}

export function saveMarketplaceActiveView(view: MarketplaceView): void {
  try {
    window.sessionStorage.setItem(MARKETPLACE_ACTIVE_VIEW_KEY, view);
  } catch (error) {
    console.error(
      `error savin ${MARKETPLACE_ACTIVE_VIEW_KEY} in session storage: ${error}`
    );
  }
}

export function getMarketplaceActiveView(): MarketplaceView {
  try {
    return MarketplaceView.deserialize(
      window.sessionStorage.getItem(MARKETPLACE_ACTIVE_VIEW_KEY)
    );
  } catch (error) {
    console.error(
      `error getting ${MARKETPLACE_ACTIVE_VIEW_KEY} from session storage: ${error}`
    );
    return MarketplaceView.defaultValue();
  }
}

export type MarketplaceView = 'sell' | 'buy'

export const MarketplaceView = {
  deserialize(str: string): MarketplaceView {
    if (str === 'sell') { return 'sell'; }

    if (str === 'buy') { return 'buy'; }

    return MarketplaceView.defaultValue();
  },
  defaultValue(): MarketplaceView {
    return 'buy';
  }
};

// for managing views in admin portal
// see marketplace functionality above to understand how this works
export function saveAdminPortalView(view: AdminPortalView): void {
  try {
    window.sessionStorage.setItem(ADMINPORTAL_VIEW_KEY, view);
  } catch (error) {
    console.error(
      `error saving ${ADMINPORTAL_VIEW_KEY} in session storage: ${error}`
    );
  }
}

export function getAdminPortalView(): AdminPortalView {
  try {
    return AdminPortalView.deserialize(
      window.sessionStorage.getItem(ADMINPORTAL_VIEW_KEY)
    );
  } catch (error) {
    console.error(
      `error getting ${ADMINPORTAL_VIEW_KEY} from session storage: ${error}`
    );
    return AdminPortalView.defaultValue();
  }
}

export type AdminPortalView = 'all-users'

export const AdminPortalView = {
  deserialize(str: string): AdminPortalView {
    if (str === 'all-users') { return 'all-users'; }

    return AdminPortalView.defaultValue();
  },
  defaultValue(): AdminPortalView {
    return 'all-users';
  }
};
