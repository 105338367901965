import * as React from 'react';

import {useNotebook} from '@service/useNotebook';
import {RoseModule, RoseNotebook} from '@types';
import {Spinner} from '@components/common/Loading';

import HelmetWrapper from '@utils/HelmetWrapper';
import UserContainer from '@utils/state/userContainer';
import {Notebook} from './Notebook';
import {EmptyState, EmptyStateNotLoggedIn} from './common/EmptyState';

export type NotebookContainerProps = {
  code?: string
  initialRoseCodes?: string[],
  children?: JSX.Element
}

export function NotebookContainer(props: NotebookContainerProps): JSX.Element {
  const {code, initialRoseCodes = []} = props;
  const {children} = props;
  const {notebook, status, mutation, error, permission} = useNotebook(code);
  const {user} = UserContainer.useContainer();
  const isReadOnly = permission === 'read';

  React.useEffect(() => {
    if (initialRoseCodes.length === 0 || code !== '') {return;}

    const modules: RoseModule[] = initialRoseCodes.map((roseCode) =>
      RoseModule.create({textBox: roseCode, runOnLoad: true})
    );
    const updatedNotebook = RoseNotebook.update(notebook, {modules});
    mutation.mutate({action: 'create', notebook: updatedNotebook});
  }, []);

  React.useEffect(() => {
    const showAlert = (ev: BeforeUnloadEvent) => {
      if (code === '') {
        ev.preventDefault();

        // Chrome requires returnValue to be set
        ev.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', showAlert);

    return function unsubscribeEvent() {
      window.removeEventListener('beforeunload', showAlert);
    };
  }, []);

  if (status === 'error') {
    return !user ? <EmptyStateNotLoggedIn message={String(error)} /> : <EmptyState message={String(error)} />;
  }

  if (status === 'loading') {
    return <Spinner size={48} padding="40px" />;
  }

  return (
    <>
      {code === 'rose.intro' ?
        <HelmetWrapper title="Help" description="Help Page" /> :
        <HelmetWrapper title="Workspace" description="User's Workspace" />
      }
      <Notebook
        isReadOnly={isReadOnly}
        notebook={notebook}
        mutation={mutation}
      />
      {children}
    </>
  );
}
