import styled from 'styled-components';

export type StyledButtonProps = {
  colored?: boolean
}

export const StyledButton = styled.a<StyledButtonProps>`
  text-transform: none !important;
  font-size: 20px; /* match footer size */
  border-radius: 30px;
  border: none;
  color: white !important;
  background-color: ${(p) =>
    p.colored ? 'rgb(125, 87, 244)' : ''};
  font-weight: 500;
  padding: 10px;
  text-align: center;
  line-height: 0;
  vertical-align: middle;
  margin: 3px;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
    color: ${(p) => p.colored ? 'white' : 'unset'};
  }
`;
