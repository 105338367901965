import * as React from 'react';
import styled from 'styled-components';
import {IoEllipsisVertical} from '@react-icons/all-files/io5/IoEllipsisVertical';
import {Popover} from 'antd';

import {Menu} from '@components/common';

import {device} from '@utils/breakpoints';

import {OptionMenu} from './Base';

export type OptionsProps = {
  options: OptionMenu[]
  color: string
}

export function Options(props: OptionsProps): JSX.Element {
  const {options, color} = props;
  const [visible, setVisbile] = React.useState<boolean>(false);

  const handleClose = () => {
    setVisbile(false);
  };

  const handleVisibleChange = (newValue: boolean) => {
    setVisbile(newValue);
  };

  const Content =
    <Menu onClick={handleClose}>
      {options.map(
        ({options: nestedOptions, hidden, title, ...restProps}) => {
          if (nestedOptions === undefined) {
            const Component = hidden ? StyledMenuItemHidden : StyledMenuItem;
            return (
              <Component {...restProps} key={title}>
                {title}
              </Component>
            );
          }

          const Component = hidden ? StyledMenuSubMenuItem : Menu.SubMenu;
          return (
            <Component {...restProps} key={title} title={title}>
              {nestedOptions.map((smProps) =>
                <StyledMenuItem {...smProps} key={`${title}-${smProps.title}`}>
                  {smProps.title}
                </StyledMenuItem>
              )}
            </Component>
          );
        }
      )}
    </Menu>;

  return (
    <>
      <StyledPopover
        content={Content}
        trigger={['click']}
        placement="left"
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <IconContainer>
          <IoEllipsisVertical color={color} size={24} />
        </IconContainer>
      </StyledPopover>
    </>
  );
}

const StyledPopover = styled(Popover)`
  & .ant-popover {
    animation-duration: 0s;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.span`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledMenuItemHidden = styled(Menu.Item)`
  display: none;

  ${device.desktop} {
    display: flex;
  }
`;

const StyledMenuSubMenuItem = styled(Menu.SubMenu)`
  display: none;

  ${device.desktop} {
    display: flex;
  }
`;
