import * as React from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';

import {Button, Typography} from '../General';
import {Empty} from '../DataDisplay';

export function AskLogin(): JSX.Element {
  const navigate = useNavigate();
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      imageStyle={{
        height: 80
      }}
      description={
        <Typography variant="primary" as="p">
          To have a chance at seeing more, you&apos;d need an account
        </Typography>
      }
    >
      <ButtonContainer>
        <Button
          type="primary"
          onClick={() => {
            navigate('/login');
          }}
        >
          Sign In
        </Button>
        <Button
          type="primary"
          onClick={() => {
            navigate('/register');
          }}
        >
          Sign Up
        </Button>
      </ButtonContainer>
    </Empty>
  );
}

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  flex-direction: row;
`;
