import React, {ChangeEvent, KeyboardEvent, useRef, useEffect} from 'react';
import styled from 'styled-components';
import {IoPlay} from '@react-icons/all-files/io5/IoPlay';
import {noop} from 'lodash';

import {Button, InputTextArea} from '@components/common';
import {ThemeVarNames} from '@theme';

import {KeyOptions} from './helpers';

export type InputProps = {
  color: string;
  value: string;
  spellCheck?: boolean;
  autoFocus?: boolean;
  currentMod?: string;
  cursor: number;
  onRun: () => void;
  updateCurrentModKey: (modKey: string) => void;
  onChange: (value: string) => void;
  onCursor?: (value: number) => void;
  onDown?: (value: boolean) => void;
  onKeyDown: (key: KeyOptions) => void;
  isDropdownOpen: boolean;
  disabled?: boolean;
  showPlayIcon?: boolean;
};

export function Input(props: InputProps): JSX.Element {
  const {
    color,
    onRun,
    value,
    spellCheck = false,
    autoFocus = false,
    currentMod,
    updateCurrentModKey,
    onChange,
    cursor,
    onCursor = noop,
    onKeyDown,
    isDropdownOpen,
    disabled = false,
    showPlayIcon = true
  } = props;

  const textArea = useRef<any>(null);

  useEffect(() => {
    if (autoFocus) {
      textArea.current?.focus?.();
    }
  }, [autoFocus]);

  useEffect(() => {
    if (textArea) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      textArea.current.resizableTextArea.textArea.selectionStart = cursor;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      textArea.current.resizableTextArea.textArea.selectionEnd = cursor;
    }
  }, [cursor]);

  const handleOnKeyUp = (e: KeyboardEvent) => {
    if (e.shiftKey) {
      return;
    }

    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onCursor(textArea.current.resizableTextArea.textArea.selectionStart);
    }
  };

  const handleOnClick = () => {
    const {selectionStart, selectionEnd} =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      textArea.current.resizableTextArea.textArea;

    if (selectionStart === selectionEnd) {
      onCursor(selectionStart);
    }
  };

  const handleOnKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      onRun();
    }
  };

  const handleOnKeyDown = (e: KeyboardEvent) => {
    if (!isDropdownOpen) {return;}

    if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'Escape') {
      e.preventDefault();
      onKeyDown(e.key);
    }

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onKeyDown(e.key);
    }
  };

  const onFocus = () => {
    updateCurrentModKey(currentMod);
  };

  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onCursor(textArea.current.resizableTextArea.textArea.selectionStart);
    onChange(e.target.value);
  };

  return (
    <TextContainer>
      <StyledInput
        ref={textArea}
        data-id="code"
        variant="alternative"
        value={value}
        onChange={handleOnChange}
        onKeyPress={handleOnKeyPress}
        onKeyUp={handleOnKeyUp}
        onKeyDown={handleOnKeyDown}
        onClick={handleOnClick}
        spellCheck={false}
        autoFocus={autoFocus}
        autoSize={{minRows: 2, maxRows: 20}}
        onFocus={onFocus}
        disabled={disabled}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        placeholder = {!showPlayIcon ? 'Show me the stock price of ...' : ''}
      />
      {
        showPlayIcon ?
          <RunIcon data-id="run" id="run-icon" color={color} size={18} onClick={() => onRun()} /> :
          <Button className="w-24 h-16 py-2 mr-2 rounded-md" type="primary" onClick={() => onRun()}>Ask Rose</Button>
      }
    </TextContainer>
  );
}

const TextContainer = styled.div`
  grid-area: input;
  width: 100%;
  height: auto;
  min-height: 80px;
  display: flex;
  background: var(${ThemeVarNames.InputAlternative});
  border-radius: 10px;
  border: 1px solid;
  border-color: var(${ThemeVarNames.Border});
  justify-content: center;
  align-items: center;

  textarea {
    border: none;

    :focus {
      box-shadow: none;
    }

    :hover {
      border: none;
    }
  }
`;

const RunIcon = styled(IoPlay)`
  fill: ${(p: any) => p.color};
  margin-right: 10px;

  :hover {
    cursor: pointer;
  }
`;

const StyledInput = styled(InputTextArea)`
  width: 100%;
  display: block;
  resize: none;
  padding: 10px;
  border: none;
  border-radius: 10px;
  white-space: pre-line;
  overflow: auto;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;
