import * as React from 'react';
import { Typography } from '@components/common';

export default function CreditsModal() {
  return (
    <>
      <Typography size="large">Add a payment method to continue</Typography>
      <br />
      <Typography size="medium">Welcome to Rose AI! For just $14.99 per month, you gain access to Ask Rose. This subscription covers 500 queries per month and provides you with dedicated support for any questions or assistance you need.</Typography>
    </>
  );
}
