import axios, {AxiosError} from 'axios';
import {Theme} from '../../theme/types';
import {HttpClient} from './HttpClient';

/**
 * API client for the `ask rose` services
 * @singleton
 * @extends HttpClient
 */
class AskRoseAPI extends HttpClient {
  private static _instance: AskRoseAPI;

  private constructor() {
    super('');
  }

  public static get instance(): AskRoseAPI {
    if (!AskRoseAPI._instance) {
      AskRoseAPI._instance = new AskRoseAPI();
    }

    return AskRoseAPI._instance;
  }

  // public getBest = <T = any, P = string>(prompt: P): Promise<T> =>
  //   this.instance.get(`rose%3Aask(${prompt})`)
  //     .then((res) => res.data.values.data[0][1])
  //     .catch((err) => console.error(err));

  public ask = async (prompt: string, theme: Theme) =>
    await this.instance.get('askrose', {params: {request: prompt, theme}})
      .then((res) => res?.data)
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          const responseData: any = err.response?.data;
          const message = responseData?.message;
          throw new Error(message);
        } else {
          throw err;
        }
      })

  public feedback = (id: string, feedback: number) => this.instance.post(
    'askrose/feedback', {id, feedback}
  );

  public getAccess = (accessCode: string) => this.instance.post(
    '/askrose/activate', {accessCode}
  );

  public subscribe = async () => this.instance.post('askrose/subscribe');
}

const AskRoseService = AskRoseAPI.instance;

export default AskRoseService;
