/* eslint-disable camelcase */
const splitByCharacterOuterLevel = function (line, character) {
  const words = []; // initialize array of inputs
  let last_split_point = 0;
  let can_split = 0;

  // for loop to clean rose codes, make them legible strings without commas and parantheses, unless these characters are within the object's rose name
  for (let j = 0; j < line.length; j += 1) {
    const char = line[j];
    if (char === character && can_split === 0) {
      words.push(line.slice(last_split_point, j));
      last_split_point = j + 1;
    } else if (char === '(') {
      can_split += 1;
    } else if (char === ')') {
      can_split -= 1;
    }
  }

  // adds cleaned rose codes to the array
  words.push(line.slice(last_split_point, line.length));

  for (let j = 0; j < words.length; j += 1) {
    words[j] = words[j].trim();
  }

  return words;
};

export default splitByCharacterOuterLevel;
