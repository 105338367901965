import * as React from 'react';
import styled from 'styled-components';
import {message} from 'antd';
import {SelectValue} from 'antd/lib/select';

import {Button, Input, InputNumber, Select, SelectOption} from '@components/common';
import {Interval} from '@types';
import {useSellingAdd} from '@service/useSelling';
import {device} from '@utils/breakpoints';

export type Package = {
  code: string
  price: number
  interval: Interval
}

export type InputPackage = {
  onAdd: (pkg: InputPackage) => void
}

export function InputPackage(): JSX.Element {
  const inputCodeRef = React.useRef<any>(null);
  const [inputCode, setInputCode] = React.useState<Package['code']>('');
  const [inputPrice, setInputPrice] = React.useState<Package['price'] | null>(
    null
  );
  const [inputInterval, setInputInterval] = React.useState<
    Package['interval'] | null
  >(null);

  const mutation = useSellingAdd();

  const canSave =
    inputCode !== '' && inputPrice !== null && inputInterval !== null;

  const focusInputCode = () => {
    inputCodeRef.current.focus();
  };

  const cleanForm = () => {
    setInputCode('');
    setInputPrice(null);
    setInputInterval(null);
    focusInputCode();
  };

  const handleOnCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputCode(e.target.value);
  };

  const handleOnPriceChange = (value: number) => {
    setInputPrice(value);
  };

  const handelOnIntervalChange = (value: SelectValue) => {
    setInputInterval(value as Interval);
  };

  const handleOnAddClick = () => {
    if (canSave) {
      mutation.mutate(
        {
          code: inputCode,
          price: inputPrice.toString(),
          interval: inputInterval
        },
        {
          onError(err) {
            const responseData: any = err.response?.data;
            message.error(responseData?.message);
          },
          onSuccess() {
            message.success('Successfully added');
            cleanForm();
          }
        }
      );
    }
  };

  return (
    <StyledInputContainer>
      <StyledInput
        ref={inputCodeRef}
        variant="alternative"
        autoFocus
        placeholder="Rosecode"
        value={inputCode}
        onChange={handleOnCodeChange}
      />
      <StyledInputNumber
        min={0}
        variant="alternative"
        value={inputPrice}
        onChange={handleOnPriceChange}
        formatter={formatPrice}
        parser={parsePrice}
      />
      <StyledSelect
        value={inputInterval}
        variant="alternative"
        onChange={handelOnIntervalChange}
        placeholder="Interval"
      >
        <SelectOption value="day">{Interval.toFriendlyText('day')}</SelectOption>
        <SelectOption value="week">{Interval.toFriendlyText('week')}</SelectOption>
        <SelectOption value="month">{Interval.toFriendlyText('month')}</SelectOption>
        <SelectOption value="year">{Interval.toFriendlyText('year')}</SelectOption>
      </StyledSelect>
      <StyledButton
        onClick={handleOnAddClick}
        disabled={!canSave}
        type="primary"
        loading={mutation.isLoading}
      >
        Add
      </StyledButton>
    </StyledInputContainer>
  );
}

function formatPrice(price: number): string {
  return `$ ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function parsePrice(displayValue: string) {
  return Number(displayValue.replace(/\$\s?|(,*)/g, ''));
}

export const StyledInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  grid-template-areas:
    'code code'
    'price interval'
    'button button';
  padding-bottom: 20px;
  border-bottom: 1px solid hsl(0 0% 90%);

  ${device.tablet} {
    grid-template-areas: 'code price interval button';
    grid-template-columns: var(--grid-template-columns);
    gap: var(--gap);
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const StyledInput = styled(Input)`
  grid-area: code;
`;

const StyledInputNumber = styled(InputNumber)`
  grid-area: price;
  width: 100%;
`;

const StyledSelect = styled(Select)`
  grid-area: interval;
`;

const StyledButton = styled(Button)`
  grid-area: button;
`;
