import * as React from 'react';
import styled from 'styled-components';

type LogoSize = 'small' | 'medium' | 'large' | number
type LogoColor = 'white' | 'gradient' | 'black'
type ImageColor = 'white' | 'gradient' | 'black'

export type LogoProps = {
  showText?: boolean
  imageColor?: ImageColor
  textColor?: LogoColor
  size?: LogoSize
}

export function Logo(props: LogoProps): JSX.Element {
  const {
    textColor = 'white',
    showText = false,
    size = 'medium',
    imageColor = 'gradient'
  } = props;

  return (
    <Container>
      <Image
        color={imageColor}
        size={size}
        src={`/img/new_logo/svg/Roselogo-${imageColor}.svg`}
        alt="logo"
      />
      {showText && <Title color={textColor}>Rose</Title>}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img<{ size: LogoSize; color: ImageColor }>`
  height: ${(p) => {
    switch (p.size) {
    case 'small':
      return '32px';
    case 'medium':
      return '50px';
    case 'large':
      return '75px';
    default:
      return `${p.size}px`;
    }
  }};
  width: ${(p) => {
    switch (p.size) {
    case 'small':
      return '32px';
    case 'medium':
      return '50px';
    case 'large':
      return '75px';
    default:
      return `${p.size}px`;
    }
  }};
  filter: ${(p) => {
    switch (p.color) {
    case 'gradient':
      return null;
    case 'white':
      return 'brightness(2000%)';
    default:
      return null;
    }
  }};
`;

const Title = styled.h2<{ color: LogoColor }>`
  margin-left: 10px;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 24px;
  color: ${({color}) => {
    switch (color) {
    case 'black':
      return 'black';
    case 'white':
      return 'white';
    default:
      return null;
    }
  }};
`;
