export type Percentage = `${number}%`

export const Percentage = {

  // e.g. '33%' => 33.333...
  toNumber(p: Percentage): number {
    const widthWithoutPercent = p?.slice?.(0, -1) ?? '100';
    const widthAsNumber = parseInt(widthWithoutPercent, 10);
    if (widthAsNumber === 33) {
      return parseFloat((1 / 3 * 100).toFixed(2));
    }

    if (widthAsNumber === 66) {
      return parseFloat((2 / 3 * 100).toFixed(2));
    }

    return widthAsNumber;
  },

  // e.g. 33.333... => '33%'
  toPercentage(num: unknown): Percentage {
    if (Percentage.isPercentage(num)) {
      return num as Percentage;
    }

    if (typeof num === 'number') {
      return `${Math.floor(num)}%` as Percentage;
    }

    if (typeof num === 'string') {
      const numberInStringFormat = /(^[0-9]+$)|^[0-9]+\.[0-9]+$/;
      if (numberInStringFormat.test(num)) {
        return `${num}%` as Percentage;
      }
    }

    return '100%';
  },

  isPercentage(value: unknown): boolean {
    if (typeof value !== 'string') {
      return false;
    }

    const match = /(^[0-9]+%$)|^[0-9]+\.[0-9]+%$/;
    return match.test(value);
  }
};

// -----

export type Interval =
  | 'day'
  | 'week'
  | 'month'
  | 'year'

export const Interval = {
  toFriendlyText(interval: Interval): string {
    switch (interval) {
    case 'day': return 'Daily';
    case 'week': return 'Weekly';
    case 'month': return 'Monthly';
    case 'year': return 'Annually';
    default: return '';
    }
  }
};
