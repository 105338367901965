import axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import { getBackendUrl } from '@config';

/**
 * HttpClient to extend to create an API client instance
 * @class
 * @abstract
 * @param baseURL: url parameter extension from the backend base URL
 */
export abstract class HttpClient {
  protected readonly instance: AxiosInstance;
  protected readonly withCredentials: boolean;
  private backendURL: string;

  public constructor(baseURL: string) {
    this.withCredentials = true;process.env.NODE_ENV !== 'production';
    this.backendURL = getBackendUrl(); //process.env.NODE_ENV !== 'production' ? getBackendUrl() : '';

    this.instance = axios.create({
      baseURL: this.backendURL + baseURL,
      withCredentials: this.withCredentials
    });
    this._initializeResponseInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse
    );
  };

  private _handleResponse = (res: AxiosResponse) => res;

  protected _handleError = (error: AxiosError) => {
    console.error(error.message);
    return Promise.reject(error);
  }
}
