import {useState, useEffect} from 'react';
import {createContainer} from 'unstated-next';

function useDashboard() {
  const [dashboard, setDashboard] = useState('notebook');

  useEffect(() => {
    try {
      const persistedState = window.sessionStorage.getItem('dashboard');
      if (persistedState) {
        setDashboard(persistedState);
      }
    } catch {
      setDashboard('notebook');
    }
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem('dashboard', dashboard);
  }, [dashboard]);

  return {dashboard, setDashboard};
}

const DashboardContainer = createContainer(useDashboard);

export default DashboardContainer;
