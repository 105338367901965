import styled from 'styled-components';

const ContentContainer = styled.div`
  position: relative;
  top: 80px;
  padding: 24px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #111928;
`;
const ContentWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const ContentP = styled.p`
  margin: 8px 8px;
  color: white;
`;

const ContentH2 = styled.h2`
  margin: 24px 0;
  color: white;
  font-weight: 700;
`;

const ContentH4 = styled.h4`
  margin: 16px 8px;
  margin-bottom: 4px;
  font-weight: 700;
  color: white
`;

export {ContentContainer, ContentWrapper, ContentP, ContentH2, ContentH4};
