import React from 'react';
import {Link} from 'react-router-dom';
import UserContainer from '@utils/state/userContainer';

const BannerSection = () => {
  const {user} = UserContainer.useContainer();

  return (
    <div
      style={{
        background: 'url("/img/banner-background.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}
      className="min-h-screen pt-36 lg:pt-24 px-5 md:px-20 xl:px-28 flex flex-col lg:flex-row items-center pb-32 lg:pb-0 gap-10 lg:gap-0">
      <div className="w-full lg:w-1/2 text-center lg:text-left flex flex-col items-center lg:items-start">
        <div className="text-white font-semibold text-[42px] lg:text-[54px] xl:text-[60px] 2xl:text-[64px] leading-tight text-center lg:text-left">
          Ask a Question <br />
          Get a Chart
        </div>
        {!user?.id ?
          <Link
            to="/register"
            className="no-underline bg-primary py-3 px-10 rounded-full !text-white font-bold mt-4"
          >
            Sign up
          </Link> : ''}
      </div>
      <div className="w-full lg:w-1/2 grow-0 pt-10 lg:pt-0 relative aspect-square md:aspect-[12/5]">
        <img
          src="/img/banner-content8.svg"
          alt="banner-content"
          className="absolute inset-0 w-full h-full object-contain md:object-cover rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
};

export default BannerSection;
