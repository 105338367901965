import React, { useEffect } from 'react';

/**
 * https://stackoverflow.com/a/72495965/
 */
function ExternalRedirect({ url }: { url: string }) {

    useEffect(() => {
        console.log(`Redirecting to ${url}...`);
        window.location.replace(url);
    }, [url]);

    return <div>Redirecting...</div>;
}

export default ExternalRedirect;
