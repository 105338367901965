import * as React from 'react';
import {Modal as AntdModal, ModalPropsWithChildren as AntdModalProps} from 'antd';
import styled from 'styled-components';

import {Portal} from '../../Portal';

export type ModalProps = AntdModalProps & {
  getContainer?: boolean;
};

/**
 * I do not know why the Modal is scrolling to top so I did this trick
 * set getContainer={false} so antd design does not reneder the modal in a Portal
 * Then I explictily wrapp the Modal in the Portal component
 *
 * I do not why but this just works
 *
 */
export function Modal(props: ModalProps) {
  if (!props.visible) {
    return null;
  }

  return (
    <Portal>
      <StyledModal {...props} getContainer={false}>
        {props.children}
      </StyledModal>
    </Portal>
  );
}

const StyledModal = styled<React.FC<AntdModalProps>>(AntdModal)`
  .ant-modal-content {
    background-color: var(--rose-secondary-bg);
    box-shadow: var(--rose-shadow);
    border-color: var(--rose-primary-bg);
    color: var(--rose-primary-text);
  }

  .ant-modal-header {
    background-color: inherit;
    border-color: inherit;
    color: inherit;
  }

  .ant-modal-title {
    color: var(--rose-secondary-text);
  }

  .ant-modal-body {
    background-color: inherit;
    border-color: inherit;
  }

  .ant-modal-footer {
    background-color: inherit;
    border-color: inherit;
  }

  .ant-modal-close-x {
    color: var(--rose-primary-text);
  }
`;
