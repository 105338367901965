import * as React from 'react';
import styled from 'styled-components';
import {Layout} from 'antd';
import {useQueryClient} from '@tanstack/react-query';
import UserContainer from '@utils/state/userContainer';
import {Button, Tabs, Typography} from '@components/common';
import CompanyAccessTab from './Tabs/CompanyAccessTab';
import GenericDataset from './GenericDataset';

const {Header} = Layout;
const {TabPane} = Tabs;

const StyledLayout = styled(Layout)`
  background-color: inherit;
  margin: 0 20px;
`;

const StyledHeader = styled(Header)`
  background-color: inherit;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  flex-wrap: wrap-reverse;
  height: auto;
  margin-bottom: 20px;
`;
const GoBackButton = styled(Button)`
  margin-top: 20px;
`;
const StyledTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    justify-content: center;
  }

  .ant-tabs-content-holder {
    overflow: scroll;
  }
`;

type Props = {
  organization: any
  goBack: () => void
}

export default function Sidebar({organization, goBack}: Props): JSX.Element {
  const {user} = UserContainer.useContainer();
  const queryClient = useQueryClient();

  return (
    <StyledLayout>
      <StyledHeader>
        <Typography variant="primary" as="h1" size="xx-large" id="page-title">
          {`Sharing Center: ${organization.code}`}
        </Typography>
        <GoBackButton size="large" onClick={goBack} id="go-back">
          Go Back
        </GoBackButton>
      </StyledHeader>
      <StyledTabs defaultActiveKey="share-tab" centered>
        <TabPane tab="Share Datasets" key="share-tab" id="share-tab">
          <GenericDataset organization={organization} cacheType="to-share" updateShareAndRevokeLists={() => {
            queryClient.invalidateQueries(['sharing-center']);
          }}/>
        </TabPane>
        <TabPane
          tab="View Shared Datasets"
          key="view-shared-tab"
          id="view-shared-tab"
        >
          <GenericDataset organization={organization} cacheType="to-revoke" updateShareAndRevokeLists={() => {
            queryClient.invalidateQueries(['sharing-center']);
          }}/>
        </TabPane>
        {user.isAdmin && user.actor ?
          <TabPane tab="Share Company Access" key="3">
            <CompanyAccessTab organization={organization} />
          </TabPane> :
          null}
      </StyledTabs>
    </StyledLayout>
  );
}
