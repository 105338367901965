import * as React from 'react';
import styled from 'styled-components';
import {Input as AntdInput} from 'antd';
import {
  TextAreaProps as AntdTextAreaProps,
  PasswordProps as AntdPasswordProps,
  SearchProps as AntdSearchProps,
  InputProps as AntdInputProps
} from 'antd/lib/input';

import {ThemeVarNames} from '@theme';

export type ExtraProps = {
  variant?: 'primary' | 'alternative'
};

export type InputProps = AntdInputProps & ExtraProps
export type InputPasswordProps = AntdPasswordProps & ExtraProps
export type InputTextAreaProps = AntdTextAreaProps & ExtraProps
export type InputSearchProps = AntdSearchProps & ExtraProps

export const Input = React.forwardRef<any, InputProps>(
  (props, ref) =>
    <StyledInput
      {...props}
      ref={ref}
      className={`${props.className} ${props.variant ?? 'primary'}`}
    />
);

export const InputPassword = React.forwardRef<any, InputPasswordProps>(
  (props, ref) =>
    <StyledInputPassword
      {...props}
      ref={ref}
      className={`${props.className} ${props.variant ?? 'primary'}`}
    />
);

export const InputTextArea = React.forwardRef<any, InputTextAreaProps>(
  (props, ref) =>
    <StyledInputTextArea
      {...props}
      ref={ref}
      className={`${props.className} ${props.variant ?? 'primary'}`}
    />
);

export const InputSearch = React.forwardRef<any, InputSearchProps>(
  (props, ref) =>
    <StyledInputSearch
      {...props}
      ref={ref}
      className={`${props.className} ${props.variant ?? 'primary'}`}
    />
);

export const BaseInputStyle = styled(AntdInput)`
  --bg: var(${ThemeVarNames.InputPrimary});

  &.alternative {
    --bg: var(${ThemeVarNames.InputAlternative});
  }

  color: var(${ThemeVarNames.PrimaryText});
  border-color: var(${ThemeVarNames.Border});

  ::placeholder {
    color: var(${ThemeVarNames.TertiaryText});
  }
`;

const StyledInput = styled(BaseInputStyle)`
  --bg: var(${ThemeVarNames.InputPrimary});

  &.alternative {
    --bg: var(${ThemeVarNames.InputAlternative});
  }

  background-color: var(--bg) !important;
  color: var(${ThemeVarNames.PrimaryText}) !important;
  border-color: var(${ThemeVarNames.Border});

  &.ant-input-affix-wrapper,
  input {
    background-color: var(--bg) !important;
    color: var(${ThemeVarNames.PrimaryText});
    border-color: var(${ThemeVarNames.Border});
  }

  :focus-within {
    border-color: var(${ThemeVarNames.Focus});
  }
`;

const StyledInputPassword = styled(BaseInputStyle.Password)`
  --bg: var(${ThemeVarNames.InputPrimary});

  &.alternative {
    --bg: var(${ThemeVarNames.InputAlternative});
  }

  border-color: var(${ThemeVarNames.Border});
  background-color: var(--bg) !important;
  color: var(${ThemeVarNames.PrimaryText}) !important;

  .ant-input-affix-wrapper,
  input {
    background-color: var(--bg) !important;
    color: var(${ThemeVarNames.PrimaryText});
    border-color: var(${ThemeVarNames.Border});
  }

  .anticon {
    color: var(${ThemeVarNames.PrimaryText});
  }

  :focus-within {
    border-color: var(${ThemeVarNames.Focus});
  }
`;

const StyledInputTextArea = styled(BaseInputStyle.TextArea)`
  --bg: var(${ThemeVarNames.InputPrimary});

  &.alternative {
    --bg: var(${ThemeVarNames.InputAlternative});
  }

  border-color: var(${ThemeVarNames.Border});
  background-color: var(--bg) !important;
  color: var(${ThemeVarNames.PrimaryText}) !important;

  &.ant-input-affix-wrapper,
  input {
    background-color: var(--bg);
    color: var(${ThemeVarNames.PrimaryText});
    border-color: var(${ThemeVarNames.Border});
  }

  .anticon {
    color: var(${ThemeVarNames.PrimaryText});
  }

  :focus-within {
    box-shadow: var(${ThemeVarNames.ShadowFocus});
    border-color: transparent;
  }
`;

const StyledInputSearch = styled(BaseInputStyle.Search)`
  --bg: var(${ThemeVarNames.InputPrimary});

  &.alternative {
    --bg: var(${ThemeVarNames.InputAlternative});
  }

  background-color: var(--bg);

  .ant-input {
    background-color: inherit;
    color: var(${ThemeVarNames.PrimaryText});
    border-color: var(${ThemeVarNames.Border});
  }

  .ant-input-group-addon {
    background-color: inherit;

    .ant-btn {
      background-color: inherit;
      color: var(${ThemeVarNames.PrimaryText}) !important;
      border-color: var(${ThemeVarNames.Border});
      border-left: none;
    }
  }
`;
