import styled from 'styled-components';
import * as React from 'react';

import {ThemeVarNames} from '@theme';
import {RoseDecision} from '@types';

export type DecisionProps = {
  roseObject: RoseDecision
}

export function Decision({roseObject}: DecisionProps): JSX.Element {
  return (
    <StyledContainer>
      <StyledText>{roseObject.decision === 'yes' ? 'Yes' : 'No'}</StyledText>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledText = styled.span`
  font-size: 32px;
  font-weight: 700;
  color: var(${ThemeVarNames.PrimaryText});
`;
