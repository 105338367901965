import * as React from 'react';
import styled from 'styled-components';

import {RoseObject} from '@types';
import {Button} from '@components/common';

import {Warnings} from './Warnings';

export type WarningsContainerProps = {
  result: RoseObject
}

export function WarningsContainer(props: WarningsContainerProps): JSX.Element {
  const {result} = props;
  const [toggleWarnings, setToggleWarnings] = React.useState(true);

  const checkWarningArray = () => {
    if (result.type === 'timeseries-group') {
      return result.warnings.some((w) => w.value.length > 0);
    }

    return result.warnings.length > 0;
  };

  if (result.type !== 'timeseries-group') {
    return (
      <StyledWarningsContainer>
        {checkWarningArray() ?
          <StyledWarningButtonContainer>
            <Button
              type="ghost"
              onClick={() => {
                setToggleWarnings(!toggleWarnings);
              }}
            >
              {toggleWarnings ? 'Hide Warnings' : 'Show Warnings'}
            </Button>
          </StyledWarningButtonContainer> :
          null}
        {toggleWarnings ?
          <Warnings roseCode={result.code} warnings={result.warnings} /> :
          null}
      </StyledWarningsContainer>
    );
  }

  return (
    <StyledWarningsContainer>
      {checkWarningArray() ?
        <StyledWarningButtonContainer>
          <Button
            type="ghost"
            onClick={() => {
              setToggleWarnings(!toggleWarnings);
            }}
          >
            {toggleWarnings ? 'Hide Warnings' : 'Show Warnings'}
          </Button>
        </StyledWarningButtonContainer> :
        null}
      {toggleWarnings ?
        result.warnings.map((w) =>
          <Warnings key={w.code} roseCode={w.code} warnings={w.value} />
        ) :
        null}
    </StyledWarningsContainer>
  );
}

const StyledWarningsContainer = styled.div`
  display: none;
  flex-direction: column;
  gap: 10px;
`;

const StyledWarningButtonContainer = styled.div`
  margin-left: auto;
  margin-right: 0;
  width: auto;
`;
