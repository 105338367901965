import React, {useState} from 'react';

import {UseMutationResult} from '@tanstack/react-query';
import {Typography} from '@components/common';

import {RoseNotebook} from '@types';
import {MutationType} from '@service/useNotebook';
import {CreateNewNotebookModal} from '../common/Save';
import {
  NotebookTitleContainer,
  NotebookTitleWrapper,
  NotebookTitleWrapperReadOnly
} from './styles';

export type TitleProps = {
  value: string
  isReadOnly: boolean
  notebook: RoseNotebook
  mutation: UseMutationResult<RoseNotebook, any, MutationType, unknown>
}

export function Title(props: TitleProps): JSX.Element {
  const {value, isReadOnly, notebook, mutation} = props;
  const [visible, setVisible] = useState(false);

  const exist = value !== '';
  const titleText = exist ? value : 'Untitled';

  const handleOnClickTitle = () => {
    setVisible(true);
  };

  // I disabled the event using CSS (pointer-events: none) that's why
  // the onClick event is not tiggered in
  // NotebookTitleWrapperReadOnly component
  const TitleWrapper = isReadOnly ?
    NotebookTitleWrapperReadOnly :
    NotebookTitleWrapper;

  return (
    <div id="notebook-title">
      <NotebookTitleContainer scrollTitle={false}>
        <TitleWrapper onClick={handleOnClickTitle}>
          <Typography as="h2" variant="secondary" size="large">
            {titleText}
          </Typography>

        </TitleWrapper>
      </NotebookTitleContainer>
      <CreateNewNotebookModal
        visible={visible}
        setVisible={setVisible}
        code={exist ? value : ''}
        type={exist ? 'rename' : 'create'}
        notebook={notebook}
        mutation={mutation}
      />
    </div>
  );
}
