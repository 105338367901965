import {Interval} from '../helpers';
import {RoseUser} from '../RoseUser';

export type MarketplacePackage = {
  code: string
  price: number
  interval: Interval
  actorId: number
}

export type FullMarketPlacePackage = MarketplacePackage & {promocode?: string}

export const MarketplacePackage = {
  isOwner(user: RoseUser, pkg: MarketplacePackage): boolean {
    return [user.actorId, user.id].includes(pkg.actorId);
  }
};
