/**
 * if you want to add a new theme you just have to add a new type here and
 * then follow the compiler
 */
export type Theme = 'dark' | 'light' | 'contrast';

/**
  * this should be called inside styled-components like this:
  *
  * styled.div`
  *   color: var(${ThemeVarNames.PrimaryBg});
  * `
  */
export enum ThemeVarNames {

  // default colors
  Blue = '--blue',
  Yellow = '--yellow',
  Red = '--red',
  Green = '--green',
  Orange = '--orange',
  Brown = '--brown',
  Purple = '--purple',
  Grey = '--grey',

  PrimaryBg = '--rose-primary-bg',
  SecondaryBg = '--rose-secondary-bg',
  TertiaryBg = '--rose-tertiary-bg',
  PrimaryText = '--rose-primary-text',
  TertiaryText = '--rose-tertiary-text',
  SecondaryText = '--rose-secondary-text',
  Hover = '--rose-hover',
  HoverText = '--rose-hover-text',
  PrimaryBrand = '--rose-primary-brand',
  SecondaryBrand = '--rose-secondary-brand',
  Border = '--rose-border',
  Focus = '--rose-focus',
  ShadowFocus = '--rose-shadow-focus',
  Shadow = '--rose-shadow',
  Disabled = '--rose-disabled',
  DisabledLight = '--rose-disabled-light',
  DisabledDark = '--rose-disabled-dark',

  // buttons
  Action = '--rose-action',
  ActionDark = '--rose-action-dark',
  ActionContrast = '--rose-action-contrast',
  Error = '--rose-error',
  ErrorLight = '--rose-error-light',
  ErrorDark = '--rose-error-dark',
  ErrorContrast = '--rose-error-contrast',
  Warning = '--rose-warning',
  WarningLight = '--rose-warning-light',
  WarningDark = '--rose-warning-dark',
  Info = '--rose-info',
  InfoLight = '--rose-info-light',
  InfoDark = '--rose-info-dark',
  Success = '--rose-success',
  SuccessLight = '--rose-success-light',
  SuccessDark = '--rose-success-dark',

  // inputs
  InputPrimary = '--rose-input-primary',
  InputAlternative = '--rose-input-alternative',

  // charts
  Timeseries1 = '--rose-timeseries-1',
  Timeseries2 = '--rose-timeseries-2',
  Timeseries3 = '--rose-timeseries-3',
  Timeseries4 = '--rose-timeseries-4',
  Timeseries5 = '--rose-timeseries-5',
  Timeseries6 = '--rose-timeseries-6',
  Timeseries7 = '--rose-timeseries-7',
  Timeseries8 = '--rose-timeseries-8',
  Timeseries9 = '--rose-timeseries-9',
  TimeseriesNeutral = '--rose-timeseries-neutral',
  SharedAxis1 = '--rose-shared-axis-1',
  SharedAxis2 = '--rose-shared-axis-2',
  SharedAxis3 = '--rose-shared-axis-3',
  SharedAxis4 = '--rose-shared-axis-4',
  SharedAxis5 = '--rose-shared-axis-5',
  /**
   * See ThemeAndOverriders in `assets/js/app/theme/GlobalStyle.tsx` and the weirdness in the ObservableHQ Plot library.
   */
  PlotBackground = '--plot-background'
}

type ThemesMap = {
  [key in Theme]: ThemeKeyValue;
};

type ThemeKeyValue = {
  [key in ThemeVarNames]: string;
};

/**
  * this is useful when you need theme values in javascript. e.g add theming to highcharts
  *
  * highcharts theming example
  * colors: [
  *   themeValuesMap[ThemeVarNames.Timeseries1],
  *   themeValuesMap[ThemeVarNames.Timeseries2],
  * ]
  */
export const themesMap: ThemesMap = {
  dark: {
    [ThemeVarNames.Blue]: '#53ADF4',
    [ThemeVarNames.Yellow]: '#E2B923',
    [ThemeVarNames.Red]: '#D94240',
    [ThemeVarNames.Green]: '#04A225',
    [ThemeVarNames.Orange]: '#F56421',
    [ThemeVarNames.Brown]: '#9F654F',
    [ThemeVarNames.Purple]: '#C31ABC',
    [ThemeVarNames.Grey]: '#A3A3A7',
    [ThemeVarNames.PrimaryBg]: 'hsl(217, 24%, 17%)',
    [ThemeVarNames.SecondaryBg]: 'hsl(217, 25%, 22%)',
    [ThemeVarNames.TertiaryBg]: 'hsl(217, 24%, 17%)',
    [ThemeVarNames.PrimaryText]: '#e6e6e6',
    [ThemeVarNames.SecondaryText]: '#cccccc',
    [ThemeVarNames.TertiaryText]: '#8c8c8c',
    [ThemeVarNames.Hover]: 'hsl(217, 24%, 35%)',
    [ThemeVarNames.HoverText]: 'hsl(0, 0%, 55%)',
    [ThemeVarNames.PrimaryBrand]: 'hsl(357, 82%, 65%)',
    [ThemeVarNames.SecondaryBrand]: 'hsl(209, 77%, 48%)',
    [ThemeVarNames.Border]: 'hsl(218, 23%, 13%)',
    [ThemeVarNames.Focus]: 'hsl(210, 100%, 64%)',
    [ThemeVarNames.ShadowFocus]: '0px 0px 0px 1px var(--rose-focus)',
    [ThemeVarNames.Shadow]: '0px 3px 8px -3px hsl(0deg 0% 0% / 35%)',
    [ThemeVarNames.Disabled]: 'hsl(0, 0%, 35%)',
    [ThemeVarNames.DisabledLight]: 'hsl(0, 0%, 45%)',
    [ThemeVarNames.DisabledDark]: 'hsl(0, 0%, 25%)',
    [ThemeVarNames.Action]: 'hsl(208, 100%, 54%)',
    [ThemeVarNames.ActionDark]: 'hsl(208, 100%, 44%)',
    [ThemeVarNames.ActionContrast]: 'hsl(0, 0%, 100%)',
    [ThemeVarNames.Error]: 'hsl(3, 43%, 46%)',
    [ThemeVarNames.ErrorLight]: 'hsl(1deg 100% 73%)',
    [ThemeVarNames.ErrorDark]: 'hsl(8, 24%, 17%)',
    [ThemeVarNames.ErrorContrast]: 'hsl(0, 0%, 100%)',
    [ThemeVarNames.Warning]: 'hsl(52, 64%, 35%)',
    [ThemeVarNames.WarningDark]: 'hsl(52, 75%, 40%)',
    [ThemeVarNames.WarningLight]: 'hsl(52, 75%, 9%)',
    [ThemeVarNames.Info]: 'hsl(217, 58%, 54%)',
    [ThemeVarNames.InfoLight]: 'hsl(217, 35%, 22%)',
    [ThemeVarNames.InfoDark]: 'hsl(217, 67%, 56%)',
    [ThemeVarNames.Success]: 'hsl(90, 45%, 32%)',
    [ThemeVarNames.SuccessLight]: 'hsl(90, 24%, 17%)',
    [ThemeVarNames.SuccessDark]: 'hsl(90, 38%, 47%)',
    [ThemeVarNames.InputPrimary]: 'hsl(217deg 24% 17%)',
    [ThemeVarNames.InputAlternative]: 'hsl(217deg 25% 23%)',
    [ThemeVarNames.Timeseries1]: '#3598db',
    [ThemeVarNames.Timeseries2]: '#e74c3c',
    [ThemeVarNames.Timeseries3]: '#2ecc71',
    [ThemeVarNames.Timeseries4]: '#9b59b6',
    [ThemeVarNames.Timeseries5]: '#34495e',
    [ThemeVarNames.Timeseries6]: '#f1c40e',
    [ThemeVarNames.Timeseries7]: '#e67e23',
    [ThemeVarNames.Timeseries8]: '#95a5a6',
    [ThemeVarNames.Timeseries9]: '#19bc9c',
    [ThemeVarNames.TimeseriesNeutral]: '#999999',
    [ThemeVarNames.SharedAxis1]: '#e9ecec',
    [ThemeVarNames.SharedAxis2]: '#fff',
    [ThemeVarNames.SharedAxis3]: '#919ea1',
    [ThemeVarNames.SharedAxis4]: 'b2bec3',
    [ThemeVarNames.SharedAxis5]: '7c898d',
    [ThemeVarNames.PlotBackground]: 'hsl(217, 24%, 17%)'
  },
  light: {
    [ThemeVarNames.Blue]: '#53ADF4',
    [ThemeVarNames.Yellow]: '#E2B923',
    [ThemeVarNames.Red]: '#D94240',
    [ThemeVarNames.Green]: '#04A225',
    [ThemeVarNames.Orange]: '#F56421',
    [ThemeVarNames.Brown]: '#9F654F',
    [ThemeVarNames.Purple]: '#C31ABC',
    [ThemeVarNames.Grey]: '#A3A3A7',
    [ThemeVarNames.PrimaryBg]: 'hsl(0, 0%, 100%)',
    [ThemeVarNames.SecondaryBg]: 'hsl(210, 40%, 98%)',
    [ThemeVarNames.TertiaryBg]: 'hsl(0, 0%, 100%)',
    [ThemeVarNames.PrimaryText]: '#1a1a1a',
    [ThemeVarNames.SecondaryText]: '#333333',
    [ThemeVarNames.TertiaryText]: '#8c8c8c',
    [ThemeVarNames.Hover]: 'hsl(0, 0%, 85%)',
    [ThemeVarNames.HoverText]: 'hsl(0, 0%, 55%)',
    [ThemeVarNames.PrimaryBrand]: 'hsl(357, 82%, 65%)',
    [ThemeVarNames.SecondaryBrand]: 'hsl(209, 77%, 48%)',
    [ThemeVarNames.Border]: 'hsl(0, 0%, 85%)',
    [ThemeVarNames.Focus]: 'hsl(210, 100%, 64%)',
    [ThemeVarNames.ShadowFocus]: '0px 0px 0px 1px var(--rose-focus)',
    [ThemeVarNames.Shadow]: '0px 3px 8px -3px hsl(0deg 0% 0% / 35%)',
    [ThemeVarNames.Disabled]: 'hsl(0, 0%, 85%)',
    [ThemeVarNames.DisabledLight]: 'hsl(0, 0%, 95%)',
    [ThemeVarNames.DisabledDark]: 'hsl(0, 0%, 70%)',
    [ThemeVarNames.Action]: 'hsl(208, 100%, 54%)',
    [ThemeVarNames.ActionDark]: 'hsl(208, 100%, 44%)',
    [ThemeVarNames.ActionContrast]: 'hsl(0, 0%, 100%)',
    [ThemeVarNames.Error]: 'hsl(359deg 100% 65%)',
    [ThemeVarNames.ErrorLight]: 'hsl(1deg 100% 73%)',
    [ThemeVarNames.ErrorDark]: 'hsl(359, 100%, 90%)',
    [ThemeVarNames.ErrorContrast]: 'hsl(0, 0%, 100%)',
    [ThemeVarNames.Warning]: 'hsl(46, 100%, 78%)',
    [ThemeVarNames.WarningDark]: 'hsl(39, 95%, 67%)',
    [ThemeVarNames.WarningLight]: 'hsl(50, 100%, 95%)',
    [ThemeVarNames.Info]: 'hsl(203, 100%, 78%)',
    [ThemeVarNames.InfoLight]: 'hsl(199, 100%, 95%)',
    [ThemeVarNames.InfoDark]: 'hsl(209, 100%, 55%)',
    [ThemeVarNames.Success]: 'hsl(94, 70%, 74%)',
    [ThemeVarNames.SuccessLight]: 'hsl(90, 100%, 96%)',
    [ThemeVarNames.SuccessDark]: 'hsl(100, 77%, 44%)',
    [ThemeVarNames.InputPrimary]: 'hsl(0, 0%, 100%)',
    [ThemeVarNames.InputAlternative]: 'hsl(210, 40%, 98%)',
    [ThemeVarNames.Timeseries1]: '#3598db',
    [ThemeVarNames.Timeseries2]: '#e74c3c',
    [ThemeVarNames.Timeseries3]: '#2ecc71',
    [ThemeVarNames.Timeseries4]: '#9b59b6',
    [ThemeVarNames.Timeseries5]: '#34495e',
    [ThemeVarNames.Timeseries6]: '#f1c40e',
    [ThemeVarNames.Timeseries7]: '#e67e23',
    [ThemeVarNames.Timeseries8]: '#95a5a6',
    [ThemeVarNames.Timeseries9]: '#19bc9c',
    [ThemeVarNames.TimeseriesNeutral]: '#666666',
    [ThemeVarNames.SharedAxis1]: '#95a5a6',
    [ThemeVarNames.SharedAxis2]: '#000',
    [ThemeVarNames.SharedAxis3]: '#2d3436',
    [ThemeVarNames.SharedAxis4]: '#b2bec3',
    [ThemeVarNames.SharedAxis5]: '#636e72',
    [ThemeVarNames.PlotBackground]: 'white'
  },
  contrast: {
    [ThemeVarNames.Blue]: '#53ADF4',
    [ThemeVarNames.Yellow]: '#E2B923',
    [ThemeVarNames.Red]: '#D94240',
    [ThemeVarNames.Green]: '#04A225',
    [ThemeVarNames.Orange]: '#F56421',
    [ThemeVarNames.Brown]: '#9F654F',
    [ThemeVarNames.Purple]: '#C31ABC',
    [ThemeVarNames.Grey]: '#A3A3A7',
    [ThemeVarNames.PrimaryBg]: '#000',
    [ThemeVarNames.SecondaryBg]: '#000',
    [ThemeVarNames.TertiaryBg]: '#000',
    [ThemeVarNames.PrimaryText]: '#fff',
    [ThemeVarNames.SecondaryText]: '#fff',
    [ThemeVarNames.TertiaryText]: '#fff',
    [ThemeVarNames.Hover]: 'rgba(255,255,255,0.3)',
    [ThemeVarNames.HoverText]: '#1890FF',
    [ThemeVarNames.PrimaryBrand]: 'hsl(357, 82%, 65%)',
    [ThemeVarNames.SecondaryBrand]: 'hsl(209, 77%, 48%)',
    [ThemeVarNames.Border]: '#fff',
    [ThemeVarNames.Focus]: 'hsl(210, 100%, 64%)',
    [ThemeVarNames.ShadowFocus]: '0px 0px 0px 1px var(--rose-focus)',
    [ThemeVarNames.Shadow]: '0px 3px 8px -3px hsl(0deg 0% 0% / 35%)',
    [ThemeVarNames.Disabled]: 'hsl(0, 0%, 85%)',
    [ThemeVarNames.DisabledLight]: 'hsl(0, 0%, 95%)',
    [ThemeVarNames.DisabledDark]: 'hsl(0, 0%, 70%)',
    [ThemeVarNames.Action]: 'hsl(208, 100%, 54%)',
    [ThemeVarNames.ActionDark]: 'hsl(208, 100%, 44%)',
    [ThemeVarNames.ActionContrast]: 'hsl(0, 0%, 100%)',
    [ThemeVarNames.Error]: 'hsl(3, 43%, 46%)',
    [ThemeVarNames.ErrorLight]: 'hsl(1deg 100% 73%)',
    [ThemeVarNames.ErrorDark]: 'hsl(8, 24%, 17%)',
    [ThemeVarNames.ErrorContrast]: 'hsl(0, 0%, 100%)',
    [ThemeVarNames.Warning]: 'hsl(52, 64%, 35%)',
    [ThemeVarNames.WarningDark]: 'hsl(52, 75%, 40%)',
    [ThemeVarNames.WarningLight]: 'hsl(52, 75%, 9%)',
    [ThemeVarNames.Info]: 'hsl(217, 58%, 54%)',
    [ThemeVarNames.InfoLight]: 'hsl(217, 35%, 22%)',
    [ThemeVarNames.InfoDark]: 'hsl(217, 67%, 56%)',
    [ThemeVarNames.Success]: 'hsl(90, 45%, 32%)',
    [ThemeVarNames.SuccessLight]: 'hsl(90, 24%, 17%)',
    [ThemeVarNames.SuccessDark]: 'hsl(90, 38%, 47%)',
    [ThemeVarNames.InputPrimary]: '#000',
    [ThemeVarNames.InputAlternative]: '#1E1E1E',
    [ThemeVarNames.Timeseries1]: '#3B7FC2',
    [ThemeVarNames.Timeseries2]: '#E2B923',
    [ThemeVarNames.Timeseries3]: '#D94249',
    [ThemeVarNames.Timeseries4]: '#04A225',
    [ThemeVarNames.Timeseries5]: '#F56421',
    [ThemeVarNames.Timeseries6]: '#9F654F',
    [ThemeVarNames.Timeseries7]: '#e67e23',
    [ThemeVarNames.Timeseries8]: '#95a5a6',
    [ThemeVarNames.Timeseries9]: '#19bc9c',
    [ThemeVarNames.TimeseriesNeutral]: '#999999',
    [ThemeVarNames.SharedAxis1]: '#e9ecec',
    [ThemeVarNames.SharedAxis2]: '#fff',
    [ThemeVarNames.SharedAxis3]: '#919ea1',
    [ThemeVarNames.SharedAxis4]: 'b2bec3',
    [ThemeVarNames.SharedAxis5]: '7c898d',
    [ThemeVarNames.PlotBackground]: 'black'
  }
};

export const Theme = {

  /**
    * this is to create a string of css variables split by lines like this:
    * --var-1: <value>
    * --var-2: <value>
    * this is useful if you want to create a scoped theme
    *
    * e.g
    * const ScopedTheme = styled.div`
    *   ${Theme.createCss(theme)}
    *
    *   & > div {
    *     color: var(${ThemeVarNames.PrimaryBg});
    *   }
    * `;
    */
  createCss(theme: Theme): string {
    const themeMap = themesMap[theme];
    const css = Object.keys(themeMap)
      .map((varName: ThemeVarNames) => `${varName}: ${themeMap[varName]};`);
    // console.log({ css });
    return css.join('\n');
  },
  friendlyThemeName: (theme: Theme): string => {
    const titlesMap: Record<Theme, string> = {
      dark: 'Dark',
      light: 'Light',
      contrast: 'Contrast'
    };
    return titlesMap[theme] ?? theme;
  },
  getAllThemes: (): Theme[] => Object.keys(themesMap) as Theme[],
  getSystemTheme: (): Theme =>
    window.matchMedia('(prefers-color-scheme: dark)').matches ?
      'dark' :
      'light',
  themesMap
};

