import * as React from 'react';
import {
  InputNumber as AntdInputNumber,
  InputNumberProps as AntdInputNumberProps
} from 'antd';
import styled from 'styled-components';

import {ThemeVarNames} from '@theme';

type ExtraProps = {
  variant?: 'primary' | 'alternative'
};

export type InputNumberProps = AntdInputNumberProps & ExtraProps

export function InputNumber(props: InputNumberProps) {
  const {variant, className, ...restProps} = props;

  return (
    <StyledInputNumber
      {...restProps}
      className={`${className} ${variant ?? 'primary'}`}
    />
  );
}

export const StyledInputNumber = styled(AntdInputNumber)`
  --bg: var(${ThemeVarNames.InputPrimary});

  &.alternative {
    --bg: var(${ThemeVarNames.InputAlternative});
  }

  color: var(${ThemeVarNames.PrimaryText});
  border-color: var(${ThemeVarNames.Border});

  .ant-input-number-input-wrap {
    background-color: var(--bg);
  }

  &.ant-input-number-focused {
    box-shadow: var(${ThemeVarNames.ShadowFocus});
  }

  &.ant-input-number-disabled {
    color: var(${ThemeVarNames.TertiaryText});
    border-color: var(${ThemeVarNames.DisabledLight});
  }

  &.ant-input-number-disabled {
    .ant-input-number-input-wrap {
      background-color: var(${ThemeVarNames.DisabledDark});
    }
  }

  .ant-input-number-handler-wrap {
    background: transparent;

    span {
      border-color: var(${ThemeVarNames.Action});
      color: var(${ThemeVarNames.Action});
    }
  }

  :hover {
    .ant-input-number-handler-wrap {
      background: transparent;

      span[aria-disabled='true'] {
        color: var(${ThemeVarNames.Action});
      }

      span[aria-disabled='false'] {
        :hover {
          border-color: var(${ThemeVarNames.Action});
          color: var(${ThemeVarNames.ActionContrast});
          background-color: var(${ThemeVarNames.ActionDark});
        }
      }
    }
  }
`;
