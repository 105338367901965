import React, {useState, useEffect} from 'react';
import {Space, Spin} from 'antd';

import {Button, Modal, Typography} from '@components/common';
import {trackError} from '@utils/createSentry';
import SharingService from '@utils/apis/SharingService';

enum ModalState {
  Confirm,
  Loading,
  Success,
  Error,
}

const RevokeCompanyModal = ({
  isVisible,
  closeModal,
  organization,
  onRevoke
}: any) => {
  const [modalState, setModalState] = useState(ModalState.Confirm);
  const [errorMessage, setErrorMessage] = useState('');

  const revokeCompanyAccess = async () => {
    try {
      setModalState(ModalState.Loading);
      await SharingService.revokeCompanyFromActor(organization);
      setModalState(ModalState.Success);
      onRevoke();
    } catch (e) {
      setModalState(ModalState.Error);
      setErrorMessage(e.message);
      trackError(e);
    }
  };

  const ModalMessage = () => {
    switch (modalState) {
    case ModalState.Confirm:
      return (
        <Typography variant="primary" as="p">
            You are about to revoke access to <b>your entire company</b> from{' '}
          <b>{organization.code}</b>.
        </Typography>
      );
    case ModalState.Loading:
      return (
        <Space direction="vertical" align="center">
          <Spin size="large" />
        </Space>
      );
    case ModalState.Success:
      return (
        <Typography variant="primary" as="p">
          {'Successfully revoked company access '}
        </Typography>
      );
    case ModalState.Error:
      return <Typography variant="primary" as="p">{errorMessage}</Typography>;
    default:
      return null;
    }
  };

  const modalFooter = () => {
    switch (modalState) {
    case ModalState.Confirm:
      return [
        <Button id="cancel" key="cancel" onClick={closeModal}>
            Cancel
        </Button>,
        <Button id="revoke" key="share" onClick={revokeCompanyAccess}>
            Revoke
        </Button>
      ];
    case ModalState.Loading:
      return null;
    case ModalState.Success || ModalState.Error:
      return [
        <Button key="ok" onClick={closeModal}>
            OK
        </Button>
      ];
    default:
      return null;
    }
  };

  const getModalTitle = () => {
    switch (modalState) {
    case ModalState.Confirm:
      return 'Revoke Company Access';
    case ModalState.Loading:
      return 'Loading...';
    case ModalState.Success:
      return 'Success';
    case ModalState.Error:
      return 'Error occured';
    default:
      return '';
    }
  };

  useEffect(() => {
    setModalState(ModalState.Confirm);
  }, []);

  return (
    <Modal
      title={getModalTitle()}
      visible={isVisible}
      footer={modalFooter()}
      onCancel={closeModal}
    >
      <ModalMessage />
    </Modal>
  );
};

export default RevokeCompanyModal;
