import * as React from 'react';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import PackageService from '@utils/apis/PackageService';
import {trackError} from '@utils/createSentry';
import {FullMarketPlacePackage} from '../types/marketplace';

type Result = {
  items: []
  totalItems: number
}

const QUERY_KEY = 'PackageService.buying.search';

export function useBuying(
  keywords: string[],
  page: number,
  pageSize: number
): UseQueryResult<Result, AxiosError> {
  const queryClient = useQueryClient();
  const result = useQuery<Result, AxiosError>(
    [QUERY_KEY, keywords, page],
    () => fetchItems(keywords, page, pageSize),
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      staleTime: 1000 * 60 // 1 minute
    }
  );

  React.useEffect(
    () => {
      queryClient.prefetchQuery([QUERY_KEY, keywords, page + 1], () =>
        fetchItems(keywords, page + 1, pageSize)
      );
    },
    [page, result.data, queryClient, keywords]
  );

  return result;
}

export function useBuyingPackage(): UseMutationResult<
  void,
  AxiosError,
  FullMarketPlacePackage
  > {
  const queryClient = useQueryClient();
  const mutation = useMutation<void, AxiosError, FullMarketPlacePackage>((pck) =>
    PackageService.buying
      .buy(pck.code, pck.interval, pck.promocode)
      .then(() => queryClient.invalidateQueries([QUERY_KEY]))
      .catch((err: AxiosError) => {
        trackError(err);
        throw err;
      })
  );
  return mutation;
}

function fetchItems(keywords: string[], page: number, pageSize: number) {
  return PackageService.buying
    .search(keywords, page, pageSize)
    .then(({data}) => ({
      items: data.objects,
      totalItems: data.length
    }))
    .catch((err: AxiosError) => {
      console.error('error PackageService.buying.search: ', err);
      trackError(err);
      throw err;
    });
}

// export function usePromocode(): UseMutationResult<
// void,
// AxiosError,
// MarketplacePackage
// > {
//   const queryClient = useQueryClient();
//   const mutation = useMutation<void, AxiosError, MarketplacePackage>((pck, promocode: string) =>
//     PackageService.promocode(pck.price, pck.code, promocode)
// }
