import React, {useState, useEffect} from 'react';

import ActorService from '@utils/apis/ActorService';
import HelmetWrapper from '@utils/HelmetWrapper';
import {Input, Button,Modal, Form, Select, SelectOption, InputTextArea, Alert} from '@components/common';

import {
  ModalState,
  BankAccountCard,
  CardsContainer,
  FormContainer
} from '../SettingsContainer';
import ExternalVendorsModal from './ExternalVendorsModal';

export function ExternalApiSettings({ vendorInfo, setVendorInfo }): JSX.Element {
  const [vendorOption, setVendorOption] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [externalVendorsModalState, setExternalVendorsModalState] = useState(ModalState.Hidden);
  const [errorMessage, setErrorMessage] = useState('');
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleVendorChange = (vendor: string) => {
    setVendorOption(vendor);
    setIsButtonDisabled(apiKey === '' || vendor === '');
  };

  const handleInputChange= (e) => {
    setApiKey(e.target.value);
    setIsButtonDisabled(e.target.value === '' || vendorOption === '');
  }
  
  const addApiKey = async () => {
    try {
      setExternalVendorsModalState(ModalState.Loading);
      const result = await ActorService.updateApiKey({
        vendor: vendorOption,
        key: apiKey
      });
      setExternalVendorsModalState(ModalState.Success);
      setFetchTrigger(true);
      setApiKey('');
    } catch (e) {
      setApiKey('');
      setErrorMessage(e.response.data.message);
      setExternalVendorsModalState(ModalState.Error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const vendorStuff = await ActorService.getApiKeys();
        setVendorInfo(vendorStuff.data);
      } catch (e) {
        console.error(e);
      }
    };
    if (fetchTrigger){
      fetchData();
      setFetchTrigger(false);
    }
  }, [fetchTrigger]);

  const supportedVendors = ['amberdata'];
  return (
    <div>
      <HelmetWrapper title="Profile" description="User Profile" />
      <CardsContainer>
        <BankAccountCard
          title="Enter Api Keys from External Vendors"
          id="enter-api-keys"
        >
      <FormContainer>
      {vendorInfo.map((item, index) => (
        <div key={index}>
          <p>Vendor: {item.vendor}</p>
          <Input id='api-key-2' value={`*****${item.key}`} readOnly />
          <p></p>
        </div>
      ))}
      
      <Select
        value={vendorOption}
        onChange={(value) => handleVendorChange(value as string)}        
        >
          <SelectOption value="">Select a vendor</SelectOption>
          {supportedVendors.map((vendor, index) => (
          <SelectOption key={index} value={vendor}>
            {vendor}
          </SelectOption>
        ))}
    </Select>
          <Input
            id="api-key"
            placeholder="Enter API Key"
            value={apiKey}
            onChange={handleInputChange}
          />
          <Button type="ghost" onClick={addApiKey} disabled={isButtonDisabled}>
            Add API Key
          </Button>
      </FormContainer>
        </BankAccountCard>
      </CardsContainer>
      <ExternalVendorsModal
        state={externalVendorsModalState}
        onClose={() => setExternalVendorsModalState(ModalState.Hidden)}
        errorMessage={errorMessage}
      />
    </div>
  );
}
