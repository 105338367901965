import * as React from 'react';
import {FaTwitter} from '@react-icons/all-files/fa/FaTwitter';

import {StyledButton} from './style';

export type TwitterButtonProps = {
  colored?: boolean
}

export function TwitterButton({colored}: TwitterButtonProps): JSX.Element {
  return (
    <StyledButton
      colored={colored}
      target="_blank"
      href="https://twitter.com/rosedotai"
    >
      <FaTwitter />
    </StyledButton>
  );
}
