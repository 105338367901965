import React, {useState} from 'react';
import styled from 'styled-components';
import {IoEllipsisVertical} from '@react-icons/all-files/io5/IoEllipsisVertical';
import {Dropdown, message} from 'antd';
import {Menu, Modal, Alert} from '@components/common';
import AdminAPI from '@utils/apis/AdminAPI';
import {ThemeVarNames} from '@theme';

type ActionsMenuProps = {
  refetchTable: () => Promise<unknown>;
  username: string;
}

export const ActionsMenu = ({refetchTable, username}: ActionsMenuProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => {
    setError(null);
    setOpen((open) => !open); // USEQUERY?
  };

  const removeUser = async () => {
    setLoading(true);
    setError(null);
    try {
      await AdminAPI.removeCompanyUser(username);
      message.success(`User ${username} removed from Organization`);
      setLoading(false);
      refetchTable();
      toggleModal();
    } catch (err) {
      setError(err.response.data.message);
      setLoading(false);
    }
  };

  const menu =
    <Container>
      <Menu selectable={false}>
        <Menu.Item key="1" onClick={toggleModal}>Remove User</Menu.Item>
        <Modal
          title="Confirmation"
          visible={open}
          onOk={removeUser}
          onCancel={toggleModal}
          confirmLoading={loading}
          okText="Confim"
          cancelText="Cancel"
        >
          <p>Would you like to remove <strong>{username}</strong> from your organization?</p>
          {error ?
            <Alert
              type="error"
              message="Could not remove user from organization at this time, please try again later."
              style={{textAlign: 'center'}}
            /> : null
          }
        </Modal>
      </Menu>
    </Container>;

  return (

    <Dropdown overlay={menu} placement="topRight" trigger={['click']}>
      <IoEllipsisVertical size={18}/>
    </Dropdown>

  );
};

const Container = styled.div`
  .ant-menu-item {
    color: var(${ThemeVarNames.SecondaryText});
  }
  border: 1px solid;
  border-color: var(${ThemeVarNames.Border});
  border-radius: 2px;

  .ant-modal-content {
    background: var(${ThemeVarNames.SecondaryBg});
  }
`;
