/* eslint-disable camelcase */
import {useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import ConnectionService from '@utils/apis/ConnectionService';
import {Connection} from '@types';
import {trackError} from '@utils/createSentry';

export type UseConnectionsResult = Omit<UseQueryResult, 'data'> & {
  data: Array<Connection>
}

export type NewConnection = {
  code: string
  dbType: string
  connectionString: string | object
}

export type UseConnectionsOptions = {
  onSuccess: (rawCode: string) => void
}

export function useConnections(): UseConnectionsResult {
  const {data, status, error, ...rest} = useQuery(
    ['connections'],
    getConnections,
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 0,
      cacheTime: 0
    }
  );

  return {
    data,
    status,
    error,
    ...rest
  };
}

function getConnections(): Promise<Connection[]> {
  return ConnectionService.all()
    .then((res) => res.data.map(Connection.deserialize))
    .catch((err) => {
      throw err;
    });
}

export function useConnectionsAdd(): UseMutationResult<
  void,
  AxiosError,
  NewConnection
  > {
  const queryClient = useQueryClient();
  const mutation = useMutation<void, AxiosError, NewConnection>(async (connection) => {
    const {code, dbType: db_type, connectionString: connection_string} = connection;
    return await ConnectionService.create(code, db_type, connection_string)
      .then(() => queryClient.invalidateQueries(['connections']))
      .catch((err) => {
        console.error('error trying to add a new connection: ', err);
        trackError(err);
        throw err;
      });
  });
  return mutation;
}
