/* eslint-disable camelcase */
import axios, {AxiosResponse} from 'axios';
import {RoseUser, RoseUserSettings} from '@types';
import {HttpClient} from './HttpClient';

/**
 * API client for the `user` services
 * @singleton
 * @extends HttpClient
 */
class UserAPI extends HttpClient {
  private static _instance: UserAPI;

  private constructor() {
    super('/users');
  }

  public static get instance(): UserAPI {
    if (!UserAPI._instance) {
      UserAPI._instance = new UserAPI();
    }

    return UserAPI._instance;
  }

  public get = (): Promise<RoseUser> =>
    this.instance
      .get('')
      .then((res) => RoseUser.deserialize(res.data))
      .catch((err) => {
        throw err;
      })

  // eslint-disable-next-line camelcase
  public login = (form: { username: string; password: string; }) => {
    return this.instance.post('/auth', form);
  }

  public logout = (): Promise<AxiosResponse> =>
    this.instance.delete('/auth')

  public checkTos = (): Promise<AxiosResponse> =>
    this.instance.get('/terms')

  public signTos = (): Promise<AxiosResponse> =>
    this.instance.post('/terms', {})

  public register = (user: any): Promise<AxiosResponse> =>
    this.instance.post('', user)

  public changePassword = (form: any): Promise<AxiosResponse> =>
    this.instance.post('/auth/password', form)

  public forgotPassword = (email: string): Promise<AxiosResponse> =>
    this.instance.post(
      '/reset/forgot',
      {email}
    )

  public checkToken = (token: string): Promise<AxiosResponse> =>
    this.instance.get(`/reset/${token}`)

  public changePasswordFromToken = (
    token: string,
    newPassword: string
  ): Promise<AxiosResponse> =>
    this.instance.post(
      `/reset/${token}`,
      {new_password: newPassword}
    )

  public changeSettings = (settings: RoseUserSettings): Promise<void> =>
    this.instance.put('/settings', settings)

  // using standard axios instance since backend url doesn't share /users and don't want to put in misc file yet
  public createContactAccount = (form: any): Promise<any> =>
    axios.post(`${this.backendURL}/create-client-form`, form)

  public getInvitee = (token: string): Promise<any> =>
    this.instance.get(`/activate/${token}`)

  public changeInviteePassword = (token: string, newPassword: string): Promise<any> =>
    this.instance.post(
      `/activate/${token}`,
      {new_password: newPassword}
    )

}

const UserService = UserAPI.instance;

export default UserService;
