/* eslint-disable react/prop-types */
import React, {useEffect, useRef, useState, useMemo} from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import styled from 'styled-components';
import {Tooltip} from 'antd';
import {useTable, usePagination, Column, Row} from 'react-table';
import {find, mapValues, merge} from 'lodash';

import {ThemeVarNames, useTheme} from '@theme';
import {DownloadButton, Button, ViewMode} from '@components/common';
import {
  ChartSettings,
  RoseModule,
  RoseModuleCodeSettings,
  RoseTimeseries,
  RoseTimeseriesGroup
} from '@types';
import {
  composeChartOptions,
  fixAxes,
  fixColors
} from '@utils/helpers/display/timeseries';

import {Tree} from './Tree';
import {DetailsButton} from './DetailsButton';
import {ChangeUnitsButton} from './ChangeUnitsButton';
import {ChartEditor} from './ChartEditor';

export type TimeseriesGroupProps = {
  roseObject: RoseTimeseriesGroup;
  mod: RoseModule;
  addModule: (partialMod: Partial<RoseModule>) => void;
  onModuleChanged: (newMod: RoseModule) => void;
  chartSettings: ChartSettings;
  onChartSettingsChanged: (newChartSettings: ChartSettings) => void;
  view: ViewMode;
  isTableShown: boolean;
};

export function TimeseriesGroup(props: TimeseriesGroupProps): JSX.Element {
  const {
    roseObject,
    addModule,
    mod,
    onModuleChanged,
    chartSettings,
    onChartSettingsChanged,
    view,
    isTableShown = true
  } = props;
  const [tree, setTree] = useState(null);
  const theme = useTheme();
  const chartSettingsRef = useRef(chartSettings);
  chartSettingsRef.current = chartSettings;
  const getChartSettings = () => chartSettingsRef.current;

  const [highchartOptions, setHighchartOptions] = useState<Highcharts.Options>(
    () =>
      composeChartOptions({
        roseObject,
        theme,
        chartSettings,
        getChartSettings,
        onChartSettingsChanged,
        rangeShown: isTableShown || view !== 'view',
        modSettings: mod?.modulesettings,
        isViewMode: view === 'view'
      })
  );
  const updateHighchartOptions = (options: Partial<Highcharts.Options>) => {
    setHighchartOptions({...highchartOptions, ...options});
  };

  useEffect(() => {
    const newChartSettings = {...highchartOptions};
    const chartMapKey: string = RoseModuleCodeSettings.composeId(
      roseObject.data.map(({code}) => code)
    );

    highchartOptions.series.forEach(
      (_: Highcharts.SeriesOptionsType, i: number) => {
        if (mod && mod.modulesettings) {
          const chartInModSettings = mod.modulesettings?.charts?.[
            chartMapKey
          ] as ChartSettings;

          const yAxisLength: number = highchartOptions.yAxis.length;

          // 3 places where axes positions could be stored
          const isChartAxisIdxValid: boolean =
            chartInModSettings?.datasets?.[i]?.yAxis < yAxisLength;
          const isModuleSettingsCodeIdxValid: boolean =
            mod.modulesettings?.units?.[_?.code] < yAxisLength;
          const isModuleSettingsIdxValid: boolean =
            mod.modulesettings?.units?.[i] < yAxisLength;

          // case when user made changes to axes positions (should be preserved)
          if (
            isChartAxisIdxValid ||
            isModuleSettingsCodeIdxValid ||
            isModuleSettingsIdxValid
          ) {
            newChartSettings.series[i as number].yAxis =
              chartInModSettings?.datasets?.[i]?.yAxis ||
              mod.modulesettings?.units?.[_?.code] ||
              mod.modulesettings?.units?.[i] ||
              0;
          } else {
            // case when a new code cell is being run
            newChartSettings.series[i as number].yAxis = 0;
          }
        }
      }
    );

    setHighchartOptions(fixColors(newChartSettings, theme));
    setHighchartOptions(fixAxes(newChartSettings, theme));
  }, []);

  const changeDatasetUnits = (unitIndex: number, datasetCode: string) => {
    const newChartSettings = {...highchartOptions};

    highchartOptions.series.forEach(
      (series: Highcharts.SeriesOptionsType & { code: string }, i: number) => {
        if (series.code === datasetCode) {
          newChartSettings.series[i as number].yAxis = unitIndex;
        }
      }
    );

    setHighchartOptions(fixColors(newChartSettings, theme));
    setHighchartOptions(fixAxes(newChartSettings, theme));
    onModuleChanged(
      RoseModule.create(mod, {
        units: {
          ...mod?.modulesettings.units,
          [datasetCode]: unitIndex
        }
      })
    );
  };

  const changeAllDatasetUnits = (unitIndex: number) => {
    const newChartSettings = {...highchartOptions};
    highchartOptions.series.forEach(
      (_: Highcharts.SeriesOptionsType, i: number) => {
        newChartSettings.series[i as number].yAxis = unitIndex;
      }
    );

    setHighchartOptions(fixColors(newChartSettings, theme));
    setHighchartOptions(fixAxes(newChartSettings, theme));
    onModuleChanged(
      RoseModule.create(mod, {
        units: mapValues(mod?.modulesettings.units, () => unitIndex)
      })
    );
  };

  const handleChartFormLabels = (chartSettings: ChartSettings) => {
    const newDataset = Object.entries(chartSettings.datasets).map(
      ([key, value]) => {
        const obj = find(
          highchartOptions.series,
          (obj: Highcharts.SeriesOptionsType & { code: string }) =>
            obj.code === key
        );
        const mergedObj = merge(obj, value);
        mergedObj.showInLegend = !!mergedObj.name;
        return mergedObj;
      }
    );

    setHighchartOptions({...highchartOptions, series: newDataset});
  };

  const changeYAxisSide = (axisIndex: number) => {
    const newChartSettings = {...highchartOptions};
    newChartSettings.yAxis[axisIndex].opposite =
      !newChartSettings.yAxis[axisIndex].opposite;
    setHighchartOptions(fixColors(fixAxes(newChartSettings, theme), theme));
    onChartSettingsChanged({...chartSettings, yAxis: newChartSettings.yAxis});
  };

  const handleOnChartCustomizationChange = (chartSettings: ChartSettings) => {
    onChartSettingsChanged(chartSettings);
    handleChartFormLabels(chartSettings);

    let widthFactor = 1;
    switch (mod.modulesettings.width) {
    case '66%':
      widthFactor = 0.66;
      break;
    case '50%':
      widthFactor = 0.5;
      break;
    case '33%':
      widthFactor = 0.33;
    }

    const titleLength = chartSettings.title?.text?.length;
    let titleFontSize = 28;
    let legendFontSize = 20;
    if (titleLength > 45 * widthFactor) {
      titleFontSize = 18;
      legendFontSize = 16;
    } else if (titleLength > 30 * widthFactor) {
      titleFontSize = 23;
      legendFontSize = 18;
    }

    const legendSettings = {
      ...highchartOptions.legend,
      itemStyle: {
        ...highchartOptions.legend.itemStyle,
        fontSize: legendFontSize.toString()
      }
    };
    const titleSettings = {
      ...highchartOptions.title,
      text: chartSettings.title?.text,
      style: {fontSize: titleFontSize.toString()}
    };
    const sourceSettings = {
      ...highchartOptions.credits,
      text:
        chartSettings.source?.length > 0 ?
          `source: ${chartSettings.source}` :
          ''
    };
    setHighchartOptions(
      fixColors(
        {
          ...highchartOptions,
          legend: {...legendSettings},
          title: {...titleSettings},
          credits: {...sourceSettings},
          yAxis: chartSettings.yAxis
        },
        theme
      )
    );
  };

  const toggleChartDetailsVisibility = (isVisible: boolean) => {
    updateHighchartOptions({
      title: {
        ...highchartOptions.title
      },
      rangeSelector: {
        ...highchartOptions.rangeSelector,
        inputEnabled: isVisible,
        enabled: isVisible,
        allButtonsEnabled: isVisible
      },
      legend: {
        ...highchartOptions.legend
      },
      navigator: {
        ...highchartOptions.navigator,
        enabled: isVisible
      },
      exporting: {
        ...highchartOptions.exporting,
        enabled: isVisible
      },
      chart: {
        zoomType: isVisible ? 'x' : undefined
      }
    });
  };

  useEffect(() => {
    if (!highchartOptions) {
      return;
    }

    if (view === 'edit') {
      toggleChartDetailsVisibility(true);
    } else {
      toggleChartDetailsVisibility(false);
    }
  }, [view]);

  const hasYAxis = highchartOptions?.yAxis?.length > 0;

  return highchartOptions ?
    <>
      <ChartEditor
        onChange={handleOnChartCustomizationChange}
        settings={{
          ...chartSettings,
          yAxis: highchartOptions.yAxis as Highcharts.YAxisOptions[]
        }}
        roseObject={roseObject}
      />
      {hasYAxis ?
        <MemoChart
          options={{...highchartOptions, xAxisBounds: chartSettings.xAxis}}
          style={{width: '100%', height: '500px'}}
        /> :
        null}

      {view === 'view' && !isTableShown ? null :
        <TableV2
          options={highchartOptions}
          tree={tree}
          setTree={setTree}
          changeDatasetUnits={changeDatasetUnits}
          changeAllDatasetUnits={changeAllDatasetUnits}
          changeYAxisSide={changeYAxisSide}
          onAddModule={addModule}
        />
      }
      {tree && <Tree autoFocus treeData={tree} addModule={addModule} />}
    </> :
    null;
}

type MemoChartProps = {
  options: Highcharts.Options | any;
  style?: React.CSSProperties;
};

/* eslint-disable react/display-name */
export const MemoChart = React.memo(
  (props: MemoChartProps) => {
    const {options, style} = props;
    const chartRef = useRef<{
      chart: Highcharts.Chart;
      container: React.RefObject<HTMLDivElement>;
    }>(null);

    function setExtremes(min: number | null, max: number | null) {
      chartRef.current.chart?.xAxis?.[0].setExtremes(min, max);
    }

    useEffect(() => {
      chartRef.current?.chart?.update({
        rangeSelector: {
          inputEnabled: true,
          selected: 5
        }
      });

      // reflow, redraw, or anything else wasn't working. only resizing the window worked
      // so this is a hack while it allows the chart to render for some time.
      // seems to be thrown off by the modal animation as it flies into the screen
      // needs to be set back to null to restore the chart's responsiveness.
      setTimeout(() => {
        chartRef.current?.chart?.setSize(
          Number(chartRef.current?.container.current.offsetWidth),
          Number(chartRef.current?.container.current.offsetHeight)
        );

        setTimeout(() => {
          chartRef.current?.chart?.setSize(null, null);
        }, 0);
      }, 100);
    }, []);

    useEffect(() => {
      if (options?.xAxisBounds?.min || options?.xAxisBounds?.max) {
        setExtremes(options.xAxisBounds.min, options.xAxisBounds.max);
      }
    }, [options]);

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
        containerProps={{
          style
        }}
      />
    );
  },
  (prevProps, nextProps) => prevProps.options === nextProps.options
);

export type MapTableProps = {
  options: any;
  tree: any;
  setTree: (tree: any) => void;
  changeDatasetUnits: (unitIndex: number, datasetCode: string) => void;
  changeAllDatasetUnits: (unitIndex: number) => void;
  changeYAxisSide: (axisIndex: number) => void;
  shown?: boolean;
};

const COLUMNS: Column<RoseTimeseries | any>[] = [
  {Header: 'Rosecode', accessor: 'code'},
  {Header: 'Organization', accessor: 'actor'},
  {Header: 'Axis', accessor: 'units'},
  {Header: '', accessor: 'actions'}
];

export function TableV2({
  options,
  tree,
  setTree,
  changeDatasetUnits,
  changeAllDatasetUnits,
  changeYAxisSide,
  shown = true
}: MapTableProps): JSX.Element {
  const changeDatasetUnitsRef = useRef(null);

  useEffect(() => {
    changeDatasetUnitsRef.current = changeDatasetUnits;
  }, [changeDatasetUnits]);

  const {columns, data} = useMemo(
    () => ({
      columns: COLUMNS.map((col) => ({
        accessor: col.accessor as keyof RoseTimeseries | any,
        Header: col.Header,
        key: col.accessor,
        collapse: true,
        Cell: ({row, value}: any) => {
          switch (col.accessor) {
          case 'actions':
            return (
              <ActionsContainer>
                <DetailsButton
                  modalTitle={row.original.code}
                  metas={row.original.metas}
                />
                <Button
                  type="primary"
                  danger={tree === row.original.metas.tree}
                  disabled={!row.original.metas.tree}
                  onClick={() => {
                    if (tree !== row.original.metas.tree) {
                      setTree(row.original.metas.tree);
                    } else if (tree === row.original.metas.tree) {
                      setTree(null);
                    } else {
                      setTree(null);
                    }
                  }}
                >
                  {tree === row.original.metas.tree ?
                    'Close Tree' :
                    'Show Tree'}
                </Button>
                <Tooltip title="Download" placement="left">
                  <DownloadButton
                    roseCode={row.values.code}
                    actorId={row.values.actor}
                  />
                </Tooltip>
              </ActionsContainer>
            );
          case 'units':
            const series = options.series.find(
              (s: any) => s.code === row.original.code
            );
            return (
              <ChangeUnitsButton
                axis={
                  options.yAxis[series.yAxis]?.title?.text ||
                    options.yAxis[series.yAxis]?.defaultLabel
                }
                chart={options}
                dataset={row.original.code}
                onSelectUnit={changeDatasetUnitsRef.current}
                onSetAll={changeAllDatasetUnits}
                changeYAxisSide={changeYAxisSide}
              />
            );
          default:
            return (
              <div>
                <SafeSpan title={value}>{value}</SafeSpan>
              </div>
            );
          }
        }
      })),
      data: options.series
    }),
    [options.series, tree, changeDatasetUnits, changeAllDatasetUnits]
  );

  return <RoseTableV2 columns={columns} data={data} shown={shown} />;
}

type RoseTableV2Props = {
  columns: Column<RoseTimeseries | any>[];
  data: any[];
  shown: boolean;
};

export function RoseTableV2({columns, data, shown = true}: RoseTableV2Props) {
  const {getTableProps, getTableBodyProps, headerGroups, prepareRow, page} =
    useTable<RoseTimeseries | any>(
      {
        columns,
        data,
        initialState: {pageIndex: 0}
      },
      usePagination
    );

  return shown ?
    <Styles>
      <div className="tableWrap">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) =>

              // key is included in spread of getHeaderGroupProps()
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) =>

                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  // key is included in spread of getHeaderProps()
                  // eslint-disable-next-line react/jsx-key
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                )}
              </tr>
            )}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: Row<RoseTimeseries>) => {
              prepareRow(row);
              return (

                // key is included in spread of getRowProps()
                // eslint-disable-next-line react/jsx-key
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any) =>

                    // key is included in spread of getCellProps()
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Styles> :
    null;
}

const SafeSpan = styled.span`
  overflow-x: hidden;
  display: inherit;
  text-overflow: ellipsis;
`;

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    scrollbar-width: thin;
    border: 1px solid var(${ThemeVarNames.Border});
    border-radius: 10px;
    background-color: var(${ThemeVarNames.PrimaryBg});

    ::-webkit-scrollbar {
      overflow: overlay;
      height: 4px;
      background: unset;
    }

    ::-webkit-scrollbar-thumb {
      display: auto;
      background-clip: padding-box;
      background-color: var(${ThemeVarNames.TertiaryBg});
      border-radius: 20px;
    }
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;

    thead {
      background-color: var(${ThemeVarNames.SecondaryBg});
    }

    tr {
      &:last-child {
        td {
          border-bottom: calc(0px * 1);
        }
      }
    }

    th,
    td {
      margin: 0.5rem;
      padding: 0.5rem;
      border-bottom: 1px solid var(${ThemeVarNames.Border});

      /* The secret sauce: Each cell should grow equally */
      width: 1%;

      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: calc(0 * 1);
      }
    }

    th {
      color: var(${ThemeVarNames.SecondaryText});
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    td {
      color: var(${ThemeVarNames.PrimaryText});
    }
  }
`;

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
