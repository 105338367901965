import * as React from 'react';
import {match} from 'ts-pattern';
import _ from 'lodash';

import {useFunctionMap} from '@service/useFunctionMap';
import {
  CATEGORIES,
  FunctionMeta,
  getFunctionContext,
  getFunctionsByCategory,
  getReplacement,
  shouldAddParenthesis
} from '@utils/helpers/roseFunctions';

import {Input, InputProps} from './Input';
import {LinkedListComponent} from './LinkedList';
import {focusListItem, scrollItemTop, KeyOptions} from './helpers';

export type InputWrapperProps = Omit<
  InputProps,
  'isDropdownOpen' | 'onCursor' | 'onKeyDown' | 'cursor'
>;

export function InputWrapper(props: InputWrapperProps): JSX.Element {
  const functionMap = useFunctionMap();
  const [cursor, setCursor] = React.useState<number>(0);
  const [category, setCategory] = React.useState<string | undefined>(undefined);
  const [index, setIndex] = React.useState<number>(-1);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    const functionContext = getFunctionContext(
      props.value,
      cursor,
      functionMap
    );
    return match(functionContext)
      .with(undefined, () => setIsDropdownOpen(false))
      .otherwise(() => setIsDropdownOpen(true));
  }, [props.value, cursor, functionMap]);

  React.useEffect(() => {
    setIsDropdownOpen(!_.isEmpty(functionMap));
  }, [functionMap]);

  const handleValue = React.useCallback(
    (functionName: string) => {
      const functionMeta = functionMap[functionName];
      const updated = getReplacement(props.value, cursor, functionName, {
        addParenthesis: shouldAddParenthesis(functionMeta)
      });
      props.onChange(updated.value);
      setCursor(updated.cursor);
    },
    [props.value, cursor]
  );

  const handleUserKeyPress = (key: KeyOptions): void => {
    if (key === 'Escape') {
      setIsDropdownOpen(false);
      return;
    }

    const {value} = props;
    const functionContext = getFunctionContext(value, cursor, functionMap);
    if (Array.isArray(functionContext)) {
      return processFunctionListKeys(key, functionContext);
    }

    processCategoryListKeys(key);
  };

  const processFunctionListKeys = (
    key: KeyOptions,
    functionContext: FunctionMeta[]
  ) => {
    if (key === 'ArrowUp' || key === 'ArrowDown') {
      let nextIndex = Math.max(0, index + (key === 'ArrowUp' ? -1 : 1));
      const LENGTH = functionContext.length;
      if (index > functionContext.length - 1) {
        nextIndex = LENGTH - 1;
      }

      focusListItem('ll-all-functions', nextIndex);
      setIndex(nextIndex);
    }

    if (key === 'Enter') {
      handleValue(functionContext[index].name);
      setIndex(-1);
    }
  };

  // eslint-disable-next-line complexity
  const processCategoryListKeys = (key: KeyOptions) => {
    if (key === 'ArrowUp' || key === 'ArrowDown') {
      let nextIndex = Math.max(0, index + (key === 'ArrowUp' ? -1 : 1));
      const CAT_LENGTH = category ?
        getFunctionsByCategory(category, functionMap).length :
        CATEGORIES.length;
      if (nextIndex > CAT_LENGTH - 1) {
        nextIndex = CAT_LENGTH - 1;
      }

      focusListItem(category ? 'll-category' : 'll-categories', nextIndex);
      setIndex(nextIndex);
    }

    if (key === 'Enter') {
      if (category && index !== -1) {
        const functionList =
          CATEGORIES.find(([title]) => title === category)[1] || [];
        handleValue(functionList[index]);
        setCategory(undefined);
        setIndex(-1);
      } else if (index !== -1) {
        scrollItemTop();
        setCategory(CATEGORIES[index][0]);
        setIndex(-1);
      }
    }
  };

  const handleOnRun = () => {
    setIsDropdownOpen(false);
    props.onRun();
  };

  return (
    <>
      <Input
        {...props}
        onRun={handleOnRun}
        isDropdownOpen={isDropdownOpen}
        onCursor={setCursor}
        cursor={cursor}
        onKeyDown={handleUserKeyPress}
      />
      {isDropdownOpen === false ? null :
        <LinkedListComponent
          value={props.value}
          cursor={cursor}
          functionMap={functionMap}
          onValue={handleValue}
          onCursor={setCursor}
          index={index}
          setIndex={setIndex}
          category={category}
          setCategory={setCategory}
        />
      }
    </>
  );
}
