import axios from 'axios';
import { getBackendUrl } from '@config';

let backendURL = getBackendUrl();
let withCredentials: boolean;

if (process.env.NODE_ENV === 'production') {
  withCredentials = false;
} else {
  withCredentials = true;
}

const ManageObjectsAPI = {
  /*
    the def at this route needs to be redone
    to check if user owns object to make it 
    safer.
  */
  deleteObject: (code: string) =>
    axios.delete(`${backendURL}/objects/${code}`,
      {
        withCredentials
      }),
    // copy and paste this request to add more functionality
};
export default ManageObjectsAPI;
