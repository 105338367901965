import * as React from 'react';
import {Pagination} from 'antd';
import styled from 'styled-components';
import {AiOutlineDoubleLeft} from '@react-icons/all-files/ai/AiOutlineDoubleLeft';
import {AiOutlineDoubleRight} from '@react-icons/all-files/ai/AiOutlineDoubleRight';

import {Button} from '@components/common';
import {ThemeVarNames} from '@theme';

export type ResultPaginationProps = {
  pageNumber: number
  onChangePage: (pageNumber: number) => void
  total: number
}

export function ResultPagination(props: ResultPaginationProps): JSX.Element {
  const {pageNumber, onChangePage, total} = props;

  if (total === 0) {
    return null;
  }

  return (
    <StyledPaginationContainer>
      <Button
        type="text"
        onClick={() => onChangePage(1)}
        disabled={pageNumber === 1}
      >
        <AiOutlineDoubleLeft />
      </Button>
      <StyledPagination
        defaultCurrent={1}
        defaultPageSize={10}
        current={pageNumber}
        onChange={(page) => onChangePage(page)}
        total={total}
        simple
        showQuickJumper
      />
      <Button
        type="text"
        onClick={() => onChangePage(Math.ceil(total / 10))}
        disabled={pageNumber === Math.ceil(total / 10)}
      >
        <AiOutlineDoubleRight />
      </Button>
    </StyledPaginationContainer>
  );
}

const StyledPaginationContainer = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background-color: var(${ThemeVarNames.SecondaryBg});
`;

const StyledPagination = styled(Pagination)`
  color: var(${ThemeVarNames.SecondaryText});

  input[type='text'] {
    width: 10ch;
    background-color: var(${ThemeVarNames.InputPrimary});
    color: var(${ThemeVarNames.PrimaryText});
    border-color: var(${ThemeVarNames.Border});
  }

  button {
    color: var(${ThemeVarNames.Action});
  }

  button:disabled {
    color: var(${ThemeVarNames.Disabled});
  }

  button:hover {
    color: var(${ThemeVarNames.ActionDark});
  }

  button:disabled:hover {
    color: var(${ThemeVarNames.DisabledDark});
  }
`;
