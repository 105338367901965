import * as React from 'react';
import PrivacyTermsHero from '@components/common/PrivacyTermsHero/PrivacyTermsHero';
import {Footer} from '@components/common/Footer';
import TermsContent from '@components/landing/Terms/components/TermsContent/TermsContent';
import {LandingContainer} from '@components/common/Containers';
import HelmetWrapper from '@utils/HelmetWrapper';
import {LandingHeader} from '@components/common';

const Privacy = (): JSX.Element => <>
  <LandingHeader colored={false} />
  <LandingContainer>
    <HelmetWrapper
      title="Terms of Service"
      description="Terms of Service"
      pathname="/terms"
    />
    <TermsContent />
    <Footer />
  </LandingContainer>
</>;

export default Privacy;
