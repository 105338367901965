import {Tabs as AntdTabs} from 'antd';
import styled from 'styled-components';

import {ThemeVarNames} from '@theme';

const StyledTabs = styled(AntdTabs)`
  color: var(${ThemeVarNames.SecondaryText});

  .ant-tabs-nav::before {
    border-color: transparent;
  }
`;

export const Tabs = StyledTabs;
