import * as React from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import styled from 'styled-components';

import { RoseUserSettings } from '@types';
import { ThemeVarNames, Theme } from '@theme';
import { useUserSettings } from '@service/useUserSettings';

import { ThemeSettingsCard } from '../SettingsContainer';

export function ThemeSettings(): JSX.Element {
  return (
    <ThemeSettingsCard title="Theme">
      <ThemeSwitch />
    </ThemeSettingsCard>
  );
}

function ThemeSwitch(): JSX.Element {
  const { settings, changeSettings } = useUserSettings();

  const handleOnChange = (even: RadioChangeEvent) => {
    const chosenTheme = even.target.value;
    const css = Theme.createCss(chosenTheme);
    // console.log('createScopedTheme', chosenTheme, css.split('\n'));
    changeSettings({
      ...settings,
      theme: chosenTheme
    });
  };

  const options = Theme.getAllThemes().map((theme) => ({
    label:
      <StyledLabelContainer aria-label="label-container">
        <Skeleton theme={theme} />
        <StyledLabelText>
          {`${Theme.friendlyThemeName(theme)} Mode`}
        </StyledLabelText>
      </StyledLabelContainer>,
    value: theme
  }));

  return (
    <StyledContainer>
      <StyledRadioGroup
        onChange={handleOnChange}
        value={settings.theme}
        optionType="button"
        options={options}
      />
    </StyledContainer>
  );
}

const StyledLabelContainer = styled.div`
  background-color: var(${ThemeVarNames.TertiaryBg});
`;

const StyledLabelText = styled.div`
  padding: 10px 0;
  font-size: 14px;
  text-align: center;
  color: var(${ThemeVarNames.PrimaryText});
`;

const StyledContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;

  img {
    max-width: 230px;
  }
`;
const StyledRadioGroup = styled(Radio.Group)`
  display: inline-flex;
  gap: 48px;

  label.ant-radio-button-wrapper {
    padding: 0;
    height: auto;
    border: none;
    overflow: hidden;
  }

  label.ant-radio-button-wrapper:focus-within {
    box-shadow: none;
  }

  label.ant-radio-button-wrapper {
    border: none;
    overflow: hidden;
  }

  /* there is a conflict between prettier and css lint for this selector */

  /* prettier-ignore */
  label.ant-radio-button-wrapper div[aria-label='label-container'] > *:first-child {
    border: 3px solid transparent;
    border-radius: 11px;
    overflow: hidden;
  }

  label.ant-radio-button-wrapper-checked {
    font-weight: 700;
  }

  /* there is a conflict between prettier and css lint for this selector */

  /* prettier-ignore */
  label.ant-radio-button-wrapper-checked div[aria-label='label-container'] > *:first-child {
    border: 3px solid hsl(357, 83%, 66%) !important;
  }

  label.ant-radio-button-wrapper::before {
    display: none;
  }

  /* Styles for small screens */
  @media (max-width: 1050px) {
    flex-direction: column;
  }
`;

type SkeletonProps = {
  theme: RoseUserSettings['theme']
}

function Skeleton({ theme }: SkeletonProps) {
  const ScopedTheme = createScopedTheme(theme);
  return (
    <ScopedTheme>
      <SkeletonContainer>
        <SkeletonHeader>
          <SkeletonLogo />
          <SkeletonNav>
            <SkeletonNavItem />
            <SkeletonNavItem />
            <SkeletonNavItem />
          </SkeletonNav>
        </SkeletonHeader>
        <SkeletonBody>
          <SkeletonBodySidebar>
            <SkeletonBodySidebarItem />
            <SkeletonBodySidebarItem />
            <SkeletonBodySidebarItem />
            <SkeletonBodySidebarItem />
            <SkeletonBodySidebarItem />
          </SkeletonBodySidebar>
          <SkeletonBodyMainContainer>
            <SkeletonBodyMainTitle />
            <SkeletonInput />
          </SkeletonBodyMainContainer>
        </SkeletonBody>
      </SkeletonContainer>
    </ScopedTheme>
  );
}

function createScopedTheme(theme: Theme) {
  const css = Theme.createCss(theme);
  // console.log('createScopedTheme', theme, css.split('\n'));
  return styled.div`
    /* stylelint-disable */
    ${css}
      /* stylelint-enable */
  `;
}

const SkeletonContainer = styled.div`
  position: relative;
  width: 210px;
  height: 147px;
  background-color: var(${ThemeVarNames.PrimaryBg});
`;

const SkeletonHeader = styled.div`
  position: relative;
  width: 100%;
  height: 16%;
  padding: 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(${ThemeVarNames.SecondaryBg});
`;

const SkeletonLogo = styled.div`
  border-radius: 50%;
  height: 100%;
  aspect-ratio: 1 / 1;
  background-color: var(${ThemeVarNames.PrimaryBrand});
`;

const SkeletonNav = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 3%;
  padding: 1.5%;

  & > div:first-child {
    background-color: var(${ThemeVarNames.Action});
  }
`;

const SkeletonNavItem = styled.div`
  height: 100%;
  width: 20%;
  border-radius: 8px;
  background-color: var(${ThemeVarNames.PrimaryText});
`;

const SkeletonBody = styled.div`
  height: 85%;
  display: flex;
  padding-top: 6%;
`;

const SkeletonBodySidebar = styled.div`
  width: 10%;
  height: 100%;
  padding: 3%;
  display: flex;
  flex-direction: column;
  gap: 6%;
`;

const SkeletonBodySidebarItem = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: var(${ThemeVarNames.Action});
`;

const SkeletonBodyMainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8%;
`;

const SkeletonBodyMainTitle = styled.div`
  width: 20%;
  height: 4%;
  border-radius: 8px;
  background-color: var(${ThemeVarNames.PrimaryText});
`;

const SkeletonInput = styled.div`
  height: 8%;
  width: 80%;
  border-radius: 8px;
  background-color: var(${ThemeVarNames.InputAlternative});
`;
