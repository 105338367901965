export function renameKey(
  obj: Record<any, any>,
  keysToRename: Record<any, any>
): Record<any, any> {
  let out = {};
  Object.keys(obj).forEach((key) => {
    if (key in keysToRename) {
      out = {...out, [keysToRename[key]]: obj[key]};
    } else {
      out = {...out, [key]: obj[key]};
    }
  });
  return out as Record<any, any>;
}

export function transformValue(
  obj: Record<any, any> = {},
  mapKeyToFunction: Record<any, Function> = {}
): Record<any, any> {
  let out = {};
  Object.keys(obj).forEach((key) => {
    if (key in mapKeyToFunction) {
      out = {...out, [key]: mapKeyToFunction[key](obj[key])};
    } else {
      out = {...out, [key]: obj[key]};
    }
  });
  return out;
}

export function fromArrayToMapKeyByIndex(arr: any[]): Record<number, any> {
  return arr.reduce(
    (out, current, currentIndex) => ({
      ...out,
      [currentIndex]: current
    }),
    {} // initial value
  );
}
