import styled from 'styled-components';

import {Button} from '@components/common';

const UnitMenuItem = styled.div<{ hoverColor?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    .unit-menu-item-text {
      color: ${(props) => props.hoverColor || 'inherit'};
    }
  }
`;

const Space = styled.span`
  width: 20px;
`;
const HiddenButton = styled(Button)`
  visibility: hidden;
`;

export {UnitMenuItem, Space, HiddenButton};
