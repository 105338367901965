import { ModalPropsWithChildren } from 'antd';
import * as React from 'react';
import styled from 'styled-components';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Joyride, { CallBackProps, ACTIONS, EVENTS, STATUS} from 'react-joyride';
import { Button, Modal } from '@components/common';
import UserContainer from '@utils/state/userContainer';
import ActorService from '@utils/apis/ActorService';
import AskRoseService from '@utils/apis/AskRoseService';
import PaymentModal, { PaymentFormData } from './PaymentModal';
import {RoseModuleType, RoseNotebook} from '@types';
import CreditsModal from './CreditsModal';
import BillingModal, { BillingFormData } from './BillingModal';
import ConfirmModal from './ConfirmModal';
import { askRoseGuideSteps } from '@helpers';
import { isMobile } from 'react-device-detect';
import * as sessionStorageAPI from '../../../service/sessionStorage';

export type TrialModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  setIsAskRose: (isAskRose: boolean) => void;
  setOnAddModule: (cellType: RoseModuleType, convertFirstCell: boolean) => void;
}

type FormData = {
  payment: PaymentFormData;
  billing: BillingFormData;
}

const initialState: FormData = {
  payment: {
    name: '',
    cardNumber: '',
    expDate: '',
    cvc: ''
  },
  billing: {
    company: '',
    address: '',
    zip: '',
    country: ''
  }
};

type TrialModalSteps = 'credits' | 'payment' | 'billing' | 'confirm' | 'guide';

// TODO: Add `billing` step when billing info is ready.
const modalSteps: Array<TrialModalSteps> = ['credits', /* 'billing' */ 'payment', 'confirm', 'guide'];

export function TrialModal({ isModalOpen, setIsModalOpen, setIsAskRose, setOnAddModule}: TrialModalProps) {
  const [trialModal, setTrialModal] = React.useState(0);
  const [formData, setFormData] = React.useState(initialState);
  const [submitting, setSubmitting] = React.useState(false);
  const [blockAdded, setBlockAdded] = React.useState(false);
  const [paymentSucceeded, setPaymentSucceeded] = React.useState(false);
  const { user } = UserContainer.useContainer();
  const stripe = useStripe();
  const elements = useElements();


  const [run, setRun] = React.useState<boolean>(false);
  const [stepIndex, setStepIndex] = React.useState<Number>(0);
  const [showJoyride, setShowJoyride] = React.useState(false);

  const handleJoyrideCallback = (data: CallBackProps) => {

    const { action, index, status, type, step, key} = data;
    const nextStepStatus: string[] = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND];
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    // add ask-rose block in the first step
    if(!blockAdded && stepIndex === 0){
      setOnAddModule('ask', false);
      setBlockAdded(true);
    }
    if (nextStepStatus.includes(type)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));

    } else if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  };

  const handleFormDataChange = (form: keyof FormData, formData: PaymentFormData | BillingFormData) => {
    setFormData((prevState) => ({ ...prevState, [form]: formData }));
  };


  const handleModalClose = () => {    
    // close the payment modal 
    setIsModalOpen(false);

    if(paymentSucceeded){
      // case where user entered card and payment succeeded
      setTrialModal(3);
      setIsAskRose(true);

      // start ask-rose guide if user is not on mobile
      if(!isMobile){
        setShowJoyride(true);
        setRun(true);
      } else {
        //automatically add an ask-rose box to the top of the notebook if on mobile
        // reload the page instantly so that users can start using it
        Promise.resolve(sessionStorageAPI.getNotebook(user?.askrose, true));
        window.location.reload(); 
      }
    } else{
      // case where user changed their mind 
      // reset back to first step if user closed out before giving payment
      setTrialModal(0);
    }

  };

  const onSubmit = async () => {
    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) { return; }

    const { error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement
    });
    if (error) {
      window.alert('There was an error with your card. Please try again.');
    } else {
      const result = await stripe.createToken(cardNumberElement);
      try {
        await ActorService.updateCC(result.token.id);

        try {
          await AskRoseService.subscribe();
          return result;

        } catch (e) {
          window.alert('there was an error subscribing you to AskRose. Please try again. ' + e);
        }
      } catch (e) {
        window.alert('There was an error with your card. Please try again.');
      }
    }
  };

  const renderStep = () => {
    switch (modalSteps[trialModal as number]) {
      case 'credits':
        return <CreditsModal />;
      case 'billing':
        return <BillingModal data={formData.billing} setData={(data: BillingFormData) => { handleFormDataChange('billing', data); }} />;
      case 'payment':
        return <PaymentModal data={formData.payment} setData={(data: PaymentFormData) => { handleFormDataChange('payment', data); }} />;
      case 'confirm':
        return <ConfirmModal user={user} />;
      case 'guide':
        return null;
      default:
        return null;
    }
  };

  return (
    <>
      <StyledModal
        visible={isModalOpen}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          trialModal !== 0 && trialModal !== modalSteps.length - 2 ? <Button key="prev" onClick={() => setTrialModal((curr: number) => curr - 1)}>
            Previous
          </Button> : null,
          trialModal === modalSteps.length - 2 ? <Button key="submit" type="primary" onClick={handleModalClose}>
            Done
          </Button> : trialModal === modalSteps.length - 3 ? <Button loading={submitting} disabled={submitting} key="next" type="primary" onClick={async () => {
            setSubmitting(true);
            try {
              const result = await onSubmit();
              if (!result.error) {
                setTrialModal((curr: number) => curr + 1);
                setSubmitting(false);
                setPaymentSucceeded(true);
              }
            } catch (e) {
              setSubmitting(false);
              setIsAskRose(false);
              console.error(e);
            }
          }}>
            Subscribe
          </Button> : <Button key="next" type="primary" onClick={() => setTrialModal((curr: number) => curr + 1)}>
            {trialModal === 0 ? 'Add Payment Information' : 'Next'}
          </Button>
        ]}
      >
        {renderStep()}
      </StyledModal>
      {showJoyride && (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          disableOverlay
          hideCloseButton
          run={run}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={askRoseGuideSteps}
          styles={{
            options: {
              arrowColor: '#1A1A1A',
              backgroundColor: '#1A1A1A',
              primaryColor: '#1492ff',
              textColor: '#FFF',
              width: 350,
              zIndex: 10000
            },
            tooltip: {
              fontFamily: 'Inter'
            },
            tooltipContent: {
              padding: '20px 10px 5px 10px'
            },
            buttonNext: {
              fontSize: '14px'
            },
            buttonBack: {
              fontSize: '14px'
            },
            buttonSkip: {
              color: '#8c8c8c'
            }
          }}
        />
      )}
    </>
  );
}

const StyledModal = styled<React.FC<ModalPropsWithChildren>>(Modal)`
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
