import * as React from 'react';
import {createRoot} from 'react-dom/client';
import '@utils/createSentry';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {BrowserRouter as Router} from 'react-router-dom';
import UserContainer from '@utils/state/userContainer';
import App from './App';

export const queryClient = new QueryClient();

const AppWrapper = (): JSX.Element =>
<UserContainer.Provider>
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <Router>
      <App />
    </Router>
  </QueryClientProvider>
</UserContainer.Provider>;

const container = document.getElementById('root');
const root = createRoot(container);

// eslint-disable-next-line react/react-in-jsx-scope
root.render(<AppWrapper />);
