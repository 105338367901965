import React, { useState, useEffect } from 'react';
import { SettingsContainer, SettingsMenuContainer } from './SettingsContainer';
import { ThemeSettings } from './components/ThemeSettings';
import { PasswordSettings } from './components/PasswordSettings';
import { SellerProfileSettings } from './components/SellerProfileSettings';
import { PaymentMethodsSettings } from './components/PaymentMethodsSettings';
import { ExternalApiSettings } from './components/ExternalApiSettings';
// import { UsageSettings } from './components/UsageSettings';
import ActorService from '@utils/apis/ActorService';
import UserContainer from '@utils/state/userContainer';

export default (): JSX.Element => {
  const [activeItem, setActiveItem] = useState(1);
  const [creditCardInfo, setCreditCardInfo] = useState(null);
  // const [askroseUsage, setAskroseUsage] = useState(null);
  const [bankAccountInfo, setBankAccountInfo] = useState(null);
  const [vendorInfo, setVendorInfo] = useState([]);
  const {user, setUser} = UserContainer.useContainer();

  const handleItemClick = (item: any) => {
    setActiveItem(item.id);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const creditCardResponse = await ActorService.getCC();
        setCreditCardInfo(creditCardResponse.data && creditCardResponse.data.length > 0 ? creditCardResponse.data[0] : null);
        
        const bankAccountResponse = await ActorService.getBankAccount();
        setBankAccountInfo(bankAccountResponse.data  ? bankAccountResponse.data[0] : null);
        
        const vendorResponse = await ActorService.getApiKeys();
        setVendorInfo(vendorResponse.data  ? vendorResponse.data : null);
        // if (user.askrose) {
        //   const askroseUsageResponse = await ActorService.getAskroseUsage();
        //   setAskroseUsage(askroseUsageResponse.data ? askroseUsageResponse.data : null);
        // }
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, []);

  const settingsItems = [
    {id: 1, name: 'Theme', component: ThemeSettings},
    {id: 2, name: 'Password', component: PasswordSettings},
    {id: 3, name: 'Marketplace', component: SellerProfileSettings, props: { bankAccountInfo, setBankAccountInfo }},
    {id: 4, name: 'Payment methods', component: PaymentMethodsSettings, props: { creditCardInfo, setCreditCardInfo }},
    {id: 5, name: 'External Data Vendors', component: ExternalApiSettings, props: { vendorInfo, setVendorInfo }}
    // ...(user.askrose ? [{id: 5, name: 'Usage', component: UsageSettings, props: { askroseUsage, setAskroseUsage }}] : [])
  ];
  const selectedItem = settingsItems.find((item) => item.id === activeItem);

  return (
    <SettingsContainer>
      <SettingsMenuContainer>
        <ul className="menu">
          {settingsItems.map((item) =>
            <li
              key={item.id}
              className={activeItem === item.id ? 'selected' : ''}
              onClick={() => handleItemClick(item)}
            >
              {item.name}
            </li>
          )}
        </ul>
      </SettingsMenuContainer>
      {React.createElement(selectedItem.component, selectedItem.props || {})}
    </SettingsContainer>
  );
};
