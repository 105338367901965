import {useQuery} from '@tanstack/react-query';
import {message} from 'antd';
import ObjectService from '@utils/apis/ObjectService';

export function useImage(code: string) {
  const result = useQuery(
    ['image', code],
    () => getImage(code),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 5
    }
  );
  return result;
}

async function getImage(code: string) {
  try {
    const data = await ObjectService.getFile(code);
    return data;
  } catch (e) {
    message.error('Error downloading', e);
  }
}
