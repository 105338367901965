import axios from 'axios';
import {GetCompanyUsersResponse} from './AdminAPI.types';
import { getBackendUrl } from '@config';

let backendURL = getBackendUrl();
let withCredentials: boolean;

if (process.env.NODE_ENV === 'production') {
  withCredentials = false;
} else {
  withCredentials = true;
}

const AdminAPI = {
  getCompanyUsers: () => axios.get<GetCompanyUsersResponse>(`${backendURL}/companies/users`, {
    withCredentials
  }),

  removeCompanyUser: (username: string) =>
    axios.post(`${backendURL}/companies/users/remove`,
      {username},
      {
        withCredentials
      }),

  inviteUser: (username: string) =>
    axios.post(`${backendURL}/companies/users/add`,
      {username},
      {
        withCredentials
      })
};
export default AdminAPI;
