import {v4 as uuidv4} from 'uuid';
import {RoseWarning} from './RoseWarning';

export type RoseChart = {
  type: 'chart'
  id: number
  key: string
  code: string
  actorId: number
  actor: string
  values: {
    columns: any[]
    data: any[][]
  }
  metas: any
  warnings: RoseWarning
}

export const RoseChart = {
  deserialize(data: any): RoseChart {
    return {
      type: 'chart',
      id: data.id,
      key: uuidv4(),
      code: data.code,
      actorId: data.actor_id,
      actor: data.actor,
      values: data.values,
      metas: data.metas,
      warnings: RoseWarning.deserialize(data.metas.warning)
    };
  },
  isRoseChart(data: any): data is RoseChart {
    return (data as RoseChart)?.type === 'chart';
  }
};
