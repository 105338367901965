import {useQuery, UseQueryResult} from '@tanstack/react-query';

import PackageService from '@utils/apis/PackageService';

export type UseSubscribedPackagesResult = {
  isSubscribed: (code: string) => boolean
} & Pick<UseQueryResult, 'status' | 'error'>

type Data = Record<string, any>[]

export function useSubscribedPackages(): UseSubscribedPackagesResult {
  const result = useQuery<Data>(
    ['packages/subscribed'],
    PackageService.subscribed.all,
    {
      refetchOnWindowFocus: false
    }
  );

  return {
    error: result.error,
    status: result.status,
    isSubscribed(code: string): boolean {
      return Boolean(result.data?.find?.((d) => d.code === code));
    }
  };
}
