import {message} from 'antd';
import React, {useState} from 'react';
import styled from 'styled-components';
import UserService from '@utils/apis/UserService';
import {trackError} from '@utils/createSentry';
import {Button, Form, Input, Typography} from '@components/common';

type NewContactFormProps = {
  firstName: string
  lastName: string
  email: string
}

export const NewContactForm: React.FC = () => {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [contactCreation, setContactCreation] = useState<NewContactFormProps>({
    firstName: '',
    lastName: '',
    email: ''
  });
  const {firstName, lastName, email} = contactCreation;

  const onHandleChange = (name: string) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setContactCreation({
      ...contactCreation,
      [name]: e.currentTarget.value
    });
    setErrorMessage('');
  };

  const onFinish = (values: any) => {
    UserService.createContactAccount(values)
      .then((res) => {
        if (res?.data?.message) {
          message.success(res?.data?.message, 5);
        }
      })
      .catch((error: any) => {
        if (error?.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          console.error('error in contact form registration: ', error);
          trackError(error);
        }
      });
    form.resetFields();
  };

  return (
    <FormContainer>
      <MessageContainer>
        <Typography as="h1" variant="secondary" size="xxx-large">
          <strong>New Contact Form</strong>
        </Typography>
        <Typography as="p" variant="primary">
          Create a Rose.ai account for a partner, networking contact or friend.
          Your contact will receive an email with instructions to activate the
          account.
        </Typography>
      </MessageContainer>
      <StyledFormContainer>
        <StyledForm
          size="large"
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{required: true, message: 'Please input first name'}]}
          >
            <Input value={firstName} onChange={onHandleChange('firstName')} />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{required: true, message: 'Please input last name'}]}
          >
            <Input value={lastName} onChange={onHandleChange('lastName')} />
          </Form.Item>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[{required: true, message: 'Please input email address'}]}
          >
            <Input value={email} onChange={onHandleChange('email')} />
          </Form.Item>
          {errorMessage && errorMessage.length ?
            <ErrorMessage as="p">{errorMessage}</ErrorMessage> :
            null}
          <Form.Item>
            <StyledButton
              type="primary"
              htmlType="submit"
              size="large"
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({errors}) => errors.length)
                  .length ||
                !firstName ||
                !lastName ||
                !email
              }
            >
              Send Email
            </StyledButton>
          </Form.Item>
          <Form.Item>
            <StyledButton htmlType="submit" size="large">
              Cancel
            </StyledButton>
          </Form.Item>
        </StyledForm>
      </StyledFormContainer>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  padding: 16px;
  max-height: 100%;
`;
const StyledFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledForm = styled(Form)`
  max-width: 600px;
  width: 100%;
  position: relative;
  padding: 20px;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07),
    0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 8px;
`;

const MessageContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 24px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ErrorMessage = styled(Typography)`
  margin-bottom: 24px;
  font-size: 14px;
  color: red;
  text-align: center;
`;
