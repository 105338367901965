import * as React from 'react';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
  useMutation,
  UseMutationResult
} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import PackageService from '@utils/apis/PackageService';
import {trackError} from '@utils/createSentry';
import {Interval} from '@types';

export type SellingPackageResults = {
  totalItems: number
  items: SellingPackage[]
}

export type SellingPackage = {
  code: string
  price: string
  interval: Interval
}

const QUERY_KEY = 'PackageService.selling.all';

export function useSelling(
  page: number,
  pageSize: number
): UseQueryResult<SellingPackageResults, AxiosError> {
  const queryClient = useQueryClient();
  const result = useQuery<SellingPackageResults, AxiosError>(
    [QUERY_KEY, page],
    () => fetchItems(page, pageSize),
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      staleTime: 1000 * 60 // ms
    }
  );

  React.useEffect(
    () => {
      queryClient.prefetchQuery(['PackageService.selling.all', page + 1], () =>
        fetchItems(page + 1, pageSize)
      );
    },
    [page, result.data, queryClient]
  );

  return result;
}

export function useSellingAdd(): UseMutationResult<
  void,
  AxiosError,
  SellingPackage
  > {
  const queryClient = useQueryClient();
  const mutation = useMutation<void, AxiosError, SellingPackage>((pck) =>
    PackageService.selling
      .list(pck)
      .then(() => queryClient.invalidateQueries([QUERY_KEY]))
      .catch((err) => {
        console.error('error trying to add a package to sell: ', err);
        trackError(err);
        throw err;
      })
  );
  return mutation;
}

export function useSellingRemove(): UseMutationResult<
  void,
  AxiosError,
  SellingPackage
  > {
  const queryClient = useQueryClient();
  const mutation = useMutation<void, AxiosError, SellingPackage>((pck) =>
    PackageService.selling
      .remove(pck.code, pck.interval)
      .then(() => queryClient.invalidateQueries([QUERY_KEY]))
      .catch((err) => {
        console.error('error trying to add a package to sell: ', err);
        trackError(err);
        throw err;
      })
  );
  return mutation;
}

function fetchItems(
  page: number,
  pageSize: number
): Promise<SellingPackageResults> {
  return PackageService.selling
    .all(page, pageSize)
    .then(parseData)
    .catch((err: AxiosError) => {
      console.error('error PackageService.selling.all: ', err);
      trackError(err);
      throw err;
    });
}

function parseData({data}: AxiosResponse<any>): SellingPackageResults {
  return {
    totalItems: data.length,
    items: data.objects.map(
      (pkg: any): SellingPackage => ({
        code: pkg.code,
        price: currencyFormatter.format(pkg.price),
        interval: pkg.interval
      })
    )
  };
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});
