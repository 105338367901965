import React from 'react';
import {Step} from 'react-joyride';

export const askRoseGuideSteps: Step[] = [
  {
    content: <RoseToolTip title="" gradientSpan="Ask Rose" description="Explore data using the power of AI"/>,
    placement: 'right',
    target: '#ask-rose-button'
  },
  {
    content: <RoseToolTip title="Ask a Question" description="Type any prompt into the chatbox, such as 'Show me the GDP of the US over time.'"/>,
    placement: 'left',
    target: 'dynamic-target'
  },
];

type RoseToolTipProps = {
  title?: string,
  description?: string,
  gradientSpan?: string,
}

function RoseToolTip({title, description, gradientSpan}:RoseToolTipProps): JSX.Element {
  return (
    <div className="font-light text-white">
      <div className="flex mb-2 font-bold text-left">
        {title ?? <p className="text-base leading-tight">{title}</p>}
        {gradientSpan ? <p className="flex text-transparent leading-tight text-base bg-clip-text bg-gradient-to-r from-[#1bbef9] to-[#b71df2] align-middle">{gradientSpan}</p> : null}
      </div>
      <div className="text-left">
        <p className="text-sm leading-tight">{description}</p>
      </div>
    </div>
  );
}

// content: <RoseToolTip title="" description=''/>,
