import {useQuery, UseQueryResult, useQueryClient} from '@tanstack/react-query';
import ObjectService from '@utils/apis/ObjectService';
import {RoseObject} from '@types';

export type Options = { enabled: boolean }

export type UseCodePreviewResult = UseQueryResult<RoseObject, Error> & {
  prefetch: () => void
}

export function useCodePreview(
  code: string,
  options: Options
): UseCodePreviewResult {
  const queryClient = useQueryClient();
  const result = useQuery<RoseObject, Error>(
    ['preview', code],
    () => ObjectService.get(`${code}:preview`),
    {
      enabled: options.enabled,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60
    }
  );

  return {
    ...result,
    prefetch() {
      queryClient.prefetchQuery(['preview', code], () =>
        ObjectService.get(`${code}:preview`)
      );
    }
  };
}
