import React, {useState} from 'react';

import UserService from '@utils/apis/UserService';

import HelmetWrapper from '@utils/HelmetWrapper';
import {InputPassword, Button} from '@components/common';
import {
  ModalState,
  PasswordCard,
  CardsContainer,
  FormContainer
} from '../SettingsContainer';
import PasswordModal from './PasswordModal';

export function PasswordSettings(): JSX.Element {
  const [oldPasswordInput, setOldPasswordInput] = useState('');
  const [newPasswordInput, setNewPasswordInput] = useState('');
  const [passwordModalState, setPasswordModalState] = useState(
    ModalState.Hidden
  );
  const [errorMessage, setErrorMessage] = useState('');

  const changePassword = async () => {
    try {
      setPasswordModalState(ModalState.Loading);
      await UserService.changePassword({
        old_password: oldPasswordInput,
        new_password: newPasswordInput
      });
      setPasswordModalState(ModalState.Success);
      setOldPasswordInput('');
      setNewPasswordInput('');
    } catch (e) {
      setErrorMessage(e.response?.data.message);
      setPasswordModalState(ModalState.Error);
    }
  };

  return (
    <div>
      <HelmetWrapper title="Profile" description="User Profile" />
      <CardsContainer>
        <PasswordCard title="Change Password" id="change-password">
          <FormContainer>
            <InputPassword
              id="old-password"
              placeholder="Old Password"
              value={oldPasswordInput}
              onChange={(e: any) => setOldPasswordInput(e.target.value)}
            />
            <InputPassword
              id="new-password"
              placeholder="New Password"
              value={newPasswordInput}
              onChange={(e: any) => setNewPasswordInput(e.target.value)}
            />
            <Button type="ghost" onClick={changePassword}>
              Update Password
            </Button>
          </FormContainer>
        </PasswordCard>
      </CardsContainer>
      <PasswordModal
        state={passwordModalState}
        onClose={() => setPasswordModalState(ModalState.Hidden)}
        errorMessage={errorMessage}
      />
    </div>
  );
}
