import React, {FC} from 'react';
import {Helmet} from 'react-helmet';

type Props = {
  title: string
  description: string
  pathname?: string
}

const getUrl = (pathname?: string): string =>
  pathname ? `${window.location.origin}${pathname}` : window.location.href;

const HelmetWrapper: FC<Props> = ({title, description, pathname}: Props) =>
  <Helmet>
    {/* Primary Meta Tags */}
    <title>{title}</title>
    <meta property="title" content={title} key="title" />
    <meta property="description" content={description} key="description" />
    {/* Open Graph Meta Tags */}
    {/* You need to use a tunnel like ngrok or https://tunnelmole.com (e.g. `tmole 8080`) and then paste that URL into https://www.opengraph.xyz to preview what our OpenGraph metadata gets interpreted as. Also temporarily set this env var: https://stackoverflow.com/a/59308020/ */}
    <meta property="og:title" content={title} key="og_title" />
    <meta
      property="og:description"
      content={description}
      key="og_description"
    />
    <meta property="og:type" content="website" key="og_type" />
    <meta property="og:url" content={getUrl(pathname)} key="og_url" />
    <meta name="thumbnail" content="/img/new_logo/svg/RoseAIlogo-gradient.svg" />
    <meta
      property="og:image"
      content="/img/new_logo/svg/RoseAIlogo-gradient.svg"
      key="og_image"
    />
    <meta property="og:image:type" content="image/jpeg" key="og_image_type" />
    <meta property="og:image:width" content="512" key="og_image_width" />
    <meta property="og:image:height" content="512" key="og_image_height" />
    {/* Twitter Meta Tags */}
    <meta
      property="twitter:card"
      content="summary_large_image"
      key="twitter_card"
    />
    <meta property="twitter:url" content={getUrl(pathname)} key="twitter_url" />
    <meta property="twitter:title" content={title} key="twitter_title" />
    <meta
      property="twitter:description"
      content={description}
      key="twitter_description"
    />
    <meta
      property="twitter:image"
      content="/img/new_logo/svg/RoseAIlogo-gradient.svg"
      key="twitter_image"
    />
  </Helmet>;

export default HelmetWrapper;
