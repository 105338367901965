import React, {useState, useEffect} from 'react';

import ActorService from '@utils/apis/ActorService';
import HelmetWrapper from '@utils/HelmetWrapper';
import {Input, Button} from '@components/common';

import {
  ModalState,
  BankAccountCard,
  CardsContainer,
  FormContainer
} from '../SettingsContainer';
import BankAccountModal from './BankAccountModal';

export function SellerProfileSettings({ bankAccountInfo, setBankAccountInfo }): JSX.Element {
  const [routingNumberInput, setRoutingNumberInput] = useState(bankAccountInfo ? bankAccountInfo.routing_number : '');
  const [bankAccountInput, setBankAccountInput] = useState(bankAccountInfo ? `********${bankAccountInfo.account_number}` : '');
  const [bankAccountModalState, setBankAccountModalState] = useState(ModalState.Hidden);
  const [errorMessage, setErrorMessage] = useState('');

  const updateBankAccount = async () => {
    try {
      setBankAccountModalState(ModalState.Loading);
      const result = await ActorService.updateBankAccount({
        routing_number: routingNumberInput,
        account_number: bankAccountInput
      });
      setBankAccountModalState(ModalState.Success);
      setBankAccountInfo(result.data);
    } catch (e) {
      setErrorMessage(e.message);
      setBankAccountModalState(ModalState.Error);
    }
  };

  useEffect(() => {
    if (bankAccountInfo) {
      setRoutingNumberInput(bankAccountInfo.routing_number);
      setBankAccountInput(`********${bankAccountInfo.account_number}`);
    }
  }, [bankAccountInfo]);

  return (
    <div>
      <HelmetWrapper title="Profile" description="User Profile" />
      <CardsContainer>
        <BankAccountCard
          title="Bank Account (required to list data in Marketplace)"
          id="bank-account"
        >
          <FormContainer>
            <Input
              id="routing-number"
              placeholder="Routing Number"
              value={routingNumberInput}
              onChange={(e: any) => setRoutingNumberInput(e.target.value)}
            />
            <Input
              id="account-number"
              placeholder="Account Number"
              value={bankAccountInput}
              onChange={(e: any) => setBankAccountInput(e.target.value)}
            />
            <Button type="ghost" onClick={updateBankAccount}>
              Update Bank Account
            </Button>
          </FormContainer>
        </BankAccountCard>
      </CardsContainer>
      <BankAccountModal
        state={bankAccountModalState}
        onClose={() => setBankAccountModalState(ModalState.Hidden)}
        errorMessage={errorMessage}
      />
    </div>
  );
}
