import {RoseWarning} from './RoseWarning';
import {RoseActor} from './RoseActor';

export type RoseQuery = {
  type: 'query'
  code: string
  actorId: number
  actor: RoseActor
  connectionId: number
  metas: any
  values: {
    columns: string[]
    data: any[][]
  }
  warnings: RoseWarning
}

export const RoseQuery = {
  deserialize(data: any): RoseQuery {
    return {
      type: 'query',
      code: data.code,
      actor: data.actor,
      actorId: data.actor_id,
      connectionId: data.connection_id,
      metas: data.metas,
      values: {
        columns: data.values.columns,
        data: data.values.data
      },
      warnings: RoseWarning.deserialize(data.metas.warning)
    };
  },
  serialize(roseQuery: RoseQuery): Record<string, any> {
    return {
      type: roseQuery.type,
      code: roseQuery.code,
      actor: RoseActor.serialize(roseQuery.actor),
      actor_id: roseQuery.actorId,
      connection_id: roseQuery.connectionId,
      metas: roseQuery.metas,
      values: roseQuery.values
    };
  },
  isRoseQuery(data: any): boolean {
    return data.type === 'query';
  }
};
