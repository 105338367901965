import * as React from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import axios, {AxiosError} from 'axios';
import ObjectService from '@utils/apis/ObjectService';
import {extractLogic} from '@utils/helpers/roseQueries';
import ConnectionService from '@utils/apis/ConnectionService';
import {Connection, RoseObject} from '@types';

export type UseRoseQueryResult = Omit<UseQueryResult, 'data' | 'refetch'> & {
  data: ApiCallResult
  error: Error
  refetch: () => void
}

type ApiCallResult = {
  roseObject: RoseObject
  connection: Connection
  code: string
}

type Metas = {
  name: string
  // eslint-disable-next-line camelcase
  connection_code: string
  query: string
}

export type UseRoseQueryOptions = {
  onSuccess: (data: ApiCallResult) => void
}

export function useRoseQuery(
  code: string,
  connection: Connection | undefined,
  options?: UseRoseQueryOptions
): UseRoseQueryResult {
  const [keyTransaction, setKeyTransaction] = React.useState(code);
  const {data, status, error, refetch, ...rest} = useQuery<
    ApiCallResult,
    Error
  >(
    ['query', code + keyTransaction],
    () => runQuery(code, connection),
    {
      enabled: false,
      retry: false,
      keepPreviousData: true,
      staleTime: 0,
      cacheTime: 0,
      onSuccess: (data_) => {
        options?.onSuccess?.(data_);
      }
    }
  );

  React.useEffect(() => {
    if (code !== '' && connection !== undefined) {
      refetch();
    }
  }, [keyTransaction]);

  return {
    data,
    status,
    error,
    refetch: () => {
      setKeyTransaction(String(Date.now()));
    },
    ...rest
  };
}

// HELPERS

async function runQuery(
  query: string,
  connection: Connection
): Promise<ApiCallResult> {
  const trimmedQuery = query.trim();
  if (trimmedQuery.split(';').length > 1 && trimmedQuery.split(';')[1] !== '') {
    throw new Error(
      'Multiple queries found. Please only run one query at a time'
    );
  }

  const [queryCode, queryLogic] = extractLogic(trimmedQuery);
  if (queryLogic) {
    try {
      await ObjectService.create(
        queryCode,
        queryLogic,
        // eslint-disable-next-line camelcase
        {connection_code: connection.code},
        'query'
      );
    } catch (error) {
      // throw error;
      // return Promise.reject({ message: error });
    }
  }

  return ConnectionService.runQuery(connection.code, queryLogic ?? queryCode)
    .then((res) => ({code: query, connection, roseObject: res.data}))
    .catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        throw new Error(err?.message ?? 'Error running query');
      } else {
        throw err;
      }
    });
}
