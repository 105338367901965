export type KeyOptions = 'ArrowUp' | 'ArrowDown' | 'Enter' | 'Escape';

export const focusListItem = (id: string, index: number): void =>
  document
    .getElementById(id)
    ?.querySelectorAll('li')
    ?.[index]?.scrollIntoView({block: 'end'});

export const scrollItemTop = (): void => {
  if (document.getElementById('ll-categories')) {
    document.getElementById('ll-categories').scrollTop = 0;
  }
};
