import {RoseWarning} from './RoseWarning';

export type RoseFunction = {
  type: 'function'
  code: string
  id?: number
  values: {
    columns: string[]
    data: any[]
    index?: any[]
  }
  metas: any
  warnings: RoseWarning
}

export const RoseFunction = {
  deserialize(data: any): RoseFunction {
    return {
      type: 'function',
      code: data.code,
      values: data.values,
      metas: data.metas,
      id: data?.id,
      warnings: RoseWarning.deserialize(data.metas.warning)
    };
  },
  serialize(roseFunction: RoseFunction): Record<string, any> {
    return {
      type: 'function',
      code: roseFunction.code,
      values: roseFunction.values,
      metas: roseFunction.metas,
      id: roseFunction?.id
    };
  },
  isRoseFunction(data: any): boolean {
    return data?.type === 'function';
  }
};
