/* eslint-disable camelcase */
import UserContainer from '@utils/state/userContainer';
import {RoseWarning} from './RoseWarning';
import {RoseModule} from './RoseModule';

export type RoseNotebook = {
  type: 'notebook'
  id: number
  code: string
  actorId: number
  actor: string
  modules: RoseModule[]
  values: {
    columns: string[]
    data: string[]
  }
  metas?: any
  warnings: RoseWarning
  permission: string
}

export const RoseNotebook = {
  deserialize(data: any): RoseNotebook {
    try {
      const notebook: RoseNotebook = {
        type: data.type,
        id: data.id,
        code: data.code,
        actorId: data.actor_id,
        actor: data.actor,
        modules: RoseModule.deserialize(data.values),
        values: data.values,
        metas: data.metas,
        warnings: RoseWarning.deserialize(data.metas.warning),
        permission: data.permission
      };
      return notebook;
    } catch (error) {
      console.warn('error serializing to RoseNotebook. ', error);
      return RoseNotebook.create();
    }
  },
  serialize(notebook: RoseNotebook): Record<string, any> {
    return {
      type: notebook.type,
      id: notebook.id,
      code: notebook.code,
      actor_id: notebook.actorId,
      actor: notebook.actor,
      modules: RoseModule.serialize(notebook.modules),
      values: notebook.values,
      metas: notebook.metas
    };
  },
  isNotebook(data: any): boolean {
    return data?.type === 'notebook';
  },
  create(askrose?: boolean): RoseNotebook {
    return {
      type: 'notebook',
      id: 0,
      code: '',
      actorId: 0,
      actor: '',
      modules: askrose ? [RoseModule.create({type: 'ask'})] : [RoseModule.create({type: 'code'})],
      values: {
        columns: [],
        data: []
      },
      metas: {
        lastUpdatedAt: '',
        lastUpdatedBy: '',
        length: 3
      },
      permission: "write",
      warnings: []
    };
  },
  update(
    notebook: RoseNotebook,
    newValues: Partial<RoseNotebook>
  ): RoseNotebook {
    return {
      ...notebook,
      ...newValues
    };
  },
  updateModule(notebook: RoseNotebook, mod: RoseModule): RoseNotebook {
    return {
      ...notebook,
      modules: notebook.modules.map((m) => m.key === mod.key ? mod : m)
    };
  },
  duplicate(notebook: RoseNotebook, code: string): RoseNotebook {
    const newNotebook = {...notebook};
    newNotebook.code = code;
    newNotebook.id = undefined;
    return newNotebook;
  }
};
