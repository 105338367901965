export const cleanFileName = (file: string) => {
  let tempCode = file.replace(/[\'\"\(\)\[\]\{\}\!\@\#\$\%\^\&\*\+\;\:\-\<\>\?\/\_\\\s]/g, '.');
  tempCode = tempCode.substring(0, tempCode.lastIndexOf('.')).toLowerCase() || tempCode.toLowerCase();

  return tempCode;
};

export const checkRoseCode = (code: string) => {
  const regex = /[\'\"\(\)\[\]\{\}\!\@\#\$\%\^\&\*\+\;\:\-\<\>\?\/\_\\\s]/g;
  return regex.test(code);
};

export const dynamicPositioning = (rangeSelectorAttr: any, marginAtrr: any, titleAttr: any, legendAttr: any, chartWidthAttr: any, viewMode: boolean) => {
  let lastElementY = 0;
  let titleY = 0;

  // set range selector
  const rs = rangeSelectorAttr;

  if (viewMode) {
    lastElementY = 0;
  } else {
    rs.options.inputPosition.y = -marginAtrr[0] + 10.5; // 10.5 is to make it allign with the hamburger button
    rs.options.inputPosition.x = -30; // use -30 to avoid overlapping with the hamburger button
    rs.options.buttonPosition.y = -marginAtrr[0] + 10.5;
    if (rs.inputGroup.translateY !== rs.buttonGroup.translateY) { // if inputGroup and buttonGroup are in two diff rows
      lastElementY = 2 * rs.maxDateBox.height + 20.5;
    } else {
      lastElementY = 10.5 + rs.maxDateBox.height;
    }
  }

  const title = titleAttr;
  if (title.textStr !== '') {
    const titleHeight = title.alignOptions.height;
    titleY = lastElementY + 30; // title is offset by fontSize by Highcharts
    lastElementY = titleHeight + lastElementY + 10;
  }

  const legendTranslate = ['translate(', legendAttr.group.translateX, ',', lastElementY, ')'].join('');

  return {
    titleY,
    marginTop: lastElementY + 10 + legendAttr.legendHeight,
    legendTranslate
  };

};
