import {useQuery} from '@tanstack/react-query';
import AdminAPI from '@utils/apis/AdminAPI';
import {GetCompanyUsersResponse} from '@utils/apis/AdminAPI.types';

const fuzzyIncludes = (uno: string, dos: string) => uno.toLowerCase().includes(dos.toLowerCase());

const useGetCompanyUsers = (searchName: string) => {

  const {status, data, refetch} = useQuery(['GetCompanyUsers'], getCompanyUsers, {
    refetchOnWindowFocus: false,
    cacheTime: 0
  });

  const matchingUsers = data ? data.map((actor) => ({
    code: actor?.code ?? ' ',
    email: actor?.email ?? ' ',
    name: actor.first_name + ' ' + actor.last_name,
    status: actor.is_admin ? 'admin' : 'user'
  })).filter((actor) => fuzzyIncludes(actor.name, searchName)) : [];

  return {
    status,
    users: matchingUsers,
    refetch
  };

};

const getCompanyUsers = async () => AdminAPI.getCompanyUsers()
  .then((res) => res.data.Users)
  .catch((err) => {
    console.error(err);
    return [] as GetCompanyUsersResponse['Users'];
  }); // USEQUERY

export default useGetCompanyUsers;
