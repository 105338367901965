import * as React from 'react';
import styled from 'styled-components';

import {Button, Table, Modal} from '@components/common';
import {MarketplacePackage} from '@types';

export type DetailsButtonProps = {
  pck: MarketplacePackage & { metas?: Record<string, any> }
}

export function DetailsButton({pck}: DetailsButtonProps): JSX.Element {
  const [visible, setVisible] = React.useState<boolean>(false);

  const detailsTableColumns = [
    {
      title: 'Tag',
      dataIndex: 'tag',
      key: 'tag'
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value'
    }
  ];

  const detailsDataSource = Object.keys(pck?.metas ?? [])
    .filter((key) => key !== 'column_order')
    .map((key) => ({
      tag: key,
      value: pck.metas[key]
    }));

  return (
    <>
      <Button type="ghost" onClick={() => setVisible(true)}>
        Details
      </Button>
      <StyledDetailsModal
        title={`${pck.code} Details`}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Table
          columns={detailsTableColumns}
          rowKey="tag"
          dataSource={detailsDataSource}
          pagination={false}
        />
      </StyledDetailsModal>
    </>
  );
}

const StyledDetailsModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
