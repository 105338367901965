/* eslint-disable max-lines-per-function */
import React, {useState} from 'react';
import styled from 'styled-components';
import {Card, message} from 'antd';
import {useNavigate} from 'react-router-dom';
import UserService from '@utils/apis/UserService';
import HelmetWrapper from '@utils/HelmetWrapper';
import {
  Button,
  Form,
  Input,
  Typography,
  DashboardContainer
} from '@components/common';
import {ThemeVarNames} from '@theme';

// eslint-disable-next-line react/display-name
export default (): JSX.Element => {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const onClickContinue = async (values: any) => {
    if (isLoading) {return;}

    setIsLoading(true);
    // eslint-disable-next-line promise/catch-or-return
    UserService.forgotPassword(values.email)
      // eslint-disable-next-line promise/always-return
      .then(() => {
        const DURATION_IN_SECONDS = 5;
        message.success('A recovery email has been sent', DURATION_IN_SECONDS);
        navigate('/login');
      })
      .catch(() => {
        setError('invalid email or password');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <HelmetWrapper
        title="Forgot Password"
        description="Forgot Your Password"
        pathname="/forgot"
      />
      <DashboardContainer style={{justifyContent: 'center'}}>
        <Container>
          <a href="/">
            <StyledImg src="/img/new_logo/svg/Roselogo-gradient.svg" alt="Rose Logo" />
          </a>
          <StyledCard>
            <Typography as="p" variant="primary">
              Enter the email address associated with your account and we&apos;ll send
              you a link to reset your password.
            </Typography>
            <Form
              name="login_form"
              initialValues={{remember: true}}
              onFinish={onClickContinue}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Please provide a valid email address'
                  },
                  {required: true, message: 'Please provide an email address'}
                ]}
              >
                <Input size="large" placeholder="Email" />
              </Form.Item>
              {error ? <Message>{error}</Message> : null}
              <StyledButton
                type="primary"
                size="large"
                htmlType="submit"
                loading={isLoading}
              >
                Continue
              </StyledButton>
            </Form>
            <BelowFormButtons>
              <StyledButton size="large" onClick={() => navigate('/login')}>
                Return to sign in
              </StyledButton>
            </BelowFormButtons>
          </StyledCard>
          <BelowCard>
            <Typography variant="primary">Don&apos;t have an account? </Typography>
            <a href="/register">Sign up</a>
          </BelowCard>
        </Container>
      </DashboardContainer>
    </>
  );
};

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledCard = styled(Card)`
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  max-width: 500px;
  background-color: var(${ThemeVarNames.SecondaryBg});
  border-color: var(${ThemeVarNames.Border});

  .ant-card-body {
    width: 100%;
  }
`;
const StyledImg = styled.img`
  width: 66px;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;
const BelowFormButtons = styled.div`
  margin-top: 20px;
  display: flex;
`;

const Message = styled.p`
  margin-bottom: 24px;
  font-size: 14px;
  text-align: center;
`;

const BelowCard = styled.p`
  margin-top: 10px;
`;
