/* eslint-disable max-statements */
import React, {useState, useEffect, useRef, useMemo} from 'react';
import styled from 'styled-components';
import {IoTrash} from '@react-icons/all-files/io5/IoTrash';
import {IoAdd} from '@react-icons/all-files/io5/IoAdd';
import {IoCode} from '@react-icons/all-files/io5/IoCode';
import {IoLogoMarkdown} from '@react-icons/all-files/io5/IoLogoMarkdown';
import {IoArrowRedoCircle} from '@react-icons/all-files/io5/IoArrowRedoCircle';
import {IoInformation} from '@react-icons/all-files/io5/IoInformation';
import {IoEyeOffOutline} from '@react-icons/all-files/io5/IoEyeOffOutline';
import {AiOutlineColumnWidth} from '@react-icons/all-files/ai/AiOutlineColumnWidth';
import {BiHide} from '@react-icons/all-files/bi/BiHide';
import {BiShow} from '@react-icons/all-files/bi/BiShow';
import _ from 'lodash';
import {Link} from 'react-router-dom';

import {
  OptionMenu,
  RoseModuleCode,
  RoseModule,
  ChartSettings,
  RoseTimeseriesGroup,
  RoseChart,
  RoseModuleChartSettings
} from '@types';
import {useCode as useCodeService} from '@service/useCode';
import {device} from '@utils/breakpoints';
import {ModuleLayout, ViewMode} from '@components/common/Layouts';
import {Spinner} from '@components/common/Loading';

import UserContainer from '@utils/state/userContainer';
import {Result} from '../../Result';
import {changeWidth} from '../common/ChangeWidth';
import {HelperText} from '../common/HelperText';
import {Options} from '../common/Options';
import {InputWrapper as Input} from './InputWrapper';

const COLOR = '#1f87e5';

export type ModuleProps = {
  mod: RoseModuleCode;
  onAddModule: (mod: Partial<RoseModule>) => void;
  onRemoveModule: () => void;
  onModuleChanged: (newMod: RoseModule, mutate?: boolean) => void;
  options: OptionMenu[];
  updateCurrentModKey: (modKey: string) => void;
  isReadOnly: boolean;
  autoFocus?: boolean;
  view: ViewMode;
};

export function Code(props: ModuleProps) {
  const {
    mod,
    onModuleChanged,
    onAddModule,
    onRemoveModule,
    options,
    updateCurrentModKey,
    isReadOnly,
    autoFocus,
    view
  } = props;
  const {user} = UserContainer.useContainer();
  const [showResults, setShowResults] = useState(true);
  const [isTimeseriesTableShown, setIsTimeseriesTableShown] = useState(view === 'edit');
  const [code, setCode] = useState<{ value: string; shouldRefetch: boolean }>({
    value: mod.textBox,
    shouldRefetch: false
  });
  const initialMount = useRef(true);
  const objectListInitialised = useRef(false);

  // the reference to `mod` is out of date for highcharts
  // when passed down, so we need a constantly up-to-date
  // reference to the module
  const moduleRef = useRef(mod);
  moduleRef.current = mod;

  const {roseObjects, status, refetch, isFetching, errors} = useCodeService(
    mod.key,
    code.value,
    {
      onLogicsCreated(rawCode) {
        const newMod = RoseModule.create(mod);
        newMod.textBox = rawCode;
        onModuleChanged(newMod, !initialMount.current);
        initialMount.current = false;
      }
    }
  );

  const objectIds = useMemo(() => roseObjects?.[0]?.data?.map((obj: any) => obj.id) || [], [roseObjects]);

  function arraysContainSameValuesAndNotNull(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i] || arr1[i] === null || arr2[i] === null) {
        return false;
      }
    }

    return true;
  }

  // for passing object ids to ObjectService.create
  useEffect(() => {
    const moduleObjects = moduleRef.current.modulesettings?.objects || [];

    if (!objectListInitialised.current && objectIds.length > 0) {
      objectListInitialised.current = true;
    }

    if (!arraysContainSameValuesAndNotNull(objectIds, moduleObjects) && objectListInitialised.current) {
      onModuleChanged({
        ...moduleRef.current,
        modulesettings: {
          ...moduleRef.current.modulesettings,
          objects: objectIds
        }
      });
    }
  }, [objectIds]);

  const chartSettingsMap: RoseModuleChartSettings = useMemo(
    () => {
      if (!_.isEmpty(mod.modulesettings?.charts)) {
        return mod.modulesettings?.charts;
      }

      let timeseries = roseObjects
        .filter(RoseTimeseriesGroup.isRoseTimeseriesGroup)
        .map((group) => group.data)
        .reduce<Record<string, ChartSettings>>((result, data) => {
          const key = data.map((d) => d.code).join('-');
          return {
            ...result,
            [key]: ChartSettings.create(data.map((d) => d.code), 'timeseries-group')
          };
        }, {});

      timeseries = {
        ...timeseries,
        ...mod.modulesettings?.charts?.timeSeries // adding this to ensure backwards compatibility on a temporary data model
      };

      let charts = roseObjects
        .filter(RoseChart.isRoseChart)
        .reduce<Record<string, ChartSettings>>((result, data: RoseChart) => {
          const columns = _.slice(data?.values.columns, 1, data?.values.columns.length);
          const key = data?.code;
          return {
            ...result,
            [key]: ChartSettings.create(columns as string[], 'chart')
          };
        }, {});

      charts = {
        ...charts,
        ...mod.modulesettings?.charts // adding this to ensure backwards compatibility on a temporary data model
      };

      return {
        ...timeseries,
        ...charts
      };
    },
    [mod.modulesettings?.charts, roseObjects]
  );

  /**
   * The ant design table inside the Result component is not respecting
   * the max-width and it's ruining the responsiveness in mobile.
   * That's why I am getting the ResultLayout dimensions
   * and set manually the max-width to the ResultContainer in mobile
   */
  const [resultLayoutRect, serResultLayoutRect] = useState<DOMRect>();
  const resultLayoutRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (resultLayoutRef?.current) {
      serResultLayoutRect(resultLayoutRef.current.getBoundingClientRect());
    }
  }, [resultLayoutRef]);

  useEffect(() => {
    if (mod.runOnLoad && code.value !== '') {
      refetch();
    }
  }, []);

  useEffect(() => {
    setCode({value: mod.textBox, shouldRefetch: false});
  }, [mod]);

  useEffect(() => {
    if (code.shouldRefetch === true) {
      refetch();
    }
  }, [code]);

  useEffect(() => {
    onViewChanged(view);
  }, [view]);

  function onViewChanged(view: ViewMode) {
    if (view === 'view') {setIsTimeseriesTableShown(false);} else {setIsTimeseriesTableShown(true);}
  }

  function handleOnRun() {
    setCode((state) => ({
      value: state.value,
      shouldRefetch: true
    }));
    onModuleChanged({...mod, textBox: code.value});
    setShowResults(true);
  }

  function handleOnAddModule() {
    onAddModule(RoseModule.create({type: 'code'}));
  }

  const handleOnChange = (value: string) => {
    setCode({value, shouldRefetch: false});
  };

  function onConvertToMarkdown() {
    const roseModuleMarkdown = RoseModule.toMarkdown(mod, {
      textBox: code.value,
      runOnLoad: false
    });
    onModuleChanged(roseModuleMarkdown);
  }

  function onConvertToAsk() {
    const roseModuleAsk = RoseModule.toAsk(mod, {
      textBox: code.value,
      runOnLoad: false
    });
    onModuleChanged(roseModuleAsk);
  }

  function handleOnChangeWidthClick(width: '33%' | '50%' | '66%' | '100%') {
    return changeWidth({
      width,
      mod,
      textBox: code.value,
      onModuleChanged
    });
  }

  function handleOnChartSettingsChange(id: string, chartSettings: ChartSettings) {
    const newModuleSettings = {
      ...mod.modulesettings,
      charts: {
        ...mod.modulesettings?.charts || {},
        [id]: chartSettings
      }
    };
    onModuleChanged({...mod, modulesettings: newModuleSettings});
  }

  let mergedOptions: OptionMenu[] = [...options];
  if (isReadOnly || view === 'view') {
    mergedOptions = [{
      title: 'Explore',
      icon: <Link
        to={{pathname: '/dashboard'}}
        target="_blank"
        onClick={() => localStorage.setItem('referrerMod', JSON.stringify([mod]))}
      >
        <IoArrowRedoCircle />
      </Link>
    }, {
      title: isTimeseriesTableShown ? 'Hide Details' : 'Show Details',
      icon: isTimeseriesTableShown ? <IoEyeOffOutline /> : <IoInformation />,
      onClick: () => setIsTimeseriesTableShown((val) => !val)
    }];
  } else {
    if (user?.askrose) {
      mergedOptions.push({
        title: 'Convert to Ask',
        icon: <img
          src="/img/new_logo/svg/Roselogo-gradient.svg"
          alt="Convert to Ask"
          style={{marginRight: 10, width: 14, height: 14}}
        />,
        onClick: onConvertToAsk
      });
    }

    mergedOptions.push(
      {
        title: 'Convert to Markdown',
        icon: <IoLogoMarkdown style={{marginRight: 10}} />,
        onClick: onConvertToMarkdown
      },
      {
        title: 'Delete cell',
        icon: <IoTrash style={{marginRight: 10}} />,
        onClick: onRemoveModule
      },
      {
        hidden: true,
        title: 'Width',
        icon: <AiOutlineColumnWidth style={{marginRight: 10}} />,
        options: [
          {
            title: '100%',
            onClick: handleOnChangeWidthClick('100%')
          },
          {
            title: '66%',
            onClick: handleOnChangeWidthClick('66%')
          },
          {
            title: '50%',
            onClick: handleOnChangeWidthClick('50%')
          },
          {
            title: '33%',
            onClick: handleOnChangeWidthClick('33%')
          }
        ]
      }
    );
  }

  if (status === 'success' && !isReadOnly && view === 'edit') {
    const successOptions: OptionMenu[] = [
      {
        title: showResults ? 'Hide' : 'Show',
        icon: showResults ? <BiHide /> : <BiShow />,
        onClick: () => {
          setShowResults(!showResults);
        }
      }
    ];

    mergedOptions.push(...successOptions);
  }

  return (
    <ModuleLayout
      key={mod.key}
      width={RoseModule.getWidth(mod)}
      isReadOnly={isReadOnly}
      view={view}
    >
      <ModuleLayout.ToolbarTop />
      <ModuleLayout.Input id={mod.key}>
        <Input
          autoFocus={autoFocus}
          onChange={handleOnChange}
          value={code.value}
          onRun={handleOnRun}
          color={COLOR}
          currentMod={mod.key}
          updateCurrentModKey={updateCurrentModKey}
          disabled={isFetching}
        />
      </ModuleLayout.Input>

      <ModuleLayout.Options>
        <OptionsContainer>
          <Options options={mergedOptions} color={COLOR} />
          {view === 'view' || isReadOnly ? null : <IconContainer onClick={handleOnAddModule}>
            <AddIcon color={COLOR} size={24} />
          </IconContainer>}
        </OptionsContainer>
      </ModuleLayout.Options>

      <ModuleLayout.ToolbarBottom>
        <ToolbarContainer>
          <HelperText />
        </ToolbarContainer>
      </ModuleLayout.ToolbarBottom>

      <ModuleLayout.RoseObject>
        {isFetching ?
          <Spinner size={64} width="100%" /> :
          status !== 'success' || !showResults ? null :
            <ResultContainer parentWidth={resultLayoutRect?.width}>
              {errors.length > 0 &&
              <StyledErrorsContainer>
                {errors.map((err) =>
                  <StyledError key={err.message + mod.key}>
                    {err.message}
                  </StyledError>
                )}
              </StyledErrorsContainer>
              }
              {roseObjects.map((rose, idx) => <Result
                key={rose.type + mod.key + idx}
                result={rose}
                mod={mod}
                onModuleChanged={onModuleChanged}
                addModule={onAddModule}
                chartSettingsMap={chartSettingsMap}
                onChartSettingsChanged={handleOnChartSettingsChange}
                view={view}
                isTimeseriesTableShown={isTimeseriesTableShown}
              />
              )}
            </ResultContainer>
        }
      </ModuleLayout.RoseObject>
    </ModuleLayout>
  );
}

const StyledErrorsContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: red;
  word-break: break-all;
`;

const StyledError = styled.li`
  color: red;
`;

const ResultContainer = styled.div<{ parentWidth: number | undefined }>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  position: relative;
  max-width: ${(p) => p.parentWidth ? `${p.parentWidth}px` : '100%'};

  ${device.desktop} {
    max-width: 100%;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45px;
  position: relative;
`;

const AddIcon = styled(IoAdd)`
  fill: ${(p: { color: string }) => p.color};

  :hover {
    cursor: pointer;
  }
`;

const IconContainer = styled.span`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
